import React, { useState, useEffect } from "react";
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button, Modal} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

const columns = [
    { id: '1', label: 'Branch name', minWidth: 50,span: false },
    { id: '2', label: 'Address 1', minWidth: 50,span: false },
    { id: '3', label: 'GSTIN', minWidth: 50,span: false },
    { id: '4', label: 'Mobile', minWidth: 50,span: false },
    { id: '5', label: 'Email', minWidth: 50,span: false },
    { id: '6', label: 'Website', minWidth: 50,span: false },
    { id: '7', label: 'Whatsapp No.', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 100,
      span: true
    },
  ];



function Branch(){

    const [age, setAge] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [modal, setModal] = React.useState(false);
    const [source, setSource] = React.useState([]);


    const [brid, setBrid] = React.useState("");
    const [brname, setBrname] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [gst, setGst] = React.useState("");
    const [mobile, setMobile] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [website, setWebsite] = React.useState("");
    const [whatsap, setWhatsap] = React.useState("");
    const [brcode, setBrcode] = React.useState("");
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    useEffect(() => {
        getData()
    }, []);


    const getData = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Branch/GetBranchList?orderBy=Name&companyId=1&includeBlocked=False", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("branch view Response", responseJson)
             setSource(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }




    const onSubmit = () => {
        var token = localStorage.getItem("token")
        console.log("token", token)
        const data = {
            "brid": brid,
            "brname": brname,
            "braddresS1": address,
            "braddresS2": "",
            "braddresS3": "",
            "brcntid": 1,
            "brstid": 1,
            "brgstin": gst,
            "brmobile": mobile,
            "brmemail": email,
            "brwebsite": website,
            "brwts": whatsap,
            "brprfx": "",
            "brlact": "",
            "brlong": "",
            "brcode": brcode,
            "brcaption": "",
            "brcomid": 1,
            "brblocked": "",
            "brcashid": "",
            "brdistid": ""
          }

        console.log("save data", data)

        fetch(global.url + "api/Branch/SaveBranch", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            if(responseJson.error == false) {
                alert(responseJson.message)
                setModal(false)
                getData()
            }
            else{
                alert(responseJson.message)
            }
            
             console.log("Branch Response", responseJson)
        })
        .catch((error)=>{
            alert("error occured")
            console.log(error)
        })
    }

    const editClick =(
        branchid,
        address1,
        gst,
        mobile,
        email,
        website,
        whatsap,
        branchname,
        brcode
    ) => {
        setModal(true)
        setBrid(branchid)
        setAddress(address1)
        setBrname(branchname)
        setGst(gst)
        setMobile(mobile)
        setEmail(email)
        setWebsite(website)
        setWhatsap(whatsap)
        setBrcode(brcode)
    }

    const addClick =(
       
    ) => {
        setModal(true)
        setBrid("")
        setAddress("")
        setBrname("")
        setGst("")
        setMobile("")
        setEmail("")
        setWebsite("")
        setWhatsap("")
        setBrcode("")
    }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
                <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Branch</p>
                    <Button variant="primary" onClick={() => addClick()} >Add</Button>
                </div>
                <div className='bulk-table'>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                                    colSpan={column.span == true ? 2: 1}
                                >
                                    {column.label}
                                    
                                </TableCell>
                                ))}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {source
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                return (
                                    <TableRow  tabIndex={-1} key={row.code}>
                                        <TableCell>
                                            {row.brname}
                                        </TableCell>
                                        <TableCell>
                                            {row.braddresS1}
                                        </TableCell>
                                        <TableCell>
                                           {row.brgstin}
                                        </TableCell>
                                        <TableCell>
                                            {row.brmobile}
                                        </TableCell>
                                        <TableCell>
                                            {row.brmemail}
                                        </TableCell>
                                        <TableCell>
                                           {row.brwebsite}
                                        </TableCell>
                                        <TableCell>
                                            {row.brwts}
                                        </TableCell>
                                        <TableCell >
                                            <a href="javascript:void(0)"  onClick={() => editClick(
                                                    row.brid,
                                                    row.braddresS1,
                                                    row.brgstin,
                                                    row.brmobile,
                                                    row.bremail,
                                                    row.brwebsite,
                                                    row.brwts,
                                                    row.brname,
                                                    row.brcode
                                            )}>
                                                <FaIcons.FaEdit fontSize={20} color="blue" />
                                            </a>
                                        </TableCell>
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
                        </TableContainer>
                        <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={source.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
            </Paper>
            <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Branch Creation
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={brname} onChange={(e) => setBrname(e.target.value)} id="outlined-basic" label="Branch Name" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={brcode} onChange={(e) => setBrcode(e.target.value)} id="outlined-basic" label="Branch Code" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={address} onChange={(e) => setAddress(e.target.value)} id="outlined-basic" label="Address" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={gst} onChange={(e) => setGst(e.target.value)} id="outlined-basic" label="GSTIN" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={mobile} onChange={(e) => setMobile(e.target.value)} id="outlined-basic" label="Mobile" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={email} onChange={(e) => setEmail(e.target.value)} id="outlined-basic" label="Email" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={website} onChange={(e) => setWebsite(e.target.value)} id="outlined-basic" label="Website" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={whatsap} onChange={(e) => setWhatsap(e.target.value)} id="outlined-basic" label="Whatsapp" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs={5}>
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                                <FormControlLabel control={
                                <Checkbox  />
                                } label="Enabled" />
                          </FormControl>
                    </Col>
                    <Col xs ={12} style={{marginTop:30}}>
                      <Button onClick={() => onSubmit()} style={{float: 'right'}} variant="primary">Submit</Button>
                      <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="danger">Cancel</Button>
                    </Col>
                    
                  </Row>
              </Modal.Body>
           </Modal>
        </div>
    );
}

export default Branch;