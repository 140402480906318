import React, {useState, useEffect,useRef} from 'react';
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button, Modal} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
// import Loader from '../loader/loader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TimePicker from '@mui/lab/TimePicker';
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { connect } from "react-redux";
import ReactToPrint from 'react-to-print';
import { Rings  } from  'react-loader-spinner';
import logo from '../../assets/images/logo.jpeg';

const columns = [
    { id: '1', label: 'Sl No#', minWidth: 50,span: false },
    { id: '1', label: 'Invoice', minWidth: 50,span: false },
    { id: '2', label: 'Location', minWidth: 50,span: false },
    { id: '3', label: 'Building', minWidth: 50,span: false },
    { id: '4', label: 'Street', minWidth: 50,span: false },
    { id: '5', label: 'Zone', minWidth: 50,span: false },   
    { id: '6', label: 'Mobile', minWidth: 50,span: false },
    { id: '7', label: 'Rate', minWidth: 50,span: false },
    { id: '8', label: 'Item, Colour, Size, Quantity', minWidth: 50,span: false },  
    // { id: '9', label: 'Description', minWidth: 50,span: false },
    { id: '10', label: 'By', minWidth: 50,span: false },
    { id: '11', label: 'Remarks', minWidth: 50,span: false },  
    { id: '7', label: 'Order Time', minWidth: 50,span: false },
  ];

 

  function Salesbilllist({branchid, branchdetails}) {
    const componentRef = useRef();
    const [age, setAge] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [source, setSource] = React.useState([])
    const [modal, setModal] = React.useState(false)
    const [detailstable, setDetailstable] = React.useState([])
    const [grandtotal, setGrandtotal] = useState(0);

    const [loader, setLoader] = useState(false);
    

    const [counterlist, setCounterlist] = React.useState([]);
    const [counter, setCounter] = useState("");

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();


    const [date1, setDate1] = React.useState(year + '-' + month + '-' + date);
    const [date2, setDate2] = React.useState(year + '-' + month + '-' + date);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const counterChange = (event) => {
        setCounter(event.target.value)
      }

    useEffect(() => {
        getData()
        getCounter()
    }, [branchid]);

    const onFilter =()=> {

    }

    const getCounter = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Counter/GetCounterlist?companyId=1&branchId="+ branchid + "&includeBlocked=false", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("counterlist response", responseJson)
              setCounterlist(responseJson)
          })
          .catch((error)=>{
              console.log(error)
          })
      }


    const getData = () => {
        setLoader(true)
        var token = localStorage.getItem("token")
        var data = {
            "companyId": 1,
            "branchId": branchid,
            "fromDate": date1,
            "toDate": date2,
            "createdBy": global.userid,
            "billno": "",
            "counterId": counter,
            "userId": "",
            "soldItemsOnly": 9,
            "accId": 10
          }
        fetch(global.url + "api/SalesReport/RunningBillList", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),  
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setLoader(false)
             console.log("api/SalesReport/RunningBillList", responseJson)
             setSource(responseJson)
        })
        .catch((error)=>{
            setLoader(false)
            console.log(error)
        })
    }


    useEffect(() => {
        var totalAmount = 0;
        for (var i = 0; i < source.length; i++) {
            totalAmount += parseFloat(source[i].saamount)
        }
       setGrandtotal(totalAmount.toFixed(2))
    }, [source]);

    const onCancelClick = (item) => {
        if(window.confirm('Are you Want to cancel?')){
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Sales/CancelSalesBill?vid=" + item + "&userId=" + global.userid, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
           
            getData()
        })
        .catch((error)=>{
           
            console.log(error)
        })}
    }

    const onViewclick = (item) => {
        setDetailstable(item)
        setModal(true)
    }

    const onDeleteclick = (id, vid) => {
        if(window.confirm('Are you Want to delete?')){
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Sales/CancelSalesItemsByDtId?dtId=" + id + "&vid=" + vid + "&userId=" +  global.userid, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
           if(responseJson.error == false) {
               alert(responseJson.message)
               window.location.reload()
           }
           else{
            alert(responseJson.message)
           }
           console.log("response", responseJson)
        })
        .catch((error)=>{
           
            console.log(error)
        })}
    }
    const formatDate = (datestring) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
        //   console.log("timeString12hr", timeString12hr)

          var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          return formattedDate; //03/02/2021
    }

      return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2 }}>
            <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>Running Bill list</p> 
            <Row>
                    <Col xs={12}  className='columnborder'>
                        <div>
                            <Row>
                            <Col sm={6} lg={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="From Bill Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setDate1(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                            <Col sm={6} lg={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="To Bill Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setDate2(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                {/* <Col sm={6} lg={2}>
                                        <FormControl fullWidth sx={{width: '100%',  mt: 3 }}>
                                                <InputLabel id="demo-simple-select-label">Counter</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={counter}
                                                label="Age"
                                                onChange={(event) => counterChange(event)}
                                                size="small"
                                                >
                                                    {
                                                        counterlist.map((i, k) => {
                                                            return(
                                                                <MenuItem value={i.ctrid}>{i.ctrname}</MenuItem>
                                                            )
                                                        
                                                        })
                                                    }
                                                </Select>
                                        </FormControl>
                            </Col> */}
                               
                                <Col xs={12} md={2}>
                                    <div style={{justifyContent: 'center', display: 'flex'}}>
                                        {
                                            loader ?
                                            <Rings
                                                height="50"
                                                width="50"
                                                color='#0d6efd'
                                                ariaLabel='loading'
                                            />: 
                                            <Button onClick={getData} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 17, width: '50%'}} variant="contained">Search</Button>
                                        }
                                        </div>
                                </Col>
                                {/* <Col xs={12} md={2}>
                                    <div style={{ justifyContent: 'center', display: 'flex'}}>
                                        <Button  onClick={() => onPrint()}  style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">print</Button>
                                    </div>
                                </Col> */}
                                <Col xs={12} md={4} >
                                    <ReactToPrint
                                        trigger={() => <Button style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 17, width: '50%'}} variant="contained">Print this out!</Button>}
                                        content={() => componentRef.current}
                                    />
                                </Col>
                            </Row>
                           
                        </div>
                    </Col> 
                </Row>
                <div >
                {/* {loader ? <Loader /> : */}
                    <TableContainer sx={{ maxHeight: 440, marginTop: 2 }}>
                    <div  className='section-to-print' ref={componentRef} style={{wwidth: '95%'}}>
                        <Table id="table-to-xls"  stickyHeader aria-label="sticky table">
                            <TableHead className="tableheaderexe">
                                <TableRow style={{height: 80}} >
                                    <TableCell colSpan={8}  style={{padding: 0}}>
                                        <Row>
                                            <img src={logo} style={{width: 100, position: 'absolute', top: 0}}/>
                                            
                                            <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                                            <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                                            </Col>
                                            <p className='printdate' style={{ position: 'absolute', left: '85%'}}>{formatDate(new Date)}</p>
                                        </Row>
                                    </TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell colSpan={8} style={{padding: 0}}>
                                        <div style={{display: 'flex', justifyContent: 'center', fontWeight: 600}} className='captiontable'>
                                            RUNNING BILL LIST FROM {date1.split("-").reverse().join("-")} TO  {date2.split("-").reverse().join("-")}
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '10%'}}>
                                    SL No
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '10%'}}>
                                    OT NO
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '10%'}}>
                                    Party
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '10%'}}>
                                    WAITER CODE
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '10%'}}>
                                    TABLE/ ROOM NO
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '10%'}}>
                                    TOTAL ITEMS
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '15%'}}>
                                    AMOUNT
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '20%'}}>
                                    DATE
                                    </TableCell>
                                    <TableCell colSpan={2} className='tablecellPrint noprint' style={{fontWeight: 'bold', width: '15%'}}>
                                    VIEW
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{marginTop: 20}} className="bodytable">
                            {
                                source.map((item, index) => {
                                    return (
                                        <TableRow className='tablerowPrint'  tabIndex={-1} key={item.code}>
                                            <TableCell className='tablecellPrint' >
                                            {index+1}
                                            </TableCell>
                                            <TableCell className='tablecellPrint'  >
                                            {item.billno}
                                            </TableCell>
                                            <TableCell className='tablecellPrint'  >
                                            {item.saaccname}
                                            </TableCell>
                                            
                                            <TableCell className='tablecellPrint' >
                                            {item.waiterid}
                                            </TableCell>
                                            <TableCell className='tablecellPrint' >
                                            {item.tableNumber}/{item.roomNumber}
                                            </TableCell>
                                            <TableCell  className='tablecellPrint' >
                                            {item.totalItems}
                                            </TableCell>
                                            <TableCell style={{textAlign: 'right'}} className='tablecellPrint' >
                                            {item.saamount.toFixed(2)}
                                            </TableCell>
                                            <TableCell style={{textAlign: 'right'}} className='tablecellPrint' >
                                            {item.billDate}
                                            </TableCell>
                                            <TableCell style={{textAlign: 'right'}} className='tablecellPrint noprint' >
                                            <Button style={{backgroundColor: '#03a5fc', color: '#fff', fontSize:10}} variant="contained" onClick={() => onCancelClick(item.vid)}>Cancel All</Button>
                                            </TableCell>
                                            <TableCell style={{textAlign: 'right'}} className='tablecellPrint noprint' >
                                            <Button style={{backgroundColor: '#03a5fc', color: '#fff', fontSize:10}} variant="contained" onClick={() => onViewclick(item.details)}>View</Button>
                                            </TableCell>
                                        </TableRow>
                                
                                );
                            })}
                            <TableRow className='tablerowPrint'>
                                <TableCell colSpan={6} style={{fontWeight: 600}} className='tablecellPrint' >
                                    Total
                                </TableCell>
                                <TableCell style={{textAlign: 'right', fontWeight: 600}} className='tablecellPrint' >
                                    {grandtotal}
                                </TableCell>
                                <TableCell  className='tablecellPrint' >
                                   
                                </TableCell>
                                <TableCell colSpan={2} className='tablecellPrint noprint' >
                                   
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        </Table>
                        </div>
                    </TableContainer>
                         
                </div>
            </Paper> 
            <Modal
                    size="lg"
                    show={modal}
                    onHide={() => setModal(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                    >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                        Details
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                        <Table responsive bordered>
                        <thead>
                        <tr>
                            <th>OT</th>
                            <th>Code</th>
                            <th>Product Name</th>
                            
                            <th>Quantity</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                                detailstable.map((i, k) => {
                                    return(
                                        <tr>
                                        <td>{i.barcode}</td>
                                        
                                            <td>{i.procode}</td>
                                            <td>{i.proname}</td>
                                           
                                            <td>{i.dtqty}</td>
                                            <td>{i.dtamount}</td>
                                            <td><Button style={{backgroundColor: '#03a5fc', color: '#fff', fontSize:10}} variant="contained" onClick={() => onDeleteclick(i.dtid, i.vid)}>Delete</Button></td>
                                        </tr>
                                    )
                                })
                            }
                        
                    </tbody>
                            </Table>
                        </Row>
                    </Modal.Body>
           </Modal>
        </div>
      );
  }

  const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(Salesbilllist);
