import React, {useState, useEffect, useRef} from 'react';
import Paper from '@mui/material/Paper';
import { Table , Row, Col, Modal, Container} from "react-bootstrap";
import TextField from '@mui/material/TextField';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { useParams, useHistory } from 'react-router-dom';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import * as IconName from "react-icons/bs";
import * as GI from "react-icons/gi";
import * as BI from "react-icons/bi";
import * as RI from "react-icons/ri";
import { connect } from "react-redux";
import { Rings  } from  'react-loader-spinner';
import {
	getAllProduct
  } from "../../redux/Shopping/shopping-actions";
import Searchlist from "../../components/Searchlist/searchlist";

function Purchase({branchid, getAllProduct, productlist}) {
    const {id} = useParams()
    const history = useHistory()
    const [modal, setModal] = useState(false);
    const [textArray, setTextarray] = React.useState([{key: "", value: ""}])
    const [selectedIndex, setSelectedindex] = React.useState("")
    const [code, setCode] = useState("");
    const hasResults = code.length > 0;
    const [loader, setLoader] = useState(false);

    const [docdate, setDocdate] = React.useState()


    const [billtypelist, setBilltypelist] = React.useState([]);
    const [docnum, setDocnum] = React.useState("");
    const [docno, setDocno] = React.useState("");
    const [vid, setVid] = React.useState(0);
    const [puid, setPuid] = React.useState(0);
    const [pucreatedon, setPucreatedon] = React.useState("");

    const [billType, setBilltype] = React.useState("");
    const [suppliername, setSuppliername] = React.useState("");
    const [supplierid, setSupplierid] = React.useState("");
    const [suppliercode, setSuppliercode] = React.useState("");
    const [supplierlist, setSupplierlist] = React.useState([]);
    const [groupid, setGroupid] = React.useState("")

    const [warehouse, setWarehouse] = React.useState("")
    const [permit, setPermit] = React.useState("")
    const [permitdate, setPermitdate] = React.useState("")
    const [chalanno, setChalanno] = React.useState("")
    const [chalandate, setChalandate] = React.useState("")
    const [subtotal, setSubtotal] = React.useState(0)
    const [tax, setTax] = React.useState("")
    const [place, setPlace] = React.useState("")
    const [licensy, setLicency] = React.useState("")
    const [invoice, setInvoice] = React.useState("")
    const [total, setTotal] = React.useState(0)
    const [totaltax, setTotaltax] = React.useState(0)
    const [editmode, setEditmode] = React.useState(false);

    const [suppliermodal, setSuppliermodal] = React.useState(false)
    const [productname, setProductname] = useState("")
    const hasResultsProduct = productname.length > 0;

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();
    
    
    
    
    
    
    const resultsRef = useRef();

    const [tablerow, setTablerow] = useState([{
    dtid: 0,
    dtprdid: 0,
    vid: vid,
    dtprdbatchid: 0,
    dtunitid: 0,
    dtqty: 0.0,
    dtprice: 0.0,
    dtamount: 0.0,
    dtdiscper: 0.0,
    dtdiscount: 0.0,
    dtgross: 0.0,
    dtsgst: 0.0,
    dtcgst: 0.0,
    dtigst: 0.0,
    dttax: 0.0,
    dtsgstper: 0.0,
    dtcgstper: 0.0,
    dtigstper: 0.0,
    dttaxper: 0.0,
    dttaxid: 0,
    dttotal: 0.0,
    dtremarks: "",
    dtgdnid: 0,
    dtcost: 0,
    dtsarate: 0,
    dtmrp: 0,
    dtrqstqty: 0,
    barcode: "",
    dtamteditable: true,
    caseno: 0,
    bottle: 0,
    prdname: "",
    prdcode: "",
    unitname: "",
    gdnname: "",
    batchLength: 0,
    bottlepercase: 0,
    sl: 0}]);

    const inputRef = useRef();

    const handleOnChange = (event, key) => {
        console.log("event.currentTarget.value", event.currentTarget.value)
        var textArray1 = [...tablerow]
        textArray1[key].prdcode = event.currentTarget.value;
        setTablerow(textArray1)
        setSelectedindex(key)
        setCode(event.currentTarget.value);
        setProductname(event.currentTarget.value)
        // getSearch(event.currentTarget.value, key)
    }

    useEffect(() => {
        inputRef.current.focus();

        getAllProduct()
      }, []);

    const getSearch = (text, key) => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Products/GetMiscProductByCode?branchId=" + branchid + "&code=" + text, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("code search Response", responseJson)
             if(responseJson.error != true){
                var input = [...tablerow]
                input[key].prdcode = responseJson.code;
                input[key].prdname = responseJson.productname;
                input[key].dtprdid = responseJson.productid;
                input[key].bottlepercase = responseJson.bottlepercase;
                input[key].pprice = responseJson.pprice;
                console.log("input", input)
                setTablerow(input)
                setProductname("")
             }
             
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    useEffect(() => {
        getDate()
        inputRef.current.focus();
      }, []);

    const caseChange = (event, key, bpercase) => {
        var input = [...tablerow]
        if(event.target.value == ""){
            input[key].caseno = 0
            input[key].dtqty = (0  * bpercase)+ input[key].bottle
        }
        else{
            input[key].caseno = parseFloat(event.target.value) 
            input[key].dtqty = (parseFloat(event.target.value)  * bpercase)+ input[key].bottle
        }
        input[key].dtamount = (input[key].pprice/bpercase * input[key].dtqty).toFixed(2)
        input[key].dttotal = (input[key].pprice/bpercase * input[key].dtqty).toFixed(2)
        setTablerow(input)
    }

    const bottleChange = (event, key, bpercase) => {
        var input = [...tablerow]
        if(event.target.value == ""){
            input[key].bottle = 0
            input[key].dtqty = 0 + (input[key].caseno * input[key].bottlepercase)
        }
        else{
            input[key].bottle = parseFloat(event.target.value)
            input[key].dtqty = parseFloat(event.target.value) + (input[key].caseno * input[key].bottlepercase)
        }
        input[key].dtamount = (input[key].pprice/bpercase * input[key].dtqty).toFixed(2)
        input[key].dttotal = (input[key].pprice/bpercase * input[key].dtqty).toFixed(2)
        setTablerow(input)
    }

    // const qtyChange = (event, key) => {
    //     var input = [...tablerow]
    //     input[key].dtqty = parseFloat(event.target.value)
    //     input[key].dtamount = input[key].pprice * input[key].dtqty
    //     input[key].dttotal = input[key].pprice * input[key].dtqty
    //     setTablerow(input)
    // }

    const priceChange = (event, key, bpercase) => {
        var input = [...tablerow]
        input[key].pprice = event.target.value
        input[key].dtamount = event.target.value * input[key].dtqty
        input[key].dttotal = event.target.value * input[key].dtqty
        
        setTablerow(input)
    }


      useEffect(() => {
        if(id == "add") {
            getBilltype()
            setEditmode(false)
            
        }
        else{
           getBilltype()
           getDetailsbyid(id)
           setEditmode(true)
        }
      }, [branchid]);

      const getDetailsbyid = (id) => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Purchase/GetMiscPurchaseById?id=" + id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            console.log("GetmiscellaneusById response", responseJson)
                setTablerow(responseJson.details)
              setVid(responseJson.vid)
              setPuid(responseJson.puid)
              setDocno(responseJson.pudocno)
              setDocnum(responseJson.pudocnum)
                setBilltype(responseJson.puformid)
              setSuppliercode(responseJson.puacccode)
              setSupplierid((responseJson.pucaccid))
                setInvoice(responseJson.puinvoiceno)
              setDocdate(responseJson.pudocdate.split('T')[0])
            //   setPermit(responseJson.permit)
            //   setPermitdate(responseJson.permitdate)
            //   setChalanno(responseJson.challanno)
            //   setChalandate(responseJson.challandate.split('T')[0])
                setPucreatedon(responseJson.pucreatedon)
          })
          .catch((error)=>{
              console.log(error)
          })
            
      }

      const getBilltype = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/BillSettings/GetBillTypeByForm?companyId=1&branchId=" + branchid + "&formType=MPU", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("billSettigsList response", responseJson)
              setBilltypelist(responseJson.billSettigsList)
          })
          .catch((error)=>{
              console.log(error)
          })
      }


    const taxChange = (event, key, bpercase) => {
        var input = [...tablerow]
        input[key].dttaxper = event.target.value
        input[key].dttax = event.target.value * ((input[key].dtamount)/100)
        input[key].dttotal = event.target.value * ((input[key].dtamount)/100) + (input[key].dtamount)
        setTablerow(input)
    }
    const onSave = () => {
        if(supplierid == "") {
            return alert("Supplier not selected")
        }
        if(billType == ""){
            return alert("Billtype not selected")
        }
        tablerow.forEach(function(v){ delete v.$id });
        var token = localStorage.getItem("token")
        console.log("token", token)
        setLoader(true)
        const data = {
            "puid": puid,
            "vid": vid,
            "puformid": billType,
            "pudocdate": docdate,
            "pudocno": docno,
            "pudocnum": docnum,
            "pucaccid": supplierid,
            "puaccname": suppliername,
            "puaccadD1": "",
            "puaccadD2": "",
            "puaccadD3": "",
            "pustateid": "",
            "pudistid": "",
            "pucomid": 1,
            "pubranchid": branchid,
            "pumob": "",
            "puamount": total,
            "puitemdisc": "",
            "pugross": "",
            "pusgst": "",
            "pucgst": "",
            "puigst": "",
            "putax": "",
            "pudiscper": "",
            "pudiscount": "",
            "pusubtotal": "",
            "punettotal": total,
            "pucreatedon": pucreatedon,
            "pucreatedby": global.userid,
            "puupdatedby": global.userid,
            "puupdatedon": "",
            "puduedate": "",
            "puremarks": "",
            "pucessper": 1.0,
            "pucessamount": 1.0,
            "putotal": 1.0,
            "pugodownid": 1,
            "puacccode": suppliercode,
            "pugstin": "",
            "pustockadded": true,
            "puinvoiceno": invoice,
            "povid": 37,
            "pumode": 38,
            "puinternational": true,
            "warehouse": "",
            "licensy": "",
            "place": "",
            "permit": "",
            "permitdate": "",
            "challanno": "",
            "challandate": "",
            "details": tablerow
          }

        console.log("save data", data)

        fetch(global.url + "api/Purchase/SaveMiscPurchase", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("responseJson", responseJson)
            setLoader(false)
            if(responseJson) {
                if(editmode == true){
                    alert(responseJson.message)
                    // history.push("/Reports/Miscellaneous") 
                }else{
                    alert("Success")
                    window.location.reload()
                }
                
            }
            
            
        })
        .catch((error)=>{
            setLoader(false)
            alert("error occured")
            console.log(error)
        })
    }

    const billtypeChange = (event) => {
        setBilltype(event.target.value);
        getInvoiceno(event.target.value)
      };

      const getInvoiceno = (id) => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/BillSettings/GetNextBillNo?billId=" + id , {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              setDocno(responseJson.billprefix + responseJson.billno)
              setDocnum(responseJson.billno)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const clickSupplier = () => {
          setSuppliermodal(true)
          var token = localStorage.getItem("token")
          fetch(global.url + "api/Settings/GetMainSettings?companyId=1&branchId=" + branchid , {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              setGroupid(responseJson.grpsuppid)
              getSupplierlist(responseJson.grpsuppid)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const getSupplierlist = (id) => {
          var token = localStorage.getItem("token")
        fetch(global.url + "api/Accounts/GetLedgerByGroupId?companyId=1&branchId=" + branchid + "&id=" + id + "&limit=0&searchBy=Name&searchKey=" + "" , {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log(responseJson)
              setSupplierlist(responseJson)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const selectSupplier = (id,code) => {
        setSupplierid(id)
        setSuppliercode(code)
        setSuppliermodal(false)
        setSupplierlist([])
      }

      const getTotalammount = () => {
        var totalAmount = 0;
        var tax = 0;
        for (var i = 0; i < tablerow.length; i++) {
          totalAmount += parseFloat(tablerow[i].dttotal)
          tax += parseFloat(tablerow[i].dttax)
       }
          setSubtotal(totalAmount.toFixed(2))
          setTotal(totalAmount.toFixed(2))
          setTotaltax(tax.toFixed(2))
    }

    useEffect(() => {
        getTotalammount()
       
      },[tablerow])

      const addRow = () => {
        var input = [...tablerow]
        setTablerow([...tablerow, 
            {
                dtid: 0,
                dtprdid: 0,
                vid: vid,
                dtprdbatchid: 0,
                dtunitid: 0,
                dtqty: 0.0,
                dtprice: 0.0,
                dtamount: 0.0,
                dtdiscper: 0.0,
                dtdiscount: 0.0,
                dtgross: 0.0,
                dtsgst: 0.0,
                dtcgst: 0.0,
                dtigst: 0.0,
                dttax: 0.0,
                dtsgstper: 0.0,
                dtcgstper: 0.0,
                dtigstper: 0.0,
                dttaxper: 0.0,
                dttaxid: 0,
                dttotal: 0.0,
                dtremarks: "",
                dtgdnid: 0,
                dtcost: 0,
                dtsarate: 0,
                dtmrp: 0,
                dtrqstqty: 0,
                barcode: "",
                dtamteditable: true,
                caseno: 0,
                bottle: 0,
                prdname: "",
                prdcode: "",
                unitname: "",
                gdnname: "",
                batchLength: 0,
                bottlepercase: 0,
                sl: 0
            }
        ])
    }

    const onRowdelete = (index) => {
        var input = [...tablerow]
        input.splice(index, 1)
        setTablerow(input)
    }

    const getDate = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/CounterStock/GetTransactionDate?branchId=" + branchid, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("GetTransactionDate response", responseJson)
              setDocdate(responseJson)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const listClickname = (text, key) => {
            getSearch(text, key)
        }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
            <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Miscellaneous New Purchase</p>
            </div>
            <Row>
                <Col sm={4} style={{marginTop:30}}>
                    <FormControl sx={{  width: '100%' }}>
                            <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Bill Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={billType}
                                label="Age"
                                onChange={billtypeChange}
                                size="small"
                                disabled={editmode ? true: false}
                            >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {billtypelist.map((row, index) => {
                        return (
                            <MenuItem value={row.btypeid}>{row.formname}</MenuItem>
                        )})}
                            </Select>
                    </FormControl>
                </Col>
                <Col sm={12}>
                    <Row>
                        {/* <Col sm={6} lg={2} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField value={warehouse} onChange={(e) => setWarehouse(e.target.value)}  label="Warehouse" id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col> */}
                        <Col sm={6} lg={2}>
                            <FormControl sx={{ width: '100%' }} style={{marginTop:30}}>
                                <TextField
                                    disabled
                                    id="datetime-local"
                                    value={docdate}
                                />
                            </FormControl>
                        </Col>
                        <Col sm={6} lg={2} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField  value={invoice} onChange={(e) => setInvoice(e.target.value)} label="Invoice NO" id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col sm={6} lg={2} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField disabled value={suppliercode}  label="Supplier" id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col sm={6} lg={3} style={{marginTop:30}}>
                            <Button onClick={() => clickSupplier()} variant="outlined">SELECT SUPPLIER</Button>
                        </Col>
                        {/* <Col sm={6} lg={2} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField value={licensy} onChange={(e) => setLicency(e.target.value)} label="Licensy" id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col> */}
                        {/* <Col sm={6} lg={2} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField value={place} onChange={(e) => setPlace(e.target.value)}  label="Place" id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col> */}
                    </Row>
                </Col>
            </Row> 
            <Row style={{marginTop: 20}}>
                <Col xs={12}>
                    <Table bordered responsive>
                        <thead>
                            <tr>
                                <th>Sl No.</th>
                                <th>Code</th>
                                <th>Name</th>
                                <th>Case</th>
                                <th>Bottle</th>
                                <th>Quantity</th>
                                <th>TOTAL</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                tablerow.map((item, index) => {
                                    return(
                                        <tr>
                                            <td>{index+1}</td>
                                            <td> 
                                                <input style={{width: '100%'}} autocomplete="off" ref={inputRef} type="text" name="query" value={item.prdcode} onChange={(event) => handleOnChange(event, index)} />
                                                {hasResultsProduct && selectedIndex === index  && (
                                                       <Searchlist hasResultsProduct={hasResultsProduct} productlist={productlist} productname={productname} inputRef={inputRef} listClickname={listClickname} index={index}/>
                                                    )}
                                            </td>

                                            <td>{item.prdname}</td>
                                            <td><input style={{width: '100%'}} value={item.caseno} autocomplete="off"  type="number" onChange={(event) => caseChange(event, index, item.bottlepercase)}/></td>
                                            <td><input style={{width: '100%'}} value={item.bottle} autocomplete="off"  type="number"  onChange={(event) => bottleChange(event, index, item.bottlepercase)}/></td>
                                            <td><input disabled style={{width: '100%'}} value={item.dtqty} autocomplete="off"  type="number" /></td>
                                            <td>{item.dttotal}</td>
                                            <td><Button onClick={() => onRowdelete(index)} variant="outlined">Delete</Button></td>
                                        </tr>
                                    )
                                })
                            }
                            
                            
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                    
                    <Col xs={6} >
                        <ul className="list-inline">
                            <li><Button onClick={() => addRow()} variant="outlined" style={{marginTop: 30}}>Add New</Button></li>
                        </ul>
                    </Col>
                </Row>
            <div>
                
                <Row style={{marginTop: 20}}>
                    {/* <Col xs={12} md={2} style={{marginTop: 30}}>
                        <FormControl sx={{width: '100%' }}>
                            <TextField value={permit} onChange={(e) => setPermit(e.target.value)} id="outlined-basic" label="Permit No" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={2} style={{marginTop: 30}}>
                            <FormControl sx={{ width: '100%' }}>
                                <TextField
                                    id="datetime-local"
                                    label="Date"
                                    type="datetime-local"
                                    defaultValue="2017-05-24T10:30"
                                    sx={{ width: '100%' }}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                    onChange={(newValue) => {
                                        setPermitdate(newValue.target.value);
                                    }}
                                />
                            </FormControl>
                    </Col>
                    <Col xs={12} md={2} style={{marginTop: 30}}>
                        <FormControl sx={{width: '100%' }}>
                            <TextField value={chalanno} onChange={(e) => setChalanno(e.target.value)} id="outlined-basic" label="Chalan No" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={2} style={{marginTop: 30}}>
                        <FormControl sx={{width: '100%' }}>
                            <TextField
                                id="datetime-local"
                                label="Date"
                                type="datetime-local"
                                defaultValue="2017-05-24T10:30"
                                sx={{ width: '100%' }}
                                InputLabelProps={{
                                shrink: true,
                                }}
                                onChange={(newValue) => {
                                    setChalandate(newValue.target.value);
                                }}
                            />
                        </FormControl>
                    </Col> */}
                    <Col xs={12} md={2} style={{marginTop: 30}}>
                        <FormControl sx={{width: '100%' }}>
                            <TextField value={subtotal} disabled  id="outlined-basic" label="Total" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    {/* <Col xs={12} md={2} style={{marginTop: 30}}>
                        <FormControl sx={{width: '100%' }}>
                            <TextField value={totaltax} disabled id="outlined-basic" label="Sales Tax" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col> */}
                    <Col xs={12} md={2} style={{marginTop: 30}}>
                        <FormControl sx={{width: '100%' }}>
                            <TextField value={total} disabled id="outlined-basic" label="Net Total" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>

                    <Col xs={6} >
                        <div style={{justifyContent: 'center', display: 'flex'}}>
                            {
                                loader ?
                                <Rings
                                    height="50"
                                    width="50"
                                    color='#0d6efd'
                                    ariaLabel='loading'
                                />:<Button onClick={() => onSave()} variant="outlined" style={{marginTop: 30}}>Save</Button>
                            }
                        </div>
                    </Col>
                </Row>
            </div>
            
          </Paper> 
          <Modal
              size="lg"
              show={suppliermodal}
              onHide={() => setSuppliermodal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                  <div>
                      <Container>
                        <Row>
                            <Col xs={12}>
                                <p style={{fontSize: 16, marginTop: 10, marginBottom: 10}}>Suppliers</p>
                                <Table bordered responsive>
                                <thead>
                                        <tr>
                                            <th>Sl No.</th>
                                            <th>Supplier</th>
                                            <th>Supplier Code</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            supplierlist.map((i, k) => {
                                                return (
                                                    <tr onClick={() => selectSupplier(i.accid,i.acccode)}>
                                                        <td>{k+1}</td>
                                                        <td>{i.acccode}</td>
                                                        <td>{i.accname}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        
                                    </tbody>
                                </Table>
                            </Col>
                            
                        </Row>
                      </Container>
                  </div>
              </Modal.Body>
           </Modal>
      </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
		getAllProduct: () => {
			dispatch(getAllProduct())
		},
    };
  };

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
        productlist: state.shop.productlist
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Purchase);