import React from 'react'
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

const columns = [
    { id: '1', label: 'Discription', minWidth: 50,span: false },
    { id: '2', label: 'Discount %', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Edit',
      minWidth: 100,
      span: true
    },
  ];
const source = [{}, {}]

function Incentivemaster(){

    const [age, setAge] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>Incentive master</p> 
                <div>
                    <Row>
                        <Col xs={12} md={4}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                    <TextField
                                        id="datetime-local"
                                        label="Start Date"
                                        type="datetime-local"
                                        defaultValue="2017-05-24T10:30"
                                        sx={{ width: 250 }}
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                    />
                                </FormControl>
                        </Col>
                        <Col xs={12} md={4}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField
                                    id="datetime-local"
                                    label="End date"
                                    type="datetime-local"
                                    defaultValue="2017-05-24T10:30"
                                    sx={{ width: 250 }}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                            </FormControl>
                        </Col>
                        
                    </Row>
                    <Row style={{marginTop: 20}}>
                        <Col xs={12} md={4}>
                            <FormControl fullWidth sx={{width: '100%' }}>
                                {/* <InputLabel id="demo-simple-select-label">Source</InputLabel> */}
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={age}
                                label="OYO TYPE"
                                onChange={handleChange}
                                size="small"
                                >
                                <MenuItem value={10}>OYO SINGLE</MenuItem>
                                <MenuItem value={20}>OYO SINGLE</MenuItem>
                                <MenuItem value={30}>OYO SINGLE</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField id="outlined-basic" label="TARIFF FROM" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>

                        <Col xs={12} md={4}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField id="outlined-basic" label="TARIFF TO" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField id="outlined-basic" label="Commision(100%) amount" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>

                        <Col xs={12} md={4}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField id="outlined-basic" label="Commision(75% - 99%) amount" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={4}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField id="outlined-basic" label="Commision(50% - 74%) amount" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4}>
                            <div style={{width: '50%', justifyContent: 'center', display: 'flex'}}>
                                <Button style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20}} variant="contained">Save</Button>
                            </div>
                        </Col>
                    </Row>
                </div>
                {/* <div style={{height: 2, width: '100%', backgroundColor: '#d3d3d3', marginTop: 30}}></div>
                <div className='bulk-table'>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                                    colSpan={column.span == true ? 2: 1}
                                >
                                    {column.label}
                                    
                                </TableCell>
                                ))}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {source
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                return (
                                    <TableRow  tabIndex={-1} key={row.code}>
                                        <TableCell>
                                            Wizard
                                        </TableCell>
                                        <TableCell>
                                           10.00
                                        </TableCell>
                                        <TableCell >
                                            <a href="javascript:void(0)">
                                                <FaIcons.FaEdit fontSize={20} color="blue" />
                                            </a>
                                        </TableCell>
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
                        </TableContainer>
                        <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={source.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div> */}
            </Paper>
        </div>
    );
}

export default Incentivemaster;