import React, {useState, useEffect, useRef} from 'react'
import './DuplicateBill.css'
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Form, Button, Modal, Table} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { connect } from "react-redux";
import ReactToPrint from 'react-to-print';
import logo from '../../../assets/images/logo.jpeg';

function DuplicateBill({branchid, branchdetails, isAdmin}){
    const componentRef = useRef();

    const current = new Date();
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;

    const [search, setSearch] = React.useState('');
    const [value, setValue] = React.useState('')
    const [modal, setModal] = React.useState(false)

    const [branchdetail, setBranchDetail] = React.useState({});
    const [details, setDetails] = React.useState({});
    const [customerDetails, setCustomerDetails] = React.useState({});
    const [roomDetails, setRoomDetails] = React.useState([]);
    const [billDetails, setBillDetails] = React.useState([]);
    const [billDetail, setBillDetail] = React.useState([]);
    const [taxDetails, setTaxDetails] = React.useState({});
    const [username, setUsername] = React.useState("");

    const firstletter = details.docno
    const banquet = firstletter && firstletter.length > 0 ? firstletter.charAt(0) : ''

    useEffect(() => {
        var username1 = localStorage.getItem("username")
        setUsername(username1)
    }, []);
    

    const handleChange = (event) => {
        setSearch(event.target.value);
    };

    const formatDate = (datestring) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
        //   console.log("timeString12hr", timeString12hr)

          var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          return formattedDate; //03/02/2021
    }

    const formatDate2 = (datestring) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
        //   console.log("timeString12hr", timeString12hr)

          var formattedDate =  day+"/"+month+"/"+year ;
          return formattedDate; //03/02/2021
    }

    const searchClick = () => {
        if(search == 'billno') {
            var token = localStorage.getItem("token")
            fetch(global.url + "api/Booking/GetDuplicateBillByBillNo?branchId=" + branchid + "&billNo=" + value, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }, 
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                 console.log("duplicate bill no.", responseJson)
                 if(responseJson.message) {
                    alert("Not Exist")
                 }
                 else{
                    setModal(true)
                    setBranchDetail(responseJson.branchDetails)
                    setDetails(responseJson)
                    setCustomerDetails(responseJson.customerDetails)
                    setRoomDetails(responseJson.roomDetails)
                    setBillDetails(responseJson.billDetails[0])
                    setBillDetail(responseJson.billDetails)
                    setTaxDetails(responseJson.taxDetails)
                 }
            })
            .catch((error)=>{
                console.log(error)
            })
        } else if(search == 'grc'){
            var token = localStorage.getItem("token")
            console.log('token', token)
            fetch(global.url + "api/Booking/GetDuplicateBill?branchId=" + branchid + "&bookingNo=" + value, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }, 
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                 console.log("duplicate grc no.", responseJson)
                 if(responseJson.message) {
                    alert("Not Exist")
                 }
                 else{
                    setModal(true)
                    setBranchDetail(responseJson.branchDetails)
                    setDetails(responseJson)
                    setCustomerDetails(responseJson.customerDetails)
                    setRoomDetails(responseJson.roomDetails)
                    setBillDetails(responseJson.billDetails[0])
                    setBillDetail(responseJson.billDetails)
                    setTaxDetails(responseJson.taxDetails)
                 }
                 
            })
            .catch((error)=>{
                console.log(error)
            })
        }
        
    }

    const print = () => {
        window.print()
    }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold'}}>Duplicate Bill</p> 
                <Row>
                    <Col xs={12} md={3}>
                        <FormControl fullWidth sx={{ mt: 3, width: '100%' }}>
                                <InputLabel id="demo-simple-select-label">Search By</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={search}
                                label="Age"
                                onChange={handleChange}
                                size="small"
                                >
                                <MenuItem value='billno'>Bill no</MenuItem>
                                <MenuItem value='grc'>GRC</MenuItem>

                                </Select>
                            </FormControl>
                    </Col>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 3, width: '100%' }}>
                            <TextField id="outlined-basic" value={value} onChange={(e) => setValue(e.target.value)}label="Value" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3}>
                        <Button onClick={searchClick} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 25, width: '100%'}} variant="contained">Search</Button>
                    </Col>
                </Row>
                <Modal
                    size="lg"
                    show={modal}
                    onHide={() => setModal(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                    >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='posprint' ref={componentRef} style={{width: '95%',margin: '10px 35px 10px 20px'}}>
                        <div className='printborder'>
                        <div style={{display: 'none'}}>
                            <ReactToPrint
                                    trigger={() => <Button id="print-button" variant="primary" className="side-topbtn">OT Print</Button>}
                                    content={() => componentRef.current}
                                />
                        </div>
                        {/* <p style={{fontSize: 13, marginBottom: 'unset'}}>{formatDate(new Date)}</p> */}
                        <p style={{fontSize: 20, textAlign: "center", fontWeight: "bold", marginBottom: 'unset'}}>TAX INVOICE</p>
                            <div className='borderall'></div>
                            <div style={{marginTop: 20}}>
                                <Row>
                                <Col xs={7}>
                                        <Row>
                                            <Col xs={3}>
                                                <img src={logo} style={{width: 65, height: 65, marginTop: -5}}/>
                                            </Col>
                                            <Col xs={9}>
                                            <p style={{fontSize: 12, marginTop: 3}}>{branchdetail.brname}<br></br>
                                            {branchdetail.braddresS1}<br></br>
                                            {branchdetail.braddresS2}<br></br>
                                            {branchdetail.braddresS3}<br></br>
                                            </p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={5}>
                                        <Row>
                                            <Col xs={4}>
                                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>GST No.</p>
                                            </Col>
                                            <Col xs={4}>
                                                <p style={{fontSize: 13, marginBottom: 'unset'}}>{branchdetail.brgstin}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4}>
                                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>BILL DATE</p>
                                            </Col>
                                            <Col xs={4}>
                                                <p style={{fontSize: 13, marginBottom: 'unset'}}>{formatDate2(details.departure)}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <div style={{marginTop: -8}}>
                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>CUSTOMER DETAILS</p>
                                <Row>
                                    <Col xs={7}>
                                        <Row>
                                            <Col xs={4}>
                                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>GUEST ID</p>
                                            </Col>
                                            <Col xs={8}>
                                                <p style={{fontSize: 13, marginBottom: 'unset'}}>{details.guestid}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4}>
                                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>GUEST NAME</p>
                                            </Col>
                                            <Col xs={8}>
                                                <p style={{fontSize: 13, marginBottom: 'unset'}}>{customerDetails.customername}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4}>
                                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>ADDRESS</p>
                                            </Col>
                                            <Col xs={8}>
                                                <p style={{fontSize: 13, marginBottom: 'unset', wordBreak: "break-all"}}>{customerDetails.addresS1}</p>
                                                <p style={{fontSize: 13, marginBottom: 'unset', wordBreak: "break-all"}}>{customerDetails.addresS2}</p>
                                                <p style={{fontSize: 13, marginBottom: 'unset', wordBreak: "break-all"}}>{customerDetails.addresS3}</p>
                                                <p style={{fontSize: 13, marginBottom: 'unset', wordBreak: "break-all"}}>{customerDetails.mobile}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4}>
                                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>GST NO.</p>
                                            </Col>
                                            <Col xs={8}>
                                                <p style={{fontSize: 13, marginBottom: 'unset'}}>{customerDetails.gstin}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4}>
                                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>PAN NO.</p>
                                            </Col>
                                            <Col xs={8}>
                                                <p style={{fontSize: 13, marginBottom: 'unset'}}>{customerDetails.panno}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4}>
                                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>TRAVEL AGENT</p>
                                            </Col>
                                            <Col xs={8}>
                                                <p style={{fontSize: 13, marginBottom: 'unset'}}>{details.source}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4}>
                                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>STATE CODE</p>
                                            </Col>
                                            <Col xs={8}>
                                                <p style={{fontSize: 13, marginBottom: 'unset'}}>{customerDetails.state}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={5}>
                                        <Row>
                                            <Col xs={4}>
                                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>GRC NO.</p>
                                            </Col>
                                            <Col xs={8}>
                                                <p style={{fontSize: 13, marginBottom: 'unset'}}>{details.docno}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4}>
                                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>BILL NO.</p>
                                            </Col>
                                            <Col xs={8}>
                                                <p style={{fontSize: 13, marginBottom: 'unset'}}>{details.billno}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4}>
                                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>ARRIVAL</p>
                                            </Col>
                                            <Col xs={8}>
                                                <p style={{fontSize: 13, marginBottom: 'unset'}}>{formatDate(details.arrival)}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4}>
                                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>DEPARTURE</p>
                                            </Col>
                                            <Col xs={8}>
                                                <p style={{fontSize: 13, marginBottom: 'unset'}}>{formatDate(details.departure)}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4}>
                                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>PRINTED BY</p>
                                            </Col>
                                            <Col xs={8}>
                                                <p style={{fontSize: 13, marginBottom: 'unset'}}>{isAdmin ? "Admin" : username}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4}>
                                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>PRINTED ON</p>
                                            </Col>
                                            <Col xs={8}>
                                                <p style={{fontSize: 13, marginBottom: 'unset'}}>{formatDate(new Date)}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4}>
                                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>REV.CHARGE</p>
                                            </Col>
                                            <Col xs={8}>
                                                <p style={{fontSize: 13, marginBottom: 'unset'}}>No</p>
                                            </Col>
                                        </Row>
                                        {banquet == 'B' ?
                                        <Row>
                                            <Col xs={4}>
                                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>REF NO.</p>
                                            </Col>
                                            <Col xs={8}>
                                                <p style={{fontSize: 13, marginBottom: 'unset'}}>{details.refno}</p>
                                            </Col>
                                        </Row> : null}
                                    </Col>
                                </Row>
                            </div>
                            <div className="borderall" style={{marginTop: 5}}></div>
                            <div style={{marginTop: 10}}>
                                <Table className='sizecolumn' bordered responsive>
                                    <thead>
                                        <tr className='tax-bord'>
                                            <th>{banquet == 'B' ? 'BANQUET' : 'ROOM'} NO(S)</th>
                                            <th>{banquet == 'B' ? 'BANQUET' : 'ROOM'} TYPE</th>
                                            {banquet == 'B' ? null : <th>GUEST</th>}
                                            <th>RATE</th>
                                        </tr>
                                    </thead>
                                    <tbody  style={{borderTop: 'unset'}}>
                                        {
                                            roomDetails?.map((i, k) => 
                                            <tr className='tax-bord'>
                                                <td>{i.roomno}</td>
                                                <td>{i.roomtype}</td>
                                                {banquet == 'B' ? null : <td>{i.noofguest}</td>}
                                                <td>{parseFloat(i.roomrate)?.toFixed(2)}</td>
                                            </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </div>
                            <div style={{marginTop: -8}}>
                                <Table className='sizecolumn' bordered responsive>
                                    <thead>
                                        <tr className='tax-bord'>
                                            <th>DATE</th>
                                            <th>DESCRIPTION</th>
                                            <th>DEBIT</th>
                                            <th>CREDIT</th>
                                        </tr>
                                    </thead>
                                   
                                        {
                                            billDetail.length == 0 ? null :
                                            billDetail.map((i, k) => {
                                                return(
                                                    i.sgst > 0 ?
                                                    <tbody style={{borderTop: 'unset'}}>
                                                        <tr className='tax-border'>
                                                            {banquet == 'B' ?
                                                            <td className='tax-bord' rowSpan={4}>{formatDate2(i.startdate)}</td> :
                                                            <td className='tax-bord' rowSpan={4}>{formatDate2(i.startdate)}-{formatDate2(i.enddate)} - (DAYS: {i.noofdays})</td>}
                                                            <td className='tax-bord'>{i.description}</td>
                                                            <td className='tax-bord' style={{textAlign:'right'}}>{parseFloat(i.total)?.toFixed(2)}</td>
                                                            <td className='tax-bord'></td>
                                                        </tr>
                                                        <tr className='tax-border'>
                                                            <td className='tax-bord'>{banquet == 'B' ? "CGST 9.00%" : "ACCOMMODATION CGST 6.00%"}</td>
                                                            <td className='tax-bord' style={{textAlign:'right'}}>{parseFloat(i.cgst)?.toFixed(2)}</td>
                                                            <td className='tax-bord'></td>
                                                        </tr>
                                                        <tr className='tax-border'>
                                                            <td className='tax-bord'>{banquet == 'B' ? "SGST 9.00%" : "ACCOMMODATION SGST 6.00%"}</td>
                                                            <td className='tax-bord' style={{textAlign:'right'}}>{parseFloat(i.sgst)?.toFixed(2)}</td>
                                                            <td className='tax-bord'></td>
                                                        </tr>
                                                    </tbody>:
                                                    <tbody style={{borderTop: 'unset'}}>
                                                        <tr className='tax-border'>
                                                            <td className='tax-bord' rowSpan={4}>{(new Date(i.startdate)).toLocaleDateString()}-{(new Date(i.enddate)).toLocaleDateString()}</td>
                                                            <td className='tax-bord'>{i.description}</td>
                                                            <td className='tax-bord' style={{textAlign:'right'}}>{parseFloat(i.total)?.toFixed(2)}</td>
                                                            <td className='tax-bord'></td>
                                                        </tr>
                                                    </tbody>
                                                )
                                            })
                                        }
                                        
                                    
                                    <tbody style={{borderTop: 'unset'}}>
                                        <tr className='tax-bord'>
                                            <td></td>
                                            <td>ADVANCE</td>
                                            <td style={{textAlign: 'right'}}>0.00</td>
                                            <td style={{textAlign: 'right'}}>{details.advance == null ? "" : parseFloat(details.advance)?.toFixed(2)}</td>
                                        </tr>
                                        <tr className='tax-bord'>
                                            <td></td>
                                            <td style={{fontWeight: 'bold'}}>TOTAL (INR)</td>
                                            <td  style={{textAlign: 'right', fontWeight: 'bold'}}>{parseFloat(details.total)?.toFixed(2)}</td>
                                            <td  style={{textAlign: 'right',fontWeight: 'bold'}}>0.00</td>
                                        </tr>
                                        <tr className='tax-bord'>
                                            <td></td>
                                            <td>({details.amountsInWords})</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr className='tax-bord'> 
                                            <td></td>
                                            <td style={{fontWeight: 'bold'}}>BALANCE (INR)</td>
                                            <td style={{textAlign: 'right', fontWeight: 'bold'}}>{parseFloat(details.balance)?.toFixed(2)}</td>
                                            <td style={{textAlign: 'right', fontWeight: 'bold'}}>0.00</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                            <div style={{marginTop: -8}}>
                                <Table className='sizecolumn' bordered responsive>
                                    <thead>
                                        <tr className='tax-bord'>
                                            <th>HSN/SAC CODE</th>
                                            <th>SALES</th>
                                            <th>CGST</th>
                                            <th>SGST</th>
                                            <th>IGST</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{borderTop: 'unset'}}>
                                        <tr className='tax-bord'>
                                            <th>{taxDetails.hsn}</th>
                                            <th>{parseFloat(taxDetails.sales)?.toFixed(2)}</th>
                                            <th>{parseFloat(taxDetails.cgst)?.toFixed(2)}</th>
                                            <th>{parseFloat(taxDetails.sgst)?.toFixed(2)}</th>
                                            <th></th>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                            <div style={{marginTop: 40, display: 'flex', justifyContent: 'space-between'}}>
                                <p style={{fontSize: 12, marginBottom: 'unset'}}>CHECKED BY</p>
                                <p style={{fontSize: 12, marginBottom: 'unset'}}>GUEST SIGNATURE</p>
                                <p style={{fontSize: 12, marginBottom: 'unset'}}>{branchdetail.brname}</p>
                            </div>
                            <div className="borderall"></div>
                            <p style={{fontSize: 13, marginTop: 20}}>ORIGINAL FOR RECIPIENT</p>
                            
                        </div>
                  
                    </div>
                    <div style={{marginTop: 15, marginBottom: 15, display: 'flex', justifyContent: 'center'}}>
                            <ReactToPrint
                                    trigger={() => <Button onClick={print} variant="contained" style={{marginTop: 10, backgroundColor: '#03a5fc', color: '#fff', fontSize: 14}}>Print</Button>}
                                    content={() => componentRef.current}
                                />
                                
                            </div>
                    </Modal.Body>
                </Modal>
            </Paper>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetail: state.shop.branchdetail,
        isAdmin: state.shop.isAdmin,
    };
};

export default connect(mapStateToProps)(DuplicateBill);