import React, {useState, useEffect, useRef} from 'react';
import Paper from '@mui/material/Paper';
import { Row, Col, Modal, Container} from "react-bootstrap";
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import * as IconName from "react-icons/bs";
import * as GI from "react-icons/gi";
import * as BI from "react-icons/bi";
import * as RI from "react-icons/ri";
import { connect } from "react-redux";

function Opening({hasResultsProduct, productlist, productname, inputRef, listClickname, index, countertransfer}) {
    const resultsRef = useRef();

    function onKeyDown1(event) {
        const isUp = event.key === 'ArrowUp';
        const isDown = event.key === 'ArrowDown';
        const inputIsFocused = document.activeElement === inputRef.current;
    
        const resultsItems = Array.from(resultsRef.current.children)
    
        const activeResultIndex = resultsItems.findIndex(child => {
          return child.querySelector('a') === document.activeElement;
        });
    
        if ( isUp ) {
          console.log('Going up!');
          if ( inputIsFocused ) {
            resultsItems[resultsItems.length - 1].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex - 1] ) {
            resultsItems[activeResultIndex - 1].querySelector('a').focus();
          } else {
            inputRef.current.focus();
          }
        }
    
        if ( isDown ) {
          console.log('Going down!')
          if ( inputIsFocused ) {
            resultsItems[0].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex + 1] ) {
            resultsItems[activeResultIndex + 1].querySelector('a').focus();
          } else {
            inputRef.current.focus();
          }
        }
      }

      useEffect(() => {
        if ( hasResultsProduct ) {
            document.body.addEventListener('keydown', onKeyDown1);
        } else {
            document.body.removeEventListener('keydown', onKeyDown1);
        }

        
        return () => {
          document.body.removeEventListener('keydown', onKeyDown1);
        }
      }, [hasResultsProduct]);
    
    return(
        <div className="autocomplete">
            <ul ref={resultsRef} className="people scrolling-div">
            {productlist?.filter(name => name.prdcode.toLowerCase().startsWith(productname.toLowerCase())).map(item => (
                
                        <li key={item.prdid}>
                          {
                            countertransfer ? 
                            <a href="javascript:void(0)" onClick={()=> listClickname()}>
                            {item.prdcode}
                            </a>:
                             <a href="javascript:void(0)" onClick={()=> listClickname(item.prdcode, index)}>
                             {item.prdcode}
                             </a>
                          }
                        </li>
                    ))}
            
            </ul>
        </div>
    )
}


export default Opening;