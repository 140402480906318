import React, {useState, useEffect, useRef} from 'react';
import { connect } from "react-redux";
import PurchaseNormal from './PurchaseNormal';
import PurchaseOoty from './PurchaseOoty';

function Purchase({branchid}) {
    console.log("sj",branchid )
    return(
        <div>
            {branchid == 1021 ?
                <PurchaseOoty /> :
                <PurchaseNormal />}
        </div>
    )
}



const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(Purchase);