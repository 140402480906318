import React, {useEffect, useStste, useRef} from 'react'
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button} from "react-bootstrap";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import ReactToPrint from 'react-to-print';
import TableRow from '@mui/material/TableRow';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { connect } from "react-redux";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import logo from '../../assets/images/logo.jpeg';

function CashStatement({branchid, branchdetails}){
    const componentRef = useRef();
    const [source, setSource] = React.useState([])
    const [summary, setSummary] = React.useState({})
    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();

    const [date1, setDate1] = React.useState(year + '-' + month + '-' + date);
    const [date2, setDate2] = React.useState(year + '-' + month + '-' + date);
    const [type, setType] = React.useState(0)

    const typeChange = (event) => {
        setType(event.target.value)
    }

    useEffect(() => {
        getData()
    }, [branchid]);

    const getData = () => {
        var token = localStorage.getItem("token")
        console.log(token)
        var data = {
            "companyId": 1,
            "branchId": branchid,
            "isbanquet": type,
            "fromDate": date1,
            "toDate": date2,
            "createdBy": "",
            "billno": "",
            "counterId": "",
            "userId": "",
            "soldItemsOnly": "",
            "accId": ""
          }
          console.log('data', data)
        fetch(global.url + "api/BookingReports/GetCashStatement", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),     
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("GetCashStatement report Response", responseJson)
            setSource(responseJson)
            if(responseJson[0].summary) {
                setSummary(responseJson[0].summary)
            }
            
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const formatDate = (datestring, type) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
          

          if(type == "time") {
            var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          }
          else{
            var formattedDate =  day+"/"+month+"/"+year
          }

          
          return formattedDate; //03/02/2021
    }
    const formatDate2 = (datestring) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
        //   console.log("timeString12hr", timeString12hr)

          var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          return formattedDate; //03/02/2021
    }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>Cash Statement</p> 
                <Row>
                    <Col xs={12}  className='columnborder'>
                        <div>
                            <Row>
                                <Col xs={12} md={3}>
                                    <FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
                                        <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={type}
                                        label="Age"
                                        onChange={typeChange}
                                        size="small"
                                        >
                                            <MenuItem value='0'>Room</MenuItem>
                                            <MenuItem value='1'>Banquet</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="From Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setDate1(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={3}  style={{height: 41}}>
                                    <FormControl sx={{ mt: 2, width: '100%' }} >
                                        <TextField
                                            id="datetime-local"
                                            label="To Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                           
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setDate2(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={2}>
                                    <div style={{justifyContent: 'center', display: 'flex'}}>
                                        <Button onClick={getData} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 17, width: '50%'}} variant="contained">Search</Button>
                                    </div>
                                </Col>
                                <Col xs={12} md={4}>
                                    <ReactToPrint
                                        trigger={() => <Button style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 17, width: '50%'}} variant="contained">Print this out!</Button>}
                                        content={() => componentRef.current}
                                    />
                                </Col>
                            </Row>
                           
                        </div>
                    </Col>
                    
                </Row>
                <div style={{height: 2, width: '100%', backgroundColor: '#d3d3d3', marginTop: 30}}></div>
                <div sx={{ maxHeight: 440, marginTop: 20 }}>
                   
                        <div  className='section-to-print' ref={componentRef} style={{width: '95%'}}>
                        {/* <Row> 
                                
                                <img src={require("../../assets/images/logo.jpeg").default} style={{width: 100, position: 'absolute'}}/>
                                
                                <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                                <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                                </Col>
                            </Row> */}
                        <Table id="table-to-xls" stickyHeader aria-label="sticky table" >
                        {/* <caption className='captiontable'>Cash Statement From {date1.split("-").reverse().join("-")} To {date2.split("-").reverse().join("-")}</caption> */}
                        <TableHead className="tableheaderexe">
                        <TableRow style={{height: 80}} >
                            <TableCell colSpan={8}  style={{padding: 0}}>
                                <Row>
                                    <img src={logo} style={{width: 100, position: 'absolute', top: 0}}/>
                                    
                                    <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                                    <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                                    </Col>
                                    <p className='printdate' style={{ position: 'absolute', left: '85%'}}>{formatDate2(new Date)}</p> 
                                </Row>
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            
                            <TableCell colSpan={8} style={{padding: 0}}>
                                <div style={{display: 'flex', justifyContent: 'center', fontWeight: 600}} className='captiontable'>
                                    CASH STATEMENT FROM {date1.split("-").reverse().join("-")} TO {date2.split("-").reverse().join("-")}
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Source</TableCell>
                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>{type == 1 ? 'Banquet Hall' : 'Room'} No.</TableCell>
                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Bill No.</TableCell>
                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>GRC/RES.</TableCell>
                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>CH.Date & Time</TableCell>
                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Description</TableCell>
                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Receipt</TableCell>
                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Payment</TableCell>
                            </TableRow>
                        </TableHead>
                        {
                            source.map((i, k) => {
                                return (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={5} className='tablecellPrint' style={{fontWeight: 'bold'}}>{formatDate(i.docdate, "date")}</TableCell>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Opening Balance</TableCell>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'end'}}>{i.openingbalance}</TableCell>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}></TableCell>
                                        </TableRow>
                                        {i.billDetails.map((i, index) => {
                                            return(
                                                <>
                                                    <TableRow>
                                                        <TableCell className='tablecellPrint' >{i.source}</TableCell>
                                                        <TableCell className='tablecellPrint' >{i.room}</TableCell>
                                                        <TableCell className='tablecellPrint' >{i.billno}</TableCell>
                                                        <TableCell className='tablecellPrint' >{i.grc}</TableCell>
                                                        <TableCell className='tablecellPrint' >{i.chkoutdate}</TableCell>
                                                        <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>{type == 1 ? 'Banquet Hall Rent' : 'Tariff'}</TableCell>
                                                        <TableCell className='tablecellPrint' style={{ textAlign: 'end'}}>{i.tariff?.toFixed(2)}</TableCell>
                                                        <TableCell className='tablecellPrint'></TableCell>
                                                    </TableRow>
                                                    {i.extraperson > 0?
                                                    <TableRow>
                                                        <TableCell colSpan={5} className='tablecellPrint' ></TableCell>
                                                        <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Extra Person</TableCell>
                                                        <TableCell className='tablecellPrint' style={{textAlign: 'end'}}>{i.extraperson?.toFixed(2)}</TableCell>
                                                        <TableCell className='tablecellPrint'></TableCell>
                                                    </TableRow> : null}
                                                    <TableRow>
                                                        <TableCell colSpan={5} className='tablecellPrint' ></TableCell>
                                                        <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>CGST</TableCell>
                                                        <TableCell className='tablecellPrint' style={{textAlign: 'end'}}>{i.cgst?.toFixed(2)}</TableCell>
                                                        <TableCell className='tablecellPrint'></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan={5} className='tablecellPrint' ></TableCell>
                                                        <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>SGST</TableCell>
                                                        <TableCell className='tablecellPrint'style={{ textAlign: 'end'}} >{i.sgst?.toFixed(2)}</TableCell>
                                                        <TableCell className='tablecellPrint'></TableCell>
                                                    </TableRow>
                                                </> 
                                            )
                                        })}
                                        <TableRow>
                                            <TableCell colSpan={5} className='tablecellPrint' style={{fontWeight: 'bold'}}></TableCell>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>{i.openingbalance == 0 ? "Cash to Office" : "Balance Cash"}</TableCell>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}}></TableCell>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}}>{i.billDetails.length == 0 ? null : i.billDetails[0].balancecash.toFixed(2)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={5} className='tablecellPrint' style={{fontWeight: 'bold'}}></TableCell>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Day Total</TableCell>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}}>{i.billDetails.length == 0 ? null : i.billDetails[0].daytotal.toFixed(2)}</TableCell>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}}>{i.billDetails.length == 0 ? null : i.billDetails[0].daytotal.toFixed(2)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                )
                                })
                            }
                        </Table>
                   
                        <Row>
                            <Col md={6}>
                            <TableContainer sx={{ maxHeight: 440, marginTop: 2 }}>
                                <Table id="table-to-xls" stickyHeader aria-label="sticky table" >
                                    <TableHead className="tableheaderexe">
                                        <TableRow>
                                            <TableCell colSpan={3} className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'center'}}>Summary</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>{type == 1 ? 'BANQUET HALL RENT' : 'TARIFF'}</TableCell>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}}>{summary.tariff}</TableCell>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}}></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>EXTRA PERSON</TableCell>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}}>{summary.extraperson}</TableCell>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}}></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>CGST</TableCell>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}}>{summary.cgst}</TableCell>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}}></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>SGST</TableCell>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}}>{summary.sgst}</TableCell>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}}></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>ADVANCE</TableCell>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}}>{summary.advance}</TableCell>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}}>{summary.advancecr}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>OPENING BALANCE</TableCell>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}}>{summary.openingbalance}</TableCell>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}}></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>{summary.balancecashcaption}</TableCell>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}}></TableCell>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}}>{summary.balancecash}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>TOTAL</TableCell>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}}>{summary.nettotal}</TableCell>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}}>{summary.nettotal}</TableCell>
                                        </TableRow>
                                    </TableBody>
                        </Table>
                    </TableContainer>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Paper>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(CashStatement);