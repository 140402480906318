import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Form, Button, Table} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { connect } from "react-redux";

function Itemmaster({branchid}){

    const [categorylist, setCategorylist] = React.useState([]);
    const [category, setCategory] = React.useState("");
    const [subcategorylist, setSubcategorylist] = React.useState([]);
    const [subcategory, setSubcategory] = React.useState("");

    const [source, setSource] = React.useState([]);
    const [fbsale, setFbsale] = React.useState("");
    const [unit, setUnit] = React.useState("");

    const [pegsperbottle, setPegsperbottle] = React.useState("");
    const [addedvalue, setAddedvalue] = React.useState("");
    const [mlperbottle, setmlperbottle] = React.useState("");
    const [bottlespercase, setBottlepercase] = React.useState("");
    const [purchaserate, setPurchaserate] = React.useState("");
    const [name, setName] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [code, setCode] = React.useState("");
    const [printname, setPrintname] = React.useState("");
    const [cost, setCost] = React.useState("");
    const [array, setArray] = React.useState([])
    const [cess, setCess] = React.useState("");
    const [branchlist, setBranchlist] = React.useState([]);

    const [wsbottle, setWsbottle] = React.useState(0.00);
    const [wspeg, setWspeg] = React.useState(0.00);

    const [localbottle, setLocalbottle] = React.useState(0.00);
    const [localpeg, setLocalpeg] = React.useState(0.00);

    const [aclocalbottle, setAclocalbottle] = React.useState(0.00);
    const [aclocalpeg, setAclocalpeg] = React.useState(0.00);

    const [roombottle, setRoombottle] = React.useState(0.00);
    const [roompeg, setRoompeg] = React.useState(0.00);

    const [acbottle, setAcbottle] = React.useState(0.00);
    const [acpeg, setAcpeg] = React.useState(0.00);

    const [barbottle, setBarbottle] = React.useState(0.00);
    const [barpeg, setBarpeg] = React.useState(0.00);

    const [executivebottle, setExecutivebottle] = React.useState(0.00);
    const [executivepeg, setExecutivepeg] = React.useState(0.00);


    function handleKeyPress(event) {
        if (!/\d/.test(event.key)) {
          event.preventDefault();
        }
      }
    
    const categoryChange = (event) => {
        console.log("sdf", event.target.value)
        setCategory(event.target.value);
    };
    const subcategoryChange = (event) => {
        setSubcategory(event.target.value);
    };

    const fbsaleChange= (event) => {
        setFbsale(event.target.value);
    };

    const unitChange= (event) => {
        setUnit(event.target.value);
    };

    useEffect(() => {
        getData()
        getBranches()
    }, [branchid]);

    const getBranches = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Branch/GetBranchList?orderBy=name&companyId=1&includeBlocked=false", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("getBranches Response", responseJson)
             setBranchlist(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getData = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Products/GetNewProduct?companyId=1&branchId=" + branchid, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("GetNewProduct Response", responseJson)
             setCategorylist(responseJson.categorylist)
             setSubcategorylist(responseJson.subcategorylist)
        })

        .catch((error)=>{
            console.log(error)
        })
    }

    const onSubmit = () => {
        console.log("array", array)
        if(name == ""){
            return alert("Name is required")
        }
        if(code == ""){
            return alert("code is required")
        }
        if(category == ""){
            return alert("category is required")
        }
        if(cost == ""){
            return alert("cost is required")
        }
        if(purchaserate == ""){
            return alert("purchaserate is required")
        }
        if(pegsperbottle == ""){
            return alert("pegs per bottle is required")
        }
        if(mlperbottle == ""){
            return alert("ml per bottle is required")
        }
        if(bottlespercase == ""){
            return alert("bottles pe rcase is required")
        }
        var token = localStorage.getItem("token")
        var data = {
            "prdid": "",
            "prdname": name,
            "prdcode": code,
            "prdprintname": printname,
            "prddescp": description,
            "prdimage": category == 4 ? subcategory : "",
         
            "prdblocked": false,
            "prdcatid": category,
            "prdsubcatid": unit,
            "prdcost": cost,
            "prdpprice": purchaserate,
            "pegsperbottle": pegsperbottle,
            "addedvalue" : branchid == 1021 ? addedvalue : "",
            "mlperbottle": mlperbottle,
            "bottlepercase": bottlespercase,
            "bottlesaleonly": fbsale,
            "allowedBranches": array,
            "priceDetails": {
                "PRICEID": 0,
                "PRODUCTID": 0,
                "UPDATEDBY": global.userid,
                "BRANCHID" : branchid,
                "BARPEG": barpeg,
                "BARBOTTLE": barbottle,
                "ACPEG": acpeg,
                "ACBOTTLE": acbottle,
                "EXPEG": executivepeg,
                "EXBOTTLE": executivebottle,
                "LOCALPEG": localpeg,
                "LOCALBOTTLE": localbottle,
                "WSBOTTLE": wsbottle,
                "UPDATEDON": "",
                "ROOMPEG" : roompeg,
                "ROOMBOTTLE": roombottle,
                "CODE": "",
                "PRODUCTNAME": "",
                "BOTTLEPERCASE": "",
                "PPRICE": "",
                "BottleSaleOnly": "",
                "StockBottle": "",
                "StockPeg": "",
                "CESSPERCASE" : cess
            },
            "selectedBranchId": branchid,
            "subcategoryid": category == 4 ? subcategory : "",
        }
        console.log("data", data)
        fetch(global.url + "api/Products/SaveProduct", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
           console.log("itemsave response", responseJson)
           if(responseJson.error == false){
               alert(responseJson.message)
               window.location.reload()
           }
           else{
            alert(responseJson.message)
           }
          
        })
        .catch((error)=>{
            alert("username or password error")
            console.log(error)
        })
    }

    const blockChange = (e,id) => {
        let isChecked = e.target.checked;
        console.log(isChecked)
        if(isChecked == true) {
            var input  = [...array]
            input.push({branchid: id})
            console.log("input", input)
            setArray(input)
        }
        else{

            var testArr = [1, 2, 3, 4, 5];
            const val = 3; // Value to remove

            for (let i = 0; i < array.length; i++) {
                if (array[i].branchid === id) {
                    const removedElements = array.splice(i, 1);
                    console.log(removedElements); // Outputs [3]
                    i--; // Since the indexes of elements following this index get updated after removal
                }
            }
        }
        console.log("array",  array)
    }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold'}}>Item master</p> 
                <Row>
                    <Col xs={12} md={3}>
                        <p style={{fontSize: 20}}>Liquor type</p>
                    </Col>
                    <Col xs={12} md={3}>
                        <FormControl fullWidth sx={{ width: '100%' }}>
                            <InputLabel id="demo-simple-select-label">Categories</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={category}
                            label="Age"
                            onChange={categoryChange}
                            size="small"
                            >
                                {
                                    categorylist.map((i) => {
                                return(
                                
                                        <MenuItem value={i.catid}>{i.catname}</MenuItem>
                                        )
                                    })
                                }
                           
                            </Select>
                        </FormControl>
                    </Col>
                    {category == 4 ?
                    <Col xs={12} md={3}>
                       
                        <FormControl fullWidth sx={{width: '100%' }}>
                            <InputLabel id="demo-simple-select-label">Sub Categories</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={subcategory}
                            label="Age"
                            onChange={subcategoryChange}
                            size="small"
                            >
                                {
                                    subcategorylist.map((i) => {
                                    return(
                                    
                                            <MenuItem value={i.subid}>{i.subcatname}</MenuItem>
                                            )
                                        })
                                }
                   
                            </Select>
                        </FormControl>
                    </Col> : null}
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField value={name} onChange={(e) => setName(e.target.value)} id="outlined-basic" label="Item name" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField value={code} onChange={(e) => setCode(e.target.value)} id="outlined-basic" label="Item Code" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField value={printname} onChange={(e) => setPrintname(e.target.value)} id="outlined-basic" label="Item Printname" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField value={description} onChange={(e) => setDescription(e.target.value)} id="outlined-basic" label="Item Description" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField type="number" value={pegsperbottle} onChange={(e) => setPegsperbottle(e.target.value)} id="outlined-basic" label="Pegs Per Bottle" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField type="number" id="outlined-basic" label="ML Per Bottle" value={mlperbottle} onChange={(e) => setmlperbottle(e.target.value)} variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                </Row>
                <Row>
                <Col xs={12} md={3}>
                        <FormControl fullWidth sx={{mt: 2,width: '100%' }}>
                            <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={unit}
                            label="Age"
                            onChange={unitChange}
                            size="small"
                            >
                            <MenuItem value={0}>FFF</MenuItem>
                            <MenuItem value={1}>LFS</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3}>
                        <FormControl fullWidth sx={{mt: 2,width: '100%' }}>
                            <InputLabel id="demo-simple-select-label">Full Bottle Sale</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={fbsale}
                            label="Age"
                            onChange={fbsaleChange}
                            size="small"
                            >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={6} className="tablebranch mt-3">
                        <Table  responsive bordered >
                                <thead>
                                    <tr>
                                        <th>Branch</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        branchlist.map((i, k) => {
                                            return(
                                                <tr >
                                                    <td>{i.brname}</td>
                                                    <td>
                                                        <FormControl sx={{  width: '100%' }}>
                                                            <FormControlLabel control={
                                                            <Checkbox onChange={e => blockChange(e, i.brid)} />
                                                            } label="" />
                                                        </FormControl>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    
                                </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField type="number" id="outlined-basic" label="Bottles per case" value={bottlespercase} onChange={(e) => setBottlepercase(e.target.value)} variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField type="number" id="outlined-basic" label="Purchase rate per case" value={purchaserate} onChange={(e) => setPurchaserate(e.target.value)} variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    { branchid == 1021 ? 
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField type="number" id="outlined-basic" label="Added Value" value={addedvalue} onChange={(e) => setAddedvalue(e.target.value)} variant="outlined"  size="small"/>
                        </FormControl>
                    </Col> : null }
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" label="Tax Percentage" value={cost} onChange={(e) => setCost(e.target.value)} variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" label="CESS Per Case" value={cess} onChange={(e) => setCess(e.target.value)} variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                </Row>
                <Row></Row>
                    <Col xs={12} md={6}>
                        <Button onClick={() => onSubmit()} variant="contained" style={{marginTop: 30, backgroundColor: '#03a5fc', marginRight: 10, width: 200, color: '#fff'}}>Save</Button>
                    </Col>
                <Row style={{marginTop: 20}}>
                    <Table responsive bordered className="section-to-print">
                        <thead>
                            <tr>
                                <th>BILLING RATE</th>
                                <th>CALC %</th>
                                <th>BOTTLE</th>
                                <th>CALC %</th>
                                <th>PEG</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <td>W/S</td>
                                <td>
                                    <FormControl>
                                        <TextField onKeyPress={handleKeyPress} id="outlined-basic"  value={0.00}   label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={wsbottle}  onChange={(e) => setWsbottle(e.target.value)} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={0.00} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={wspeg}  onChange={(e) => setWspeg(e.target.value)} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                            </tr>
                            <tr  >
                                <td>LOCAL</td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={0.00} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={localbottle}  onChange={(e) => setLocalbottle(e.target.value)} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td> 
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={0.00} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={localpeg}  onChange={(e) => setLocalpeg(e.target.value)} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                            </tr>
                            <tr >
                                <td>AC LOCAL</td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={0.00} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={aclocalbottle}  onChange={(e) => setAclocalbottle(e.target.value)} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td> 
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={0.00} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={aclocalpeg}  onChange={(e) => setAclocalpeg(e.target.value)}  label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                            </tr>
                            <tr  >
                                <td>ROOM</td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={0.00} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={roombottle}  onChange={(e) => setRoombottle(e.target.value)} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td> 
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={0.00} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={roompeg}  onChange={(e) => setRoompeg(e.target.value)} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                            </tr>
                            <tr >
                                <td>A/C</td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={0.00} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={acbottle}  onChange={(e) => setAcbottle(e.target.value)} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td> 
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={0.00} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={acpeg}  onChange={(e) => setAcpeg(e.target.value)} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                            </tr>
                            <tr >
                                <td>BAR</td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={0.00} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={barbottle}  onChange={(e) => setBarbottle(e.target.value)} variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td> 
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={0.00} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic"value={barpeg}  onChange={(e) => setBarpeg(e.target.value)} variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                            </tr>
                            <tr >
                                <td>EXECUTIVE</td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={0.00} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={executivebottle}  onChange={(e) => setExecutivebottle(e.target.value)} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td> 
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={0.00} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={executivepeg}  onChange={(e) => setExecutivepeg(e.target.value)}  label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Row>
                
            </Paper>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
    };
};

export default connect(mapStateToProps)(Itemmaster);