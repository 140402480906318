import React, {Component, useEffect, useState} from 'react';
import { Route, Redirect } from 'react-router-dom';
import Sidebar from '../sidebar/sidebar';
import accessimgae from '../../assets/images/access.png'

const DashboardLayout = ({ children, ...rest }) => {
    const [toggle,  setToggle] = useState(false)
    const [access,  setAccess] = useState(false)

    const [userid,  setUserid] = useState('')
    
    function setData(childData) {
        setToggle(childData)
      }

      useEffect(() => {
        const formname = JSON.parse(localStorage.getItem("formname"));
        var userid1 = localStorage.getItem("userid")
        setUserid(userid1)
        console.log("path", children.props.match.path)
        console.log("formname", formname)
        if(userid1 != 1) {
          const conditioncheck = formname.some(function(o){return o["formname"] === children.props.match.path;})
          console.log("conditioncheck", conditioncheck)
          setAccess(conditioncheck)
        }
        else{
          setAccess(true)
        }
    }, [children]);
    

    global.userid = userid

    

      
  return (
    <div style={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}} className={toggle == true ? "toggle": ""}>
        <Sidebar activeData = {toggle} getData = {setData} />
        {
          access ? 
          <div style={{marginTop: '90px'}}>{children}</div>:
          <div style={{marginTop: '90px', marginLeft: '500px'}}>
            <img src={accessimgae} alt='' />
          </div>
        }
        
    </div>
  );
};

const DashboardLayoutRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = localStorage.getItem("isAuthenticatedhm");
  return (
    <Route
      {...rest}
      render={(props) => (
        isAuthenticated ?
        <DashboardLayout>
          <Component {...props} />
        </DashboardLayout> :
         <Redirect to="/" /> 
      )}
    />
  );
};

export default DashboardLayoutRoute;