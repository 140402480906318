import React, {useState, useEffect, useRef} from 'react';
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Form, Button} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { connect } from "react-redux";
import ReactToPrint from 'react-to-print';
import logo from '../../../assets/images/logo.jpeg';

const columns = [
    { id: '2', label: 'Room No.', minWidth: 50,span: false },
    { id: '3', label: 'Checkout', minWidth: 50,span: false },
    { id: '4', label: 'Tarif Actual', minWidth: 50,span: false },
    { id: '5', label: 'Discount', minWidth: 50,span: false },
    { id: '6', label: 'Tarif', minWidth: 50,span: false },
    { id: '7', label: 'Extra Bed', minWidth: 50,span: false },
    { id: '8', label: 'Early Checkin', minWidth: 50,span: false },
    { id: '9', label: 'CGST', minWidth: 50,span: false },
    { id: '10', label: 'SGST', minWidth: 50,span: false },
    { id: '14', label: 'Amount', minWidth: 50,span: false },
  ];
const source = [{}, {}]

function BillSummary({branchid, branchdetails}){
    const componentRef = useRef();
    const [age, setAge] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [source, setSource] = React.useState([]);

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();


    const [fromdate, setFromdate] = React.useState(year + '-' + month + '-' + date);
    const [todate, setTodate] = React.useState(year + '-' + month + '-' + date);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    useEffect(() => {
        getData()
    }, [branchid]);

    const getData = () => {
        var token = localStorage.getItem("token")
        var data = {
            "companyId": 1,
            "branchId": branchid,
            "fromDate": fromdate,
            "toDate": todate,
            "createdBy": "",
            "billno": "",
            "counterId": "",
            "userId": "",
            "soldItemsOnly": "",
            "accId": "10" 
        }
        fetch(global.url + "api/Booking/GetBookingList", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("GetBookingList  Response", responseJson)
             setSource(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const formatDate = (datestring, type) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
          

          if(type == "time") {
            var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          }
          else{
            var formattedDate =  day+"/"+month+"/"+year
          }

          
          return formattedDate; //03/02/2021
    }
    const formatDate2 = (datestring) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
        //   console.log("timeString12hr", timeString12hr)

          var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          return formattedDate; //03/02/2021
    }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold'}}>Bill Summary</p>
                <Row>
                        <Col xs={12} md={3}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField
                                    id="datetime-local"
                                    label="From Date"
                                    type="date"
                                    defaultValue={year + '-' + month + '-' + date}
                                    sx={{ width: '100%' }}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                    onChange={(newValue) => {
                                        setFromdate(newValue.target.value);
                                    }}
                                />
                            </FormControl>
                        </Col>
                        <Col xs={12} md={3}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField
                                    id="datetime-local"
                                    label="To Date"
                                    type="date"
                                    defaultValue={year + '-' + month + '-' + date}
                                    sx={{ width: '100%' }}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                    onChange={(newValue) => {
                                        setTodate(newValue.target.value);
                                    }}
                                />
                            </FormControl>
                        </Col>
                        <Col xs={12} md={3}>
                        <div style={{justifyContent: 'center', display: 'flex'}}>
                                <Button onClick={getData} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 17, width: '50%'}} variant="contained">Search</Button>
                            </div>
                        </Col>
                        <Col xs={12} md={4} >
                            <ReactToPrint
                                trigger={() => <Button style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 17, width: '50%'}} variant="contained">Print this out!</Button>}
                                content={() => componentRef.current}
                            />
                        </Col>
                        
                </Row> 
                {/* <Row>
                    <Col xs={12} md={6}>
                    <div>
                        <p style={{fontSize: 14, color: '#000'}}>GRC NO.  :  272</p>
                        <p style={{fontSize: 14, color: '#000', marginBottom: 'unset'}}>Check In Date  :  12/12/21</p>
                    </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <div>
                            <p style={{fontSize: 14, color: '#000', marginBottom: 'unset'}}>Test1</p>
                            <p style={{fontSize: 14, color: '#000', marginBottom: 'unset'}}>Address1</p>
                            <p style={{fontSize: 14, color: '#000', marginBottom: 'unset'}}>Address2</p>
                            <p style={{fontSize: 14, color: '#000', marginBottom: 'unset'}}>Address3</p>
                        </div>
                    </Col>
                </Row> */}
                <div className='bulk-table'>
                    <TableContainer sx={{ maxHeight: 440 }}>
                    <div  className='section-to-print' ref={componentRef} style={{width: '95%'}}>
                        {/* <Row> 
                                
                                <img src={require("../../../assets/images/logo.jpeg").default} style={{width: 100, position: 'absolute'}}/>
                                
                                <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                                <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                                </Col>
                            </Row> */}
                        <Table stickyHeader aria-label="sticky table" style={{width: '100%', marginTop: 10}}>
                        {/* <caption className='captiontable'>BILL SUMMARY</caption> */}
                            <TableHead className="tableheaderexe">
                            <TableRow style={{height: 80}} >
                                    <TableCell colSpan={10}  style={{padding: 0}}>
                                        <Row>
                                            <img src={logo} style={{width: 100, position: 'absolute', top: 0}}/>
                                            
                                            <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                                            <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                                            </Col>
                                            <p className='printdate' style={{ position: 'absolute', left: '85%'}}>{formatDate2(new Date)}</p> 
                                        </Row>
                                    </TableCell>
                                 </TableRow>
                                 <TableRow>
                                    
                                    <TableCell colSpan={10} style={{padding: 0}}>
                                        <div style={{display: 'flex', justifyContent: 'center', fontWeight: 600}} className='captiontable'>
                                        BILL SUMMARY
                                        </div>
                                    </TableCell>
                                </TableRow>
                            <TableRow  className='tablerowPrint'>
                                {columns.map((column) => (
                                <TableCell
                                className='tablecellPrint' style={{fontWeight: 'bold'}}
                                    key={column.id}
                                    align={column.align}
                                    colSpan={column.span == true ? 2: 1}
                                >
                                    {column.label}
                                    
                                </TableCell>
                                ))}
                            </TableRow>
                            </TableHead>
                            <TableBody className="bodytable">
                            {source
                                .map((row) => {
                                return (
                                    <TableRow  className='tablerowPrint'>
                                        <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>
                                            {row.roomno}
                                        </TableCell>
                                        <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>
                                        {formatDate(row.departure, "time")} 
                                        </TableCell>
                                        <TableCell className='tablecellPrint' style={{fontWeight: 'bold',textAlign: 'right'}}>
                                        {row.actualtariff.toFixed(2)} 
                                        </TableCell>
                                        <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}}>
                                            {row.discount.toFixed(2)}
                                        </TableCell>
                                        <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}}>
                                        {row.tariff.toFixed(2)}
                                        </TableCell>
                                        <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>
                                            {row.extrabed}
                                        </TableCell>
                                        <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>
                                        {formatDate(row.earlycheckin, "time")} 
                                 
                                        </TableCell>
                                        <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}}>
                                            {row.cgst.toFixed(2)}
                                        </TableCell>
                                        <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}}>
                                            {row.sgst.toFixed(2)}
                                        </TableCell>
                                        <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}}>
                                            {row.total.toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
                        </div>
                        </TableContainer>
                       
                </div>
                {/* <Row>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" label="Sub Total" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" label="Late Checkout" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" label="Total" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                </Row> */}
            </Paper>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(BillSummary);