import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import { Row, Col, Modal, Container} from "react-bootstrap";
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import * as IconName from "react-icons/bs";
import * as GI from "react-icons/gi";
import * as BI from "react-icons/bi";
import * as RI from "react-icons/ri";
import { connect } from "react-redux";

function Opening({branchid}) {
    const [modal, setModal] = useState(false);
    const [counter, setCounter] = useState("");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [search, setSearch] = React.useState('');

    const [tablerow, setTablerow] = useState([]);

    const [counterlist, setCounterlist] = React.useState([]);
    

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();

    const [date1, setDate1] = React.useState(year + '-' + month + '-' + date);

    useEffect(() => {
        getCounter()
      }, [branchid]);

      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };

      const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };

      const counterChange = (event) => {
        setCounter(event.target.value)
      }

      

      const getCounter = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Counter/GetCounterlist?companyId=1&branchId="+ branchid + "&includeBlocked=false", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("counterlist response", responseJson)
              setCounterlist(responseJson)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const getStockdata = () => {
        var token = localStorage.getItem("token")
        const data = {
            "companyId": 1,
            "branchId": branchid,
            "fromDate": date1,
            "toDate": date1,
            "createdBy": global.userid,
            "billno": "1",
            "counterId": counter
        }

        console.log("save data", data)

        fetch(global.url + "api/CounterStock/GetOpeningStock", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setTablerow(responseJson)
             console.log("get opening Response", responseJson)
        })
        .catch((error)=>{
            alert("error occured")
            console.log(error)
        })
      }

      const bottleChange =(event, key) => { 
        var textArray1 = [...tablerow]
        textArray1[key].opbarbottle = event.currentTarget.value;
        setTablerow(textArray1)
      }

      const pegChange =(event, key) => { 
        var textArray1 = [...tablerow]
        textArray1[key].opbarpeg = event.currentTarget.value;
        setTablerow(textArray1)
    }

  const onSave = () => {
    var token = localStorage.getItem("token")
    var datatable = [...tablerow]
    for(var i=0; i < datatable.length; i++){
      
        datatable[i].entrydate = date1
        datatable[i].counterid = counter
        datatable[i].opacpeg = 0
        datatable[i].opacbottle = 0
        datatable[i].opexpeg = 0
        datatable[i].opexbottle = 0
        datatable[i].oplocalpeg = 0
        datatable[i].oplocalbottle = 0
        datatable[i].opwsbottle = 0
        datatable[i].salbarpeg = 0
        datatable[i].salbarbottle = 0
        datatable[i].salacpeg = 0
        datatable[i].salacbottle = 0
        datatable[i].salexbottle = 0
        datatable[i].sallocalpeg = 0
        datatable[i].sallocalbottle = 0
        datatable[i].salwsbottle = 0
        datatable[i].rcdbarpeg = 0

        datatable[i].rcdbarbottle = 0
        datatable[i].rcdacpeg = 0
        datatable[i].rcdacbottle = 0
        datatable[i].rcdexpeg = 0
        datatable[i].rcdexbottle = 0
        datatable[i].rcdlocalpeg = 0
        datatable[i].rcdlocalbottle = 0
        datatable[i].rcdwsbottle = 0
        datatable[i].trnbarpeg = 0
        datatable[i].trnbarbottle = 0
        datatable[i].trnacpeg = 0
        datatable[i].trnacbottle = 0
        datatable[i].trnexpeg = 0
        datatable[i].trnexbottle = 0
        datatable[i].trnlocalpeg = 0
        datatable[i].trnlocalbottle = 0
        datatable[i].trnwsbottle = 0
        datatable[i].pucases = 0
        datatable[i].pubottle = 0
        datatable[i].opgdnbottle = 0
    }
    const data = datatable

    console.log("save data", data)

    fetch(global.url + "api/CounterStock/SaveOpeningStock", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        console.log(responseJson)
        if(responseJson.error == false) {
            alert(responseJson.message)
            // window.location.reload()
        }
        else{
            alert(responseJson.message)
        }
        
    })
    .catch((error)=>{
        alert("error occured")
        console.log(error)
    })
  }

  const filter = (e) => {
    const keyword = e.target.value;

    if (keyword !== '') {
    const results = tablerow.filter((user) => {
        return user.code.toLowerCase().startsWith(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
    });
        setTablerow(results);
    } else {
        getStockdata()
    // If the text field is empty, show all users
    }

    setSearch(keyword);
};

    

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
            <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Opening</p>
            </div>
            <Row>
                <Col sm={12}>
                    <Row>
                        <Col sm={6} lg={3}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <TextField
                                    id="datetime-local"
                                    label="Bill Date"
                                    type="date"
                                    defaultValue={year + '-' + month + '-' + date}
                                    sx={{ width: '100%' }}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                    onChange={(newValue) => {
                                        setDate1(newValue.target.value);
                                    }}
                                />
                            </FormControl>
                        </Col>
                        <Col sm={6} lg={2}>
                                    <FormControl fullWidth sx={{width: '100%',  mt: 3 }}>
                                            <InputLabel id="demo-simple-select-label">Counter</InputLabel>
                                            <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={counter}
                                            label="Age"
                                            onChange={(event) => counterChange(event)}
                                            size="small"
                                            >
                                                {
                                                    counterlist.map((i, k) => {
                                                        return(
                                                            <MenuItem value={i.ctrid}>{i.ctrname}</MenuItem>
                                                        )
                                                    
                                                    })
                                                }
                                            </Select>
                                    </FormControl>
                        </Col>
                        <Col sm={6} lg={2} style={{marginTop:30}}>
                            <Button onClick={() => getStockdata()} variant="outlined">Check</Button>
                        </Col>
                    </Row>                    
                </Col>
            </Row> 
            <Row>
               
                <Col sm={6} lg={2}>
                    <FormControl style={{marginTop: 20}} sx={{  width: '100%' }}>
                        <TextField value={search} onChange={(e) => filter(e)}  label="Code Search" id="outlined-basic" variant="outlined"  size="small"/>
                    </FormControl>
                </Col>
                <Col sm={6} lg={2} style={{marginTop:20}}>
                    <Button onClick={onSave}  variant="outlined">Update</Button>
                </Col>
            </Row>  
            <Row style={{marginTop: 20}}>
                <Col xs={12}>
                    <Table bordered responsive>
                        <TableHead>
                            <TableRow>
                                <TableCell>Item Code</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Bottle(Opening)</TableCell>
                                <TableCell>Pegs(Opening)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                tablerow.map((i, k) => {
                                    return(
                                        <TableRow>
                                            <TableCell>{i.code}</TableCell>
                                            <TableCell>{i.productname}</TableCell>
                                            {/* <TableCell>
                                              
                                                    <FormControl fullWidth sx={{width: '100%',  mt: 3 }}>
                                                            <InputLabel id="demo-simple-select-label">Counter</InputLabel>
                                                            <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={i.counterid}
                                                            label="Age"
                                                            onChange={(event) => counterChange(event, k)}
                                                            size="small"
                                                            >
                                                                {
                                                                    counterlist.map((i, k) => {
                                                                        return(
                                                                            <MenuItem value={i.ctrid}>{i.ctrname}</MenuItem>
                                                                        )
                                                                    
                                                                    })
                                                                }
                                                            </Select>
                                                    </FormControl>
                                                          
                                            </TableCell> */}
                                            <TableCell><input onChange={(event) => bottleChange(event, k)} value={i.opbarbottle} autocomplete="off"  type="number" /></TableCell>
                                            <TableCell><input onChange={(event) => pegChange(event, k)} value={i.opbarpeg} autocomplete="off"  type="number" /></TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                
                        </TableBody>
                    </Table>
                    {/* <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={tablerow.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        /> */}
                </Col>
            </Row>    
                 
          </Paper> 
      </div>
    )
}
const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(Opening);