import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as GoIcons from "react-icons/go";
import * as SiIcons from "react-icons/si";
import * as HiIcons from "react-icons/hi";                                          
import * as MdIcons from "react-icons/md";
import * as CgIcons from "react-icons/cg";
import * as FiIcons from "react-icons/fi";
import * as BiIcons from "react-icons/bi";

export const SidebarData = [
	// {
	// 	title: "Dashboard",
	// 	path: "/dashboard",
	// 	icon: <MdIcons.MdSettingsSystemDaydream style={{color: '#000'}}/>,
	// 	id: "Admin"
	// },
	{
		title: "Reception",
		path: "/counter/counter",
		icon: <MdIcons.MdOutlineCountertops style={{color: '#000'}}/>,
		iconClosed: <AiIcons.AiOutlinePlus style={{color: '#000'}}/>,
		iconOpened: <AiIcons.AiOutlineMinus  style={{color: '#000'}}/>,
		id: "Reception",
		subNav: [
			{
				title: "Bulk Booking",
				path: "/Counter/BulkBooking",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Room Status",
				path: "/Counter/RoomStatus",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Reservation",
				path: "/Counter/Reservation",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Reservation List",
				path: "/Counter/ReservationLists",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Day Close",
				path: "/Counter/DayClose",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			// {
			// 	title: "Guest Laundry",
			// 	path: "/Counter/GuestLaundry",
			// 	icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			// },
			{
				title: "Duplicate Bill",
				path: "/Counter/DuplicateBill",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Occupancy",
				path: "/Counter/Occupancy",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Register Occupancy",
				path: "/Counter/RegisterOccupancy",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			// {
			// 	title: "Voucher",
			// 	path: "/Counter/Voucher",
			// 	icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			// },
			{
				title: "Bill Summary",
				path: "/Counter/BillSummary",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Change Room",
				path: "/Counter/EditRoom",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Edit Address",
				path: "/Counter/EditAddress",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			// {
			// 	title: "Credit Sale",
			// 	path: "/Counter/CreditSale",
			// 	icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			// },
			{
				title: "Extra Person",
				path: "/Counter/ExtraPerson",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Edit GRC",
				path: "/Counter/grc",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
		]
	},
	{
		title: "Reports",
		path: "",
		icon: <HiIcons.HiOutlineDocumentReport style={{color: '#000'}}/>,
		iconClosed: <AiIcons.AiOutlinePlus style={{color: '#000'}}/>,
		iconOpened: <AiIcons.AiOutlineMinus  style={{color: '#000'}}/>,
		id: "Reception",
		subNav: [
			{
				title: "Cash Statement",
				path: "/Reports/CashStatement",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Rent & Tax Statement",
				path: "/Reports/RentAndTax",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Foreigners List",
				path: "/Reports/ForeignersLists",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Checkout Statement",
				path: "/Reports/CheckoutStatement",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Tariff Statement",
				path: "/Reports/Tariff",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			// {
			// 	title: "Incentive Report",
			// 	path: "/Reports/IncentiveReport",
			// 	icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			// },
			{
				title: "Reserved Rooms",
				path: "/Reports/ReservedRooms",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Room Change History",
				path: "/Reports/RoomChange",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Day Close History",
				path: "/Reports/FrontOffice",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
		]
	},
	{
		title: "Master",
		path: "",
		icon: <MdIcons.MdImportantDevices style={{color: '#000'}}/>,
		iconClosed: <AiIcons.AiOutlinePlus style={{color: '#000'}}/>,
		iconOpened: <AiIcons.AiOutlineMinus  style={{color: '#000'}}/>,
		id: "Reception",
		subNav: [
			{
				title: "Discountmaster",
				path: "/Master/Discountmaster",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			// {
			// 	title: "Incentive Master",
			// 	path: "/Master/Incetivemaster",
			// 	icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			// },
			{
				title: "Floor Master",
				path: "/Master/Floormaster",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Source Master",
				path: "/Master/Sourcemaster",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Room type Master",
				path: "/Master/Roomtype",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Room Tariff Master",
				path: "/Master/Roomtafriffmaster",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Room",
				path: "/Master/Room",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			// {
			// 	title: "LuxaryTax slablist",
			// 	path: "/Master/LuxaryTax",
			// 	icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			// },
			// {
			// 	title: "Addressproof",
			// 	path: "/Master/Addressproof",
			// 	icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			// },

			
		]
	},
	{
		title: "Accounts",
		path: "",
		icon: <MdIcons.MdImportantDevices style={{color: '#000'}}/>,
		iconClosed: <AiIcons.AiOutlinePlus style={{color: '#000'}}/>,
		iconOpened: <AiIcons.AiOutlineMinus  style={{color: '#000'}}/>,
		id: "Accounts",
		subNav: [
			{
				title: "Group",
				path: "/Accounts/Group",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Ledger",
				path: "/Accounts/Ledger",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Payment",
				path: "/Transaction/payment/add",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Receipt",
				path: "/Transaction/reciept/add",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Contra",
				path: "/Transaction/contra",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Journal",
				path: "/Transaction/journal",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Payment Report",
				path: "/Reports/PaymentReport",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Receipt Report",
				path: "/Reports/ReceiptReport",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Cashbook",
				path: "/Reports/Cashbook",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Bankbook",
				path: "/Reports/Bankbook",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Daybook",
				path: "/Accounts/Daybook",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Ledger Detailed Report",
				path: "/Accounts/LedgerReport",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Trial Balance",
				path: "/Accounts/TrailBalance",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Profit & Loss",
				path: "/Accounts/ProfitLoss",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Balance Sheet",
				path: "/Accounts/BalanceSheet",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
		]
	},
	{
		title: "Bar",
		path: "",
		icon: <FiIcons.FiSettings style={{color: '#000'}}/>,
		iconClosed: <AiIcons.AiOutlinePlus style={{color: '#000'}}/>,
		iconOpened: <AiIcons.AiOutlineMinus  style={{color: '#000'}}/>,
		id: "Bar",

		subNav: [
			{
				title: "Category",
				path: "/Barmaster/Category",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			// {
			// 	title: "Sub Category",
			// 	path: "/Barmaster/SubCategory",
			// 	icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			// },
			{
				title: "Itemmaster",
				path: "/Barmaster/Itemmaster",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Price List",
				path: "/Barmaster/Pricelist",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Food Price List",
				path: "/Barmaster/FoodPricelist",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Table master",
				path: "/Barmaster/Tablemaster",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Waiter master",
				path: "/Barmaster/Waitermaster",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
		]
	},
	{
		title: "Transactions",
		path: "",
		icon: <AiIcons.AiOutlineTransaction style={{color: '#000'}}/>,
		iconClosed: <AiIcons.AiOutlinePlus style={{color: '#000'}}/>,
		iconOpened: <AiIcons.AiOutlineMinus  style={{color: '#000'}}/>,
		id: 'Bar',
		subNav: [
			{
				title: "Countersales",
				path: "/Transaction/Countersale",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Bar Day Entry",
				path: "/Transaction/BarDayEntry",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			// {
			// 	title: "Opening",
			// 	path: "/Transaction/Opening",
			// 	icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			// },
			{
				title: "Purchase",
				path: "/Transaction/Purchase/add",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Transferentry",
				path: "/Transaction/Transferentry",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Godown issue",
				path: "/Transaction/Godown",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Miscellaneous Purchase",
				path: "/Transaction/MiscellaneousPurchase/add",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			
			{
				title: "Update Day sales",
				path: "/Transaction/updatedaysales",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Post Day sales",
				path: "/Transaction/postdaysales",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			
			{
				title: "POS",
				path: "/pos",
				icon: <AiIcons.AiOutlineTransaction style={{color: '#000'}}/>,
			},
		]
	},
	{
		title: "Bar Report",
		path: "",
		icon: <FiIcons.FiSettings style={{color: '#000'}}/>,
		iconClosed: <AiIcons.AiOutlinePlus style={{color: '#000'}}/>,
		iconOpened: <AiIcons.AiOutlineMinus  style={{color: '#000'}}/>,
		id: "Bar",
		subNav: [
			
			// {
			// 	title: "Barday Entry",
			// 	path: "/Reports/Bardayentry",
			// 	icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			// },
			{
				title: "Counter Stock Report",
				path: "/Reports/Counterstock",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Actual Sales Statement",
				path: "/Reports/Actualstalestatement",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Sale Statement Report",
				path: "/Reports/Salestatementreport",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Ws Report",
				path: "/Reports/Wsreport",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Excise Statement Report",
				path: "/Reports/Excisestatementreport",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Excise Consolidate Report",
				path: "/Reports/Exciseconsolidate",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Waiter Sales Report",
				path: "/Reports/Waitersalesreport",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Godown Issue Report",
				path: "/Reports/Godownreport",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Purchase Report",
				path: "/Reports/Purchasereport",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Miscellaneous Purchase Report",
				path: "/Reports/Miscellaneous",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Counter stock transfer report",
				path: "/Reports/Godowntransfer",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Stock Value Report",
				path: "/Reports/Stockvalue",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Godown Stock Report",
				path: "/Reports/Godownstock",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Food Sales Report",
				path: "/Reports/foodsalesreport",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Kot/Bot",
				path: "/Reports/kotbot",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Sales bill list",
				path: "/Reports/salesbilllist",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Running bill list",
				path: "/Reports/runningbilllist",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Cancelled bill list",
				path: "/Reports/cancelledbilllist",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
		]
	},
	// {
	// 	title: "Maintenance",
	// 	path: "",
	// 	icon: <AiIcons.AiOutlineTransaction style={{color: '#000'}}/>,
	// 	iconClosed: <AiIcons.AiOutlinePlus style={{color: '#000'}}/>,
	// 	iconOpened: <AiIcons.AiOutlineMinus  style={{color: '#000'}}/>,

	// 	subNav: [
	// 		{
	// 			title: "Item Master",
	// 			path: "/Maintenance/maintenancemaster",
	// 			icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
	// 		},
	// 		{
	// 			title: "Stock List",
	// 			path: "/Maintenance/stocklist",
	// 			icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
	// 		},
	// 		{
	// 			title: "Service Entry",
	// 			path: "/Maintenance/serviceentry",
	// 			icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
	// 		},
	// 		{
	// 			title: "Service View",
	// 			path: "/Maintenance/serviceview",
	// 			icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
	// 		},
	// 	]
	// },
	{
		title: "Admin",
		path: "",
		icon: <AiIcons.AiOutlineTransaction style={{color: '#000'}}/>,
		iconClosed: <AiIcons.AiOutlinePlus style={{color: '#000'}}/>,
		iconOpened: <AiIcons.AiOutlineMinus  style={{color: '#000'}}/>,

		subNav: [
			{
				title: "Branch",
				path: "/Admin/Branch",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "Employee",
				path: "/Admin/User",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "User Rights",
				path: "/Admin/UserRights",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
			{
				title: "User Logs",
				path: "/Admin/Userlogs",
				icon: <BiIcons.BiBookAlt style={{color: '#000'}}/>,
			},
		]
	},
	
];
