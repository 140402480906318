import React, {useState, useEffect,useRef} from 'react';
import Paper from '@mui/material/Paper';
import { Table , Row, Col, Modal, Container} from "react-bootstrap";
import TextField from '@mui/material/TextField';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { connect } from "react-redux";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import * as IconName from "react-icons/bs";
import * as GI from "react-icons/gi";
import * as BI from "react-icons/bi";
import * as RI from "react-icons/ri";
import { Rings  } from  'react-loader-spinner'
import {
	getAllProduct
  } from "../../redux/Shopping/shopping-actions";
import Searchlist from "../../components/Searchlist/searchlist";

function Transferentry({branchid, getAllProduct, productlist}) {
    const [modal, setModal] = useState(false);
    const [age, setAge] = React.useState('');
    

    const [from, setFrom] = React.useState('')
    const [to, setTo] = React.useState('');
    const [loader, setLoader] = useState(false);

    const [counterlist, setCounterlist] = React.useState([]);
    const inputRef = useRef();

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();

    const [transferdate, setTransferdate] = React.useState(year + '-' + month + '-' + date);
    const [counter, setCounter] = useState("");
    const [productname, setProductname] = useState("")
    const hasResultsProduct = productname.length > 0;
    const [selectedIndex, setSelectedindex] = React.useState("")

    const [tablerow, setTablerow] = React.useState([{
        transferid: 0,
        transferdate: transferdate,
        counterfrom: 1,
        counterto: 1,
        createdby: global.userid,
        createdon: "",
        bottle: 1,
        description: "",
        prdid: 1,
        branchid: branchid,
        code: "",
        productname: ""
      }]);

    const toChange = (event, index) => {
        var input=[...tablerow]
        input[index].counterto = event.target.value
        input[index].description = ""
        input[index].prdid = 0
        input[index].code = ""
        input[index].productname = ""
        setTablerow(input)
    };

    const fromChange = (event, index) => {
        var input=[...tablerow]
        input[index].counterfrom = event.target.value

        input[index].description = ""
        input[index].prdid = 0
        input[index].code = ""
        input[index].productname = ""
        setTablerow(input)
    };

    useEffect(() => {
        getCounter()
        // getData()
        getDate()
      }, [branchid, transferdate]);

      const getDate = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/CounterStock/GetTransactionDate?branchId=" + branchid, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("GetTransactionDate response", responseJson)
              setTransferdate(responseJson)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const getCounter = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Counter/GetCounterlist?companyId=1&branchId="+ branchid + "&includeBlocked=false", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("counterlist response", responseJson)
              setCounterlist(responseJson)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const addRow = () => {
          const input = [...tablerow]
          setTablerow([...tablerow, {
            transferid: 0,
            transferdate: transferdate,
            counterfrom: 1,
            counterto: 1,
            createdby: global.userid,
            createdon: "",
            bottle: 1,
            description: "",
            prdid: 1,
            branchid: branchid,
            code: "",
            productname: ""
          }])
      }

      const handleOnChange = (event, key) => {
        console.log("event.currentTarget.value", event.currentTarget.value)
        var textArray1 = [...tablerow]
        textArray1[key].code = event.currentTarget.value;
        setTablerow(textArray1)
        setSelectedindex(key)
        setProductname(event.currentTarget.value)
    }

    const bottleChange = (event, key) => {
        var textArray1 = [...tablerow]
        textArray1[key].bottle = event.currentTarget.value;
        setTablerow(textArray1)
    }

    const getSearch = (text, key, dtprdid,counterid, dtqty) => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Products/GetProductByCode?branchId=" + branchid +"&code=" + text, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("code search Response", responseJson)
             if(responseJson.error != true){
                var input = [...tablerow]
                input[key].code = responseJson.code;
                input[key].productname = responseJson.productname; 
                input[key].stock = responseJson.stock; 
                input[key].prdid = responseJson.productid; 
                
                console.log("input", input)
                setTablerow(input)
                setProductname("")
                var token = localStorage.getItem("token")
                fetch(global.url + "api/Products/CheckOutOfStockInTransfer?counterId=" + counterid + "&branchId=" + branchid + "&productId=" +  responseJson.productid + "&bottle=" +  dtqty + "&peg=" + 0, {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    
                })
                .then((response)=> response.json())
                .then((responseJson)=>{
                    console.log("CheckOutOfStock", responseJson)
                    if(responseJson == 0){
                        alert("Out of stock")
                        console.log("index", key)
                        var input = [...tablerow]
                        input.splice(key, 1)
                        setTablerow(input)
                        setProductname("")
                    }
                    
                })
                .catch((error)=>{
                    console.log(error)
                })
             }
             
        })
        .catch((error)=>{
            console.log(error)
        })

        fetch(global.url + "api/Products/GetCounterStockByCode?counterid=" + tablerow[key].counterfrom  + "&branchId=" + branchid +"&code=" + text, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("GetCounterStockByCode search Response", responseJson)
             if(!responseJson.message){
                var input = [...tablerow]
                input[key].stockBottle = responseJson
                console.log("input", input)
                setTablerow(input)
             }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const onSave = () => {
        setLoader(true)
        var blocksave = false

        for(var i=0; i<tablerow.length; i++){
            if(tablerow[i].counterfrom == tablerow[i].counterto) {
                blocksave = true
            }
        }

        if(blocksave == false) {
            var token = localStorage.getItem("token")
            console.log("token", token)
            const data = tablerow
    
            console.log("save data", data)
            
            fetch(global.url + "api/CounterStock/SaveCounterStockTransfer", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                
                if(responseJson.error == false) {
                    alert(responseJson.message)
                    window.location.reload()
                }
                else{
                    alert(responseJson.message)
                }
                setLoader(false)
                 console.log("Transfer Response", responseJson)
            })
            .catch((error)=>{
                alert("error occured")
                setLoader(false)
                console.log(error)
            })
        }
        else{
           
            alert("Same counter to counter not allowed")
            setLoader(false)
        }
        
    }


    useEffect(() => {
        inputRef.current.focus();
        getAllProduct()
      }, []);

    const deleteRow = (index) => {
        console.log("index", index)
      var input = [...tablerow]
      input.splice(index, 1)
      setTablerow(input)
  }

    const getData = () => {
        var token = localStorage.getItem("token")
        var data = {
            "companyId": 1,
            "branchId": branchid,
            "fromDate":  transferdate,
            "toDate": transferdate,
            "createdBy": global.userid,
            "billno": "",
            "counterId": counter
        }
        console.log("getdatareq", data)
        fetch(global.url + "api/CounterStock/GetTransferList", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("GetTransferList view Response", responseJson)
            setTablerow(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const stockCheck = async(dtprdid,counterid, dtqty, index, type) => {
        if(type != "qty"){
           await getSearch(tablerow[index].code, index, dtprdid,counterid, dtqty)
        }
        else{
            var token = localStorage.getItem("token")
            fetch(global.url + "api/Products/CheckOutOfStockInTransfer?counterId=" + counterid + "&branchId=" + branchid + "&productId=" + dtprdid + "&bottle=" +  dtqty + "&peg=" + 0, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("CheckOutOfStock fcff", responseJson)
                if(responseJson == 0){
                    alert("Out of stock")
                    console.log("index", index)
                    var input = [...tablerow]
                    input.splice(index, 1)
                    setTablerow(input)
                    setProductname("")
                }
                
            })
            .catch((error)=>{
                console.log(error)
            })
        }
        
        
     
    }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
            <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}> Transfer Entry </p>
            </div>
            <Row>
                <Col sm={12}>
                    <Row>
                        
                        <Col sm={6} lg={3}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <TextField
                                    disabled
                                    value={transferdate}
                                    label="Transaction date"
                                />
                            </FormControl>
                        </Col>
                        
                    </Row>
                
                    
                </Col>
            </Row> 
            <Row style={{marginTop: 20}}>
                <Col xs={12}>
                    <Table bordered responsive>
                        <thead>
                            <tr>
                                <th>From</th>
                                <th>To</th>
                                <th>Code</th>
                                <th>Name</th>
                                <th>Stock</th>
                                <th>Bottles</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                tablerow.map((item, index)=> {
                                    return(
                                    <tr>
                                        <td>
                                            <FormControl fullWidth sx={{width: '100%' }}>
                                                    <InputLabel id="demo-simple-select-label">From</InputLabel>
                                                    <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={item.counterfrom}
                                                    label="Age"
                                                    onChange={(event) => fromChange(event, index)}
                                                    size="small"
                                                    >
                                                        {
                                                            counterlist.map((i, k) => {
                                                                return(
                                                                    <MenuItem value={i.ctrid}>{i.ctrname}</MenuItem>
                                                                )
                                                            
                                                            })
                                                        }
                                                    </Select>
                                            </FormControl>
                                        </td>
                                        <td>
                                            <FormControl fullWidth sx={{width: '100%' }}>
                                                    <InputLabel id="demo-simple-select-label">To</InputLabel>
                                                    <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={item.counterto}
                                                    label="Age"
                                                    onChange={(event) => toChange(event, index)}
                                                    size="small"
                                                    >
                                                    {
                                                        counterlist.map((i, k) => {
                                                            return(
                                                                <MenuItem value={i.ctrid}>{i.ctrname}</MenuItem>
                                                            )
                                                        
                                                        })
                                                    }
                                                    </Select>
                                            </FormControl>
                                        </td>
                                        <td> 
                                            <input style={{width: '100%'}} autocomplete="off" ref={inputRef} type="text" name="query" value={item.code} onChange={(event) => handleOnChange(event, index)} />
                                            {hasResultsProduct && selectedIndex === index  && (
                                                <Searchlist hasResultsProduct={hasResultsProduct} productlist={productlist} productname={productname} inputRef={inputRef} listClickname={() => stockCheck(item.prdid, item.counterfrom, 1, index, "")} index={index} countertransfer={true}/>
                                            )}
                                        </td>
                                        
                                    
                                        <td>{item.productname}</td>
                                        <td>{item.stockBottle}</td>
                                        <td><input style={{width: '100%'}}  autocomplete="off" ref={inputRef} type="text" name="query" value={item.bottle}  onChange={(event) => bottleChange(event, index)} /></td>
                                        <td><Button onClick={() => deleteRow(index)} variant="outlined">Delete</Button></td>
                                    </tr>
                                    )
                                })
                            }
                           
                            
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row style={{marginTop: 20}}>
                    
                    <Col xs={6} >
                        <ul className="list-inline">
                            <li><Button onClick={() => addRow()} variant="outlined" style={{marginTop: 30}}>Add New</Button></li>
                        </ul>
                    </Col>
                </Row>
            <div style={{marginTop: 30}}>
                <Row>
                    <Col xs={2} >
                    <div style={{justifyContent: 'center', display: 'flex'}}>
                            {
                                loader ?
                                <Rings
                                    height="50"
                                    width="50"
                                    color='#0d6efd'
                                    ariaLabel='loading'
                                />:
                                <Button onClick={() => onSave()} variant="outlined" style={{marginTop: 30}}>Save</Button>
                            }
                    </div>
                    </Col>
                </Row>
            </div>
            
          </Paper> 
          <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                  <div>
                      <Container>
                        <Row>
                            <Col xs={12} md={6}>
                                <p style={{fontSize: 16, marginTop: 10, marginBottom: 10}}>Last 5 Purchases</p>
                                <Table bordered responsive>
                                <thead>
                                        <tr>
                                            <th>Sl No.</th>
                                            <th>Doc Date</th>
                                            <th>Doc No.</th>
                                            <th>Tax</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                            <Col xs={12} md={6}>
                                <p style={{fontSize: 16, marginTop: 10, marginBottom: 10}}>Last 5 Purchase Order</p>
                                <Table bordered responsive>
                                    <thead>
                                        <tr>
                                            <th>Sl No.</th>
                                            <th>Doc Date</th>
                                            <th>Doc No.</th>
                                            <th>Tax</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                            <Col xs={12} md={6}>
                                <p style={{fontSize: 16, marginTop: 10, marginBottom: 10}}>Last 5 Payment</p>
                                <Table bordered responsive>
                                    <thead>
                                        <tr>
                                            <th>Sl No.</th>
                                            <th>Doc Type</th>
                                            <th>Doc No.</th>
                                            <th>Voucher Type</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                      </Container>
                  </div>
              </Modal.Body>
           </Modal>
      </div>
    )
}
const mapDispatchToProps = (dispatch) => {
    return {
		getAllProduct: () => {
			dispatch(getAllProduct())
		},
    };
  };

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
        productlist: state.shop.productlist
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Transferentry);