import React, {useState, useEffect,useRef} from 'react';
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button, Modal} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { connect } from "react-redux";
import ReactToPrint from 'react-to-print';
import { Rings  } from  'react-loader-spinner'  
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import logo from '../../assets/images/logo.jpeg';
import { BiLogo500Px } from 'react-icons/bi';

  function FoodSalesReport({branchid, branchdetails}) {
    const [source, setSource] = React.useState([])
    const componentRef = useRef();

    const [loader, setLoader] = useState(false);
    const [grandtotal, setGrandtotal] = useState(0);
    const [counterlist, setCounterlist] = React.useState([]);
    const [counter, setCounter] = useState("");
    const [countername, setCountername] = useState("");

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();


    const [date1, setDate1] = React.useState(year + '-' + month + '-' + date);
    const [date2, setDate2] = React.useState(year + '-' + month + '-' + date);
  

    useEffect(() => {
        getData()
        getCounter()
    }, [branchid]);

    const getCounter = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Counter/GetCounterlist?companyId=1&branchId="+ branchid + "&includeBlocked=false", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("counterlist response", responseJson)
              setCounterlist(responseJson)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

    useEffect(() => {
        var totalAmount = 0;
        for (var i = 0; i < source.length; i++) {
            totalAmount += parseFloat(source[i].dtamount)
        }
       setGrandtotal(totalAmount.toFixed(2))
    }, [source]);

    const getData = () => {
        var token = localStorage.getItem("token")
        console.log(token)
        var data = {
            "companyId": 1,
            "branchId": branchid,
            "fromDate": date1,
            "toDate": date2,
            "createdBy": "",
            "billno": "",
            "counterId": counter,
            "userId": "",
            "soldItemsOnly": "",
            "accId": ""
          }
        fetch(global.url + "api/SalesReport/FoodSalesReport", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            //  console.log("purchase report Response", responseJson)
             setSource(responseJson)
        })
        .catch((error)=>{
            setLoader(false)
            console.log(error)
        })
    }

    const counterChange = (event) => {
        setCounter(event.target.value)
        getCounterbyid(event.target.value)
      }

      const getCounterbyid = (id) => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Counter/GetCounterById?id=" + id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("GetCounterById", responseJson)
              if(responseJson.message){
                setCountername("All")
              }
              else{
                setCountername(responseJson.ctrname)
              }
              
          })
          .catch((error)=>{
              console.log(error)
          })
      }
      const formatDate = (datestring) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
        //   console.log("timeString12hr", timeString12hr)

          var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          return formattedDate; //03/02/2021
    }

      return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2 }}>
            <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>Food Sales Report</p> 
            <Row>
                    <Col xs={12}  className='columnborder'>
                        <div>
                            <Row>
                                <Col sm={6} lg={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="From"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            value={date1}
                                            onChange={(newValue) => {
                                                setDate1(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col sm={6} lg={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="To"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            value={date2}
                                            onChange={(newValue) => {
                                                setDate2(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col sm={6} lg={2}>
                                        <FormControl fullWidth sx={{width: '100%',  mt: 2 }}>
                                                <InputLabel id="demo-simple-select-label">Counter</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={counter}
                                                label="Age"
                                                onChange={(event) => counterChange(event)}
                                                size="small"
                                                >
                                                    <MenuItem value={0}>ALL</MenuItem>
                                                    {
                                                        counterlist.map((i, k) => {
                                                            return(
                                                                <MenuItem value={i.ctrid}>{i.ctrname}</MenuItem>
                                                            )
                                                        
                                                        })
                                                    }
                                                </Select>
                                        </FormControl>
                            </Col>
                                <Col xs={12} md={2}>
                                    <div style={{justifyContent: 'center', display: 'flex'}}>
                                            {
                                                loader ?
                                                <Rings
                                                    height="50"
                                                    width="50"
                                                    color='#0d6efd'
                                                    ariaLabel='loading'
                                                />: 
                                                <Button onClick={getData} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 17, width: '50%'}} variant="contained">Search</Button>
                                            }
                                        </div>
                                </Col>
                                <Col xs={12} md={4} >
                                    <ReactToPrint
                                        trigger={() => <Button style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">Print this out!</Button>}
                                        content={() => componentRef.current}
                                    />
                                </Col>
                            </Row>
                           
                        </div>
                    </Col> 
                </Row>
                <div style={{margin: 20,}}>
                    <TableContainer sx={{ maxHeight: 440, marginTop: 2 }}>
                        <div  className='section-to-print' ref={componentRef} style={{width: '95%'}}>
                        <Table id="table-to-xls" stickyHeader aria-label="sticky table" >
                        <TableHead className="tableheaderexe">
                        <TableRow style={{height: 80}} >
                            <TableCell colSpan={6}  style={{padding: 0}}>
                                <Row>
                                    <img src={logo} style={{width: 100, position: 'absolute', top: 0}}/>
                                    
                                    <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                                    <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                                    </Col>
                                    <p className='printdate' style={{ position: 'absolute', left: '85%'}}>{formatDate(new Date)}</p>  
                                </Row>
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            
                            <TableCell colSpan={6} style={{padding: 0}}>
                                <div style={{display: 'flex', justifyContent: 'center', fontWeight: 600}} className='captiontable'>
                                    FOOD STATEMENT OF {countername} FROM {date1.split("-").reverse().join("-")} TO {date2.split("-").reverse().join("-")}
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Sl.No</TableCell>
                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Description</TableCell>
                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Counter</TableCell>
                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold',textAlign: 'right'}}>Rate</TableCell>
                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold',textAlign: 'right'}}>Quantity</TableCell>
                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold',textAlign: 'right'}}>Amount</TableCell>
                            </TableRow>
                        </TableHead>
                            <TableBody>
                                {source.map((i, index) => {
                                    return(
                                        <TableRow>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>{index+1}</TableCell>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>{i.productname}</TableCell>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>{i.ctrname}</TableCell>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}}>{i.dtprice.toFixed(2)}</TableCell>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}}>{i.dtqty}</TableCell>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}}>{i.dtamount.toFixed(2)}</TableCell>
                                        </TableRow>
                                    )
                                })}

                                <TableRow>
                                    <TableCell colSpan={5} className='tablecellPrint' style={{fontWeight: 'bold',textAlign: 'right'}}>Grand Total</TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold',textAlign: 'right'}}>{grandtotal}</TableCell>
                                    
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                    </TableContainer>
                </div>
            </Paper> 
        </div>
      );
  }

  const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(FoodSalesReport);
