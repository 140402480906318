import React, {useState, useEffect,useRef} from 'react';
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button, Modal} from "react-bootstrap";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const columns = [
    { id: '1', label: 'Allow Rights', minWidth: 50,span: false },
    { id: '2', label: 'Form/Rights', minWidth: 50,span: false },
  ];
  const source = [{}, {}]

  function UserRights() {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [userlists, setUserlist] = React.useState([]);
    const [user, setUser] = React.useState("");
    const [userrights, setUserrights] = React.useState([]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const userChange = (event) => {
        setUser(event.target.value) 
      }

      const blockChange = (e,index) => {
        let isChecked = e.target.checked;
        console.log(isChecked)
        if(isChecked == true) {
            var input  = [...userrights]
            input[index].blocked = 0
            setUserrights(input)
        }

        else{
            var input  = [...userrights]
            input[index].blocked = 1
            setUserrights(input)
        }
    }

    useEffect(() => {
        getUsers()
    }, [])

    const getUsers = () => {
        var token = localStorage.getItem("token")
       
        fetch(global.url + "api/UserMgmt/GetUserList", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("counterlist response", responseJson)
              setUserlist(responseJson)
             
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const onSubmit = () => {
          console.log("userrights", userrights)
        var token = localStorage.getItem("token")
        var userid = localStorage.getItem("userid")
        var data = userrights
        fetch(global.url + "api/UserMgmt/SaveUserMgmt", {
            method: "POST", 
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("counterlist", responseJson)
              if(responseJson.error == false){
                alert(responseJson.message)
                window.location.reload()
              }
              else{
                alert(responseJson.message)
              }
            //   setUserrights(responseJson)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

    const searchUser = () => {
        var token = localStorage.getItem("token")
        var userid = localStorage.getItem("userid")
        // var data = {
        //     usermgid : 0,
        //     roleid : user,
        //     userid : userid,
        //     menuid: 1,
        //     blocked : 1,
        //     formname : ""
        // }
        fetch(global.url + "api/UserMgmt/GetUsersSelectedMenuList?userId=" + user, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            // body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("counterlist", responseJson)
              setUserrights(responseJson)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2 }}>
            <p style={{fontSize: 25, fontWeight: 'bold'}}>User Rights</p> 
            <Row>
                <Col sm={6} lg={2}>
                    <FormControl fullWidth sx={{width: '100%',  mt: 3 }}>
                            <InputLabel id="demo-simple-select-label">User</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={user}
                            label="Age"
                            onChange={(event) => userChange(event)}
                            size="small"
                            >
                                {
                                    userlists.map((i, k) => {
                                        return(
                                            i.userId !=1 ?
                                            <MenuItem value={i.userId}>{i.firstName}</MenuItem> :
                                            null
                                        )
                                    
                                    })
                                }
                            </Select>
                    </FormControl>
                </Col>              
                <Col xs={12} md={2}>
                    <div  style={{display: 'flex', justifyContent: 'center'}}>
                        <Button onClick={searchUser} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">Search</Button>
                    </div>
                </Col>       
            </Row>
            <Row>
                <Col xs={12} md={6}>
                    <div className='bulk-table'>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                                        colSpan={column.span == true ? 2: 1}
                                    >
                                        {column.label}
                                        
                                    </TableCell>
                                    ))}
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {userrights
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((i, index) => {
                                    return (
                                        <TableRow >

                                            <TableCell>
                                                <FormControl sx={{  width: '100%' }}>
                                                    <FormControlLabel control={
                                                    <Checkbox checked={i.blocked == 0 ? true : false} onChange={e => blockChange(e, index)} />
                                                    } label="" />
                                                </FormControl>
                                            </TableCell>
                                            <TableCell>
                                            {i.formname}
                                            </TableCell>
                                        
                                        </TableRow>
                                    );
                                    })}
                                </TableBody>
                            </Table>
                            </TableContainer>
                            
                    </div>  
                </Col>
                <Col xs={12} md={6}>
                        <Button onClick={() => onSubmit()} variant="contained" style={{marginTop: 30, backgroundColor: '#03a5fc', marginRight: 10, width: 200, color: '#fff'}}>Save</Button>
                    </Col>
            </Row>
                
            </Paper> 
        </div>
      );
  }


export default UserRights;
