import React, {useState, useEffect, useRef} from 'react';
import Paper from '@mui/material/Paper';
import { Table , Row, Col, Modal, Container} from "react-bootstrap";
import TextField from '@mui/material/TextField';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import * as IconName from "react-icons/bs";
import * as GI from "react-icons/gi";
import * as BI from "react-icons/bi";
import * as RI from "react-icons/ri";
import { connect } from "react-redux";
import { Rings  } from  'react-loader-spinner';
import {
	getAllProduct
  } from "../../redux/Shopping/shopping-actions";
import Searchlist from "../../components/Searchlist/searchlist";

function Godownissue({branchid, getAllProduct, productlist}) {
    const [modal, setModal] = useState(false);
    const [age, setAge] = React.useState('');
    
    const [counter, setCounter] = React.useState('');

    const [billtypelist, setBilltypelist] = React.useState([]);
    const [docnum, setDocnum] = React.useState("");
    const [docno, setDocno] = React.useState("");
    const [billType, setBilltype] = React.useState("");

    const [subtotal, setSubtotal] = React.useState(0);
    const [total, setTotal] = React.useState(0);

    const [loader, setLoader] = useState(false);

    const [counterlist, setCounterlist] = React.useState([]);
    const [productname, setProductname] = useState("")
    const hasResultsProduct = productname.length > 0;
    const [selectedIndex, setSelectedindex] = React.useState("")

    const [tablerow, setTablerow] = React.useState([{
        dtid: 0,
        vid: 0,
        dtprdid: 0,
        dtunitid: 4,
        dtqty: 0.0,
        dtprice: 0.0,
        dtamount: 0.0,
        dttotal: 0.0,
        dtremarks: "",
        bottle: 0,
        cases: 0,
        procode: "",
        proname: "",
        bottlepercase: 0,
        dttotalbot: 0
      }]);

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();

    const [docdate, setDocdate] = React.useState(year + '-' + month + '-' + date +  'T' + hours + ":" + minutes);

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const inputRef = useRef();

    const counterChange = (event) => {
        setCounter(event.target.value)
    }

    const billtypeChange = (event) => {
        console.log("event", event)
        setBilltype(event);
        getInvoiceno(event)
      };

      useEffect(() => {
        getBilltype()
        getCounter()
        getDate()
      }, [branchid]);

      const getCounter = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Counter/GetCounterlist?companyId=1&branchId="+ branchid + "&includeBlocked=false", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("counterlist response", responseJson)
              setCounterlist(responseJson)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const getBilltype = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/BillSettings/GetBillTypeByForm?companyId=1&branchId=" + branchid + "&formType=GTRN", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("billSettigsList response", responseJson)
              setBilltypelist(responseJson.billSettigsList)
              billtypeChange(responseJson.billSettigsList[0].btypeid)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const getInvoiceno = (id) => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/BillSettings/GetNextBillNo?billId=" + id , {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              setDocno(responseJson.billprefix + responseJson.billno)
              setDocnum(responseJson.billno)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const handleOnChange = (event, key) => {
        console.log("event.currentTarget.value", event.currentTarget.value)
        var textArray1 = [...tablerow]
        textArray1[key].procode = event.currentTarget.value;
        setSelectedindex(key)
        setProductname(event.currentTarget.value)
        setTablerow(textArray1)
       
    }

    const listClickname = (text, key) => {
            getSearch(text, key)
    }

    const getSearch = (text, key) => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Products/GetProductByCode?branchId=" + branchid +"&code=" + text, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("code search Response", responseJson)
             if(responseJson.error != true){
               
                    var input = [...tablerow]
                    input[key].procode = responseJson.code;
                    input[key].proname = responseJson.productname; 
                    input[key].dtprdid = responseJson.productid;
                    input[key].bottlepercase = responseJson.bottlepercase;
                    input[key].godownStockBottle = responseJson.godownStockBottle
                    input[key].bottle = 0
                    input[key].cases = 0
                    
                    input[key].dtprice = responseJson.pprice;
                    // input[key].dtamount = (responseJson.bottlepercase*responseJson.pprice).toFixed(2)
                    // input[key].dttotal = (responseJson.bottlepercase*responseJson.pprice).toFixed(2)
                     
                    console.log("input", input)
                    setTablerow(input)
                    setProductname("")
               
             }
             
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const caseChange = (event, key, bottlepercase) => {
        var input = [...tablerow]
        console.log("value", input[key].bottlepercase)
        if(((input[key].bottlepercase * parseFloat(event.target.value)) + input[key].bottle) > input[key].godownStockBottle){
           alert("Out of stock")
           input.splice(key, 1)
            setTablerow(input)
        }
        else{
           
            const qtity = (parseFloat(event.target.value)  * bottlepercase)+ input[key].bottle
            console.log("input[key].dtprice", input[key].dtprice)
            input[key].dtqty = qtity
            // input[key].dtamount = (qtity * input[key].dtprice).toFixed(2)
            // input[key].dttotal = (qtity * input[key].dtprice).toFixed(2)
            input[key].dttotalbot = (parseFloat(event.target.value)*input[key].bottlepercase) + input[key].bottle
            input[key].dtamount = (input[key].dtprice/bottlepercase * input[key].dtqty).toFixed(2)
            input[key].dttotal = (input[key].dtprice/bottlepercase * input[key].dtqty).toFixed(2)
            
            console.log("input", input)
            setTablerow(input)
        }
        
    }

    const caseChange1 = (event, key) => {
        var input = [...tablerow]
        input[key].cases = parseFloat(event.target.value) 
        setTablerow(input)
    }

    const bottleChange1 = (event, key) => {
        var input = [...tablerow]
        input[key].bottle = parseFloat(event.target.value) 
        setTablerow(input)
    }

    const bottleChange = (event, key, bottlepercase) => {
        var input = [...tablerow]
        if((((input[key].cases * input[key].bottlepercase)) + parseFloat(event.target.value)) > input[key].godownStockBottle){
            alert("Out of stock")
            input.splice(key, 1)
            setTablerow(input)
         }
         else{
            input[key].bottle = parseFloat(event.target.value)
            const qtity = parseFloat(event.target.value) + (input[key].cases * input[key].bottlepercase)
            input[key].dtqty = qtity
            // input[key].dtamount = (qtity * input[key].dtprice).toFixed(2)
            // input[key].dttotal = (qtity * input[key].dtprice).toFixed(2)
            input[key].dttotalbot = (input[key].cases * input[key].bottlepercase) + parseFloat(event.target.value)
            input[key].dtamount = (input[key].dtprice/bottlepercase * input[key].dtqty).toFixed(2)
            input[key].dttotal = (input[key].dtprice/bottlepercase * input[key].dtqty).toFixed(2)
            console.log("input", input)
            setTablerow(input)
         }
    }

    const addRow = () => {
        var input = [...tablerow]
        setTablerow([...tablerow, {
            dtid: 0,
            vid: 0,
            dtprdid: 0,
            dtunitid: 4,
            dtqty: 0.0,
            dtprice: 0.0,
            dtamount: 0.0,
            dttotal: 0.0,
            dtremarks: "",
            bottle: 0,
            cases: 0,
            procode: "",
            proname: "",
            bottlepercase: 0,
            dttotalbot:0
          }])
    }

    const getTotalammount = () => {
        var totalAmount = 0;
        for (var i = 0; i < tablerow.length; i++) {
          totalAmount += parseFloat(tablerow[i].dttotal)
       }
          setSubtotal(totalAmount)
          setTotal(totalAmount)
    }

    useEffect(() => {
        getTotalammount()
       
      },[tablerow])

      const onSave = () => {
        setLoader(true)
        var token = localStorage.getItem("token")
        if(counter == ""){
            setLoader(false)
           return alert("counter empty")
        }
        const valueArr = tablerow.map((item) => {
            return item.dtprdid
          });
          const isDuplicate = valueArr.some((item, idx) => {
            return valueArr.indexOf(item) !== idx
          });
        if(isDuplicate == true) {
            setLoader(false)
           return alert("Doesn't allow single item in multiple row")
        }

        var zeroError = false
        for(var i = 0; i<tablerow.length; i++) {
            if(tablerow[i].bottle == 0 && tablerow[i].cases == 0){
                 
                 zeroError = true
            }
            else{
                zeroError = false
            }
        }

        console.log("token", token)
        if(zeroError) {
            setLoader(false)
            alert("Bottle and case is zero")
        }
        else{
            const data = {
                "gdnid": 0,
                "vid": 0,
                "gdnformid": billType,
                "gdndocdate": docdate,
                "gdndocno": docno,
                "gdnocnum": docnum,
                "gdnbranchid": branchid,
                "gdncomid": 1,
                "gdnnettotal": total,
                "gdncreatedon": "",
                "gdncreatedby": global.userid,
                "gdnupdatedby": global.userid,
                "gdnupdatedon": "",
                "gdnremarks": "",
                "counterid": counter,
                "details": tablerow
            }

            console.log("save data", data)

            fetch(global.url + "api/GodownTransfer/SaveGodownTransfer", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                if(responseJson.error == false) {
                    
                    alert(responseJson.message)
                    window.location.reload()
                }
                else{
                
                    alert(responseJson.message)
                }
                setLoader(false)
                console.log("SaveGodownTransfer Response", responseJson)
            })
            .catch((error)=>{
                setLoader(false)
                alert("error occured")
                console.log(error)
            })
        }
      }

      const deleteRow = (index) => {
          console.log("index", index)
        var input = [...tablerow]
        input.splice(index, 1)
        setTablerow(input)
    }

    const getDate = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/CounterStock/GetTransactionDate?branchId=" + branchid, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("GetTransactionDate response", responseJson)
              setDocdate(responseJson)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      useEffect(() => {
        inputRef.current.focus();

        getAllProduct()
      }, []);

      useEffect(() => {
        console.log("tablerow", tablerow)
      }, [tablerow])
    
    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
            <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Godown Issue</p>
            </div>
            <Row>
                <Col sm={12}>
                    <Row>
                        {/* <Col sm={4} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Bill Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={billType}
                                        label="Age"
                                        onChange={billtypeChange}
                                        size="small"
                                        
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {billtypelist.map((row, index) => {
                                return (
                                    <MenuItem value={row.btypeid}>{row.formname}</MenuItem>
                                )})}
                                    </Select>
                            </FormControl>
                        </Col> */}
                        {/* <Col sm={6} lg={2} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField  label="Document No" id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col> */}
                        <Col sm={6} lg={2}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <TextField
                                    disabled
                                    id="datetime-local"
                                    label="Bill Date"
                                    value={docdate}
                                />
                            </FormControl>
                        </Col>
                        <Col xs={12} md={3}>
                            <FormControl fullWidth sx={{width: '100%', mt: 3 }}>
                                    <InputLabel id="demo-simple-select-label">Department</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={counter}
                                    label="Age"
                                    onChange={counterChange}
                                    size="small"
                                    >
                                        {
                                            counterlist.map((i, k) => {
                                                return(
                                                    <MenuItem value={i.ctrid}>{i.ctrname}</MenuItem>
                                                )
                                            
                                            })
                                        }
                                    
                                    </Select>
                            </FormControl>
                        </Col>
                        
                    </Row>
                
                    
                </Col>
            </Row> 
            <Row style={{marginTop: 20}}>
                <Col xs={12}>
                    <Table bordered responsive>
                        <thead>
                            <tr>
                                <th>Sl No.</th>
                                <th>Code</th>
                                <th>Description</th>
                                <th>Stock</th>
                                <th>Case(S)</th>
                                <th>Bottle</th>
                                <th>Total Bottle</th>
                                {/* <th>Rate</th>  */}
                                <th>Amount</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                tablerow.map((item, index) => {
                                    return(
                                        <tr>
                                            <td>{index+1}</td>
                                            <td>
                                                <input autocomplete="off" ref={inputRef} type="text" name="query" value={item.procode} onChange={(event) => handleOnChange(event, index)} onBlur={() => getSearch(item.procode,index)}/>
                                                {hasResultsProduct && selectedIndex === index  && (
                                                       <Searchlist hasResultsProduct={hasResultsProduct} productlist={productlist} productname={productname} inputRef={inputRef} listClickname={listClickname} index={index}/>
                                                    )}
                                                </td>
                                            <td>{item.proname}</td>
                                            <td>{item.godownStockBottle}</td>
                                            <td><input value={item.cases} autocomplete="off"  type="number" onChange={(event) => caseChange1(event, index)} onBlur={(event) => caseChange(event, index, item.bottlepercase)}/></td>
                                            <td><input value={item.bottle} autocomplete="off"  type="number" onChange={(event) => bottleChange1(event, index)}  onBlur={(event) => bottleChange(event, index, item.bottlepercase)}/></td>
                                            <td>{item.dttotalbot}</td>
                                            {/* <td>{item.dtamount}</td> */}
                                            <td>{item.dttotal}</td>
                                            <td><Button onClick={() => deleteRow(index)} variant="outlined">Delete</Button></td>
                                        </tr>
                                    )
                                })
                            }
                            
                            
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row style={{marginTop: 20}}>
                    
                    <Col xs={6} >
                        <ul className="list-inline">
                            <li><Button onClick={() => addRow()} variant="outlined" style={{marginTop: 30}}>Add New</Button></li>
                        </ul>
                    </Col>
                </Row>
            <div style={{marginTop: 30}}>
                
                <Row>
                    <Col xs={12} md={2} style={{marginTop: 10}}>
                        <FormControl sx={{width: '100%' }}>
                            <TextField value={total} disabled id="outlined-basic" label="Net Amount" variant="outlined"  size="small"/>
                        </FormControl> 
                    </Col>  
                    <Col xs={6} >
                    <div style={{display: 'flex'}}>
                            {
                                loader ?
                                <Rings
                                    height="50"
                                    width="50"
                                    color='#0d6efd'
                                    ariaLabel='loading'
                                />:<Button onClick={() => onSave()} variant="outlined" style={{marginTop: 10}}>Save</Button>
                            }
                        </div>
                    </Col>
                </Row>
            </div>
            
          </Paper> 
          <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                  <div>
                      <Container>
                        <Row>
                            <Col xs={12} md={6}>
                                <p style={{fontSize: 16, marginTop: 10, marginBottom: 10}}>Last 5 Purchases</p>
                                <Table bordered responsive>
                                <thead>
                                        <tr>
                                            <th>Sl No.</th>
                                            <th>Doc Date</th>
                                            <th>Doc No.</th>
                                            <th>Tax</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                            <Col xs={12} md={6}>
                                <p style={{fontSize: 16, marginTop: 10, marginBottom: 10}}>Last 5 Purchase Order</p>
                                <Table bordered responsive>
                                    <thead>
                                        <tr>
                                            <th>Sl No.</th>
                                            <th>Doc Date</th>
                                            <th>Doc No.</th>
                                            <th>Tax</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                            <Col xs={12} md={6}>
                                <p style={{fontSize: 16, marginTop: 10, marginBottom: 10}}>Last 5 Payment</p>
                                <Table bordered responsive>
                                    <thead>
                                        <tr>
                                            <th>Sl No.</th>
                                            <th>Doc Type</th>
                                            <th>Doc No.</th>
                                            <th>Voucher Type</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                      </Container>
                  </div>
              </Modal.Body>
           </Modal>
      </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
		getAllProduct: () => {
			dispatch(getAllProduct())
		},
    };
  };

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
        productlist: state.shop.productlist
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Godownissue);