import React, { useState, useEffect } from "react";
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button, Modal, Table} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
// import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { connect } from "react-redux";
import { Rings  } from  'react-loader-spinner'

const columns = [
    { id: '1', label: 'Sl No.', minWidth: 50,span: false },
    { id: '1', label: 'Branch Name', minWidth: 50,span: false },
    { id: '2', label: 'Form Name', minWidth: 50,span: false },
    { id: '3', label: 'Username', minWidth: 50,span: false },
    { id: '4', label: 'Date & Time', minWidth: 50,span: false },
    { id: '5', label: 'Event', minWidth: 50,span: false },
    { id: '6', label: 'Description', minWidth: 50,span: false },
  ];


function Userlogs({branchid}){
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [loader, setLoader] = React.useState(false);
    const [source, setSource] = React.useState([]);
    const [userlist, setUserlist] = React.useState([]);
    const [branchlist, setBranchlist] = React.useState([]);
    const [selectedbranch, setSelectedbranch] = React.useState("0");
    const [userid, setUserid] = React.useState("")

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();

    const [date1, setDate1] = React.useState(year + '-' + month + '-' + date);
    const [date2, setDate2] = React.useState(year + '-' + month + '-' + date);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const userChange = (event) => {
        setUserid(event.target.value);
    };

    const branchChange = (event) => {
        setSelectedbranch(event.target.value);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };

    useEffect(() => {
        getUser()
        getData()
        getBranch()
    }, [branchid]);

    useEffect(() => {
        getUser()
    }, [selectedbranch ]);

    const getBranch = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Branch/GetBranchList?orderBy=Name&companyId=1&includeBlocked=False", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("branch view Response", responseJson)
             setBranchlist(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getUser = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/UserMgmt/GetUserList", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("user Response", responseJson)
             setUserlist(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getData = () => {
        setLoader(true)
        var token = localStorage.getItem("token")
        var data = {
            "companyId": 1,
            "branchId": selectedbranch,
            "fromDate": date1,
            "toDate": date2,
            "createdBy": "",
            "billno": "",
            "counterId": "",
            "userId": userid,
            "soldItemsOnly": "",
            "accId": ""
        }
        console.log("data", data)
        fetch(global.url + "api/UserMgmt/GetUsersActivityList", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("user log Response", responseJson)
             setLoader(false)
             setSource(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <div>
                <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>User Logs</p>
                <Row>
                    <Col sm={6} lg={3}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <TextField
                                    id="datetime-local"
                                    label="From Date"
                                    type="date"
                                    defaultValue={year + '-' + month + '-' + date}
                                    sx={{ width: '100%' }}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                    onChange={(newValue) => {
                                        setDate1(newValue.target.value);
                                    }}
                                />
                            </FormControl>
                        </Col>
                        <Col sm={6} lg={3}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <TextField
                                    id="datetime-local"
                                    label="To Date"
                                    type="date"
                                    defaultValue={year + '-' + month + '-' + date}
                                    sx={{ width: '100%' }}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                    onChange={(newValue) => {
                                        setDate2(newValue.target.value);
                                    }}
                                />
                            </FormControl>
                        </Col>
                        <Col sm={6} lg={2}>
                            <FormControl fullWidth sx={{width: '100%',  mt: 3 }}>
                                <InputLabel id="demo-simple-select-label">Branch</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedbranch}
                                label="Age"
                                onChange={(event) => branchChange(event)}
                                size="small"
                                >
                                    <MenuItem>None</MenuItem>
                                    <MenuItem value="0">All</MenuItem>
                                    {
                                        branchlist?.map((i, k) => {
                                            return(
                                                <MenuItem value={i.brid}>{i.brname}</MenuItem>
                                            )
                                        
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Col> 
                        <Col sm={6} lg={2}>
                            <FormControl fullWidth sx={{width: '100%',  mt: 3 }}>
                                <InputLabel id="demo-simple-select-label">User</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={userid}
                                label="Age"
                                onChange={(event) => userChange(event)}
                                size="small"
                                >
                                    <MenuItem>None</MenuItem>
                                    <MenuItem value="0">All</MenuItem>
                                    {
                                        userlist?.map((i, k) => {
                                            return(
                                                <MenuItem value={i.userId}>{i.firstName}</MenuItem>
                                            )
                                        
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Col>  
                         
                        <Col xs={12} md={2}>
                            <div  style={{display: 'flex', justifyContent: 'center'}}>
                            {loader ?
                                <Rings
                                    height="50"
                                    width="50"
                                    color='#0d6efd'
                                    ariaLabel='loading'
                                />: 
                                <Button onClick={getData} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">Search</Button>}
                            </div>
                        </Col>
                    </Row>  
                </div>
                <div className='bulk-table'>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                                    colSpan={column.span == true ? 2: 1}
                                >
                                    {column.label}
                                    
                                </TableCell>
                                ))}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {source?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                                return (
                                    <TableRow  tabIndex={-1} key={row.code}>
                                        <TableCell>
                                        {index + 1}
                                        </TableCell>
                                        <TableCell>
                                        {row.branhname}
                                        </TableCell>
                                        <TableCell>
                                        {row.formname}
                                        </TableCell>
                                        <TableCell>
                                        {row.username}
                                        </TableCell>
                                        <TableCell>
                                        { `${(new Date(row.addedon)).getDate()}/${(new Date(row.addedon)).getMonth() + 1}/${(new Date(row.addedon)).getFullYear().toString().slice(-2)}`} -  {(new Date(row.addedon)).toLocaleTimeString()}
                                        </TableCell>
                                        <TableCell>
                                        {row.reason}
                                        </TableCell>
                                        <TableCell>
                                        {row.reasonfor}
                                        </TableCell>
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
                        </TableContainer>
                        <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={source.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
            </Paper>
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
    };
};

export default connect(mapStateToProps)(Userlogs);