import React from 'react'
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Form, Button} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextareaAutosize from '@mui/material/TextareaAutosize';

function Voucher(){

    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold'}}>Voucher</p> 
                <div>
                    <Row>
                        <Col xs={12} md={3}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField id="outlined-basic" label="Booking No." variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={3}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField id="outlined-basic" label="Room No." variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={3}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField id="outlined-basic" label="Voucher No." variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <TextField
                                    id="datetime-local"
                                    label="Date"
                                    type="datetime-local"
                                    defaultValue="2017-05-24T10:30"
                                    sx={{ width: 250 }}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                            </FormControl>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4}>
                            <FormControl fullWidth sx={{ mt: 3, width: '100%' }}>
                                <InputLabel id="demo-simple-select-label">Head</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={age}
                                label="Age"
                                onChange={handleChange}
                                size="small"
                                >
                                <MenuItem value={10}>1</MenuItem>
                                <MenuItem value={20}>2</MenuItem>
                                <MenuItem value={30}>3</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <TextareaAutosize 
                                minRows={2}
                                placeholder="Address"
                                style={{background: 'transparent', width: '100%', marginTop: 25}}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={3}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <TextField id="outlined-basic" label="Amount" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={3} style={{marginTop: 30}}>
                            <Button style={{backgroundColor: '#03a5fc', color: '#fff'}} variant="contained">Save</Button>
                        </Col>
                    </Row>
                </div>
            </Paper>
        </div>
    );
}

export default Voucher;