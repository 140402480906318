import React, {useState, useEffect,useRef} from 'react';
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button, Modal} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
// import Loader from '../loader/loader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TimePicker from '@mui/lab/TimePicker';
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { connect } from "react-redux";
import ReactToPrint from 'react-to-print';
import { Rings  } from  'react-loader-spinner';
import logo from '../../assets/images/logo.jpeg';

  function Kot({branchid, branchdetails}) {

    const componentRef = useRef();
    const [source, setSource] = React.useState([])
    const [refresh, setRefresh] = React.useState(false);
    const [loader, setLoader] = useState(false);
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const [counterlist, setCounterlist] = React.useState([]);
    const [counter, setCounter] = useState("");

    const [searchname, setSearchname] = React.useState("");
    const [searchcode, setSearchcode] = React.useState("");

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();


    const [date1, setDate1] = React.useState(year + '-' + month + '-' + date);
    const [date2, setDate2] = React.useState(year + '-' + month + '-' + date);

    useEffect(() => {
        if(refresh == true) {
            window.location.reload()
            setRefresh(false)
        }
    }, [refresh]);


    useEffect(() => {
        getData()
        getCounter()
    }, [branchid]);


    const getCounter = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Counter/GetCounterlist?companyId=1&branchId="+ branchid + "&includeBlocked=false", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("counterlist response", responseJson)
              setCounterlist(responseJson)
          })
          .catch((error)=>{
              console.log(error)
          })
      }


    const getData = () => {
        setLoader(true)
        var token = localStorage.getItem("token")
        var data = {
            "companyId": 1,
            "branchId": branchid,
            "fromDate": date1,
            "toDate": date2,
            "createdBy": global.userid,
            "billno": "",
            "otnumber": "",
            "itemcode": searchcode,
            "counterId": counter,
            "userId": "",
            "soldItemsOnly": "",
            "accId": ""
          }
          console.log("data", data)
        fetch(global.url + "api/SalesReport/SalesBillListKotBot", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),  
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setLoader(false)
             console.log("api/SalesReport/SalesBillList", responseJson)
             setSource(responseJson)
        })
        .catch((error)=>{
            setLoader(false)
            console.log(error)
        })
    }

    const counterChange = (event) => {
        setCounter(event.target.value)
    }

    const filter = (e, type) => {
        
        if(type == "name") {
            const keyword = e.target.value;

            if (keyword !== '') {
            const results = source.filter((user) => {
                return user.productname.toLowerCase().startsWith(keyword.toLowerCase());
                // Use the toLowerCase() method to make it case-insensitive
            });
                setSource(results);
            } else {
                getData();
            // If the text field is empty, show all users
            }
            setSearchname(keyword);
        } else{
            
            const keyword = e.target.value;
            // console.log("keyword", keyword)
            if (keyword !== '') {
            const results = source.filter((user) => {
                user.details.filter((item) => {
                    console.log("item", item.procode, keyword)
                    console.log("keyword", keyword)
                    return item.procode.toLowerCase().startsWith(keyword.toLowerCase());
                })
                
            });
            console.log("FILTER", results)
                setSource(results);
            } else {
                getData();
            // If the text field is empty, show all users
            }
            setSearchcode(keyword);
        }
};

    const formatDate = (datestring) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
        //   console.log("timeString12hr", timeString12hr)

          var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          return formattedDate; //03/02/2021
    }

      return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2 }}>
            <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>Itemwise BOT/KOT</p> 
            <Row>
                    <Col xs={12}  className='columnborder'>
                        <div>
                            <Row>
                                <Col sm={6} lg={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="From Bill Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setDate1(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col sm={6} lg={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="To Bill Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setDate2(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col sm={6} lg={3}>
                                    <FormControl fullWidth sx={{width: '100%',  mt: 2 }}>
                                            <InputLabel id="demo-simple-select-label">Counter</InputLabel>
                                            <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={counter}
                                            label="Age"
                                            onChange={(event) => counterChange(event)}
                                            size="small"
                                            >
                                                <MenuItem value="0">All</MenuItem>
                                                {
                                                    counterlist.map((i, k) => {
                                                        return(
                                                            <MenuItem value={i.ctrid}>{i.ctrname}</MenuItem>
                                                        )
                                                    
                                                    })
                                                }
                                            </Select>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField value={searchcode}
                                        onChange={(e) => setSearchcode(e.target.value)} id="outlined-basic" label="Item Code" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={2}>
                                    <div style={{justifyContent: 'center', display: 'flex'}}>
                                        {
                                            loader ?
                                            <Rings
                                                height="50"
                                                width="50"
                                                color='#0d6efd'
                                                ariaLabel='loading'
                                            />: 
                                            <Button onClick={getData} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 17, width: '50%'}} variant="contained">Search</Button>
                                        }
                                        </div>
                                </Col>
                                
                                <Col xs={12} md={4} >
                                    <ReactToPrint
                                        trigger={() => <Button style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 17, width: '50%'}} variant="contained">Print this out!</Button>}
                                        content={() => componentRef.current}
                                    />
                                </Col>
                            </Row>
                           
                        </div>
                    </Col> 
                </Row>
                <div style={{marginTop: 20}}>
                {/* {loader ? <Loader /> : */}
                    <TableContainer sx={{ maxHeight: 440, marginTop: 2 }}>
                    <div  className='section-to-print' ref={componentRef} style={{width: '95%'}}>
                            
                        <Table id="table-to-xls"  stickyHeader aria-label="sticky table">
                       
                            <TableHead className="tableheaderexe">
                            <TableRow style={{height: 80}} >
                                    <TableCell colSpan={11}  style={{padding: 0}}>
                                        <Row>
                                            <img src={logo} style={{width: 100, position: 'absolute', top: 0}}/>
                                            
                                            <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                                            <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                                            </Col>
                                            <p className='printdate' style={{ position: 'absolute', left: '85%'}}>{formatDate(new Date)}</p>  
                                        </Row>
                                    </TableCell>
                                 </TableRow>
                                 <TableRow>
                                    
                                    <TableCell colSpan={11} style={{padding: 0}}>
                                        <div style={{display: 'flex', justifyContent: 'center', fontWeight: 600}} className='captiontable'>
                                            ITEMWISE BOT/KOT FROM {date1.split("-").reverse().join("-")} TO  {date2.split("-").reverse().join("-")}
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                  
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '8%'}}>
                                    Date
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '8%'}}>
                                    Counter Name
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '8%'}}>
                                   Bill No.
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '5%'}}>
                                   Waiter
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '8%'}}>
                                    BOT
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '8%'}}>
                                    Code
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '18%'}}>
                                    Description
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '5%'}}>
                                    Type
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '10%'}}>
                                    Rate
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '5%'}}>
                                    Qty
                                    </TableCell>
                                   
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '12%'}}>
                                    Amount
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody style={{marginTop: 20}} className="bodytable">
                            {
                            
                            source.map((item, index) => {
                                return (
                                     item.details.map((row) => (
                                        <TableRow className='tablerowPrint'  tabIndex={-1} key={item.code}>
                                    
                                            <TableCell className='tablecellPrint'  >
                                            {item.billDate}
                                            </TableCell>
                                            <TableCell className='tablecellPrint' >
                                            {row.countername}
                                            </TableCell>
                                            <TableCell className='tablecellPrint' >
                                            {item.billno}
                                            </TableCell>
                                            <TableCell  className='tablecellPrint' style={{textAlign: 'center'}}>
                                            {item.waiterid}
                                            </TableCell>
                                            <TableCell className='tablecellPrint' >
                                            {row.barcode}
                                            </TableCell>
                                            <TableCell className='tablecellPrint' >
                                            {row.procode}
                                            </TableCell>
                                            <TableCell  className='tablecellPrint' >
                                            {row.proname}
                                            </TableCell>
                                            <TableCell  className='tablecellPrint' style={{textAlign: 'center'}} >
                                            {row.unitname}
                                            </TableCell>
                                            <TableCell style={{textAlign: 'right'}} className='tablecellPrint' >
                                            {parseFloat(row.dtsarate)?.toFixed(2)}
                                            </TableCell>
                                            <TableCell  className='tablecellPrint' style={{textAlign: 'center'}} >
                                            {row.dtqty}
                                            </TableCell>
                                            
                                            <TableCell style={{textAlign: 'right'}} className='tablecellPrint' >
                                            {parseFloat(row.dtamount)?.toFixed(2)}
                                            </TableCell>
                                        </TableRow>
                                     ))
                        );
                        })}
                        </TableBody>
                        </Table>
                        </div>
                    </TableContainer>
                         
                </div>
            </Paper> 
        </div>
      );
  }

  const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(Kot);
