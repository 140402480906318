import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button, Table, Modal} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { connect } from "react-redux";
import { Rings  } from  'react-loader-spinner'

function Updatesales({branchid}){

    const [source, setSource] = React.useState([])
    const [modal, setModal] = React.useState(false)
    const [loader, setLoader] = useState(false);
    const [counterlist, setCounterlist] = React.useState([]);
    const [detailstable, setDetailstable] = React.useState([])
    const [counter, setCounter] = useState("");
    const [total, setTotal] = useState("0");

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();


    const [date1, setDate1] = React.useState(year + '-' + month + '-' + date);
  

    useEffect(() => {
        getData()
        getDate()
        getCounter()
    }, [branchid]);

    const onFilter =()=> {

    }

    const getDate = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/CounterStock/GetTransactionDate?branchId=" + branchid, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("GetTransactionDate response", responseJson)
              setDate1(responseJson)
          })
          .catch((error)=>{
              console.log(error)
          })
    }

    const getCounter = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Counter/GetCounterlist?companyId=1&branchId="+ branchid + "&includeBlocked=false", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("counterlist response", responseJson)
              setCounterlist(responseJson)
          })
          .catch((error)=>{
              console.log(error)
          })
      }


    const getData = () => {
        setLoader(true)
        var token = localStorage.getItem("token")
        var data = {
            "companyId": 1,
            "branchId": branchid,
            "fromDate":  date1,
            "toDate": date1,
            "createdBy": global.userid,
            "billno": "",
            "counterId": counter
          }
        fetch(global.url + "api/CounterStock/GetUpdateSalesList", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setLoader(false)
             console.log("GetUpdateSalesList view Response", responseJson)
             setSource(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    // const editClick = (data) => {
    //     setDetailstable(data)
    //     setModal(true)
    // }

    const updateSales = () => {
        setLoader(true)
        var token = localStorage.getItem("token")
        var data = {
            "companyId": 1,
            "branchId": branchid,
            "fromDate":  date1,
            "toDate": date1,
            "createdBy": global.userid,
            "billno": "",
            "counterId": counter
          }
        fetch(global.url + "api/CounterStock/UpdateSales", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setLoader(false)
             console.log("UpdateSales view Response", responseJson)
             if(responseJson.error == false){
                 alert(responseJson.message)
                 window.location.reload()
             }
             else{
                alert(responseJson.message)
                
             }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const viewSale = (counterid) => {
        var token = localStorage.getItem("token")
        var date = formatDate(date1)
        var data = {
            "companyId": 1,
            "branchId": branchid,
            "fromDate": date,
            "toDate": date,
            "createdBy": global.userid,
            "billno": "",
            "otnumber": "",
            "itemcode": "",
            "counterId": counterid,
            "userId": "",
            "soldItemsOnly": "",
            "accId": ""
          }
          console.log("api/SalesReport/SalesBillListViewOnly", data)
        fetch(global.url + "api/SalesReport/SalesBillListViewOnly", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),  
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setLoader(false)
            console.log("api/SalesReport/SalesBillListViewOnly 1234", responseJson)
             setDetailstable(responseJson)
             setModal(true)
        })                     
        .catch((error)=>{
            setLoader(false)
            console.log(error)
        })
    }

    const formatDate = (datestring, type) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();
  
          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
          
  
          if(type == "time") {
            var formattedDate = timeString12hr;
          }
          else{
            var formattedDate = year+"-"+month+"-"+day
          }
          return formattedDate; //03/02/2021
    }

    useEffect(() => {
        var totalamount = 0;
        for(var i=0; i<source.length; i++){
            if(source[i].totalsales){
                totalamount += parseFloat(source[i].totalsales) 
            }
        }
        setTotal(totalamount)
    }, [source])
 
    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>Update Day Sales</p> 
                <Row>
                    <Col xs={12}  className='columnborder'>
                        <div>
                            <Row>
                            <Col sm={6} lg={3}>
                                    <FormControl sx={{ mt: 3, width: '100%' }}>
                                        <TextField
                                          value={date1}
                                            disabled
                                            label="Date"
                                        />
                                    </FormControl>
                                </Col>
                                {/* <Col sm={6} lg={2}>
                                        <FormControl fullWidth sx={{width: '100%',  mt: 3 }}>
                                                <InputLabel id="demo-simple-select-label">Counter</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={counter}
                                                label="Age"
                                                onChange={(event) => counterChange(event)}
                                                size="small"
                                                >
                                                    {
                                                        counterlist.map((i, k) => {
                                                            return(
                                                                <MenuItem value={i.ctrid}>{i.ctrname}</MenuItem>
                                                            )
                                                        
                                                        })
                                                    }
                                                </Select>
                                        </FormControl>
                            </Col> */}
                               
                                <Col xs={12} md={2}>
                                    <div style={{justifyContent: 'center', display: 'flex'}}>
                                        {
                                            loader ?
                                            <Rings
                                                height="50"
                                                width="50"
                                                color='#0d6efd'
                                                ariaLabel='loading'
                                            />: 
                                            <Button onClick={getData} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">Check</Button>
                                        }
                                    </div>
                                </Col>
                                {/* <Col xs={12} md={2}>
                                    <div style={{ justifyContent: 'center', display: 'flex'}}>
                                        <Button style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">print</Button>
                                    </div>
                                </Col> */}
                            </Row>
                           
                        </div>
                    </Col> 
                </Row>
                
                <div style={{marginTop: 30}}>
                <Table responsive bordered>
                    <thead>
                        <tr>
                            <th>Counter Name</th>
                            <th>Sales Amount</th>
                            <th>View Sales</th>                
                    </tr>
                    </thead>
                    <tbody>
                        {
                                source.map((i, k) => {
                                    return(
                                        <tr>
                                            <td>{i.countername}</td>
                                            <td style={{textAlign: 'right'}}>{i.totalsales.toFixed(2)}</td>
                                            <td style={{ justifyContent: 'center', display: 'flex'}}>
                                                <Button style={{backgroundColor: '#03a5fc', color: '#fff', fontSize:10}} onClick={() => viewSale(i.counterid)} variant="contained">View</Button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        <tr>
                            <td style={{fontWeight: 'bold', fontSize: 16}}>Total</td>
                            <td style={{fontWeight: 'bold', fontSize: 16, textAlign: 'right'}}>{parseFloat(total)?.toFixed(2)}</td>
                            <td></td>
                        </tr>
                    </tbody>
                    </Table>
                </div>
                <Row>
                <Col xs={12} md={2}>
                    <div style={{ justifyContent: 'center', display: 'flex'}}>
                        {
                            loader ?
                            <Rings
                                height="50"
                                width="50"
                                color='#0d6efd'
                                ariaLabel='loading'
                            />: 
                            <Button onClick={() => updateSales()} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">Update</Button>
                        }
                    </div>
                </Col>
                </Row>
            </Paper>
            
            {/* <Modal
                    size="lg"
                    show={modal}
                    onHide={() => setModal(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                    >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                        Details
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                        <Table responsive bordered>
                        <thead>
                        <tr>
                            <th>Code</th>
                            <th>Product Name</th>
                            <th>Bottles</th>
                            <th>Pegs</th>
                            <th>PUR Total</th>
                            <th>Sales Tax</th>
                            <th>CESS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                                detailstable.map((i, k) => {
                                    return(
                                        <tr>
                                        
                                            <td>{i.procode}</td>
                                            <td>{i.proname}</td>
                                            <td>{i.cases}</td>
                                            <td>{i.bottle}</td>
                                            <td>{i.dtqty}</td>
                                            <td>{i.dtprice}</td>
                                            <td>{i.dttotal}</td>
                                        </tr>
                                    )
                                })
                            }
                        
                    </tbody>
                            </Table>
                        </Row>
                    </Modal.Body>
           </Modal> */}

           <Modal
                    size="lg"
                    show={modal}
                    onHide={() => setModal(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                    >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                        Details
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row style={{marginTop: 20}}>
                        <Table responsive bordered>
                        <TableHead>
                        <TableRow>
                            {/* <TableCell className='tablecellPrint' >Date</TableCell>
                            <TableCell className='tablecellPrint' >Counter Name</TableCell>
                            <TableCell className='tablecellPrint' >Bill No.</TableCell>
                            <TableCell className='tablecellPrint' >Waiter</TableCell>
                            <TableCell className='tablecellPrint' >Bot</TableCell> */}
                            <TableCell className='tablecellPrint' >Code</TableCell>
                            <TableCell className='tablecellPrint' >Description</TableCell>
                            <TableCell className='tablecellPrint' >Type</TableCell>
                            <TableCell className='tablecellPrint' >Qty</TableCell>
                            <TableCell className='tablecellPrint' >Rate</TableCell>
                            <TableCell className='tablecellPrint' >Amount</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                                detailstable.map((row, k) => {
                                    return(
                                        row.details.map((i) => (
                                        <TableRow>
                                            {/* <TableCell className='tablecellPrint'>{row.billDate}</TableCell>
                                            <TableCell className='tablecellPrint'>{i.countername}</TableCell>
                                            <TableCell className='tablecellPrint' >{row.billno}</TableCell>
                                            <TableCell className='tablecellPrint' >{row.waiterid}</TableCell>
                                            <TableCell className='tablecellPrint'>{i.barcode}</TableCell> */}
                                            <TableCell className='tablecellPrint'>{i.procode}</TableCell>
                                            <TableCell className='tablecellPrint' >{i.proname}</TableCell>
                                            <TableCell className='tablecellPrint' >{i.unitname}</TableCell>
                                            <TableCell className='tablecellPrint'>{i.dtqty}</TableCell>
                                            <TableCell className='tablecellPrint' style={{textAlign: 'right'}}>{parseFloat(i.dtsarate)?.toFixed(2)}</TableCell>
                                            <TableCell className='tablecellPrint' style={{textAlign: 'right'}}>{parseFloat(i.dtamount)?.toFixed(2)}</TableCell>
                                        </TableRow>
                                        ))
                                        
                                    )
                                })
                            }
                    </TableBody>
                            </Table>
                        </Row>
                    </Modal.Body>
           </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(Updatesales);