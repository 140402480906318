import React, {Component, useEffect, useState, useRef} from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { Container, Row, Col, Form, Table, Modal  } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import * as RiIcons from "react-icons/ri";
import ReactToPrint from 'react-to-print';
import { Rings  } from  'react-loader-spinner';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import * as IconName from "react-icons/bs";
import * as GI from "react-icons/gi";
import * as BI from "react-icons/bi";
import * as RI from "react-icons/ri";
import { connect } from "react-redux";


import './payment.css'
import { useParams } from 'react-router-dom';


function Reciept({branchid}) {
    const componentRef = useRef();
    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();
    
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    const [pvid, setPvid] = React.useState(0);
    const [vid, setVid] = React.useState(0);

    const [paymentvoucherId, setPaymentvoucherid] = React.useState('');
    const [registerDate, setRegisterDate] = React.useState(year + '-' + month + '-' + date);
    const [refDate, setRefdate] = React.useState(year + '-' + month + '-' + date);
    const [createdon, setCreatedon] = React.useState(year + '-' + month + '-' + date);
    const [updatedon, setUpdatedon] = React.useState(year + '-' + month + '-' + date);
    const [refno, setRefno] = React.useState('');
    const [remarks, setRemarks] = React.useState('');
    const [billType, setBilltype] = React.useState('');
    const [invoice, setInvoice] = React.useState('');
    const [paymode, setPaymode] = React.useState('');
    const [userId, setUserid] = React.useState('');
    const [modal, setModal] = React.useState(false);
    const [allmodal, setAllmodal] = React.useState(false);
    const [againstmodal, setAgainstmodal] = React.useState(false);
    const [specacclist, setSpectacclist] = React.useState([]);

    const [ledgercode, setLedgercode] = React.useState("");
    const [ledgername, setLedgername] = React.useState("");
    const [ledgerid, setLedgerid] = React.useState("");

    const [paymentcode, setPaymentcode] = React.useState("");
    const [paymentname, setPaymentname] = React.useState("");
    const [paymenttype, setPaymenttype] = React.useState("");
    const [paymentaccid, setPaymentaccid] = React.useState("");

    const [accountlist, setAccountlist] = React.useState([]);
    const [billtypelist, setBilltypelist] = React.useState([]);
    const [allaccountlist, setAllaccountlist] = React.useState([]);
    const [purchaselist, setPurchaselist] = React.useState([]);
    const {id} = useParams()
    const [paymentId, setPaymentid] = React.useState("");
    const [editmode, setEditmode] = React.useState(false);
    
    const [amount, setAmount] = React.useState("");
    const [tableRow, setTablerow] = React.useState([]);

    const [total, setTotal] = React.useState("");

    const [purchaseid, setPurchaseid] = React.useState("");
    const [billinvoice, setBillinvoice] = React.useState("");

    const [bpaymode, setBpaymode] = React.useState("");
    const [bank, setBank] = React.useState("");

    const [paymentnarration, setPaymentnarration] = React.useState("");
    const [paymentamount, setPaymentamount] = React.useState("");
    const [paymentbalance, setPaymentbalance] = React.useState("");
    const [docnum, setDocnum] = React.useState("");
    
    const [billid, setBillid] = React.useState("");
    const [billdet1, setBillDet1] = React.useState({});
    const [billdet2, setBillDet2] = React.useState([]);
    const [printmodal, setPrintmodal] = React.useState(false);
    const [searchname, setSearchname] = React.useState([])
    const [loader, setLoader] = useState(false);
    const [counter, setCounter] = React.useState("");
    const delay = ms => new Promise(res => setTimeout(res, ms));

    const changePaymentamount = (event) => {
        const inputValue = event.target.value;
    
        // Allow only numbers with decimal values
        const regex = /^\d*\.?\d*$/;
    
        if (regex.test(inputValue)) {
            setPaymentamount(inputValue);
        }
      };

    const billtypeChange = (event, index) => {
        setBilltype(event.target.value);
        getInvoiceno(event.target.value)
        if(index.props.children == "BAR" ){
            setCounter(1)
        }else if(index.props.children == "LODGE"){
            setCounter(2)
        }
        else if(index.props.children == "RESTAURANT"){
            setCounter(3)
        }
      };

      const paymodeChange = (event) => {
        setPaymode(event.target.value);
        setBpaymode("")
      };

    //   function handleKeyPress(event) {
    //     if (!/\d/.test(event.key)) {
    //     event.preventDefault();
    //     }
    // }

      useEffect(() => {
        setLedgercode("")
        setLedgername("")
        setLedgerid("")
        setTablerow([])
        setPaymode("")
    },[billType])


      const bpaymodeChange = (event) => {
        setBpaymode(event.target.value);
      };

      const getBalance = (id) => {
        const data = {
            "ledgerid": id,
          }
        //   fetch(global.url + "accountBalanceByBank", {
        //     method: "POST",
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify(data),
        //   })
        //   .then((response)=> response.json())
        //   .then((responseJson)=>{
        //       console.log("balance response", responseJson)
        //       setAccbalance(responseJson.data)
        //   })
        //   .catch((error)=>{
        //       console.log(error)
              
        //   })
      }

      const bankChange =(event) => {
        setBank(event.target.value)
        
        const data = {
            "bankmasterid": event.target.value,
          }
        //   fetch(global.url + "accountByBankMaster", {
        //     method: "POST",
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify(data),
        //   })
        //   .then((response)=> response.json())
        //   .then((responseJson)=>{
        //       console.log("respone", responseJson)
        //       setLedgerid(responseJson.data.bankmaster_ledgername)
        //       setLedgercode(responseJson.data.bankmaster_bankcode)
        //       setLedgername(responseJson.data.bankmaster_bankcode)
        //       getBalance(responseJson.data.bankmaster_ledgername)
        //   })
        //   .catch((error)=>{
        //       console.log(error)
              
        //   })
      }

      const selectAccount = () => {
        setModal(true)
        getSpecaccountlist()
      }

      const filter = (e) => {
        const keyword = e.target.value;

        if (keyword !== '') {
        const results = allaccountlist.filter((user) => {
            return user.accname.toLowerCase().startsWith(keyword.toLowerCase());
            // Use the toLowerCase() method to make it case-insensitive
        });
            setAllaccountlist(results);
        } else {
            getAllaccountlist()
        // If the text field is empty, show all users
        }

            setSearchname(keyword);
    };

      const getSpecaccountlist = () => {
        if(paymode == "Cash"){
            var token = localStorage.getItem("token")
            fetch(global.url + "api/Accounts/GetCashLedgerListNew?branchId=" + branchid + "&counterId=" + counter, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("list response", responseJson)
                setSpectacclist(responseJson)
            })
            .catch((error)=>{
                console.log(error)
            })
        }
        else if(paymode == "Bank"){
            var token = localStorage.getItem("token")
            fetch(global.url + "api/Accounts/GetBankLedgerListNew?branchId=" + branchid + "&counterId=" + counter, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("list response", responseJson)
                setSpectacclist(responseJson)
            })
            .catch((error)=>{
                console.log(error)
            })

        }
        
    }

      const getAccountlist = () => {
        const data = {
          "payment_mode": paymode,
        }
        // fetch(global.url + "viewPaymentByMode", {
        //   method: "POST",
        //   headers: {
        //       'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify(data),
        // })
        // .then((response)=> response.json())
        // .then((responseJson)=>{
        //     setAccountlist(responseJson.data)
        // })
        // .catch((error)=>{
        //     console.log(error)
            
        // })
      }
      useEffect(() => {
        getTotalammount()
       
        console.log("content123",amount )
      },[tableRow])

      useEffect(() => {
        if(id == "add") {
            getBilltype()
            setEditmode(false)
            
        }
        else{
           getBilltype()
           getDetailsbyid(id)
           setEditmode(true)
        }
      }, [branchid]);

      const getTotalammount = () => {
          var totalAmount = 0;
          for (var i = 0; i < tableRow.length; i++) {
            totalAmount += parseFloat(tableRow[i].dtamount)
         }
            setAmount(totalAmount.toFixed(2))
      }

      const getInvoiceno = (id) => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/BillSettings/GetNextBillNo?billId=" + id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              setInvoice(responseJson.billno)
              setDocnum(responseJson.billprefix + responseJson.billno + responseJson.billsuffix)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const getDetailsbyid = (id) => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Receipt/GetReceiptById?id=" + id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
             console.log("responseJson.pvdate.toISOString().split('T')[0]", responseJson)
            //  getInvoiceno(responseJson.rvformid)
              setBilltype(responseJson.rvformid)
              if(responseJson.counterid == "1"){
                setCounter(1)
              }
              if(responseJson.counterid == "2"){
                setCounter(2)
              }
              if(responseJson.counterid == "3"){
                setCounter(3)
              }
  
              setInvoice(responseJson.rvno)
              setDocnum(responseJson.rvnonum)
              setRefno(responseJson.rvrefno)
              setRegisterDate(responseJson.rvdate.split('T')[0])
            
              setRefdate(responseJson.rvrefdate.split('T')[0])
              setCreatedon(responseJson.rvcreatedon.split('T')[0])
              setUpdatedon(responseJson.rvupdatedon.split('T')[0])
            setPvid(responseJson.rvid)
            setVid(responseJson.vid)
              setPaymode(responseJson.rvpaymode)
              setRemarks(responseJson.rvnarration)
              getLedgerbyid(responseJson.rvcashid)
              setLedgerid(responseJson.rvcashid)
              setTablerow(responseJson.details)
              console.log("GetPaymentById response", responseJson)
              
          })
          .catch((error)=>{
              console.log(error)
          })
            
      }

      const getLedgerbyid = (id) => {

            var token = localStorage.getItem("token")
            fetch(global.url + "api/Accounts/GetLedgerById?id=" + id, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("GetLedgerById response", responseJson)
                setLedgercode(responseJson.acccode)
                setLedgername(responseJson.accname)
            })
            .catch((error)=>{
                console.log(error)
            })
      }

      const getBilltype = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/BillSettings/GetBillTypeByForm?companyId=1&branchId=" + branchid + "&formType=RCT", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("billSettigsList response", responseJson)
              setBilltypelist(responseJson.billSettigsList)
          })
          .catch((error)=>{
              console.log(error)
          })
      }
      
      const selectedAcc = (name, code, id) => {
          console.log("click")
            setLedgercode(code)
            setLedgername(name)
            setLedgerid(id)
            setModal(false)
      }

      const addRow = () => {
          if(paymentcode == "") {
              return alert("Code required")
          }

          if(paymentamount == "") {
            return alert("Amount required")
          }

        setTablerow([...tableRow, {dtpvid: 0, dtvid: 0, dtaccid: paymentaccid, dtnarration: paymentnarration, dtamount: paymentamount, dtbalance: paymentbalance, code: paymentcode, account: paymentname}])
        // setPaymentcode("")
        // setPaymentname("")
        setPaymenttype("")
        // setPaymentaccid("")
        setPaymentnarration("")
        setPaymentamount("")
        setPurchaseid("")
    }

        
    const tableselectedAccount = (code, name, id, type) => {
        console.log("click")
        setLedgercode(code)
        setLedgername(name)
        setLedgerid(id)
        setModal(false)
    }

    const tableselectedAccount1 = (code, name, id, type, balance) => {
        setPaymentcode(code)
        setPaymentname(name)
        setPaymenttype(type)
        setPaymentaccid(id)
        setPaymentbalance(balance)
        setAllmodal(false)
    }

    const addCode = () => {
        getAllaccountlist()
        setModal(true)
    }

    const addCode1 = () => {
        getAllaccountlist()
        setAllmodal(true)
    }

    const getAllaccountlist = () => {
        var token = localStorage.getItem("token")
        console.log('branchid', branchid)
        console.log('counter', counter)
        fetch(global.url + "api/Accounts/GetAllLedgerListNew?branchId=" + branchid + "&counterId=" + counter, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("GetAllLedgerList response", responseJson)
            setAllaccountlist(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const onSave = () =>{

        if(billType == ""){
            return alert("Counter not selected")
        }
        if(ledgerid == "") {
            return alert("Account not selected")
        }

        if(tableRow.length == 0) {
            return alert("Empty Details")
        }
        setLoader(true)
        var token = localStorage.getItem("token")
        console.log("paymentId", paymentId)
        tableRow.forEach(function(v){ 
            v.dtpvid=0;
            delete v.$id });
        const data = {
            "rvid": pvid,
            "vid": vid,
            "rvno": invoice,
            "rvdate": registerDate,
            "rvrefno": refno,
            "rvnarration": remarks,
            "rvcomid": 1,
            "rvbranchid": branchid,
            "rvcreatedby": global.userid,
            "rvupdatedby": global.userid,
            "rvcreatedon": createdon,
            "rvupdatedon": updatedon,
            "rvnonum": docnum,
            "rvformid": billType,
            "rvcashid": ledgerid,
            "rvbankid": ledgerid,
            "rvpaidamt": amount,
            "rvmode": "",
            "rvpaymode": paymode,
            "rvchequestatus": 1,
            "rvrefdate": refDate,
            "details": tableRow,
            "code": "",
            "account": "",
            "printFile": "",
            "amountsInWords": ""
          }
        console.log("data", data)
        fetch(global.url + "api/Receipt/SaveReceipt", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response receipt bill", responseJson)
            setLoader(false)
            if(responseJson.error == false){
                setBillid(responseJson.responsevalue)
                getBillDetails(responseJson.responsevalue) 
                 alert(responseJson.message)        
            }
            else{
                alert(responseJson.message)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getBillDetails = (id) => {
        console.log('billid',id )
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Receipt/GetReceiptById?id=" + id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("responseJson123", responseJson)
              setBillDet1(responseJson)
              setBillDet2(responseJson.details)
            //   document.getElementById('print-button').click();
            //   await delay(2000);
              window.location.reload()
          })
          .catch((error)=>{
              console.log(error)
          })
    }

    const deleteClick = (index) => {
        var input = [...tableRow]
        input.splice(index, 1)
        setTablerow(input)
    }

    const getBill = () => {
        setAgainstmodal(true)

        const data = {
            "supplier_id": paymentaccid,
            "type": "supplier",
        }
        console.log("againstTransaction data", data)
        // fetch(global.url + "againstTransaction", {
        //     method: "POST",
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify(data),
        // })
        // .then((response)=> response.json())
        // .then((responseJson)=>{
        //     console.log("bill details", responseJson)
        //     if(responseJson.error == false){
        //         setPurchaselist(responseJson.data)
        //     }
        //     else{
        //         alert("No Record Found")
        //     }
            
        // })
        // .catch((error)=>{
        //     console.log(error)
        // })
    }

    const billSelect = (total, id, bill) => {
        setPaymentamount(total)
        setPurchaseid(id)
        setBillinvoice(bill)
        setAgainstmodal(false)
    }

    const onRowdelete = (index) => {
        var input = [...tableRow]
        input.splice(index, 1)
        setTablerow(input)
    }


  return (
        <div className="main-content">
            <Row>
            <Col xs={12}>
                            <p style={{fontWeight: 'bold', fontSize: 20}}>Receipt</p>
                        </Col>
            </Row>
            <Row>
                <Col sm={6}>
                <div style={{display: 'none'}}>
                        <ReactToPrint
                                trigger={() => <Button id="print-button" variant="primary" className="side-topbtn">OT Print</Button>}
                                content={() => componentRef.current}
                            />
                    </div>
                    <Row>
                        
                        <Col sm={2} style={{marginTop:30}}>
                            <p className="label">Counter</p>
                        </Col>
                        <Col sm={4} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Counter</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={billType}
                                        disabled={editmode ? true: false}
                                        label="Age"
                                        onChange={(e, index) => billtypeChange(e, index)}
                                        size="small"
                                        
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {billtypelist.map((row, index) => {
                                return (
                                    <MenuItem value={row.btypeid}>{row.formname}</MenuItem>
                                )})}
                                    </Select>
                            </FormControl>
                        </Col>
                        <Col sm={2} style={{marginTop:30}}>
                            <p className="label">SL No</p>
                        </Col>
                        <Col sm={4} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField value={invoice} disabled onChange={(e) => setInvoice(e.target.value)} id="outlined-basic" label="SL No" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col sm={2} style={{marginTop:20}}>
                            <p className="label">Pay Mode</p>
                        </Col>
                        <Col sm={10} style={{marginTop:20}}>
                            <FormControl sx={{  width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Pay Mode</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={paymode}
                                        label="Age"
                                        onChange={paymodeChange}
                                        size="small"  
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                        <MenuItem value={"Cash"}>Cash Account</MenuItem>
                                        <MenuItem value={"Bank"}>Bank Account</MenuItem>
                                    </Select>
                            </FormControl>
                        </Col>
                    </Row>
                </Col>
                <Col sm={6}>
                <Row>
                        <Col sm={2} style={{marginTop:30}}>
                            <p className="label">Date</p>
                        </Col>
                        <Col sm={10} style={{marginTop:30}}>
                            {/* <FormControl sx={{  width: '100%' }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                    size="small"
                                        label="Date"
                                        value={registerDate}
                                        onChange={(newValue) => {
                                        setRegisterDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl> */}
                            <FormControl sx={{ width: '100%' }}>
                                <TextField
                                    id="datetime-local"
                                    label="Date"
                                    type="date"
                                    defaultValue={year + '-' + month + '-' + date}
                                    sx={{ width: '100%' }}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                    value={registerDate}
                                    onChange={(newValue) => {
                                        setRegisterDate(newValue.target.value);
                                    }}
                                />
                            </FormControl>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={2} style={{marginTop:20}}>
                            <p className="label">Ref No</p>
                        </Col>
                        <Col sm={4} style={{marginTop:20}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField  value={refno}  onChange={(e) => setRefno(e.target.value)} id="outlined-basic" label="Ref No" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col sm={2} style={{marginTop:20}}>
                            <p className="label">Ref Date</p>
                        </Col>
                        <Col sm={4} style={{marginTop:20}}>
                            {/* <FormControl sx={{  width: '100%' }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                        size="small"
                                            label="Date"
                                            value={refDate}
                                            onChange={(newValue) => {
                                            setRefdate(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                </LocalizationProvider>
                            </FormControl> */}
                            <FormControl sx={{ width: '100%' }}>
                                <TextField
                                    id="datetime-local"
                                    label="Date"
                                    type="date"
                                    defaultValue={year + '-' + month + '-' + date}
                                    sx={{ width: '100%' }}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                    value={refDate}
                                    onChange={(newValue) => {
                                        setRefdate(newValue.target.value);
                                    }}
                                />
                            </FormControl>
                        </Col>
                    </Row>
                </Col>
            </Row>
            
                
            <Row>
                <Col sm={3} style={{marginTop:20}}>
                    <FormControl component="fieldset">
                        <TextField disabled value={ledgercode} id="outlined-basic" label="Code" variant="outlined"  size="small"/>
                    </FormControl>
                </Col>
                <Col sm={3} style={{marginTop:20}}>
                    <FormControl component="fieldset">
                        <TextField disabled value={ledgername} id="outlined-basic" label="Account" variant="outlined"  size="small"/>
                    </FormControl>
                </Col>
                <Col sm={5} style={{marginTop:20}}>
                    <Button onClick={selectAccount} variant="outlined">SELECT BANK/CASH ACCOUNT</Button>
                </Col>
            </Row>
                
            <Row >
                <Col sm={2} style={{marginTop:20}}>
                    <Button onClick={addCode1} variant="outlined">SELECT ACCOUNT</Button>
                </Col>
                {/* <Col sm={3} style={{marginTop:20}}>
                    <FormControl component="fieldset">
                        <TextField onClick={addCode1} value={paymentcode} disabled id="outlined-basic" label="Code" variant="outlined"  size="small"/>
                    </FormControl>
                </Col> */}
                <Col sm={3} style={{marginTop:20}}>
                    <FormControl component="fieldset" style={{width: '100%'}}>
                        <TextField id="outlined-basic" disabled  value={paymentname} label="Account" variant="outlined"  size="small"/>
                    </FormControl>
                </Col>
                <Col sm={2} style={{marginTop:20}}>
                    
                    <FormControl component="fieldset">
                        <TextField id="outlined-basic" value={paymentamount} onChange={changePaymentamount} label="Amount" variant="outlined"  size="small"/>
                    </FormControl>
                </Col>
                <Col sm={6} style={{marginTop:20}}>
                    <TextareaAutosize 
                        value={paymentnarration}  onChange={(e) => setPaymentnarration(e.target.value)}
                        minRows={2}
                        placeholder="Narration"
                        style={{background: 'transparent', width: '100%'}}
                    />
                    {/* <FormControl component="fieldset">
                        <TextField id="outlined-basic" value={paymentnarration} onChange={(e) => setPaymentnarration(e.target.value)} label="Narration" variant="outlined"  size="small"/>
                    </FormControl> */}
                </Col>
                
                <Col sm={2} style={{marginTop:20}}>
                    <Button onClick={addRow} variant="outlined">ADD ACCOUNT</Button>
                </Col>
                
                
            </Row>
            <Row style={{marginTop: 20}}>
                <Col xs={12}>
                    <Table bordered>
                        <thead>
                            <tr>
                            <th style={{wwidth: '10%'}}>SlNo</th>
                            {/* <th style={{width: '20%'}}>Account Code</th> */}
                            <th style={{width: '50%'}}>Account</th>
                            {/* <th style={{width: '50%'}}>Balance</th> */}
                            <th  style={{width: '40%'}}>Narration</th>
                            <th style={{wwidth: '10%'}}>Amount</th>
                           
                            <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableRow.map((row, index) => {
                                        return (
                                            <tr>
                                                <td style={{wwidth: '10%'}}>{index+1}</td>
                                                {/* <td>{row.code}</td> */}
                                                <td>{row.account}</td>
                                                {/* <td style={{textAlign: 'right'}}>{row.dtbalance}</td> */}
                                                <td>{row.dtnarration}</td>
                                                <td style={{textAlign: 'right'}}>{row.dtamount}</td>
                                                
                                                <td><Button onClick={() => onRowdelete(index)}  variant="outlined">Delete</Button></td>
                                                
                                                {/* <td >
                                                    <a href="javascript:void(0)" onClick={() => deleteClick(index)}>
                                                        <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                                    </a>
                                                </td> */}
                                            </tr>
                                        )}
                                    )}
                                    <tr>
                                        <td colSpan="3" style={{textAlign: 'right', fontWeight: 'bold'}}>Total</td>
                                        <td  style={{textAlign: 'right'}}> {amount}</td>
                                        <td ></td>
                                    </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row style={{marginTop: 20}}>
                <Col xs={12}>
                    <TextareaAutosize 
                    value={remarks}  onChange={(e) => setRemarks(e.target.value)}
                        minRows={3}
                        placeholder="Remarks"
                        style={{background: 'transparent', width: '100%'}}
                    />
                </Col>
            </Row>
            
            <Row style={{marginTop: 20}}>
                <Col xs={12} >
                    {
                        loader ? 
                        <Rings
                            height="50"
                            width="50"
                            color='#0d6efd'
                            ariaLabel='loading'
                        /> : 
                    <ul className="list-inline">
                        <li><Button onClick={() => onSave()} style={{float: 'right', backgroundColor: 'blue', color: '#fff'}} variant="primary">Submit</Button></li>
                    </ul>}
                </Col>
            </Row>


            <Modal
                size="lg"
                show={modal}
                onHide={() => setModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                     Select Account
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <ul>
                            {specacclist.map((row, index) => {
                                return (
                                    <a style={{textDecoration: 'none', listStyle: 'none'}} href="javascript:void(0)" onClick={() =>tableselectedAccount(row.acccode, row.accname, row.accid, "")}>
                                        <li className="pay-lists" style={{color: '#000', marginTop: 5, fontSize: 15, cursor: 'pointer'}}>{row.accname} - {row.accledger}</li>
                                    </a>
                                )})
                                }
                            </ul>
                        </Col>
                        
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal
                size="lg"
                show={allmodal}
                onHide={() => setAllmodal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                     Select Account
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <input
                                type="search"
                                value={searchname}
                                onChange={filter}
                                className="input"
                                placeholder="Search"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <ul>
                            {allaccountlist.map((row, index) => {
                                return (
                                    <a style={{textDecoration: 'none', listStyle: 'none'}} href="javascript:void(0)" onClick={() =>tableselectedAccount1(row.acccode, row.accname, row.accid, "", row.balance)}>
                                        <li className="pay-lists" style={{color: '#000', marginTop: 5, fontSize: 15, cursor: 'pointer'}}>{row.accname} - {row.accledger}</li>
                                    </a>
                                )})
                                }
                            </ul>
                        </Col>
                        
                    </Row>
                </Modal.Body>
            </Modal>

            <div  className='posprint testprint' ref={componentRef} style={{width: '100%'}}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div className='biillls-div'>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div style={{ padding: 5, width: 80}}>
                                <p style={{fontSize: 12, fontWeight: 'bold', color: '#000', marginBottom: 'unset', textAlign: 'center'}}>VOUCHER</p>
                            </div>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 10}}>
                            <p style={{marginBottom: 'unset', fontSize: 13}}>No.: {billdet1.rvid}</p>
                            <p style={{marginBottom: 'unset', fontSize: 13}}>Date: {(new Date(billdet1.rvdate)).toLocaleDateString()}</p>
                        </div>
                        <p style={{marginBottom: 'unset', marginTop: 10, fontSize: 13}}>A/C : {billdet1.rvnarration}</p>
                        <div style={{marginTop: 10}}>
                            <Table bordered responsive>
                                <tbody>
                                    <tr>
                                        <th>Sl No.</th>
                                        <th colSpan={3}>Perticulars</th>
                                        <th>Rs.</th>
                                        <th>Ps.</th>
                                    </tr>
                                    {billdet2?.map((row, index) => (
                                        <tr>
                                            <td>{index+1}</td>
                                            <td colSpan={3}>{row.account}</td>
                                            <td style={{textAlign: 'right'}}>{row.dtamount}</td>
                                            <td style={{textAlign: 'right'}}>0</td>
                                        </tr>
                                    ))}
                                    <tr> 
                                        <td colSpan={4}><span style={{float: "right", fontWeight: "bold"}}>Total</span></td>
                                        <td style={{textAlign: 'right'}}>{billdet1.rvpaidamt}</td>
                                        <td style={{textAlign: 'right'}}>0</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <p style={{fontSize: 13, marginTop: 10}}>Rupees in words:</p>
                        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 10}}>
                            <p style={{marginBottom: 'unset', fontSize: 13}}>Name & Signature:</p>
                            <p style={{marginBottom: 'unset', fontSize: 13}}>Manager</p>
                        </div>
                    </div>
                </div>
            </div>
                    
        </div>
  );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(Reciept);
