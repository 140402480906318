import React from 'react';
import {Container, Row, Col, Form, Button, Modal, Table} from "react-bootstrap";
import './Bills.css'

function Bills () {
    return(
        <div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <div className='biillls-div'>
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: 20}}>
                        <div style={{backgroundColor: '#000', paddig: 5, width: 80}}>
                            <p style={{fontSize: 12, fontWeight: 'bold', color: '#fff', marginBottom: 'unset', textAlign: 'center'}}>VOUCHER</p>
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 10}}>
                        <p style={{marginBottom: 'unset', fontSize: 13}}>No.</p>
                        <p style={{marginBottom: 'unset', fontSize: 13}}>Date:</p>
                    </div>
                    <p style={{marginBottom: 'unset', marginTop: 10, fontSize: 13}}>A/C : </p>
                    <div style={{marginTop: 10}}>
                        <Table bordered responsive>
                            <tbody>
                                <tr>
                                    <th>Sl No.</th>
                                    <th colSpan={3}>Perticulars</th>
                                    <th>Rs.</th>
                                    <th>Ps.</th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td colSpan={3}></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr> 
                                    <td colSpan={4}><span style={{float: "right", fontWeight: "bold"}}>Total</span></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <p style={{fontSize: 13, marginTop: 10}}>Rupees in words:</p>
                    <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 10}}>
                        <p style={{marginBottom: 'unset', fontSize: 13}}>Name & Signature:</p>
                        <p style={{marginBottom: 'unset', fontSize: 13}}>Manager</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bills;