import React, {useEffect, useState, useRef} from 'react'
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { connect } from "react-redux";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ReactToPrint from 'react-to-print';
import logo from '../../assets/images/logo.jpeg';

function Tariff({branchid, branchdetails}){

    const componentRef = useRef();
    const [source, setSource] = React.useState([])

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();

    const [date1, setDate1] = React.useState(year + '-' + month + '-' + date);
    const [date2, setDate2] = React.useState(year + '-' + month + '-' + date);

    useEffect(() => {
        getData()
    
    }, [branchid]);

    const getData = () => {
        var token = localStorage.getItem("token")
        const data = {
            "companyId": 1,
            "branchId": branchid,
            "fromDate": date1,
            "toDate": date2,
            "createdBy": "",
            "billno": "",
            "counterId": "",
            "userId": "",
            "soldItemsOnly": "",
            "accId": ""
          }
        fetch(global.url + "api/BookingReports/GetTariffStatementList", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),  
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            //  console.log("foreign listResponse", responseJson)
             setSource(responseJson)
             
        })
        .catch((error)=>{
            console.log(error)
        })
    }
    const formatDate = (datestring) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
        //   console.log("timeString12hr", timeString12hr)

          var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          return formattedDate; //03/02/2021
    }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>Tariff Statement</p> 
                <Row>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField
                                id="datetime-local"
                                label="From Date"
                                type="date"
                                defaultValue={year + '-' + month + '-' + date}
                                sx={{ width: '100%' }}
                                InputLabelProps={{
                                shrink: true,
                                }}
                                onChange={(newValue) => {
                                    setDate1(newValue.target.value);
                                }}
                            />
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField
                                id="datetime-local"
                                label="To Date"
                                type="date"
                                defaultValue={year + '-' + month + '-' + date}
                                sx={{ width: '100%' }}
                                InputLabelProps={{
                                shrink: true,
                                }}
                                onChange={(newValue) => {
                                    setDate2(newValue.target.value);
                                }}
                            />
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3}>
                        <div style={{justifyContent: 'center', display: 'flex'}}>
                            <Button onClick={getData} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 17, width: '50%'}} variant="contained">Search</Button>
                        </div>
                    </Col>
                    <Col xs={12} md={3}>
                        <ReactToPrint
                            trigger={() => <Button style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 17, width: '50%'}} variant="contained">Print this out!</Button>}
                            content={() => componentRef.current}
                        />
                    </Col>
                </Row>
                <div style={{height: 2, width: '100%', backgroundColor: '#d3d3d3', marginTop: 30}}></div>
                <div>
                    <TableContainer sx={{ maxHeight: 440, marginTop: 2 }}>
                        <div  className='section-to-print' ref={componentRef} style={{width: '95%'}}>
                        {/* <Row> 
                                
                                <img src={require("../../assets/images/logo.jpeg").default} style={{width: 100, position: 'absolute'}}/>
                                
                                <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                                <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                                </Col>
                            </Row> */}
                        <Table id="table-to-xls" stickyHeader aria-label="sticky table">
                        {/* <caption className='captiontable'>Tariff Statement From: {date1.split("-").reverse().join("-")} To {date2.split("-").reverse().join("-")}</caption> */}
                        <TableHead className="tableheaderexe">
                        <TableRow style={{height: 80}} >
                                    <TableCell colSpan={8}  style={{padding: 0}}>
                                        <Row>
                                            <img src={logo} style={{width: 100, position: 'absolute', top: 0}}/>
                                            
                                            <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                                            <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                                            </Col>
                                            <p className='printdate' style={{ position: 'absolute', left: '85%'}}>{formatDate(new Date)}</p>
                                        </Row>
                                    </TableCell>
                                 </TableRow>
                                 <TableRow>
                                    
                                    <TableCell colSpan={8} style={{padding: 0}}>
                                        <div style={{display: 'flex', justifyContent: 'center', fontWeight: 600}} className='captiontable'>
                                        TARIFF STATEMENT FROM {date1.split("-").reverse().join("-")} TO {date2.split("-").reverse().join("-")}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            <TableRow>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Check Out</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Time</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Source</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Room No.</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Check In</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Bill No.</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Tariff</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Total</TableCell>
                            </TableRow>
                        </TableHead>
                            <TableBody>
                                {source.map((i, index) => {
                                    return(
                                        <TableRow>
                                            <TableCell className='tablecellPrint'>{i.checkoutdate}</TableCell>
                                            <TableCell className='tablecellPrint'>{i.checkouttime}</TableCell>
                                            <TableCell className='tablecellPrint'>{i.source}</TableCell>
                                            <TableCell className='tablecellPrint'>{i.room}</TableCell>
                                            <TableCell className='tablecellPrint'>{i.checkin}</TableCell>
                                            <TableCell className='tablecellPrint'>{i.billno}</TableCell>
                                            <TableCell className='tablecellPrint' style={{textAlign: 'right'}}>{i.tariff.toFixed(2)}</TableCell>
                                            <TableCell className='tablecellPrint' style={{textAlign: 'right'}}>{i.total.toFixed(2)}</TableCell>
                                        </TableRow>
                                    )
                                    })}
                            </TableBody>
                        </Table>
                    </div>
                    </TableContainer>
                </div>
            </Paper>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(Tariff);