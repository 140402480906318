import React, {useState, useEffect} from 'react';
import './pos.css';
import {Table} from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";

function Bill(){
    const { id } = useParams();

    const [tablerow, setTablerow] = React.useState([]);
    const [subtotal, setSubtotal] = React.useState(0);
    const [makebill, setMakebill] = React.useState({});


    useEffect(() => {
        console.log("id", id)
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Sales/GetSalesById?id=" + id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("makebill view Response", responseJson)
             setMakebill(responseJson)
             setTablerow(responseJson.details)
             window.print()
        })
        .catch((error)=>{
            console.log(error)
        })
    }, []);

    useEffect(() => {
        getTotalamount()
    }, [tablerow]);

    const getTotalamount = () => {
        var totalAmount = 0;
        for (var i = 0; i < tablerow.length; i++) {
            totalAmount += parseInt(tablerow[i].dtsarate)
        }
       setSubtotal(totalAmount)
    }
    
    
    return(
        <div>
            <div  style={{display: 'flex', justifyContent: 'center', marginTop: 100}}>
                    
            </div>  
        </div>
    )
}

export default Bill;