import React, {useState, useEffect, useRef} from 'react';
import './TaxInvoice.css'
import {Container, Row, Col, Form, Button, Table} from "react-bootstrap";
import { connect } from "react-redux";
import ReactToPrint from 'react-to-print';
import logo from '../../../assets/images/logo.jpeg';

function TaxInvoice(props){
    const componentRef = useRef();
    const current = new Date();
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const [username, setUsername] = React.useState("");
    const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
    useEffect(() => {
        var username1 = localStorage.getItem("username")
        setUsername(username1)
        getData()
        console.log("checkoutdetails",props.checkoutdetails)
        // if(!props.isAdmin){
        //     var user =  JSON.parse(localStorage.getItem("formname"));
        //     console.log("user", user)
        // }
    }, []);

    const formatDate = (datestring, type) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
          

          if(type == "time") {
            var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          }
          else{
            var formattedDate =  day+"/"+month+"/"+year
          }

          
          return formattedDate; //03/02/2021
    }

    const getData = () => {
        console.log("boocheckoutdetailskingdetails", props.checkoutdetails)
        // setRoomid(props.bookingdetails)
    }
    const checkout =() => {
        var token = localStorage.getItem("token")
        console.log("roomid", props.roomid)
        fetch(global.url + "api/Booking/ConfirmCheckOut?roomId="+ props.roomid, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then(async(responseJson)=>{
            console.log("ConfirmCheckOut", responseJson)
            if(responseJson.message) {
                alert(responseJson.message)
            }
            else if(responseJson.bookingid){
                alert("success")
                document.getElementById('print-button').click();
                await delay(2000);
                props.parentCallback()
            }
            
        })
        .catch((error)=>{
            console.log(error)
        })
    }
    return(
        <div className='posprint' ref={componentRef} style={{width: '95%',margin: '10px 35px 10px 25px'}}>
            <div className='printborderm'>
            <div style={{display: 'none'}}>
                <ReactToPrint
                        trigger={() => <Button id="print-button" variant="primary" className="side-topbtn">OT Print</Button>}
                        content={() => componentRef.current}
                    />
            </div>
            {/* <p style={{fontSize: 13, marginBottom: 'unset'}}>{formatDate(new Date, "time")}</p> */}
            <p style={{fontSize: 20, textAlign: "center", fontWeight: "bold", marginBottom: 'unset'}}>TAX INVOICE</p>
            <div className="borderall"></div>
            <div style={{marginTop: 20}}>
                <Row>
                <Col xs={7}>
                        <Row>
                            <Col xs={3}>
                                <img src={logo} style={{width: 65, height: 65, marginTop: -5}}/>
                            </Col>
                            <Col xs={9}>
                               <p style={{fontSize: 12, marginTop: 3}}>{props.branchdetails.brname}<br></br>
                               {props.branchdetails.braddresS1}<br></br>
                               {props.branchdetails.braddresS2}<br></br>
                               {props.branchdetails.braddresS3}<br></br>
                               </p>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={5}>
                        <Row>
                            <Col xs={4}>
                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>GST No.</p>
                            </Col>
                            <Col xs={4}>
                                <p style={{fontSize: 13, marginBottom: 'unset'}}>{props.branchdetails.brgstin}</p>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col xs={4}>
                                <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: 'unset'}}>PAN No.</p>
                            </Col>
                            <Col xs={4}>
                                <p style={{fontSize: 13, marginBottom: 'unset'}}>{props.checkoutdetails.customerDetails.panno}</p>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col xs={4}>
                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>BILL DATE</p>
                            </Col>
                            <Col xs={4}>
                                <p style={{fontSize: 13, marginBottom: 'unset'}}>{formatDate(props.checkoutdetails.departure, "date")}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            {/* <div className='borderall' style={{marginTop: 10}}></div> */}
            <div style={{marginTop: -8}}>
                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>CUSTOMER DETAILS</p>
                <Row>
                    <Col xs={7}>
                        <Row>
                            <Col xs={4}>
                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>GUEST ID</p>
                            </Col>
                            <Col xs={8}>
                                <p style={{fontSize: 13, marginBottom: 'unset'}}>{props.checkoutdetails.guestid}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>GUEST NAME</p>
                            </Col>
                            <Col xs={8}>
                                <p style={{fontSize: 13, marginBottom: 'unset'}}>{props.checkoutdetails.customerDetails.customername}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>ADDRESS</p>
                            </Col>
                            <Col xs={8}>
                                <p style={{fontSize: 13, marginBottom: 'unset', wordBreak: 'break-all'}}>{props.checkoutdetails.customerDetails.addresS1}</p>
                                <p style={{fontSize: 13, marginBottom: 'unset', wordBreak: 'break-all' }}>{props.checkoutdetails.customerDetails.addresS2}</p>
                                <p style={{fontSize: 13, marginBottom: 'unset', wordBreak: 'break-all' }}>{props.checkoutdetails.customerDetails.addresS3}</p>
                                <p style={{fontSize: 13, marginBottom: 'unset', wordBreak: 'break-all' }}>{props.checkoutdetails.customerDetails.mobile}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>GST NO.</p>
                            </Col>
                            <Col xs={8}>
                                <p style={{fontSize: 13, marginBottom: 'unset'}}>{props.checkoutdetails.customerDetails.gstin}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>PAN No.</p>
                            </Col>
                            <Col xs={8}>
                                <p style={{fontSize: 13, marginBottom: 'unset'}}>{props.checkoutdetails.customerDetails.panno}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>TRAVEL AGENT</p>
                            </Col>
                            <Col xs={8}>
                                <p style={{fontSize: 13, marginBottom: 'unset'}}>{props.checkoutdetails.source}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>STATE CODE</p>
                            </Col>
                            <Col xs={8}>
                                <p style={{fontSize: 13, marginBottom: 'unset'}}>{props.checkoutdetails.customerDetails.state}</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={5}>
                        <Row>
                            <Col xs={4}>
                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>GRC NO.</p>
                            </Col>
                            <Col xs={8}>
                                <p style={{fontSize: 13, marginBottom: 'unset'}}>{props.checkoutdetails.docno}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>BILL NO.</p>
                            </Col>
                            <Col xs={8}>
                                <p style={{fontSize: 13, marginBottom: 'unset'}} className='billnohide'>{props.checkoutdetails.billno}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>ARRIVAL</p>
                            </Col>
                            <Col xs={8}>
                                <p style={{fontSize: 13, marginBottom: 'unset'}}>{formatDate(props.checkoutdetails.arrival, "time")}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>DEPARTURE</p>
                            </Col>
                            <Col xs={8}>
                                <p style={{fontSize: 13, marginBottom: 'unset'}}>{formatDate(props.checkoutdetails.departure, "time")}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>PRINTED BY</p>
                            </Col>
                            <Col xs={8}>
                                <p style={{fontSize: 13, marginBottom: 'unset'}}>{props.isAdmin ? "Admin" : username}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>PRINTED ON</p>
                            </Col>
                            <Col xs={8}>
                                <p style={{fontSize: 13, marginBottom: 'unset'}}>{formatDate(new Date,  "time")}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>REV.Charge</p>
                            </Col>
                            <Col xs={8}>
                                <p style={{fontSize: 13, marginBottom: 'unset'}}>{props.checkoutdetails.reversecharge == 0? "No" : "Yes"}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className='borderall' style={{marginTop: 5}}></div>
            <div style={{marginTop: 10}}>
                <Table className='sizecolumn' bordered responsive>
                    <thead>
                        <tr className='tax-bord'>
                            <th>ROOM NO(S)</th>
                            <th>ROOM TYPE</th>
                            <th>GUEST</th>
                            <th>RATE</th>
                        </tr>
                    </thead>
                    <tbody style={{borderTop: 'unset'}}>
                        {
                            props.checkoutdetails.roomDetails?.map((i, k) => 
                            <tr className='tax-bord'>
                                <td>{i.roomno}</td>
                                <td>{i.roomtype}</td>
                                <td>{i.noofguest}</td>
                                <td>{parseFloat(i.roomrate)?.toFixed(2)}</td>
                            </tr>
                            )
                        }
                        
                    </tbody>
                </Table>
            </div>
            <div style={{marginTop: -8}}>
                <Table className='sizecolumn' bordered responsive>
                    <thead>
                        <tr className='tax-bord'>
                            <th>DATE</th>
                            <th>DESCRIPTION</th>
                            <th>DEBIT</th>
                            <th>CREDIT</th>
                        </tr>
                    </thead>
                   
                        {
                            props.checkoutdetails.billDetails.length == 0 ? null :
                            props.checkoutdetails.billDetails.map((i, k) => {
                                return(
                                    i.sgst > 0 ?
                                    <tbody  style={{borderTop: 'unset'}}>
                                   
                                   <tr className='tax-border'>
                                        <td className='tax-bord' rowSpan={4}>{formatDate(i.startdate, "date")}-{formatDate(i.enddate, "date")} - (DAYS: {i.noofdays})</td>
                                        <td className='tax-bord'>{i.description}</td>
                                        <td className='tax-bord' style={{textAlign:'right'}}>{parseFloat(i.total)?.toFixed(2)}</td>
                                        <td className='tax-bord'></td>
                                    </tr>
                                    
                                    <tr className='tax-border'>
                                       
                                        <td className='tax-bord'>ACCOMMODATION CGST 6.00% </td>
                                        <td className='tax-bord' style={{textAlign:'right'}}>{parseFloat(i.cgst)?.toFixed(2)}</td>
                                        <td className='tax-bord'></td>
                                    </tr>
                                    <tr className='tax-border'>
                                       
                                        <td className='tax-bord'>ACCOMMODATION SGST 6.00% </td>
                                        <td className='tax-bord' style={{textAlign:'right'}}>{parseFloat(i.sgst)?.toFixed(2)}</td>
                                        <td className='tax-bord'></td>
                                    </tr>
                                   </tbody> :
                                   <tbody  style={{borderTop: 'unset'}}>
                                    <tr className='tax-border'>
                                        <td className='tax-bord' rowSpan={4}>{formatDate(i.startdate, "date")}-{formatDate(i.enddate, "date")}</td>
                                        <td className='tax-bord'>{i.description}</td>
                                        <td className='tax-bord' style={{textAlign:'right'}}>{parseFloat(i.total)?.toFixed(2)}</td>
                                        <td className='tax-bord'></td>
                                    </tr>
                                </tbody>

                                )
                            })
                        }
                    <tbody  style={{borderTop: 'unset'}}>
                        {
                            props.checkoutdetails.advance > 0 ? 
                            <tr className='tax-bord'>
                                <td className='tax-bord'></td>
                                <td className='tax-bord'>ADVANCE</td>
                                <td className='tax-bord' style={{textAlign: 'right'}}></td>
                                <td className='tax-bord' style={{textAlign: 'right'}}>{parseFloat(props.checkoutdetails.advance)?.toFixed(2)}</td>
                            </tr> :
                            null
                        }
                        
                        <tr className='tax-bord'>
                            <td className='tax-bord'></td>
                            <td className='tax-bord' style={{fontWeight: 'bold', fontSize: 20}}>TOTAL (INR)</td>
                            <td className='tax-bord' style={{textAlign: 'right', fontWeight: 'bold', fontSize: 16}}>{parseFloat(props.checkoutdetails.total)?.toFixed(2)}</td>
                            <td className='tax-bord' style={{textAlign: 'right', fontWeight: 'bold', fontSize: 16}}>0.00</td>
                        </tr>
                        <tr className='tax-bord'>
                            <td></td>
                            <td>({props.checkoutdetails.amountsInWords})</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr className='tax-bord'>
                            <td></td>
                            <td style={{fontWeight: 'bold'}}>BALANCE (INR)</td>
                            <td style={{textAlign: 'right', fontWeight: 'bold'}}>{parseFloat(props.checkoutdetails.balance)?.toFixed(2)}</td>
                            <td style={{textAlign: 'right', fontWeight: 'bold'}}>0.00</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
            <div style={{marginTop: -8}}>
                <Table className='sizecolumn' bordered responsive>
                    <thead>
                        <tr className='tax-bord'>
                            <th>HSN/SAC CODE</th>
                            <th style={{textAlign:'right'}}>SALES</th>
                            <th style={{textAlign:'right'}}>CGST</th>
                            <th style={{textAlign:'right'}}>SGST</th>
                            <th>IGST</th>
                        </tr>
                    </thead>
                    <tbody  style={{borderTop: 'unset'}}>
                        <tr className='tax-bord'>
                            <th>{props.checkoutdetails.taxDetails.hsn}</th>
                            <th style={{textAlign:'right'}}>{parseFloat(props.checkoutdetails.taxDetails.sales)?.toFixed(2)}</th>
                            <th style={{textAlign:'right'}}>{parseFloat(props.checkoutdetails.taxDetails.cgst)?.toFixed(2)}</th>
                            <th style={{textAlign:'right'}}>{parseFloat(props.checkoutdetails.taxDetails.sgst)?.toFixed(2)}</th>
                            <th></th>
                        </tr>
                    </tbody>
                </Table>
            </div>
            <div style={{marginTop: 40, display: 'flex', justifyContent: 'space-between'}}>
                <p style={{fontSize: 12, marginBottom: 'unset'}}>CHECKED BY</p>
                <p style={{fontSize: 12, marginBottom: 'unset'}}>GUEST SIGNATURE</p>
                <p style={{fontSize: 12, marginBottom: 'unset'}}>{props.branchdetails.brname}</p>
            </div>
            <div style={{width: '100%', height: 1, backgroundColor: '#000'}}></div>
            <p style={{fontSize: 13, marginTop: 20}}>ORIGINAL FOR RECIPIENT</p>
            <div className='printDnone' style={{marginTop: 15, marginBottom: 15, display: 'flex', justifyContent: 'center'}}>
                <Button onClick={() => checkout()} variant="contained" style={{marginTop: 10, backgroundColor: '#03a5fc', color: '#fff', fontSize: 14}}>Checkout</Button>
            </div>
        </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        // branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(TaxInvoice);