import React, {useState, useEffect} from 'react'
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { connect } from "react-redux";

const columns = [
    { id: '1', label: 'Date', minWidth: 50,span: false },
    { id: '2', label: 'DocNo', minWidth: 50,span: false },
    { id: '3', label: 'Status', minWidth: 50,span: false },
    { id: '1', label: 'Reportedby', minWidth: 50,span: false },
    { id: '2', label: 'Reportedon', minWidth: 50,span: false },
    { id: '3', label: 'View', minWidth: 50,span: false },
    { id: '1', label: 'Edit', minWidth: 50,span: false },
  ];


function Serviceview({branchid}){
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [source, setSource] = React.useState([]);

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();

    const [date1, setDate1] = React.useState(year + '-' + month + '-' + date);
    const [date2, setDate2] = React.useState(year + '-' + month + '-' + date);
    const [ledger, setLedger] = React.useState('');
    const [mode, setMode] = React.useState('');
    const [ledglist, setLedg] =  React.useState([]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
  
      const handleChangeRowsPerPage = (event) => {
          setRowsPerPage(+event.target.value);
          setPage(0);
        };

    const modeChange = (event) => {
        setMode(event.target.value);
      };

      const legerChange = (event) => {
        setLedger(event.target.value)
      }

    useEffect(() => {
        getData()
        getLedger()
    }, []);


    const getData = () => {
        var token = localStorage.getItem("token")
        var data = {
                "counterId": "",
                "companyId": 1,
                "branchId": branchid,
                "fromDate": date1,
                "toDate": date2,
                "createdBy": "",
                "accId": ledger,
                "godownId": "",
                "productId": "",
                "unitId": "",
                "voucherType": mode,
                "bankGroupId": "",
                "sectionId": "",
                "deparmentId": "",
                "categoryId": "",
                "subCategoryId": "",
                "color": "",
                "sizeId": "",
                "userId": "",
                "cashGroupId": "",
                "posOnly": "",
                "groupId": "",
                "supplierGroupId": "",
                "deliveryAccId": "",
                "discountAccId": "",
                "directSaleOnly": "",
                "salesAccountAccId": "",
                "contryId": "",
                "waiterId": ""
        }
        console.log("paymentdata", data)
        fetch(global.url + "api/PaymentReport/PaymentReport", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("payment", responseJson)
             setSource(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const filterData = () => {
        getData()
    }

    const getLedger = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Accounts/GetLedgerList?orderBy=Name&companyId=1&branchId=" + branchid + "&includeBlocked=False", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("ledger Response", responseJson)
             setLedg(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }


    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>Serviceview</p> 
                <Row>
                    <Col xs={12}  className='columnborder'>
                        <div>
                            <Row>
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="From Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setDate1(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="To Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setDate2(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={3}>
                                    {/* <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField id="outlined-basic" value={ledger} onChange={(e) => setLedger(e.target.value)}  label="Ledger" variant="outlined"  size="small" />
                                    </FormControl> */}
                                    <FormControl fullWidth sx={{width: '100%', mt: 3 }}>
                                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                            <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={ledger}
                                            label="Age"
                                            onChange={legerChange}
                                            size="small"
                                            >
                                                <MenuItem value="">Test</MenuItem>
                                                <MenuItem value="">Test</MenuItem>
                                
                                            </Select>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField  id="outlined-basic" label="Search" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                               
                                <Col xs={12} md={2}>
                                    <div style={{justifyContent: 'center', display: 'flex'}}>
                                        <Button  style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">Search</Button>
                                    </div>
                                </Col>
                                {/* <Col xs={12} md={2}>
                                    <div style={{ justifyContent: 'center', display: 'flex'}}>
                                        <Button style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">print</Button>
                                    </div>
                                </Col> */}
                            </Row>
                           
                        </div>
                    </Col> 
                </Row>
                <div style={{height: 2, width: '100%', backgroundColor: '#d3d3d3', marginTop: 30}}></div>
                <div className='bulk-table'>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                                    colSpan={column.span == true ? 2: 1}
                                >
                                    {column.label}
                                    
                                </TableCell>
                                ))}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {source
                                .map((row, index) => {
                                return (
                                    <TableRow  key={row.vid}>
                                        <TableCell>
                                        {index + 1}
                                        </TableCell>
                                        <TableCell>
                                            { (new Date(row.pvdate)).toLocaleDateString() }
                                        </TableCell>
                                        <TableCell >
                                            {row.pvno}
                                        </TableCell>
                                        <TableCell>
                                            {row.pvpaymode}
                                        </TableCell>
                                        <TableCell>
                                            {row.pvrefno}
                                        </TableCell>
                                        
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
                        </TableContainer>
                        
                    </div>
            </Paper>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
    };
};

export default connect(mapStateToProps)(Serviceview);