import React, {useState, useEffect, useRef} from 'react';
import './pos.css';
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button, Table, Modal} from "react-bootstrap";
import { connect } from "react-redux";
import { Route, useHistory, useLocation } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Skeleton from '@mui/material/Skeleton';
import { FaGalacticSenate } from 'react-icons/fa';
import ReactToPrint from 'react-to-print';
import accessimgae from '../../assets/images/access.png'

function Pos({branchid, branchdetails}){
    const componentRef = useRef();
    const componentRef1 = useRef();
    const componentRef2 = useRef();
    
    const [source, setSource] = React.useState([]);
    const [products, setProducts] = React.useState([]);
    const [tablerow, setTablerow] = React.useState([]);
    const [subtotal, setSubtotal] = React.useState(0);
    const [formid, setFormid] = React.useState("");
    const [docno, setDocno] = React.useState("");
    const [docnum, setDocnum] = React.useState("");
    const [accid, setAccid] = React.useState("");
    const [docdate, setDocdate] = React.useState("");
    const [accname, setAccname] = React.useState("");
    const [categoryid1, setCategoryid1] = React.useState("");
    const [roomnumber, setRoomnumber] = React.useState("");
    const [tablenumber, setTablenumber] = React.useState("");
    const [happyhr, setHappyhr] = React.useState(false);
    const [editmode, setEditmode] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);

    const [vid, setVid] = React.useState(0);
    const [said, setSaid] = React.useState(0);

    const [splitdetails, setSplitdetails] = React.useState();
    const [splitpopup, setSplitpopup] = React.useState(false);

    
    

    const [waiterlist, setWiaterlist] = React.useState([]);

    


    

    
    
    const [loader, setLoader] = React.useState(false);

    const [tablelist, setTablelist] = React.useState([]);
    const [tableid, setTableid] = React.useState("");
    const [billstatus, setBillstatus] = React.useState(0);

    const [tableview, setTableview] = React.useState(true);

    const [roomlist, setRoomlist] = React.useState([]);
    const [roomid, setRoomid] = React.useState("");

    const [counter, setCounter] = React.useState("");
    const [counterlist, setCounterlist] = React.useState([]);

    const [makebill, setMakebill] = React.useState({});
    const [selectedindex, setSelectedindex] = React.useState();

    const [name, setName] = React.useState("");

    const [productid, setProductid] = React.useState("");
    const [productname, setProductname] = React.useState("");
    const [productcode, setProductcode] = React.useState("");
    const [barbottle, setBarbottle] = React.useState(0);
    const [acpeg, setAcpeg] = React.useState(0);
    const [acbottle, setAcbottle] = React.useState(0);
    const [barpeg, setBarpeg] = React.useState(0);
    const [printmodal, setPrintmodal] = React.useState(false);
    const [kotprintmodal, setKotPrintmodal] = React.useState(false);
    const [categoryid, setCategoryid] = React.useState(false);

    const [waitermodal, setWaitermodal] = React.useState(false);
    const [waitercode, setWaitercode] = React.useState("");

    const [modal, setModal] = React.useState(false);

    const [pricefix, setPricefix] = React.useState("peg");

    const [countername, setCountername] = React.useState("");

    const delay = ms => new Promise(res => setTimeout(res, ms));
    const location = useLocation();
    const [access,  setAccess] = useState(false)

    useEffect(() => {
        const formname = JSON.parse(localStorage.getItem("formname"));
        console.log("path", location.pathname)
        var userid1 = localStorage.getItem("userid")
        console.log("formname", formname)
        if(userid1 != 1) {
          const conditioncheck = formname.some(function(o){return o["formname"] === location.pathname})
          console.log("conditioncheck", conditioncheck)
          setAccess(conditioncheck)
        }
        else{
          setAccess(true)
        }
    }, []);

    const counterClick = (id) => {
        setCounter(id);
        getCounterbyid(id)
    };

    const getCounterbyid =(id) => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Counter/GetCounterById?id=" + id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("counter id Response", responseJson)
             setCountername(responseJson.ctrname)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const radioChange = (event) => {
        console.log(event.target.value, "event.target.value")
        if(event.target.value == "peg") {
           setPricefix("peg")
        }
        else {
            setPricefix("bottle")
        }
      }
    

    let history = useHistory();

    useEffect(() => {
        getCounterlist()
        getCategory()
        getRoomlist()
        getTablelist()
    }, [branchid]);

    useEffect(() => {
        if(refresh == true) {
            window.location.reload()
            setRefresh(false)
        }
    }, [refresh]);

   

    const getCounterlist = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Counter/GetCounterlist?companyId=1&branchId=" + branchid + "&includeBlocked=false", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("counter list Response", responseJson)
            setCounterlist(responseJson)
            setCounter(responseJson[0].ctrid)
            getCounterbyid(responseJson[0].ctrid)
        })
        .catch((error)=>{
            console.log(error)
        })
    }



    const getRoomlist = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Room/GetRoomList?branchId=" + branchid, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("room list Response", responseJson)
            setRoomlist(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getTablelist = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Tables/GetTablesList?branchId=" + branchid, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("table list Response", responseJson)
            setTablelist(responseJson)
            if(responseJson[0].counterid == 1) {
                if(responseJson[0].vid == 0) {
                    onTableclick(responseJson[0].tableid, responseJson[0].tablename)
                }
                else{
                    onRetrievet(responseJson[0].vid,responseJson[0].tableid,"", responseJson[0].tablename)
                }
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    useEffect(() => {
        getTotalamount()
    }, [tablerow]);

    const getTotalamount = () => {
        var totalAmount = 0;
        for (var i = 0; i < tablerow.length; i++) {
            totalAmount += parseFloat(tablerow[i].dtamount)
        }
       setSubtotal(totalAmount.toFixed(2))
    }

    const getCategory = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Sales/NewSale?branchId=" + branchid, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("category view Response", responseJson)
             setSource(responseJson.categoryList)
             setFormid(responseJson.saformid)
             setDocno(responseJson.kot)
             setDocnum(responseJson.kotnum)
             setAccid(responseJson.saaccid)
             setDocdate(responseJson.docdate)
             setAccname(responseJson.saaccname)
             setHappyhr(responseJson.happyHour)
             setWiaterlist(responseJson.waiterList)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const categoryClick = (data, id) => {
        setProducts(data)
        setCategoryid(data)
        setCategoryid1(id)
    }

    const outofStockcheck = async(
        priceid,
        productid,
        updatedby,
        branchid,
        barpeg,
        barbottle,
        acpeg,
        acbottle,
        expeg,
        exbottle,
        localpeg,
        localbottle,
        wsbottle,
        updatedon,
        roompeg,
        roombottle,
        code,
        productname,
        bottleSaleOnly,
        type,
        bottle,
        peg) => {
         var token = localStorage.getItem("token")
        await fetch(global.url + "api/Products/CheckOutOfStock?counterId=" + counter + "&branchId=" + branchid + "&productId=" + productid + "&bottle=" + bottle + "&peg=" + peg, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("CheckOutOfStock", responseJson)
                if(responseJson == 0){
                    alert("Out of stock")
                    return 
                }
                else{
                    productClick(
                        priceid,
                        productid,
                        updatedby,
                        branchid,
                        barpeg,
                        barbottle,
                        acpeg,
                        acbottle,
                        expeg,
                        exbottle,
                        localpeg,
                        localbottle,
                        wsbottle,
                        updatedon,
                        roompeg,
                        roombottle,
                        code,
                        productname,
                        bottleSaleOnly,
                        type 
                    )
                }
                
            })
            .catch((error)=>{
                console.log(error)
            })
    }

    const productClick = (
        priceid,
        productid,
        updatedby,
        branchid,
        barpeg,
        barbottle,
        acpeg,
        acbottle,
        expeg,
        exbottle,
        localpeg,
        localbottle,
        wsbottle,
        updatedon,
        roompeg,
        roombottle,
        code,
        productname,
        bottleSaleOnly,
        type

    ) => {
        console.log("categoryid", categoryid)
        if(tableid == "") {
            if(roomid!= ""){

            }
            else{
                if(roomid == "") {
                    alert("Please Select Room")
                    return
                }
                else{
                    alert("Please Select Table")
                    return
                }
            } 
        }

       
        if(bottleSaleOnly) {

            if(countername == "AC BAR" && acbottle == 0){
                alert("Price 0")
                return
            }
            if(countername != "AC BAR" && barbottle == 0){
                alert("Price 0")
                return
            }
            
            
            var input = [...tablerow, {
                dtid: 0,
                vid: "",
                dtprdid: productid,
                dtprdbatchid: "",
                dtunitid: 2,
                dtqty: 1,
                dtprice: countername == "AC BAR"  ? acbottle : barbottle,
                dtamount: countername == "AC BAR"  ? acbottle : barbottle,
                dtdiscper: "",
                dtdiscount: "",
                dtgross: "",
                dtsgst: "",
                dtcgst: "",
                dtigst: "",
                dttax: "",
                dtsgstper: "",
                dtcgstper: "",
                dtigstper: "",
                dttaxper: "",
                dttaxid: "",
                dttotal: "",
                dtlremarks: "",
                dtgdnid: "",
                dtcost: "",
                dtsarate: countername == "AC BAR"  ? acbottle : barbottle,
                dtmrp: "",
                batchcode: "",
                barcode: "",
                procode: code,
                proname: productname,
                localname: productname,
                unitname: "",
                gdnname: "",
              }]
              setTablerow(input)
        }
        else if(type == "F"){

            if(countername == "AC BAR" && acbottle == 0){
                alert("Price 0")
                return
            }
            if(countername != "AC BAR" && barbottle == 0){
                alert("Price 0")
                return
            }

            var input = [...tablerow, {
                dtid: 0,
                vid: "",
                dtprdid: productid,
                dtprdbatchid: "",
                dtunitid: 2,
                dtqty: 1,
                dtprice: countername == "AC BAR"  ? acbottle : barbottle,
                dtamount: countername == "AC BAR"  ? acbottle : barbottle,
                dtdiscper: "",
                dtdiscount: "",
                dtgross: "",
                dtsgst: "",
                dtcgst: "",
                dtigst: "",
                dttax: "",
                dtsgstper: "",
                dtcgstper: "",
                dtigstper: "",
                dttaxper: "",
                dttaxid: "",
                dttotal: "",
                dtlremarks: "",
                dtgdnid: "",
                dtcost: "",
                dtsarate: countername == "AC BAR"  ? acbottle : barbottle,
                dtmrp: "",
                batchcode: "",
                barcode: "",
                procode: code,
                proname: productname,
                localname: productname,
                unitname: "",
                gdnname: ""
              }]
              setTablerow(input)
            // setModal(true)
            // setProductid(productid)
            // setProductname(productname)
            // setProductcode(code)
            // setBarpeg(barpeg)
            // setBarbottle(barbottle)
            // setAcpeg(acpeg)
            // setAcbottle(acbottle)
        }
        else{

            if(countername == "AC BAR" && acpeg == 0){
                alert("Price 0")
                return
            }
            if(countername != "AC BAR" && barpeg == 0){
                alert("Price 0")
                return
            }
            var input = [...tablerow, {
                dtid: 0,
                vid: "",
                dtprdid: productid,
                dtprdbatchid: "",
                dtunitid: 1,
                dtqty: 1,
                dtprice: countername == "AC BAR"  ? acpeg : barpeg,
                dtamount: countername == "AC BAR"  ? acpeg : barpeg,
                dtdiscper: "",
                dtdiscount: "",
                dtgross: "",
                dtsgst: "",
                dtcgst: "",
                dtigst: "",
                dttax: "",
                dtsgstper: "",
                dtcgstper: "",
                dtigstper: "",
                dttaxper: "",
                dttaxid: "",
                dttotal: "",
                dtlremarks: "",
                dtgdnid: "",
                dtcost: "",
                dtsarate: countername == "AC BAR"  ? acpeg : barpeg,
                dtmrp: "",
                batchcode: "",
                barcode: "",
                procode: code,
                proname: productname,
                localname: productname,
                unitname: "",
                gdnname: ""
              }]
              setTablerow(input)
        }
    }

    const onAddsubmit = () => {
        var input = [...tablerow, {
            dtid: 0,
            vid: "",
            dtprdid: productid,
            dtprdbatchid: "",
            dtunitid: pricefix == "peg" ? 1 : 2,
            dtqty: 1,
            dtprice: countername == "AC BAR" && pricefix == "peg" ?  
                        acpeg : countername == "AC BAR" && pricefix == "bottle" ? 
                        acbottle : countername == "BAR" && pricefix == "peg" ?
                        barpeg : barbottle,
            dtamount: countername == "AC BAR" && pricefix == "peg" ?  
                        acpeg : countername == "AC BAR" && pricefix == "bottle" ? 
                        acbottle : countername == "BAR" && pricefix == "peg" ?
                        barpeg : barbottle,
            dtdiscper: "",
            dtdiscount: "",
            dtgross: "",
            dtsgst: "",
            dtcgst: "",
            dtigst: "",
            dttax: "",
            dtsgstper: "",
            dtcgstper: "",
            dtigstper: "",
            dttaxper: "",
            dttaxid: "",
            dttotal: "",
            dtlremarks: "",
            dtgdnid: "",
            dtcost: "",
            dtsarate: countername == "AC BAR" && pricefix == "peg" ?  
                    acpeg : countername == "AC BAR" && pricefix == "bottle" ? 
                    acbottle : countername == "BAR" && pricefix == "peg" ?
                    barpeg : barbottle,
            dtmrp: "",
            batchcode: "",
            barcode: "",
            procode: productcode,
            proname: productname,
            localname: productname,
            unitname: "",
            gdnname: ""
          }]

          setTablerow(input)
          setModal(false)
    }

    const onSave = () => {
        if(tablerow.length == 0){
            alert("No items in Table")
            return
        }
        
        setLoader(true)
        var token = localStorage.getItem("token")
        console.log("token", token)
        if(tableid == "") {
            if(roomid!= ""){

            }
            else{
                if(roomid == "") {
                    alert("Please Select Room")
                    return
                }
                else{
                    alert("Please Select Table")
                    return
                }
            } 
        }

        tablerow.forEach(function(v){ delete v.$id });


        const data = {
            "said": said,
            "vid": vid,
            "saformid": formid,
            "sadocdate": docdate,
            "sadocno": docno,
            "sadocnum": docnum,
            "sacaccid": accid,
            "saaccname": accname,
            "saaccadD1": "",
            "saaccadD2": "",
            "saaccadD3": "",
            "sastateid": "",
            "sadistid": "",
            "sacomid": 1,
            "sabranchid": branchid,
            "samob": "",
            "saamount":  subtotal,
            "saitemdisc": "",
            "sagross":"",
            "sasgst": "",
            "sacgst": "",
            "saigst": "",
            "satax": "",
            "sadiscper": "",
            "sadiscount": "",
            "sasubtotal": subtotal,
            "sanettotal": subtotal,
            "sacreatedon": "",
            "sacreatedby": global.userid,
            "saupdatedby": global.userid,
            "printFile": "",
            "sacashpaid": "",
            "sabankpaid": "",
            "satotalpaid": "",
            "saupdatedon": "",
            "saduedate": "",
            "saremarks": "",
            "sacessper": "",
            "sacessamount": "",
            "satotal": subtotal,
            "sagodownid": 1,
            "sacode": "",
            "sagstin": "",
            "sarepid": "",
            "saispos": "",
            "sacardrefno": "",
            "sadayclose": "",
            "sacounterid": counter,
            "sadaycloseid": "",
            "sacardtype": "",
            "sadeliverycharge": "",
            "sarefcode": happyhr? 'H' : null,
            "tableid": "",
            "roomid": "",
            "billnonum": "",
            "billno": "",
            "waiterid": "",
            "details": tablerow,
            "billDate": "",
            "user": 1,
            "time": "",
            "sacash": "",
            "balance": "",
            "inWords": "",
            "billedstatus": 0,
            "tableid": tableid,
            "roomid": roomid
          }
        console.log("save data", data)

        fetch(global.url + "api/Sales/SaveSales", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            if(responseJson) {
                // alert("success")
                getSalesbyid(responseJson.vid)
            }
        })
        .catch((error)=>{
            alert("error occured")
            console.log(error)
        })
    }

    const getSalesbyid = (id) => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Sales/GetSalesById?id=" + id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
        .then((response)=> response.json())
        .then(async(responseJson)=>{
            console.log('print', responseJson)
            setLoader(false)
            if(responseJson) {
                // alert("success")
                
                setMakebill(responseJson)
                setKotPrintmodal(true)
                console.log("log", editmode)
                setRefresh(false)
                document.getElementById('print-button').click();
                await delay(2000);
                setRefresh(true)
                // window.location.reload()
            }
        })
        .catch((error)=>{
            alert("error occured")
            console.log(error)
        })
    }


    const onTableclick = (id, tnum) => {
        setTableid(id)
        setRoomid("")
        setTablenumber(tnum)
        setRoomnumber("")
        setTablerow([])
        getCategory()
        setSaid(0)
        setVid(0)
        setEditmode(false)
    }

    const onRoomclick = (id, rname) => {
        setRoomid(id)
        setRoomnumber(rname)
        setTablenumber("")
        setTableid("")
        setTablerow([])
        setSaid(0)
        setVid(0)
        setEditmode(false)
    }

    const onRetrievet =(id, table, room, roomnum) => {
        setEditmode(true)
        var token = localStorage.getItem("token")
       if(room == "") {
        setTableid(table)
        setRoomid("")
       }
       else{
        setRoomid(room)
        setTableid("")
       }
        
        
        fetch(global.url + "api/Sales/GetSalesById?id=" + id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("makebill view Response", responseJson)
             setMakebill(responseJson)
             setDocno(responseJson.sadocno)
             setTablerow(responseJson.details)
            
             setTablenumber(roomnum)
             setSaid(responseJson.said)
             setVid(responseJson.vid)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const onRetriever =(id, table, room, roomnum) => {
        setEditmode(true)
        var token = localStorage.getItem("token")
       if(room == "") {
        setTableid(table)
        setRoomid("")
       }
       else{
        setRoomid(room)
        setTableid("")
       }
        
        
        fetch(global.url + "api/Sales/GetSalesById?id=" + id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("makebill view Response", responseJson)
             setMakebill(responseJson)
             setDocno(responseJson.sadocno)
             setTablerow(responseJson.details)
            
             setRoomnumber(roomnum)
             setSaid(responseJson.said)
             setVid(responseJson.vid)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

     const filter = (e) => {
            const keyword = e.target.value;

            if (keyword !== '') {
            const results = products.filter((user) => {
                return user.productname.toLowerCase().startsWith(keyword.toLowerCase());
                // Use the toLowerCase() method to make it case-insensitive
            });
                setProducts(results);
            } else {
                categoryClick(categoryid, categoryid1)
            // If the text field is empty, show all users
            }

            setName(keyword);
    };

    const onMakebillclick =()=> {
        console.log("waitercode", waitercode)
        if(waitercode == "") {
            return alert("Empty Waitercode")
        }
        var waitercodeinclude = false
        for(var i=0; i< waiterlist.length; i++){
            if(waitercode == waiterlist[i].waiterid) {
                waitercodeinclude = true
            }
        }
        if(waitercodeinclude == true) {
            var token = localStorage.getItem("token")
            console.log("token", token)
            const data = {
                "said": said,
                "vid": vid,
                "saformid": formid,
                "sadocdate": docdate,
                "sadocno": docno,
                "sadocnum": docnum,
                "sacaccid": accid,
                "saaccname": accname,
                "saaccadD1": "",
                "saaccadD2": "",
                "saaccadD3": "",
                "sastateid": "",
                "sadistid": "",
                "sacomid": 1,
                "sabranchid": branchid,
                "samob": "",
                "saamount":  subtotal,
                "saitemdisc": "",
                "sagross":"",
                "sasgst": "",
                "sacgst": "",
                "saigst": "",
                "satax": "",
                "sadiscper": "",
                "sadiscount": "",
                "sasubtotal": subtotal,
                "sanettotal": subtotal,
                "sacreatedon": makebill.sacreatedon,
                "sacreatedby": global.userid,
                "saupdatedby": global.userid,
                "printFile": "",
                "sacashpaid": "",
                "sabankpaid": "",
                "satotalpaid": "",
                "saupdatedon": "",
                "saduedate": "",
                "saremarks": "",
                "sacessper": "",
                "sacessamount": "",
                "satotal": subtotal,
                "sagodownid": 1,
                "sacode": "",
                "sagstin": "",
                "sarepid": "",
                "saispos": "",
                "sacardrefno": "",
                "sadayclose": "",
                "sacounterid": counter,
                "sadaycloseid": "",
                "sacardtype": "",
                "sadeliverycharge": "",
                "sarefcode": happyhr? 'H' : null,
                "tableid": "",
                "roomid": "",
                "billnonum": "",
                "billno": "",
                "waiterid": waitercode,
                "details": tablerow,
                "billDate": "",
                "user": 1,
                "time": "",
                "sacash": "",
                "balance": "",
                "inWords": "",
                "billedstatus": 1,
                "tableid": tableid,
                "roomid": roomid,
                "billedstatus": true,
                "roomvid": 1,
                "creditsale": true,
              }
            console.log("save table data", data)
    
            fetch(global.url + "api/Sales/SaveSales", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then(async(responseJson)=>{
                console.log("makebill response", responseJson)
                if(responseJson) {
                    // alert("success")
                    setMakebill(responseJson)
                    setPrintmodal(true)
                    setRefresh(false)
                    document.getElementById('print-button1').click();
                    await delay(2000);
                    setRefresh(true)
                }
            })
            .catch((error)=>{
                alert("error occured")
                console.log(error)
            })
        }
        else{
            alert("Invalid Waitercode")
        }
        
    }

    const tableRowclick = (index, dolr) => {
        setSelectedindex(index)
        if(dolr) {
            setEditmode(true) 
        }
        else{
            setEditmode(false)
        }
        
    }

    const onRowdelete = () => {
        var input = [...tablerow]
        input.splice(selectedindex, 1)
        setTablerow(input)
        setSelectedindex()
        setEditmode(true) 
    }

    const plusQty = () => {
        var input = [...tablerow]
        input[selectedindex].dtqty = input[selectedindex].dtqty + 1
        input[selectedindex].dtamount = (input[selectedindex].dtqty) * input[selectedindex].dtsarate
        setTablerow(input)
    }

    const minusQty = () => {
        var input = [...tablerow]
        if(input[selectedindex].dtqty > 1) {
            input[selectedindex].dtqty = input[selectedindex].dtqty - 1
            input[selectedindex].dtamount = (input[selectedindex].dtqty) * input[selectedindex].dtsarate
            setTablerow(input)
        }
        
    }

    const qtyChange = (event, index) => {
        var textArray1 = [...tablerow]
        if(textArray1[index].dtunitid == 2) { 
            const rx_live = /^[+-]?\d*(?:[,]\d*)?$/;
            if (rx_live.test(event.target.value)) {
                var totalprice = textArray1[index].dtsarate * event.target.value
                textArray1[index].dtqty = event.target.value
                // textArray1[index].dtsarate = totalprice.toFixed(2)
                textArray1[index].dtamount = totalprice.toFixed(2)
                setTablerow(textArray1)
            }
            
        }
        else{
            const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
            if (rx_live.test(event.target.value)) {
                var totalprice = textArray1[index].dtsarate * event.target.value
                textArray1[index].dtqty = event.target.value
                // textArray1[index].dtsarate = totalprice.toFixed(2)
                 textArray1[index].dtamount = totalprice.toFixed(2)
                setTablerow(textArray1)
            }
        }
        
    }

    const splitClick = () => {
        if(tablerow.length == 0){
            alert("No items in Table")
            return
        }
        
        setLoader(true)
        var token = localStorage.getItem("token")
        console.log("token", token)
        if(tableid == "") {
            if(roomid!= ""){

            }
            else{
                if(roomid == "") {
                    alert("Please Select Room")
                    return
                }
                else{
                    alert("Please Select Table")
                    return
                }
            } 
        }

        tablerow.forEach(function(v){ delete v.$id });


        const data = {
            "said": said,
            "vid": vid,
            "saformid": formid,
            "sadocdate": docdate,
            "sadocno": docno,
            "sadocnum": docnum,
            "sacaccid": accid,
            "saaccname": accname,
            "saaccadD1": "",
            "saaccadD2": "",
            "saaccadD3": "",
            "sastateid": "",
            "sadistid": "",
            "sacomid": 1,
            "sabranchid": branchid,
            "samob": "",
            "saamount":  subtotal,
            "saitemdisc": "",
            "sagross":"",
            "sasgst": "",
            "sacgst": "",
            "saigst": "",
            "satax": "",
            "sadiscper": "",
            "sadiscount": "",
            "sasubtotal": subtotal,
            "sanettotal": subtotal,
            "sacreatedon": "",
            "sacreatedby": global.userid,
            "saupdatedby": global.userid,
            "printFile": "",
            "sacashpaid": "",
            "sabankpaid": "",
            "satotalpaid": "",
            "saupdatedon": "",
            "saduedate": "",
            "saremarks": "",
            "sacessper": "",
            "sacessamount": "",
            "satotal": subtotal,
            "sagodownid": 1,
            "sacode": "",
            "sagstin": "",
            "sarepid": "",
            "saispos": "",
            "sacardrefno": "",
            "sadayclose": "",
            "sacounterid": counter,
            "sadaycloseid": "",
            "sacardtype": "",
            "sadeliverycharge": "",
            "sarefcode": happyhr? 'H' : null,
            "tableid": "",
            "roomid": "",
            "billnonum": "",
            "billno": "",
            "waiterid": "",
            "details": tablerow,
            "billDate": "",
            "user": 1,
            "time": "",
            "sacash": "",
            "balance": "",
            "inWords": "",
            "billedstatus": 0,
            "tableid": tableid,
            "roomid": roomid
          }
        console.log("save data", data)

        fetch(global.url + "api/Sales/SaveSales", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            if(responseJson) {
                // alert("success")
                afterSplitclick(responseJson.vid)
            }
        })
        .catch((error)=>{
            alert("error occured")
            console.log(error)
        })
    }

    const afterSplitclick = (id) => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Sales/GetSplitSalesById?id=" + id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
        .then((response)=> response.json())
        .then(async(responseJson)=>{
            console.log('GetSplitSalesById', responseJson)
            setSplitdetails(responseJson)
            setSplitpopup(true)
            setRefresh(false)
            document.getElementById('print-button2').click();
            await delay(2000);
            setRefresh(true)
        })
        .catch((error)=>{
            alert("error occured")
            console.log(error)
        })
    }



    return(
        <div>
           
        {
          access ?
          <>
            <Container style={{maxWidth: '98%'}}>
                <Row>
                    <Col xs={12} md={6} lg={4}>
                        {/* <div style={{width: '100%'}}>
						    <FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
                                <InputLabel id="demo-simple-select-label">Counter</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={counter}
                                label="Age"
                                onChange={handleChange}
                                size="small"
                                >
                                {counterlist.map((i) => {
                                        return(
                                            <MenuItem value={i.ctrid}>{i.ctrname}</MenuItem>
                                        )
                                    })
                                }
                                </Select>
                            </FormControl>
                        </div> */}
                        <div>
                            {
                                counterlist.map((i, k) => {
                                    return(
                                        <Button onClick={() => counterClick(i.ctrid)} style={{marginRight: 10, marginTop: 10}} variant="primary" >{i.ctrname}</Button>
                                    )
                                })
                            }
                        
                        </div>
                    <Row >
                        <Col xs={6}>
                        {
                            tableview ? 
                            <Button style={{width: "100%"}}  onClick={() => setTableview(false)} variant="primary" className='btn2'>Room</Button>: 
                            <Button style={{width: "100%"}}  onClick={() => setTableview(true)} variant="primary" className='btn2'>Table</Button>
                        }
                        </Col>
                        <Col xs= {6}>
                        {
                            happyhr ? 
                            <div className="blink_me" style={{fontSize: 20, color: 'green', fontWeight: 'bold', marginTop: 15}}>Happy Hour</div> :
                            null
                        }
                        </Col>
                    </Row>
                        <Row>
                            <Col xs={4} md={3} lg={2}>
                                
                                {
                                    tableview ? 
                                    <Row>
                                        {
                                            tablelist.map((i) => {
                                                return(
                                                    i.counterid == counter ?
                                                    i.vid == 0 ? 
                                                    <Button onClick={() => onTableclick(i.tableid, i.tablename)} variant="primary" className='btn2'>{i.tablename}</Button>:
                                                    <Button onClick={() => onRetrievet(i.vid,i.tableid,"", i.tablename)} variant="primary" className='btn2' style={{background: 'red'}}>{i.tablename}</Button>:
                                                    null
                                                
                                                )
                                            })
                                        }
                                    </Row> :
                                    <Row>
                                        {
                                            roomlist.map((i) => {
                                                return(
                                                    
                                                    i.salesvid == 0 ? 
                                                    <Button onClick={() => onRoomclick(i.roomid, i.roomname)} variant="primary" className='btn2'>{i.roomname}</Button>:
                                                    <Button onClick={() => onRetriever(i.salesvid,"", i.roomid,i.roomname)} variant="primary" className='btn2' style={{background: 'red'}}>{i.roomname}</Button>
                                                
                                                )
                                            })
                                        }
                                    </Row>
                                }
                                
                            </Col>
                            <Col xs={8} md={9} lg={10}>
                                <div className='sidemenu-div'>
                                    <div style={{display: 'none'}}>
                                            <ReactToPrint
                                                    trigger={() => <Button id="print-button" variant="primary" className="side-topbtn">OT Print</Button>}
                                                    content={() => componentRef.current}
                                                />
                                            <ReactToPrint
                                                trigger={() => <Button id="print-button1" variant="primary" className="side-topbtn">OT Print</Button>}
                                                content={() => componentRef1.current}
                                            />
                                            <ReactToPrint
                                                trigger={() => <Button id="print-button2" variant="primary" className="side-topbtn">OT Print</Button>}
                                                content={() => componentRef2.current}
                                            />
                                        </div>
                                    {/* <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Button onClick={() => plusQty()} variant="primary" className="side-topbtn">Add Qty</Button>
                                       
                                        <Button onClick={() => minusQty()} variant="primary" className="side-topbtn">Reduce Qty</Button>
                                    </div> */}
                                    <p style={{fontWeight: 'bold', color: 'gray', marginBottom: 'unset'}}>Date - {docdate}</p>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Button onClick={()=> splitClick()} variant="primary" className="side-topbtn">Split</Button>
                                        {loader ? 
                                        <Skeleton variant="text" sx={{width: '35%'}}/> :

                                        <Button onClick={() => onSave()} variant="primary" className="side-topbtn">OT Print</Button>
                                        
                                        }
                                       {
                                           editmode ? null : 
                                           <Button onClick={() => onRowdelete()} variant="primary" className="side-topbtn">Delete</Button>
                                       }
                                        
                                    </div>
                                    <div style={{marginTop: 15}}>
                                        <p style={{fontSize: 15, fontWeight: 'bold', marginBottom: 'unset'}}>Dine In #{docno}</p>
                                        <p style={{fontSize: 13, marginBottom: 'unset', color:'#bdbdbd'}}>{roomnumber == "" ? "Table" : "Room"} #: {roomnumber == "" ? tablenumber: roomnumber }</p>
                                    </div>
                                    <div style={{marginTop: 15}}>
                                            <Row >
                                                
                                                <Col xs={3}>QTY</Col>
                                                <Col xs={6}>Name</Col>
                                                <Col xs={3}>Amount</Col>
                                            </Row>
                                        {  
                                            tablerow.map((i, k) => {
                                                return(
                                                    <Row onClick={() => tableRowclick(k, i.$id)} style={selectedindex == k ? {background: 'red', cursor: 'pointer'} : {cursor: 'pointer'}}>
                                                        <Col xs={3}>
                                                            
                                                            <input  type="text"
                                                            id="depositedAmount"
                                                            disabled = {editmode && i.$id ? true: false}
                                                            maxLength={9}
                                                            pattern="[+-]?\d+(?:[,]\d+)?" 
                                                            style={{width: "100%"}} 
                                                            onChange={(event) => qtyChange(event, k)} 
                                                            value={i.dtqty} autoComplete="off"  />
                                                        </Col>
                                                        <Col xs={6}>{i.proname}</Col>
                                                        <Col xs={3}>Rs {i.dtamount}</Col>
                                                    </Row>
                                                )
                                            }) 
                                        }
                                    </div>
                                    <div style={{width: '100%', height: 1, marginTop: 30, backgroundColor: '#d2d2d2'}}></div>
                                    <div style={{marginTop: 10, display: 'flex', justifyContent: 'space-between'}}>
                                        <p style={{marginBottom: 'unset', fontSize: 15}}>Subtotal</p>
                                        <p style={{marginBottom: 'unset', fontSize: 15}}>Rs {subtotal}</p>
                                    </div>
                                    <div style={{marginTop: 5, display: 'flex', justifyContent: 'space-between'}}>
                                        <p style={{marginBottom: 'unset', fontSize: 15}}>Tax</p>
                                        <p style={{marginBottom: 'unset', fontSize: 15}}>Rs 0</p>
                                    </div>
                                    <div style={{marginTop: 5, display: 'flex', justifyContent: 'space-between'}}>
                                        <p style={{marginBottom: 'unset', fontSize: 15, fontWeight: 'bold'}}>Total</p>
                                        <p style={{marginBottom: 'unset', fontSize: 15,  fontWeight: 'bold'}}>Rs {subtotal}</p>
                                    </div>
                                </div>
                                <Button style={{width: '100%'}} onClick={()=> setWaitermodal(true)} variant="primary" className="posdown-btn1">Make Bill</Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={6} lg={8}>
                        {/* <div>
                            <Button variant="primary" className="poscat-btn">Bar</Button>
                        </div> */}
                        <div>
                            {
                                source.map((i, k) => {
                                    return(
                                        <Button onClick={() => categoryClick(i.products, i.catid)} variant="primary" className="side-rightbtn">{i.catname}</Button>
                                    )
                                })
                            }
                        
                        </div>

                        <div style={{width: '100%', height: 1, marginTop: 15, backgroundColor: '#d2d2d2'}}></div>
                       
                        <input
                            type="search"
                            value={name}
                            onChange={filter}
                            className="input"
                            placeholder="Search"
                        />
                       
                        <div>
                            {
                                products.map((i, k) => {
                                    return(
                                        i.bottleSaleOnly ? 
                                        <Button onClick={() => outofStockcheck(
                                            i.priceid,
                                            i.productid,
                                            i.updatedby,
                                            i.branchid,
                                            i.barpeg,
                                            i.barbottle,
                                            i.acpeg,
                                            i.acbottle,
                                            i.expeg,
                                            i.exbottle,
                                            i.localpeg,
                                            i.localbottle,
                                            i.wsbottle,
                                            i.updatedon,
                                            i.roompeg,
                                            i.roombottle,
                                            i.code,
                                            i.productname,
                                            i.bottleSaleOnly,
                                            "",
                                            1,
                                            0
                                            )} variant="primary" className="side-rightdownbtn">{i.productname}</Button>:
                                            <Button  style={{position: 'relative', fontSize: 12, paddingTop: 20}} variant="" className="side-rightdownbtn">
                                                <Button onClick={() => outofStockcheck(
                                            i.priceid,
                                            i.productid,
                                            i.updatedby,
                                            i.branchid,
                                            i.barpeg,
                                            i.barbottle,
                                            i.acpeg,
                                            i.acbottle,
                                            i.expeg,
                                            i.exbottle,
                                            i.localpeg,
                                            i.localbottle,
                                            i.wsbottle,
                                            i.updatedon,
                                            i.roompeg,
                                            i.roombottle,
                                            i.code,
                                            i.productname,
                                            i.bottleSaleOnly,
                                            "F",
                                            1,
                                            0
                                            )} style={{position: 'absolute', right: 0, top: 0, marginRight: 10, padding: 6}}>
                                                    F
                                                </Button>
                                                <Button onClick={() => outofStockcheck(
                                            i.priceid,
                                            i.productid,
                                            i.updatedby,
                                            i.branchid,
                                            i.barpeg,
                                            i.barbottle,
                                            i.acpeg,
                                            i.acbottle,
                                            i.expeg,
                                            i.exbottle,
                                            i.localpeg,
                                            i.localbottle,
                                            i.wsbottle,
                                            i.updatedon,
                                            i.roompeg,
                                            i.roombottle,
                                            i.code,
                                            i.productname,
                                            i.bottleSaleOnly,
                                            "L",
                                            0,
                                            1
                                            )} style={{position: 'absolute', Left: 0,  top: 0, marginLeft: 0, padding: 6}}>
                                                    L
                                                </Button>
                                                {i.productname} 
                                            </Button>
                                    )
                                })
                            }
                        </div>
                    </Col>
                </Row>
                <div style={{display: 'flex', justifyContent: 'center', marginTop: 30}}>
                    {/* <div>
                        
                        <Button variant="primary" className="posdown-btn">Pay</Button>
                        <Button variant="primary" className="posdown-btn">Exact</Button>
                    </div> */}
                </div>
                <Modal
                    size="sm"
                    show={modal}
                    onHide={() => setModal(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                    >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                        Select
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12} >
                                <FormControl sx={{ mt: 4}} component="fieldset">
                                    <RadioGroup
                                        defaultValue="credit"
                                        name="radio-buttons-group"
                                        onClick={radioChange}
                                    >
                                        <FormControlLabel value="peg"  control={<Radio checked={pricefix == "peg"?  true: false}/>} label="peg" color = '#999'  />
                                        <FormControlLabel value="bottle" color= '#999' control={<Radio checked={pricefix == "bottle"?  true: false}/>} label="bottle" />
                                    </RadioGroup>
                                </FormControl>
                            </Col>
                            <Col xs ={12} style={{marginTop:30}}>
                                <Button onClick={() => onAddsubmit()} style={{float: 'right'}} variant="primary">Submit</Button>
                            </Col>
                            
                        </Row>
                    </Modal.Body>
           </Modal>

           <Modal
                    size="sm"
                    show={waitermodal}
                    onHide={() => setWaitermodal(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                    >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                        Waiter Code
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12} >
                                <FormControl sx={{ mt: 4, width: '100%' }}>
                                    <TextField type="number" value={waitercode} onChange={(e) => setWaitercode(e.target.value)} id="outlined-basic" label="Waiter Code" variant="outlined"  size="small" />
                                </FormControl>
                            </Col>
                            <Col xs ={12} style={{marginTop:30}}>
                                <Button onClick={() => onMakebillclick()} style={{float: 'right'}} variant="primary">Submit</Button>
                            </Col>
                            
                        </Row>
                    </Modal.Body>
           </Modal>
           <div className='posprint testprint' ref={componentRef}>
                <p style={{fontSize: 13, fontWeight: 'bold', textAlign: "center", marginBottom: 'unset'}}>{branchdetails.brname}</p>
                <p style={{fontSize: 11, fontWeight: 500, textAlign: "center", marginBottom: 'unset'}}>{branchdetails.braddresS1}, {branchdetails.braddresS2}, {branchdetails.braddresS3}</p>
                <p style={{fontSize: 11, fontWeight: 500, textAlign: "center", marginBottom: 'unset'}}>Date&Time {makebill.billDate}</p>
                <div style={{width: '100%', height: 1, backgroundColor: "#d3d3d3", marginTop: 5, marginBottom: 5}}></div>
                <Table responsive className='posprinttable'>
                    <tbody>
                    <tr>
                            <th>Sl</th>
                            <th>Description</th>
                            <th>Qty</th>
                            
                        </tr>
                        
                        {  
                            makebill?.details?.map((i, k) => {
                                return(
                                    i.dtgdnid == null ?
                                    <tr>
                                        <td>{k+1}</td>
                                        <td>{i.proname}</td>
                                        <td>{i.dtqty}</td>
                                    </tr>:
                                    null
                                )
                            }) 
                        }
                        
                    </tbody>
                </Table>
                <div>
                    <p style={{fontSize: 11, marginBottom: 'unset'}}>OT No : {makebill.sadocno}</p>
                    <p style={{fontSize: 11, marginBottom: 'unset'}}>Table No. {makebill.tableNumber}</p>
                </div>
            </div>
           
            <div className='posprint testprint' ref={componentRef1}>
                <p style={{fontSize: 13, fontWeight: 'bold', textAlign: "center", marginBottom: 'unset'}}>{branchdetails.brname}</p>
                <p style={{fontSize: 11, fontWeight: 500, textAlign: "center", marginBottom: 'unset'}}>{branchdetails.braddresS1}, {branchdetails.braddresS2}, {branchdetails.braddresS3}</p>
                <p style={{fontSize: 11, fontWeight: 500, textAlign: "center", marginBottom: 'unset'}}>Bill No. {makebill.billno}  Date&Time {makebill.billDate}</p>
                <div style={{width: '100%', height: 1, backgroundColor: "#d3d3d3", marginTop: 5, marginBottom: 5}}></div>
                <Table responsive className='posprinttable'>
                    <tbody>
                    <tr>
                            <th>Sl</th>
                            <th>Description</th>
                            <th>Qty</th>
                            <th>Rate</th>
                            <th>Amt</th>
                        </tr>
                        
                        {  
                            makebill?.details?.map((i, k) => {
                                return(
                                    <tr>
                                        <td>{k+1}</td>
                                        <td>{i.proname}</td>
                                        <td>{i.dtqty}</td>
                                        <td style={{textAlign: 'right'}}>{i.dtprice}</td>
                                        <td style={{textAlign: 'right'}}>{i.dtamount}</td>
                                    </tr>
                                )
                            }) 
                        }
                        
                        <tr>
                            <td colSpan={4} style={{textAlign: 'right'}}><span >Total Amount</span></td>
                            <td style={{textAlign: 'right'}}>{makebill.saamount}</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: 'right'}} colSpan={4}><span >Round Off</span></td>
                            <td style={{textAlign: 'right'}}>00.00</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: 'right'}} colSpan={4}><span >Net Amount</span></td>
                            <td style={{textAlign: 'right'}}>{makebill.saamount}</td>
                        </tr>
                    </tbody>
                </Table>
                <div>
                    <p style={{fontSize: 11, marginBottom: 'unset'}}>OT No : {makebill.sadocno}</p>
                    <p style={{fontSize: 11, marginBottom: 'unset'}}>Waiter : {makebill.waiterid}</p>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <p style={{fontSize: 11, marginBottom: 'unset'}}>Total Items : {tablerow.length}</p>
                        <p style={{fontSize: 11, marginBottom: 'unset'}}>Table No. {makebill.tableNumber}</p>
                    </div>
                    <p style={{fontSize: 11, marginBottom: 'unset', fontWeight: 600, marginTop: 10, textAlign: 'center'}}>Thank You Visit Again.</p>
                </div>
            </div>

            <div  className='posprint testprint' ref={componentRef2}>
                    <div>
                        <p style={{fontSize: 13, fontWeight: 'bold', textAlign: "center", marginBottom: 'unset'}}>{branchdetails.brname}</p>
                        <p style={{fontSize: 11, fontWeight: 500, textAlign: "center", marginBottom: 'unset'}}>{branchdetails.braddresS1}, {branchdetails.braddresS2}, {branchdetails.braddresS3}</p>
                        <p style={{fontSize: 11, fontWeight: 500, textAlign: "center", marginBottom: 'unset'}}>Date&Time {splitdetails?.billDate}</p>
                        <div style={{width: '100%', height: 1, backgroundColor: "#d3d3d3", marginTop: 5, marginBottom: 5}}></div>
                        <Table responsive className='posprinttable'>
                            <tbody>
                            <tr>
                                    <th>Sl</th>
                                    <th>Description</th>
                                    <th>Qty</th>
                                   
                                </tr>
                                
                                {  
                                    splitdetails?.kotItems.map((i, k) => {
                                        return(
                                            i.dtgdnid == null ?
                                            <tr>
                                                <td>{k+1}</td>
                                                <td>{i.proname}</td>
                                                <td>{i.dtqty}</td>
                                            </tr> :
                                            null
                                        )
                                    }) 
                                }
                                
                            </tbody>
                        </Table>
                        <div>
                            <p style={{fontSize: 11, marginBottom: 'unset'}}>OT No : {splitdetails?.sadocno}</p>
                        </div>
                    </div>
                    <div style={{marginTop: 80}}>
                        <p style={{fontSize: 13, fontWeight: 'bold', textAlign: "center", marginBottom: 'unset'}}>{branchdetails.brname}</p>
                        <p style={{fontSize: 11, fontWeight: 500, textAlign: "center", marginBottom: 'unset'}}>{branchdetails.braddresS1}, {branchdetails.braddresS2}, {branchdetails.braddresS3}</p>
                        <p style={{fontSize: 11, fontWeight: 500, textAlign: "center", marginBottom: 'unset'}}>Date&Time {splitdetails?.billDate}</p>
                        <div style={{width: '100%', height: 1, backgroundColor: "#d3d3d3", marginTop: 5, marginBottom: 5}}></div>
                        <Table responsive className='posprinttable'>
                            <tbody>
                            <tr>
                                    <th>Sl</th>
                                    <th>Description</th>
                                    <th>Qty</th>
                                   
                                </tr>
                                
                                {  
                                    splitdetails?.botItems.map((i, k) => {
                                        return(
                                            <tr>
                                                <td>{k+1}</td>
                                                <td>{i.proname}</td>
                                                <td>{i.dtqty}</td>
                                            </tr>
                                        )
                                    }) 
                                }
                                
                            </tbody>
                        </Table>
                        <div>
                            <p style={{fontSize: 11, marginBottom: 'unset'}}>OT No : {splitdetails?.sadocno}</p>
                        </div>
                    </div>
            </div>
            </Container>
            </>: 
            <div style={{marginTop: '90px', marginLeft: '500px'}}>
            <img src={accessimgae} alt='' />
          </div>
}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(Pos);