import React, { useState, useEffect } from "react";
import {Container, Row, Col, Form, Button} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import image from '../../assets/images/login.jpeg';
import avatar from '../../assets/images/user.png';
import './Login.css';
import {useHistory} from 'react-router-dom';
import { Rings  } from  'react-loader-spinner'
import {
    adminCheck,
    setPos,
  } from "../../redux/Shopping/shopping-actions";
import { connect } from "react-redux";



function Login({adminCheck, setPos}) {
    let history = useHistory();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [loader, setLoader] = useState(false);


    const onSubmit = () => {
        // console.log("username", username)
        // console.log("password", password)
        setLoader(true)
        fetch(global.url + "api/Account/Login?username=" + username + "&" + "password=" + password, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("login Response", responseJson)
            if(responseJson == 1){
                localStorage.setItem("userid", responseJson);
                adminCheck(true)
                generateToken()
            }
            else{
                
                var userid = responseJson.userMenu[0].userid
                console.log("userid", userid)
                localStorage.setItem("formname", JSON.stringify(responseJson.userMenu));
                localStorage.setItem("allowedIcons", JSON.stringify(responseJson.allowedIcons));
                localStorage.setItem("userid", userid);
                localStorage.setItem("username", responseJson.userName);
                adminCheck(false)
                setPos(responseJson.posUser)
                localStorage.setItem("allowedBranches", JSON.stringify(responseJson.allowedBranches));
                generateToken()
            }
             
        })
        .catch((error)=>{
            setLoader(false)
            alert("username or password error")
            console.log(error)
        })

    }

    const generateToken = () => {
        fetch(global.url + "api/Account/GenerateToken?username=" + username + "&" + "password=" + password, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setLoader(false)
            if(responseJson){
                localStorage.setItem("token", responseJson.access_token);
                localStorage.setItem("isAuthenticatedhm", true);
                history.push('/menu')
            }
             console.log("token Response", responseJson)
        })
        .catch((error)=>{
            setLoader(false)
            console.log(error)
        })

        
    }

    return(
        <div className='login'>
            <Container>
                <Row>
                    <Col xs={12} md={7}>
                        <img src={image} alt='' style={{marginTop: 100}} className="login-img"/>
                    </Col>
                    <Col xs={12} md={5}>
                        <div className='login-name'>
                            <p className='log-headp'>Login</p>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <img src={avatar} alt='' className="login-img1"/>
                            </div>
                            <div className="inputtext">
                                <Form>
                                    <Form.Control onChange={(e) => setUsername(e.target.value)} type="email" placeholder="Username" className='textbox1' />
                                </Form>
                                <Form>
                                    <Form.Control onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" className='textbox2' />
                                </Form>
                                <div style={{marginTop: 10}}>
                                    <Row>
                                        <Col xs={12} sm={6}>
                                            <FormControl sx={{ width: '100%' }}>
                                                <FormControlLabel  sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} control={<Checkbox defaultChecked />} label="Keep me signed in" />
                                            </FormControl>
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <div className="forgotpassword">
                                                <p className="forgot-text">Forgot password?</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div  style={{display: 'flex', justifyContent: 'center'}}>
                                    {
                                    loader ? 
                                    <Rings
                                        height="50"
                                        width="50"
                                        color='#0d6efd'
                                        ariaLabel='loading'
                                    /> : 
                                    <Button onClick={() => onSubmit()} variant="primary" className='loginbtn'>Login</Button>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
      adminCheck: (item) => {
          dispatch(adminCheck(item))
    },
    setPos: (item) => {
        dispatch(setPos(item))
        }
    };
  };

  export default connect(null, mapDispatchToProps)(Login);