import React, {useEffect, useState, useRef} from 'react'
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button, Modal} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { connect } from "react-redux";
import ReactToPrint from 'react-to-print';
import logo from '../../assets/images/logo.jpeg';

const columns = [
    { id: '1', label: 'Sl No.', minWidth: 50,span: false },
    { id: '2', label: 'Voucher Date', minWidth: 50,span: false },
    { id: '3', label: 'Voucher No.', minWidth: 50,span: false },
    { id: '1', label: 'Voucher Type', minWidth: 50,span: false },
    { id: '3', label: 'Accounts', minWidth: 50,span: false },
    { id: '3', label: 'Remarks', minWidth: 50,span: false },
    { id: '1', label: 'Debit', minWidth: 50,span: false },
    { id: '2', label: 'Credit', minWidth: 50,span: false },
  ];

  const source = [{}, {}]

function LedgerReport({branchid, branchdetails}){
    const componentRef = useRef();
    const [source, setSource] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [modal, setModal] = React.useState(false);
    const [supplierlist, setSupplierlist] = React.useState([]);
    const [mode, setMode] = React.useState('');
    const [ledger, setLedger] = React.useState('');
    const [ledgername, setLedgername] = React.useState('');

    const [billType, setBilltype] = React.useState('');
    const [billtypelist, setBilltypelist] = React.useState([]);
    const [counter, setCounter] = React.useState('');
    const [countername, setCountername] = useState("");

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();

    const [date1, setDate1] = React.useState(year + '-' + month + '-' + date);
    const [date2, setDate2] = React.useState(year + '-' + month + '-' + date);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };

    const modeChange = (event) => {
    setMode(event.target.value);
    };

    const formatDate = (datestring, type) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
          

          if(type == "time") {
            var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          }
          else{
            var formattedDate =  day+"/"+month+"/"+year
          }

          
          return formattedDate; //03/02/2021
    }

    const legerChange = (event, index) => {
        console.log("index.props.children", index.props.children)
        setLedger(event.target.value)
        setLedgername(index.props.children)
      }

      const getBilltype = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/BillSettings/GetBillTypeByForm?companyId=1&branchId=" + branchid + "&formType=PAY", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("billSettigsList response", responseJson)
              setBilltypelist(responseJson.billSettigsList)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      useEffect(() => {
        getBilltype()
        getLedger()
      },[branchid])

    const getData = () => {
        var token = localStorage.getItem("token")
        var data = {
            // "counterId": "",
            // "companyId": 1,
            "branchId": branchid,
            "fromDate": date1,
            "toDate": date2,
            // "createdBy": "",
            "accId": ledger,
            // "godownId": "",
            // "productId": "",
            // "unitId": "",
            // "voucherType": mode,
            // "bankGroupId": "",
            // "sectionId": "",
            // "deparmentId": "",
            // "categoryId": "",
            // "subCategoryId": "",
            // "color": "",
            // "sizeId": "",
            // "userId": "",
            // "cashGroupId": "",
            // "posOnly": "",
            // "groupId": "",
            // "supplierGroupId": "",
            // "deliveryAccId": "",
            // "discountAccId": "",
            // "directSaleOnly": "",
            // "salesAccountAccId": "",
            // "contryId": "",
            // "waiterId": ""
          }
        fetch(global.url + "api/Ledger/LedgerDetail", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data), 
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("ledger details Response", responseJson)
             setSource(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    // const getLedger = () => {
    //     var token = localStorage.getItem("token")
    //     fetch(global.url + "api/Settings/GetMainSettings?companyId=1&branchId=" + branchid , {
    //       method: "GET",
    //       headers: {
    //           'Content-Type': 'application/json',
    //           'Authorization': 'Bearer ' + token
    //       },
    //     })
    //     .then((response)=> response.json())
    //     .then((responseJson)=>{
    //         getSupplierlist(responseJson.grpsuppid)
    //     })
    //     .catch((error)=>{
    //         console.log(error)
    //     })
    // }

    // const getSupplierlist = (id) => {
    //     var token = localStorage.getItem("token")
    //   fetch(global.url + "api/Accounts/GetLedgerByGroupId?companyId=1&branchId=" + branchid + "&id=" + id + "&limit=0&searchBy=Name&searchKey=" + "" , {
    //       method: "GET",
    //       headers: {
    //           'Content-Type': 'application/json',
    //           'Authorization': 'Bearer ' + token
    //       },
    //     })
    //     .then((response)=> response.json())
    //     .then((responseJson)=>{
    //         console.log('ledger', responseJson)
    //         setSupplierlist(responseJson)
    //     })
    //     .catch((error)=>{
    //         console.log(error)
    //     })
    // }

    const getLedger = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Accounts/GetLedgerList?orderBy=Name&companyId=1&branchId=" + branchid + "&includeBlocked=False", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("ledger Response", responseJson)
             setSupplierlist(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const billtypeChange = (event, index) => {
        console.log("index", index)
        console.log("event", event)
        setBilltype(event.target.value);
        // getCounterbyid(event.target.value)
        if(index.props.children == "BAR" ){
            getAllaccountlist(1)
            setCountername("BAR")
        }else if(index.props.children == "LODGE"){
            getAllaccountlist(2)
            setCountername("LODGE")
        }
        else if(index.props.children == "RESTAURANT"){
            getAllaccountlist(3)
            setCountername("RESTAURANT")
        }

      };

      const getCounterbyid = (id) => {
        console.log("ById", id)
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Counter/GetCounterById?id=" + id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("GetCounterById", responseJson)
                setCountername(responseJson.ctrname)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const getAllaccountlist = (id) => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Accounts/GetAllLedgerListNew?branchId=" + branchid + "&counterId=" + id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("GetAllLedgerList response", responseJson)
            setSupplierlist(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
      }
      const formatDate2 = (datestring) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
        //   console.log("timeString12hr", timeString12hr)

          var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          return formattedDate; //03/02/2021
    }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
                <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Ledger Detailed Report</p>
                </div>
                <Row style={{marginTop: 10}}>
                    <Col xs={12}  className='columnborder'>
                        <div>
                            <Row>
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="From Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setDate1(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="To Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setDate2(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col sm={3} style={{marginTop:20}}>
                                    <FormControl sx={{  width: '100%' }}>
                                            <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Counter</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                               
                                                value={billType}
                                                label="Age"
                                                onChange={(e, index)=>billtypeChange(e, index)}
                                                size="small"
                                                
                                            >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {billtypelist.map((row, index) => {
                                        return (
                                            <MenuItem value={row.btypeid}>{row.formname}</MenuItem>
                                        )})}
                                            </Select>
                                    </FormControl>
                                </Col> 
                                <Col xs={12} md={3}>
                                    <FormControl fullWidth sx={{width: '100%', mt: 3 }}>
                                            <InputLabel id="demo-simple-select-label">Ledger</InputLabel>
                                            <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={ledger}
                                            label="Age"
                                            onChange={(e, index) => legerChange(e, index)}
                                            size="small"
                                            >
                                                {
                                                    supplierlist.map((i, k) => {
                                                        return(
                                                            <MenuItem value={i.accid}>{i.accname}</MenuItem>
                                                        )
                                                    
                                                    })
                                                }
                                
                                            </Select>
                                    </FormControl>
                                </Col>                               
                                <Col xs={12} md={2}>
                                    <div style={{justifyContent: 'center', display: 'flex'}}>
                                        <Button onClick={getData}  style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">Search</Button>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} >
                                    <ReactToPrint
                                        trigger={() => <Button style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 17, width: '50%'}} variant="contained">Print this out!</Button>}
                                        content={() => componentRef.current}
                                    />
                                </Col>
                            </Row>
                           
                        </div>
                    </Col> 
                </Row>
                <div className='bulk-table'>
                    <TableContainer sx={{ maxHeight: 440 }}>
                    <div  className='section-to-print' ref={componentRef} style={{width: '100%'}}>
                            {/* <Row> 
                                
                                <img src={require("../../assets/images/logo.jpeg").default} style={{width: 100, position: 'absolute'}}/>
                                
                                <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                                <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                                </Col>
                            </Row> */}
                        <Table  id="table-to-xls"  stickyHeader aria-label="sticky table" style={{width: '95%'}}>
                            <TableHead className="tableheaderexe">
                            <TableRow style={{height: 80}} >
                                    <TableCell colSpan={8}  style={{padding: 0}}>
                                        <Row>
                                            <img src={logo} style={{width: 100, position: 'absolute', top: 0}}/>
                                            
                                            <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                                            <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                                            </Col>
                                            <p className='printdate' style={{ position: 'absolute', left: '85%'}}>{formatDate2(new Date)}</p>  
                                        </Row>
                                    </TableCell>
                                 </TableRow>
                                 <TableRow>
                                    
                                    <TableCell colSpan={8} style={{padding: 0}}>
                                        <div style={{display: 'flex', justifyContent: 'center', fontWeight: 600}} className='captiontable'>
                                            LEDGER REPORT FOR {countername} - {ledgername} FROM {date1.split("-").reverse().join("-")} TO  {date2.split("-").reverse().join("-")}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            <TableRow className='tablerowPrint'>
                                {columns.map((column) => (
                                <TableCell
                                className='tablecellPrint' style={{fontWeight: 'bold'}}
                                    key={column.id}
                                    align={column.align}
                                    colSpan={column.span == true ? 2: 1}
                                > 
                                    {column.label}
                                    
                                </TableCell>
                                ))}
                            </TableRow>
                            </TableHead>
                            <TableBody  className="bodytable">
                            {source
                                .map((row, index) => {
                                return (
                                    <TableRow className='tablerowPrint'>
                                        <TableCell className='tablecellPrint'>
                                           {index+1}
                                        </TableCell>   
                                        <TableCell className='tablecellPrint'>
                                        {formatDate(row.vchdate, "date")}
                                        </TableCell>  
                                        <TableCell className='tablecellPrint'>
                                           {row.vchdocno}
                                        </TableCell> 
                                        <TableCell className='tablecellPrint'>
                                        {row.trnname}
                                        </TableCell>   
                                        
                                        <TableCell className='tablecellPrint'>
                                        {row.accname}
                                        </TableCell>  
                                        <TableCell className='tablecellPrint'>
                                        {row.vchremarks}
                                        </TableCell >  
                                        <TableCell className='tablecellPrint' style={{textAlign: 'right'}}>
                                        {row.dtdebit == 0 ? null : row.dtdebit.toFixed(2)}
                                        </TableCell>   
                                        <TableCell className='tablecellPrint' style={{ textAlign: 'right'}}>
                                        {row.dtcredit == 0 ? null : row.dtcredit.toFixed(2)}
                                        </TableCell>                                       
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
                        </div>
                        </TableContainer>
                    </div>
            </Paper>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(LedgerReport);