import React, {useState, useEffect} from 'react'
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { connect } from "react-redux";

const columns = [
    { id: '2', label: 'Item Name', minWidth: 50,span: false },
    { id: '3', label: 'Description', minWidth: 50,span: false },
    { id: '1', label: 'Code', minWidth: 50,span: false },
    { id: '2', label: 'Serial No.', minWidth: 50,span: false },
    { id: '3', label: 'Warantee Available', minWidth: 50,span: false },
    { id: '1', label: 'Purchase Date', minWidth: 50,span: false },
  ];


function Stocklist({branchid}){

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    
    const [itemlist, setItemlist] = React.useState([]);

    useEffect(() => {
        getData()
    }, [branchid]);

    const getData = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/ServiceEntry/ItemList?branchId=" + branchid, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("item list", responseJson)
             setItemlist(responseJson)
        })

        .catch((error)=>{
            console.log(error)
        })
    }

    const formatDate = (datestring, type) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
          

          if(type == "time") {
            var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          }
          else{
            var formattedDate =  day+"/"+month+"/"+year
          }

          
          return formattedDate; //03/02/2021
    }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>Stock List</p> 
                <div style={{height: 2, width: '100%', backgroundColor: '#d3d3d3', marginTop: 30}}></div>
                <div className='bulk-table'>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                                    colSpan={column.span == true ? 2: 1}
                                >
                                    {column.label}
                                    
                                </TableCell>
                                ))}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {itemlist
                                .map((row, index) => {
                                return (
                                    row.itemDetails.map((i) => (
                                    <TableRow  key={row.vid}>
                                        <TableCell>
                                         {row.itemname}
                                        </TableCell>
                                        <TableCell >
                                        {row.itemdescription}
                                        </TableCell>
                                        <TableCell>
                                        {i.itemcode}
                                        </TableCell>
                                        <TableCell>
                                        {i.serialno}
                                        </TableCell>
                                        <TableCell>
                                        {i.waranteeavailable == true ? "Yes" : 'No'}
                                        </TableCell>
                                        <TableCell>
                                        {formatDate(i.purchasedate, "date")}
                                        </TableCell>
                                    </TableRow>
                                    ))
                                    
                                );
                                })}
                            </TableBody>
                        </Table>
                        </TableContainer>
                        
                    </div>
            </Paper>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
    };
};

export default connect(mapStateToProps)(Stocklist);