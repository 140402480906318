import React, { useState, useEffect } from "react";
import './block.css';
import {Container, Row, Col, Button, Form} from "react-bootstrap";
import { GiBeerBottle } from "react-icons/gi";
import { RiAdminFill } from "react-icons/ri";
import { FaReceipt } from "react-icons/fa";
import { MdOutlineBedroomParent } from "react-icons/md";
import { MdAccountBalance } from "react-icons/md";
import { GrPersonalComputer } from "react-icons/gr";
import { MdOutlineSell } from "react-icons/md";
import { HiOutlineReceiptTax } from "react-icons/hi";
import {useHistory} from 'react-router-dom';
import { MdLocationOn } from "react-icons/md";
import Select from '@mui/material/Select';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
  } from "react-router-dom";
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Avatar from '@mui/material/Avatar';
import {
    addToCart,
	saveBranch,
    setPos
  } from "../../redux/Shopping/shopping-actions";
import { connect } from "react-redux";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';



function Block({setPos, isPosuser, isAdmin,branchid}) {
    let history = useHistory();
    const [branch, setBranch] = React.useState(branchid);
    const [backendMenu, setBackendMenu] = React.useState([]);
    const [source, setSource] = useState([
        {title: "Admin",icon: <RiAdminFill style={{color:'blue', fontSize: 45}}/>,id: "All", firstpath: "/Counter/Counter", caption: "MENU-ADMIN"},
        {title: "Bar",icon: <GiBeerBottle style={{color:'blue', fontSize: 45}}/>, id: "Bar", firstpath: "/Barmaster/Itemmaster", caption: "MENU-BAR"},
        {title: "Reception",icon: <FaReceipt style={{color:'blue', fontSize: 45}}/>, id: "Reception", firstpath: "/Counter/Counter", caption: "MENU-RECEPTION"},
        {title: "Banquet hall",icon: <MdOutlineBedroomParent style={{color:'blue', fontSize: 45}}/>, id: "Banquet", firstpath: "https://banquet.hms-edassery.com/", caption: "MENU-BANQUET"}, 
        {title: "Accounts",icon: <MdAccountBalance style={{color:'blue', fontSize: 45}}/>, id: "Accounts", firstpath: "/Accounts/Group", caption: "MENU-ACCOUNTS"},
        {title: "POS",icon: <GrPersonalComputer style={{color:'blue', fontSize: 45}}/>, id: "POS", firstpath: "/pos", caption: "MENU-POS"},
        {title: "Counter Sale",icon: <MdOutlineSell style={{color:'blue', fontSize: 45}}/>, id: "Countersales", firstpath: "/Transaction/Countersale", caption: "MENU-COUNTERSALES"},
        {title: "GST",icon: <HiOutlineReceiptTax style={{color:'blue', fontSize: 45}}/>, id: "Rentandtax", firstpath: "/Reports/RentAndTax", caption: "MENU-GST"}]);
    const [loading, setLoading] = useState(false);

    const blockClick = (id, path) => {
        if(id == 'Banquet') {
            const newTab = window.open(path, '_blank');
            return newTab;
        }else{
            localStorage.setItem("blockmenu", id);
            history.push(path)
        }
    }

    const [branchlist, setBranchlist] = useState([])



    useEffect(() => {
        const formname = JSON.parse(localStorage.getItem("allowedIcons"));
        console.log("backend", formname)
        setBackendMenu(formname)
        var array = []

        
        if(formname !== null){
            formname.map((item, index) => {
                source.map((item1, index1) => {
                    if(item.caption===item1.caption){
                        array.push(item1)
                    }
                })
            })
            setSource(array)
        }
        
        console.log("array", array)
		var isadmin =  localStorage.getItem("isAdmin")
		checkUser(isadmin)
    }, []);

	const checkUser = async(check) => {
		// await delay(6000);
		if(isAdmin == true) {
			getData()
		}
		if(isAdmin == false){
			var userBranches = JSON.parse(localStorage.getItem("allowedBranches"))
			setBranchlist(userBranches)
			addToCart(userBranches[0].brid)
			setBranch(userBranches[0].brid);
			getBranchdetails(userBranches[0].brid)
		}
	}

	
    const handleChange = (event) => {
        setBranch(event.target.value);
		addToCart(event.target.value)
		getBranchdetails(event.target.value)
    };

	const getBranchdetails = (id) => {
		var token = localStorage.getItem("token")
        fetch(global.url + "api/Branch/GetBranchById?id=" + id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("branch detail Response", responseJson)
			 saveBranch(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
	}

	const getData = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Branch/GetBranchList?orderBy=Name&companyId=1&includeBlocked=False", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("branch view Response", responseJson)
            setBranchlist(responseJson)
			 addToCart(responseJson[0].brid)
			 setBranch(responseJson[0].brid);
			 getBranchdetails(responseJson[0].brid)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    
    return(
        <div className="App">
            <header className="onboard-component">
                <Container fluid>
                <div style={{paddingRight: 25, paddingLeft: 25, display: 'none'}}>
						<FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
							<InputLabel id="demo-simple-select-label">Branch</InputLabel>
							<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={branch}
							label="Age"
							onChange={handleChange}
							size="small"
							>
							{branchlist.map((i) => {
									return(
										<MenuItem value={i.brid}>{i.brname}</MenuItem>
									)
								})
							}
							</Select>
						</FormControl>
					</div>
                    <Row>
                        <Col xs={12} style={{marginTop: 20}}>
                            <Container>
                                <Row>
                                    {source.map((item, index) => (
                                        isAdmin == false && isPosuser == false && item.title == "POS" ? 
                                        null :
                                        <Col xs={6} lg={4}>
                                        <a onClick={() => blockClick(item.id, item.firstpath)} style={{color:'unset', textDecoration: 'none'}}>
                                        <div className="second-box">
                                            <Row>
                                               
                                                <Col xs={12}>
                                                    {item.icon}
                                                    <p className="box-para1">{item.title}</p>
                                                </Col>
                                            </Row>
                                        </div>
                                        </a>
                                    </Col>
                                    ))}
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                    
                </Container> 
            </header>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
      addToCart: (item) => {
          dispatch(addToCart(item))
        },
		saveBranch: (item) => {
			dispatch(saveBranch(item))
		  },
    };
  };

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
		isAdmin: state.shop.isAdmin,
        isPosuser: state.shop.isPosuser
    };
};

  export default connect(mapStateToProps, mapDispatchToProps)(Block);