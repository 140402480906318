import React, {useState, useEffect} from 'react'
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button, Modal} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { connect } from "react-redux";

const columns = [
    { id: '1', label: 'Category Name', minWidth: 50,span: false },
    { id: '2', label: 'Sub Category Name', minWidth: 50,span: false },
    { id: '3', label: 'Sequence', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Edit',
      minWidth: 100,
      span: true
    },
  ];

function SubCategory({branchid}){

    const [age, setAge] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [modal, setModal] = React.useState(false);
    const [source, setSource ] = useState([]);
    const [category, setCategory] = React.useState("");
    const [categoryList, setCategorylist] = React.useState([]);
    const [subcategoryName, setSubcategoryname] = React.useState("");
    const [sequence, setSequence] = React.useState("");
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    useEffect(() => {
        getData()
    }, [branchid])

    const getData = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/SubCategory/GetSubCategoryList?orderBy=Name&companyId=1&branchId=" + branchid + "&includeBlocked=false", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("sub Category Response", responseJson)
             setSource(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }
    const getCat = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Category/GetCategoryList?orderBy=Name&companyId=1&branchId=" + branchid + "&includeBlocked=false", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("Category Response", responseJson)
             setCategorylist(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }
    const categoryChange = (event) => {
        setCategory(event.target.value);
      };
    const onAdd = () => {
        setModal(true)
        getCat()
    }
    const onSubmit = () => {
        var token = localStorage.getItem("token")
        console.log("token", token)
        const data = {
            "subcatname": subcategoryName,
            "catname": '',
            "subid": '',
            "subseq": sequence,
            "subcatid": category,
            "subcatdescp": "",
            "sublocked": '',
            "subcomid": 1,
            "subbranchid": branchid,
          }

        console.log("save data", data)

        fetch(global.url + "api/SubCategory/SaveSubCategory", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("save Response", responseJson)
            if(responseJson.error == false) {
                alert(responseJson.message)
                setModal(false)
                getData()
            }
            else{
                alert(responseJson.message)
            }
            
        })
        .catch((error)=>{
            alert("error")
            console.log(error)
        })
    }
    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
                <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Sub Category</p>
                    <Button variant="primary" onClick={onAdd} >Add</Button>
                </div>
                <div className='bulk-table'>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                                    colSpan={column.span == true ? 2: 1}
                                >
                                    {column.label}
                                    
                                </TableCell>
                                ))}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {source
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                return (
                                    <TableRow  tabIndex={-1} key={row.code}>
                                        <TableCell>
                                          {row.catname}
                                        </TableCell>
                                        <TableCell>
                                        {row.subcatname}
                                        </TableCell>
                                        <TableCell>
                                        {row.subseq}
                                        </TableCell>
                                        <TableCell >
                                            <a href="javascript:void(0)"  onClick={() => setModal(true)}>
                                                <FaIcons.FaEdit fontSize={20} color="blue" />
                                            </a>
                                        </TableCell>
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
                        </TableContainer>
                        <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={source.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
            </Paper>
            <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Sub Category Creation
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12} >
                      <FormControl sx={{ mt: 3, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Category</InputLabel>
                            <Select
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              value={category}
                              label="Age"
                              onChange={categoryChange}
                              size="small"
                              
                            >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {categoryList.map((item, index) => (
                                <MenuItem value={item.catid}>{item.catname}</MenuItem>
                              ))}
                          </Select>
                      </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField  onChange={(e) => setSubcategoryname(e.target.value)} value={subcategoryName} id="outlined-basic" label="Sub Category" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField  onChange={(e) => setSequence(e.target.value)} value={sequence} id="outlined-basic" label="Sequence" variant="outlined"  size="small" type='number' />
                        </FormControl>
                    </Col>
                    <Col xs={5}>
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                                <FormControlLabel control={
                                <Checkbox  />
                                } label="Enabled" />
                          </FormControl>
                    </Col>
                    <Col xs ={12} style={{marginTop:30}}>
                      <Button onClick={onSubmit} style={{float: 'right'}} variant="primary">Submit</Button>
                      <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="danger">Cancel</Button>
                    </Col>
                    
                  </Row>
              </Modal.Body>
           </Modal>
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
    };
};

export default connect(mapStateToProps)(SubCategory);