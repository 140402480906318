import React, {useState, useEffect,useRef} from 'react';
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button, Modal} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { connect } from "react-redux";
import ReactToPrint from 'react-to-print';
import logo from '../../assets/images/logo.jpeg';


const columns = [
    { id: '1', label: 'Code', minWidth: 50,span: false },
    { id: '2', label: 'Description', minWidth: 50,span: false },
    { id: '3', label: 'Opening', minWidth: 50,span: false },
    { id: '4', label: 'RCVD', minWidth: 50,span: false },
    { id: '5', label: 'TRFD', minWidth: 50,span: false },
    { id: '6', label: 'TOTAL', minWidth: 50,span: false },
    { id: '7', label: 'Sales', minWidth: 50,span: false },
    { id: '8', label: 'Closing', minWidth: 50,span: false },
    { id: '9', label: 'Amount', minWidth: 50,span: false },
  ];
const source = [{}, {}]

function IncentiveReport({branchid, branchdetails}){
    const [age, setAge] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [source, setSource] = React.useState([])
    const [modal, setModal] = React.useState(false)
    const [detailstable, setDetailstable] = React.useState([])
    
    const componentRef = useRef();
    const [counterlist, setCounterlist] = React.useState([]);
    const [counter, setCounter] = useState("");

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();


    const [date1, setDate1] = React.useState(year + '-' + month + '-' + date);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const counterChange = (event) => {
        setCounter(event.target.value)
      }

    useEffect(() => {
        getData()
        getCounter()
    }, [branchid]);

    const onFilter =()=> {

    }

    const getCounter = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Counter/GetCounterlist?companyId=1&branchId="+ branchid + "&includeBlocked=false", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("counterlist response", responseJson)
              setCounterlist(responseJson)
          })
          .catch((error)=>{
              console.log(error)
          })
      }


    const getData = () => {
        var token = localStorage.getItem("token")
        var data = {
            "companyId": 1,
            "branchId": branchid,
            "fromDate":  date1,
            "toDate": date1,
            "createdBy": global.userid,
            "billno": "",
            "counterId": counter
          }
        fetch(global.url + "api/GodownTransfer/GetGodownTransferSimpleList", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("GetGodownTransferList view Response", responseJson)
             setSource(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const editClick = (data) => {
        setDetailstable(data)
        setModal(true)
    }

    const onDeleteclick = (id) => {
        console.log('id', id)
        if(window.confirm('Are you want to delete?')){
            var token = localStorage.getItem("token")
            fetch(global.url + "api/GodownTransfer/DeleteGodownIssueByItem?dtId=" + id, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
                
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
            if(responseJson.error == false) {
                alert(responseJson.message)
                window.location.reload()
            }
            else{
                alert(responseJson.message)
            }
            console.log("response", responseJson)
            })
            .catch((error)=>{
            
                console.log(error)
            })
        }
    }

    const formatDate = (datestring) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
        //   console.log("timeString12hr", timeString12hr)

          var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          return formattedDate; //03/02/2021
    }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>Godown Issue Report</p> 
                <Row>
                    <Col xs={12}  className='columnborder'>
                        <div>
                            <Row>
                            <Col sm={6} lg={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="Bill Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setDate1(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col sm={6} lg={2}>
                                        <FormControl fullWidth sx={{width: '100%',  mt: 2 }}>
                                                <InputLabel id="demo-simple-select-label">Counter</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={counter}
                                                label="Age"
                                                onChange={(event) => counterChange(event)}
                                                size="small"
                                                >
                                                    <MenuItem value={0}>All</MenuItem>
                                                    {
                                                        counterlist.map((i, k) => {
                                                            return(
                                                                <MenuItem value={i.ctrid}>{i.ctrname}</MenuItem>
                                                            )
                                                        
                                                        })
                                                    }
                                                </Select>
                                        </FormControl>
                                </Col>
                               
                                <Col xs={12} md={2}>
                                    <div style={{justifyContent: 'center', display: 'flex'}}>
                                        <Button onClick={getData} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 17, width: '50%'}} variant="contained">Search</Button>
                                    </div>
                                </Col>
                                <Col xs={12} md={3}>
                                    <ReactToPrint
                                        trigger={() => <Button  style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 17, width: '50%'}} variant="contained">Print this out!</Button>}
                                        content={() => componentRef.current}
                                    />
                                </Col>
                            </Row>
                           
                        </div>
                    </Col> 
                </Row>
                
                {/* <div className='bulk-table section-to-print' ref={componentRef}>
                <Row> 
                                
                                <img src={require("../../assets/images/logo.jpeg").default} style={{width: 100, position: 'absolute'}}/>
                                
                                <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                                <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                                </Col>
                            </Row>
                <Table responsive bordered>
                <caption className='captiontable'>Godown Issue Report for {date1.split("-").reverse().join("-")}</caption>
                    <thead>
                        <tr>
                            <th>Counter</th>
                            <th>Issued date</th>
                            <th>Docno</th>
                            <th>Code</th>
                            <th>Description</th>
                            <th>Cases</th>
                            <th>Bottles</th>
                            <th>Total</th>
                            <th className='godownprnt'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                                source.map((i, k) => {
                                    return(
                                        <tr>
                                        
                                            <td>{i.countername}</td>
                                            <td>{i.gdndocdate}</td>
                                            <td>{i.gdndocno}</td>
                                            <td>{i.procode}</td>
                                            <td>{i.proname}</td>
                                            <td>{i.cases}</td>
                                            <td>{i.bottle}</td>
                                            <td style={{textAlign: 'right'}}>{i.dttotal}</td>
                                            <td className='godownprnt'><Button onClick={() => onDeleteclick(i.dtid)} style={{backgroundColor: '#03a5fc', color: '#fff',}} variant="contained">Delete</Button></td>
                                        </tr>
                                    )
                                })
                            }
                        
                    </tbody>
                    </Table>
                </div> */}
                    <div style={{height: 2, width: '100%', backgroundColor: '#d3d3d3', marginTop: 30}}></div>
                    <div className='bulk-table'>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <div  className='section-to-print' ref={componentRef}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead className="tableheaderexe">
                            <TableRow style={{height: 80}} >
                                    <TableCell colSpan={9}  style={{padding: 0}}>
                                        <Row>
                                            <img src={logo} style={{width: 100, position: 'absolute', top: 0}}/>
                                            
                                            <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                                            <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                                            </Col>
                                            <p className='printdate' style={{ position: 'absolute', left: '85%'}}>{formatDate(new Date)}</p>  
                                        </Row>
                                    </TableCell>
                                 </TableRow>
                                 <TableRow>
                                    
                                    <TableCell colSpan={9} style={{padding: 0}}>
                                        <div style={{display: 'flex', justifyContent: 'center', fontWeight: 600}} className='captiontable'>
                                        GODOWN ISSUE REPORT FOR {date1.split("-").reverse().join("-")}
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: "10%"}}>
                                    Counter
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: "10%"}}>
                                    Issued date
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold',  width: "10%"}}>
                                    Docno
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold',  width: "10%"}}>
                                    Code
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold',  width: "10%"}}>
                                    Description
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold',width: "10%"}}>
                                    Cases
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold',width: "10%"}}>
                                    Bottles
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold',width: "10%"}}>
                                    Total
                                    </TableCell>
                                    <TableCell className='tablecellPrint godownprnt' style={{fontWeight: 'bold',width: "10%"}}>
                                    Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                            source.map((i, k) => {
                                return (
                                    <TableRow className='tablerowPrint'  tabIndex={-1} key={k}>
                                        <TableCell className='tablecellPrint'  >
                                        {i.countername}
                                        </TableCell>
                                        <TableCell className='tablecellPrint'   >
                                        {i.gdndocdate}
                                        </TableCell>
                                        <TableCell className='tablecellPrint'   >
                                        {i.gdndocno}
                                        </TableCell>
                                        <TableCell className='tablecellPrint' >
                                        {i.procode}
                                        </TableCell>
                                        <TableCell className='tablecellPrint'  >
                                        {i.proname}
                                        </TableCell>
                                        <TableCell className='tablecellPrint'  >
                                        {i.cases}
                                        </TableCell>
                                        <TableCell className='tablecellPrint'  >
                                        {i.bottle}
                                        </TableCell>
                                        <TableCell className='tablecellPrint' style={{textAlign: 'center'}}  >
                                        {i.dttotal}
                                        </TableCell>
                                        <TableCell className='tablecellPrint godownprnt'   >
                                        <Button onClick={() => onDeleteclick(i.dtid)} style={{backgroundColor: '#03a5fc', color: '#fff',}} variant="contained">Delete</Button>
                                        </TableCell>
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
                        </div>
                        </TableContainer>
                        
                    </div>
            </Paper>
            <Modal
                    size="lg"
                    show={modal}
                    onHide={() => setModal(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                    >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                        Details
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                        <Table responsive bordered>
                        <thead>
                        <tr>
                            <th>Product Code</th>
                            <th>Product Name</th>
                            <th>Cases</th>
                            <th>Bottles</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                                detailstable.map((i, k) => {
                                    return(
                                        <tr>
                                        
                                            <td>{i.procode}</td>
                                            <td>{i.proname}</td>
                                            <td>{i.cases}</td>
                                            <td>{i.bottle}</td>
                                            <td>{i.dtqty}</td>
                                            <td>{i.dtprice}</td>
                                            <td>{i.dttotal}</td>
                                        </tr>
                                    )
                                })
                            }
                        
                    </tbody>
                            </Table>
                        </Row>
                    </Modal.Body>
           </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(IncentiveReport);