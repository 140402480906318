import React, {useState, useEffect, useRef} from 'react';
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button, Table, Modal} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { connect } from "react-redux";
import { Rings  } from  'react-loader-spinner'


const columns = [
    { id: '1', label: 'Code', minWidth: 50,span: false },
    { id: '2', label: 'Description', minWidth: 50,span: false },
    { id: '3', label: 'Opening', minWidth: 50,span: false },
    { id: '4', label: 'RCVD', minWidth: 50,span: false },
    { id: '5', label: 'TRFD', minWidth: 50,span: false },
    { id: '6', label: 'TOTAL', minWidth: 50,span: false },
    { id: '7', label: 'Sales', minWidth: 50,span: false },
    { id: '8', label: 'Closing', minWidth: 50,span: false },
    { id: '9', label: 'Amount', minWidth: 50,span: false },
  ];


function Bardayentry({branchid}){
    const [age, setAge] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [counterlist, setCounterlist] = React.useState([]);
    const [counter, setCounter] = React.useState('');
    const [tablerow, setTablerow] = React.useState([])
    const [source, setSource] = React.useState([])
    const [modal, setModal] = React.useState(false)
    const [popupdetails, setPopupdetails] = React.useState([])

    const [formid, setFormid] = React.useState('');
    const [docno, setDocno] = React.useState('');
    const [docnum, setDocnum] = React.useState('');
    const [accid, setAccid] = React.useState('');
    const [docdate, setDocdate] = React.useState('');
    const [accname, setAccname] = React.useState('');
    const [happyhr, setHappyhr] = React.useState('');
    const [subtotal, setSubtotal] = React.useState(0);
    const [grandtotal, setGrandtotal] = React.useState(0);

    const [directsaletotal, setDirectsaletotal] = React.useState(0);
    const [indirectsaletotal, setIndirectsaletotal] = React.useState(0);

    const [checkloader, setCheckloader] = useState(false);
    const [saveloader, setSaveloader] = useState(false);

    // const [docdate, setDocdate] = React.useState("");
   

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();


    const [date1, setDate1] = React.useState(year + '-' + month + '-' + date);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };
    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const counterChange = (event) => {
        setCounter(event.target.value)
      }

    useEffect(() => {
        getCounter()
        getDocDate()
      }, [branchid]);

      useEffect(() => {
        setTablerow([])
      }, [counter, date1]);

      useEffect(() => {
        // getTotalamount()
        getTotalamount1()
        getTotalamount2()
        getGrandtotal()
        
      }, [popupdetails]);

    //   const getDirectsales = () => {
    //     var array = [];
    //     for (var i = 0; i < popupdetails.length; i++) {
    //         popupdetails[i].directsale = (popupdetails[i].pegs * popupdetails[i].pegrate) + (popupdetails[i].bottlerate * popupdetails[i].bottle)
    //     }
    //     getTotalamount1()        
    // }


    //   const getTotalamount = () => {
    //     var totalAmount = 0;
    //     for (var i = 0; i < tablerow.length; i++) {
    //         totalAmount += parseInt(tablerow[i].directsale)
    //     }
    //    setSubtotal(totalAmount)
    // }

    const getTotalamount1 = () => {
        var totalAmount = 0;
        for (var i = 0; i < popupdetails.length; i++) {
            totalAmount += parseFloat(popupdetails[i].directsale)
        }
       setDirectsaletotal(totalAmount.toFixed(2))
    }

    const getGrandtotal = () => {
        var totalAmount1 = 0;
        var totalAmount2 = 0;
        for (var i = 0; i < popupdetails.length; i++) {
            totalAmount1 += parseFloat(popupdetails[i].directsale)
            totalAmount2 += parseFloat(popupdetails[i].indirectsale)
        }
       setGrandtotal((totalAmount1 + totalAmount2).toFixed(2))
    }

    const getTotalamount2 = () => {
        var totalAmount = 0;
        for (var i = 0; i < popupdetails.length; i++) {
            console.log(popupdetails[i])
            totalAmount += parseInt(popupdetails[i].indirectsale)
        }
       setIndirectsaletotal(totalAmount.toFixed(2))
    }

    const getCounter = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Counter/GetCounterlist?companyId=1&branchId="+ branchid + "&includeBlocked=false", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("counterlist response", responseJson)
              setCounterlist(responseJson)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const onCheck = () => {
          setCheckloader(true)
        console.log("branch view Rsponse")
        var token = localStorage.getItem("token")
        var data ={
            "companyId": 1,
            "branchId": branchid,
            "fromDate": docdate,
            "toDate": docdate,
            "createdBy": global.userid,
            "billno": "",
            "counterId": counter
          }
        fetch(global.url + "api/CounterStock/GetDayCloseList", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("GetDayCloseList view Response", responseJson)
             if(responseJson.error == false) {
                setTablerow(responseJson.responsevalue)
             }
             else{
                alert(responseJson.message)
             }
             
             setCheckloader(false)
        })
        .catch((error)=>{
            console.log(error)
        })
      }

      const bottleChange = (event, key) => {
        var textArray1 = [...tablerow]
        const rx_live = /^[+-]?\d*(?:[,]\d*)?$/;
            if (rx_live.test(event.target.value)) {
                // if(event.currentTarget.value <= textArray1[key].closingbottle) {
                    console.log(event.currentTarget.value)
                    textArray1[key].changedbottles = event.currentTarget.value;
                    textArray1[key].counterid = counter;
                    textArray1[key].branchid = branchid;
                    setTablerow(textArray1)
                // }
            }
    }

    const pagsChange = (event, key) => {
        var textArray1 = [...tablerow]
        // if(event.currentTarget.value <= textArray1[key].closingpeg) {
            textArray1[key].changedpeg = event.currentTarget.value;
            textArray1[key].counterid = counter;
            textArray1[key].branchid = branchid;
            setTablerow(textArray1)
        // }
    }

    const onUpdateclick =() => {
        var token = localStorage.getItem("token")
        var data =tablerow
        console.log("data", data)
        fetch(global.url + "api/CounterStock/GetDayCheckList", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("api/CounterStock/GetDayCheckList view Response", responseJson)
             setPopupdetails(responseJson)
             setModal(true)
        })
        .catch((error)=>{
            console.log(error)
        })

       
        fetch(global.url + "api/Sales/NewSale?branchId=" + branchid, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("category view Response", responseJson)
             setFormid(responseJson.saformid)
             setDocno(responseJson.kot)
             setDocnum(responseJson.kotnum)
             setAccid(responseJson.saaccid)
            //  setDocdate(responseJson.docdate)
             setAccname(responseJson.saaccname)
             setHappyhr(responseJson.happyHour)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getDocDate = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/CounterStock/GetTransactionDate?branchId=" + branchid, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("get docdate", responseJson)
             setDocdate(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }  

    const formatDate = (datestring, type) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
          

          if(type == "time") {
            var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          }
          else{
            var formattedDate =  day+"/"+month+"/"+year
          }

          
          return formattedDate; //03/02/2021
    }

    const onSubmit = () => {
        if (window.confirm('Are you sure you want to save ?')) {
            setSaveloader(true)
            var token = localStorage.getItem("token")
            const data ={
                "said": 0,
                "vid": 0,
                "saformid": formid,
                "sadocdate": docdate,
                "sadocno": docno,
                "sadocnum": docnum,
                "sacaccid": accid,
                "saaccname": accname,
                "saaccadD1": "",
                "saaccadD2": "",
                "saaccadD3": "",
                "sastateid": "",
                "sadistid": "",
                "sacomid": 1,
                "sabranchid": branchid,
                "samob": "",
                "saamount":  popupdetails[0].nettotal,
                "saitemdisc": "",
                "sagross":"",
                "sasgst": "",
                "sacgst": "",
                "saigst": "",
                "satax": "",
                "sadiscper": "",
                "sadiscount": "",
                "sasubtotal":  popupdetails[0].nettotal,
                "sanettotal":  popupdetails[0].nettotal,
                "sacreatedon": "",
                "sacreatedby": global.userid,
                "saupdatedby": global.userid,
                "printFile": "",
                "sacashpaid": "",
                "sabankpaid": "",
                "satotalpaid": "",
                "saupdatedon": "",
                "saduedate": "",
                "saremarks": "",
                "sacessper": "",
                "sacessamount": "",
                "satotal": popupdetails[0].nettotal,
                "sagodownid": 1,
                "sacode": "",
                "sagstin": "",
                "sarepid": "",
                "saispos": "",
                "sacardrefno": "",
                "sadayclose": "",
                "sacounterid": counter,
                "sadaycloseid": 1,
                "sacardtype": "",
                "sadeliverycharge": "",
                "sarefcode": "",
                "tableid": 0,
                "roomid": 0,
                "billnonum": "",
                "billno": "", 
                "waiterid": 0,
                "bardetails": popupdetails,
                "billDate": "",
                "user": 1,
                "time": "",
                "sacash": "",
                "balance": "",
                "inWords": "",
                "billedstatus": 1,
                "tableid": "",
                "roomid": ""
            }
            console.log("data", data)
            fetch(global.url + "api/Sales/SaveSales", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(data),
                
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("api/Sales/SaveSale", responseJson)
                setSaveloader(false)
                if(responseJson) {
                    setModal(false)
                    alert("Saved")
                    window.location.reload()
                }
            })
            .catch((error)=>{
                console.log(error)
            })
        }
        else{

        }
    }

    const savelaterClick = () => {
        var token = localStorage.getItem("token")
            var examplearray = []
            for(var i=0; i< tablerow.length; i++) {
                  examplearray.push({
                    "laterid": 0,
                    "productid": tablerow[i].productid,
                    "bottle": tablerow[i].changedbottles,
                    "pegs": tablerow[i].changedpeg,
                    "branchid": tablerow[i].branchid,
                    "counterid": tablerow[i].counterid,
                    "entrydate": ""
                  })  
            }

            const data = examplearray
            
            console.log("data", data)
            fetch(global.url + "api/CounterStock/SaveLater", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(data),
                
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("api/Sales/SaveSale", responseJson)
                if(responseJson.error == false) {
                    alert(responseJson.message)
                    window.location.reload()
                }
                else{
                    alert(responseJson.message)
                }
            })
            .catch((error)=>{
                console.log(error)
            })
    }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>DAY ENTRY</p> 
                <Row>
                    <Col xs={12}  className='columnborder'>
                        <div>
                            <Row>
                                <Col sm={6} lg={3}>
                                    {/* <FormControl sx={{ mt: 3, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="Bill Date"
                                            type="date"
                                         
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setDate1(newValue.target.value);
                                            }}
                                        />
                                    </FormControl> */}
                                    {docdate == "" ? 
                                    <FormControl sx={{  width: '100%', mt: 3 }}>
                                        <TextField value={docdate}  disabled label="Date" id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>:
                                    <FormControl sx={{  width: '100%', mt: 3 }}>
                                        <TextField value={formatDate(docdate, "date")} disabled label="Date" id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>}
                                </Col>

                                <Col sm={6} lg={2}>
                                        <FormControl fullWidth sx={{width: '100%',  mt: 3 }}>
                                                <InputLabel id="demo-simple-select-label">Counter</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={counter}
                                                label="Age"
                                                onChange={(event) => counterChange(event)}
                                                size="small"
                                                >
                                                    {
                                                        counterlist.map((i, k) => {
                                                            return(
                                                                <MenuItem value={i.ctrid}>{i.ctrname}</MenuItem>
                                                            )
                                                        
                                                        })
                                                    }
                                                </Select>
                                        </FormControl>
                                </Col>
                               
                                <Col xs={12} md={2}>
                                    <div style={{justifyContent: 'center', display: 'flex'}}>
                                    {
                                            checkloader ?
                                            <Rings
                                                height="50"
                                                width="50"
                                                color='#0d6efd'
                                                ariaLabel='loading'
                                            />:
                                        <Button onClick={() => onCheck()} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">Check</Button>
                                    }
                                    </div>
                                </Col>
                                <Col xs={12} md={4}>
                                    <div style={{ justifyContent: 'center', display: 'flex'}}>
                                        <Button style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained" onClick={() => onUpdateclick()}>View and update</Button>
                                    </div>
                                </Col>
                                <Col xs={12} md={4}>
                                    <div>
                                        <Button onClick={()=> savelaterClick()} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">Save and Edit later</Button>
                                    </div>
                                </Col>
                            </Row>
                           
                        </div>
                    </Col> 
                </Row>
                <div className='bulk-table'>
                <Table responsive bordered>
                    <thead>
                        <tr>
                            <th style={{width: '5%',fontSize: 12}}>SL NO</th>
                            <th style={{width: '5%',fontSize: 12}}>ITEM CODE</th>
                            <th style={{width: "40%",fontSize: 12}}>DESCRIPTION</th>
                            <th style={{width: '5%',fontSize: 12}}>BOTTLE(OPENING)</th>
                            {
                                counter == 4 ? null :
                                <th style={{width: '5%',fontSize: 12}}>PEGS(OPENING)</th>
                            }
                            
                            <th style={{width: '5%',fontSize: 12}}>BOTTLE(CLOSING)</th>
                            {
                                counter == 4 ? null :
                                <th style={{width: '5%',fontSize: 12}}>PEGS(CLOSING)</th>
                            }
                            <th style={{width: '5%',fontSize: 12}}>BOTTLE(CLOSING)</th>
                            {
                                counter == 4 ? null :
                                <th style={{width: '5%',fontSize: 12}}>PEGS(CLOSING)</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tablerow.map((i, k) => {
                                return(
                                    <tr>
                                        <td>{k+1}</td>
                                        <td>{i.code}</td>
                                        <td>{i.productname}</td>
                                        <td>{i.opbarbottle}</td>
                                        {
                                            counter == 4 ? null :
                                            <td>{i.opbarpeg}</td>
                                        }
                                        <td>{i.closingbottle}</td>
                                        {
                                            counter == 4 ? null :
                                            <td>{i.closingpeg}</td>
                                        }
                                        <td><input style={{width: '100%'}}  autocomplete="off" type="text" name="query" value={i.changedbottles} onChange={(event) => bottleChange(event, k)}/></td>
                                        {
                                            counter == 4 ? null :
                                            <td><input style={{width: '100%'}}  autocomplete="off" type="number" name="query" value={i.changedpeg} onChange={(event) => pagsChange(event, k)}/></td>
                                        }
                                    </tr>
                                )
                            })
                        }
                        
                    </tbody>
                    </Table>
                </div>
            </Paper>
            <Modal
                    size="lg"
                    show={modal}
                    onHide={() => setModal(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                    className="widthmodal"
                    >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                        Details
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                        <Table responsive bordered>
                        <thead>
                        <tr>
                            <th>Product Code</th>
                            <th>Product Name</th>
                            <th>Bottle</th>
                            <th>Bot Rate</th>
                            <th>Bot Total</th>
                            <th>Pegs</th>
                            <th>Peg Rate</th>
                            <th>Peg Total</th>
                            <th>Direct Sales</th>
                            <th>Indirect Sales</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                                popupdetails.map((i, k) => {
                                    return(
                                        <tr>
                                        
                                            <td>{i.code}</td>
                                            <td>{i.productname}</td>
                                            <td>{i.bottle}</td>
                                            <td>{i.bottlerate}</td>
                                            <td>{i.totalbottlerate}</td>
                                            <td>{i.pegs}</td>
                                            <td>{i.pegrate}</td>
                                            <td>{i.totalpegrate}</td>
                                            <td style={{textAlign: 'right'}}>{i.directsale}</td>
                                            <td style={{textAlign: 'right'}}>{i.indirectsale}</td>
                                        </tr>
                                    )
                                })
                            }
                            <tr>
                                <td style={{textAlign: 'right'}} colSpan={8}>Total</td>
                                <td style={{textAlign: 'right'}}>{directsaletotal}</td>
                                <td style={{textAlign: 'right'}}>{indirectsaletotal}</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'right'}} colSpan={8}>Grand Total</td>
                                <td style={{textAlign: 'right'}} colSpan={2}>{grandtotal}</td>
                            </tr>
                    </tbody>
                            </Table>
                        </Row>
                        <Row>
                            <Col xs ={12} style={{marginTop:30}}>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                {
                                    saveloader ?
                                    <Rings
                                        height="50"
                                        width="50"
                                        color='#0d6efd'
                                        ariaLabel='loading'
                                    />:
                                    <div>
                                        <Button onClick={() => onSubmit()} style={{float: 'right'}} variant="primary">YES</Button>
                                        <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="danger">No</Button>
                                    </div>
                                }
                                </div>
                                
                               
                            </Col>
                        </Row>
                    </Modal.Body>
           </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(Bardayentry);