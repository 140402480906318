import React, {useState, useEffect,useRef} from 'react';
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button, Modal} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DatePicker from '@mui/lab/DatePicker';
// import TimePicker from '@mui/lab/TimePicker';
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { connect } from "react-redux";
import ReactToPrint from 'react-to-print';
import { Rings  } from  'react-loader-spinner';
import logo from '../../assets/images/logo.jpeg';

const columns = [
    { id: '1', label: 'Sl No#', minWidth: 50,span: false },
    { id: '1', label: 'Invoice', minWidth: 50,span: false },
    { id: '2', label: 'Location', minWidth: 50,span: false },
    { id: '3', label: 'Building', minWidth: 50,span: false },
    { id: '4', label: 'Street', minWidth: 50,span: false },
    { id: '5', label: 'Zone', minWidth: 50,span: false },   
    { id: '6', label: 'Mobile', minWidth: 50,span: false },
    { id: '7', label: 'Rate', minWidth: 50,span: false },
    { id: '8', label: 'Item, Colour, Size, Quantity', minWidth: 50,span: false },  
    // { id: '9', label: 'Description', minWidth: 50,span: false },
    { id: '10', label: 'By', minWidth: 50,span: false },
    { id: '11', label: 'Remarks', minWidth: 50,span: false },  
    { id: '7', label: 'Order Time', minWidth: 50,span: false },
  ];

  

  function Miscellaneous({branchid, branchdetails}) {
    const [age, setAge] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [source, setSource] = React.useState([])
    const [modal, setModal] = React.useState(false)
    const [detailstable, setDetailstable] = React.useState([])
    const componentRef = useRef();
    

    const [counterlist, setCounterlist] = React.useState([]);
    const [counter, setCounter] = useState("");
    const [loader, setLoader] = useState(false);

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();


    const [fromdate, setFromdate] = React.useState(year + '-' + month + '-' + date);
    const [todate, setTodate] = React.useState(year + '-' + month + '-' + date);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const counterChange = (event) => {
        setCounter(event.target.value)
      }

    useEffect(() => {
        getData()
        getCounter()
    }, [branchid]);

    const onFilter =()=> {

    }

    const getCounter = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Counter/GetCounterlist?companyId=1&branchId="+ branchid + "&includeBlocked=false", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("counterlist response", responseJson)
              setCounterlist(responseJson)
          })
          .catch((error)=>{
              console.log(error)
          })
      }


    const getData = () => {
        var token = localStorage.getItem("token")
        console.log(token)
        var data = {
            "companyId": 1,
            "branchId": branchid,
            "fromDate":  fromdate,
            "toDate": todate,
            "createdBy": global.userid,
            "billno": "",
            "counterId": ""
          }
        fetch(global.url + "api/Purchase/GetMiscPurchaseList", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("purchase report Response", responseJson)
             setSource(responseJson)
        })
        .catch((error)=>{
            setLoader(false)
            console.log(error)
        })
    }

    const editClick = (data) => {
        setDetailstable(data)
        setModal(true)
    }

    const formatDate = (datestring, type) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
          

          if(type == "time") {
            var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          }
          else{
            var formattedDate =  day+"/"+month+"/"+year
          }

          
          return formattedDate; //03/02/2021
    }

    const formatDate2 = (datestring) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
        //   console.log("timeString12hr", timeString12hr)

          var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          return formattedDate; //03/02/2021
    }

      return(
        <div className="main-content">
                <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>Miscellaneous Purchase Report</p> 
                <Row>
                    <Col xs={12}  className='columnborder'>
                        <div>
                            <Row>
                                <Col sm={6} lg={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="From Date"
                                            type="date"
                                            value={fromdate}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setFromdate(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                    
                                </Col>
                                <Col sm={6} lg={3}>
                                <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="To Date"
                                            type="date"
                                            value={todate}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setTodate(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                {/* <Col sm={6} lg={2}>
                                        <FormControl fullWidth sx={{width: '100%',  mt: 2 }}>
                                                <InputLabel id="demo-simple-select-label">Counter</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={counter}
                                                label="Age"
                                                onChange={(event) => counterChange(event)}
                                                size="small"
                                                >
                                                    <MenuItem value={0}>All</MenuItem>
                                                    {
                                                        counterlist.map((i, k) => {
                                                            return(
                                                                <MenuItem value={i.ctrid}>{i.ctrname}</MenuItem>
                                                            )
                                                        
                                                        })
                                                    }
                                                </Select>
                                        </FormControl>
                                </Col> */}
                               
                                <Col xs={12} md={2}>
                                    <div style={{justifyContent: 'center', display: 'flex'}}>
                                        <Button onClick={getData} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 17, width: '50%'}} variant="contained">Search</Button>
                                    </div>
                                </Col>
                                <Col xs={12} md={3}>
                                    <ReactToPrint
                                        trigger={() => <Button style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 17, width: '50%'}} variant="contained">Print this out!</Button>}
                                        content={() => componentRef.current}
                                    />
                                </Col>
                            </Row>
                           
                        </div>
                    </Col> 
                </Row>
                
                <TableContainer sx={{ maxHeight: 440, marginTop: 2 }}>
                    <div  className='section-to-print' ref={componentRef} style={{width: '95%'}}>
                {/* <Row> 
                                
                                <img src={require("../../assets/images/logo.jpeg").default} style={{width: 100, position: 'absolute'}}/>
                                
                                <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                                <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                                </Col>
                            </Row> */}
                <Table id="table-to-xls"  stickyHeader aria-label="sticky table" >
                {/* <caption className='captiontable'>Miscellaneous Purchase Report of {fromdate.split("-").reverse().join("-")} to {todate.split("-").reverse().join("-")}</caption> */}
                    <TableHead>
                        <TableRow style={{height: 80}} >
                                    <TableCell colSpan={9}  style={{padding: 0}}>
                                        <Row>
                                            <img src={logo} style={{width: 100, position: 'absolute', top: 0}}/>
                                            
                                            <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                                            <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                                            </Col>
                                            <p className='printdate' style={{ position: 'absolute', left: '85%'}}>{formatDate2(new Date)}</p>  
                                        </Row>
                                    </TableCell>
                                 </TableRow>
                                 <TableRow>
                                    
                                    <TableCell colSpan={9} style={{padding: 0}}>
                                        <div style={{display: 'flex', justifyContent: 'center', fontWeight: 600}} className='captiontable'>
                                             MISCELLANEOUS PURCHASE REPORT OF {fromdate.split("-").reverse().join("-")} TO {todate.split("-").reverse().join("-")}
                                        </div>
                                    </TableCell>
                                </TableRow>
                        <TableRow>
                            <TableCell className='tablecellPrint'>Date</TableCell>
                            <TableCell className='tablecellPrint'>Doc No.</TableCell>
                            <TableCell className='tablecellPrint'>Code</TableCell>
                            <TableCell className='tablecellPrint'>Item</TableCell>
                            <TableCell className='tablecellPrint'>Cases</TableCell>
                            <TableCell className='tablecellPrint'>Bottles</TableCell>
                            <TableCell className='tablecellPrint'>Qty</TableCell>
                            <TableCell className='tablecellPrint'>Total</TableCell>
                            <TableCell className='tablecellPrint noprint'>
                                Edit
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                                source.map((i, index) => {
                                    return(
                                            i.details.length == 1 ? 
                                            <TableRow>
                                                <TableCell className='tablecellPrint'  >{formatDate(i.pudocdate, "date")}</TableCell>
                                                <TableCell className='tablecellPrint'  >{i.pudocno}</TableCell>
                                                <TableCell className='tablecellPrint' d>{i.details[0]?.prdcode}</TableCell>
                                                <TableCell className='tablecellPrint' >{i.details[0]?.prdname}</TableCell>
                                                <TableCell className='tablecellPrint' >{i.details[0]?.caseno}</TableCell>
                                                <TableCell className='tablecellPrint' >{i.details[0]?.bottle}</TableCell>
                                                <TableCell className='tablecellPrint' >{i.details[0]?.dtqty}</TableCell>
                                                <TableCell className='tablecellPrint'  style={{textAlign: 'right'}}>{i.details[0]?.dttotal}</TableCell>
                                         
                                                    <TableCell className='tablecellPrint noprint' style={{textAlign: 'center'}} rowSpan={i.details.length}>
                                                        <a href={'/Transaction/MiscellaneousPurchase/' + i.vid}  >
                                                            <FaIcons.FaEdit fontSize={20} color="blue" />
                                                        </a>
                                                    </TableCell> 
                                            </TableRow> :
                                            i.details.map((item, index) => (
                                                <TableRow>
                                                    {
                                                        index==0 ? 
                                                        <TableCell className='tablecellPrint'  rowSpan={i.details.length}>{formatDate(i.pudocdate, "date")}</TableCell> : null
                                                    }
                                                    {
                                                            index==0 ? 
                                                            <TableCell className='tablecellPrint'  rowSpan={i.details.length}>{i.pudocno}</TableCell>: null
                                                    }
                                                    <TableCell className='tablecellPrint' >{item.prdcode}</TableCell>
                                                    <TableCell className='tablecellPrint' >{item.prdname}</TableCell>
                                                    <TableCell className='tablecellPrint' >{item.caseno}</TableCell>
                                                    <TableCell className='tablecellPrint' >{item.bottle}</TableCell>
                                                    <TableCell className='tablecellPrint' >{item.dtqty}</TableCell>
                                                    <TableCell className='tablecellPrint'  style={{textAlign: 'right'}}>{item.dttotal.toFixed(2)}</TableCell>
                                                    {index == 0 ? 
                                                        <TableCell className='tablecellPrint noprint' style={{textAlign: 'center'}} rowSpan={i.details.length}>
                                                            <a href={'/Transaction/MiscellaneousPurchase/' + i.vid} >
                                                                <FaIcons.FaEdit fontSize={20} color="blue" />
                                                            </a>
                                                        </TableCell>: null
                                                    } 
                                                </TableRow>
                                            ))
                                            
                                    )
                                })
                            }
                    </TableBody>
                    </Table>
                </div>
            </TableContainer>
            </Paper>

            {/* <Modal
                    size="lg"
                    show={modal}
                    onHide={() => setModal(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                    >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                        Details
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                        <Table responsive bordered>
                        <thead>
                        <tr>
                            <th>Product Code</th>
                            <th>Product Name</th>
                            <th>Cases no</th>
                            <th>Bottles no</th>
                            <th>Quantity</th>
                            <th>Tax</th>
                            <th>Price</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                                detailstable.map((i, k) => {
                                    return(
                                        <tr>
                                        
                                            <td>{i.procode}</td>
                                            <td>{i.proname}</td>
                                            <td>{i.caseno}</td>
                                            <td>{i.bottle}</td>
                                            <td>{i.dtqty}</td>
                                            <td>{i.dttax}</td>
                                            <td>{i.dtprice}</td>
                                            <td>{i.dttotal}</td>
                                        </tr>
                                    )
                                })
                            }
                        
                    </tbody>
                            </Table>
                        </Row>
                    </Modal.Body>
           </Modal> */}
        </div>
      );
  }

  const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(Miscellaneous);
