import React from 'react'
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Table, Button, Form} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";

const columns = [
    { id: '1', label: 'Date', minWidth: 50,span: false },
    { id: '2', label: 'Voc No.', minWidth: 50,span: false },
    { id: '3', label: 'Amount', minWidth: 50,span: false },
    { id: '4', label: 'Rec/Pay', minWidth: 50,span: false },
    { id: '5', label: 'Entered By', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Edit',
      minWidth: 100,
      span: true
    },
  ];
const source = [{}, {}]

function GuestLaundry(){

    const [age, setAge] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>Guest Laundry</p> 
                <div>
                    <Row>
                        <Col xs={12} md={4}>
                            <FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
                                <InputLabel id="demo-simple-select-label">Room No.</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={age}
                                label="Age"
                                onChange={handleChange}
                                size="small"
                                >
                                <MenuItem value={10}>101</MenuItem>
                                <MenuItem value={20}>102</MenuItem>
                                <MenuItem value={30}>103</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={4}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField
                                    id="datetime-local"
                                    label="Date"
                                    type="datetime-local"
                                    defaultValue="2017-05-24T10:30"
                                    sx={{ width: 250 }}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                            </FormControl>
                        </Col>
                    </Row>
                </div>
                <Table bordered responsive style={{marginTop: 20}}>
                        <thead>
                            <tr>
                                <th style={{width: '10%'}}>SlNo</th>
                                <th>Code</th>
                                <th  style={{width: '40%'}}>Description</th>
                                <th>QTY</th>
                                <th>Total</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>
                                <Form.Control
                                    type="text"
                                />
                                </td>
                                <td></td>
                                <td>
                                <Form.Control
                                    type="text"
                                />
                                </td>
                                <td></td>
                                <td >
                                    <a href="javascript:void(0)">
                                        <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="4" style={{textAlign: 'right', fontWeight: 'bold'}}>Total</td>
                                <td colSpan="2"></td>
                            </tr>
                        </tbody>
                    </Table>
                    <div style={{justifyContent: 'center', display: 'flex'}}>
                        <Button style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 30}} variant="contained">Save</Button>
                    </div>
            </Paper>
        </div>
    );
}

export default GuestLaundry;