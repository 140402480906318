import React, { useState, useEffect, useRef } from "react";
import Paper from '@mui/material/Paper';
import { Modal, Container, Row, Col  } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { connect } from "react-redux";
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { ThreeDots  } from  'react-loader-spinner'
import ReactToPrint from 'react-to-print';
import logo from '../../assets/images/logo.jpeg';

const columns = [
    { id: '1', label: 'Code', minWidth: 50,span: false },
    { id: '3', label: 'Item', minWidth: 50,span: false },
    { id: '4', label: ' Non-AC Bar Bot', minWidth: 50,span: false },
    { id: '5', label: ' Non-AC Bar Peg', minWidth: 50,span: false },
    { id: '6', label: ' AC Bar Bot', minWidth: 50,span: false },
    { id: '7', label: ' AC Bar Peg', minWidth: 50,span: false },
    { id: '8', label: ' AC Bot', minWidth: 50,span: false },
    { id: '9', label: ' AC Peg', minWidth: 50,span: false },
    { id: '7', label: ' WS Bot', minWidth: 50,span: false },
    { id: '8', label: ' Local Bot', minWidth: 50,span: false },
    { id: '9', label: ' Local Peg', minWidth: 50,span: false },
    { id: '9', label: 'Edit', minWidth: 50,span: false },
  ];


function Pricelist({branchid, branchdetails}){
    const componentRef = useRef();
    const [age, setAge] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [source, setSource] = React.useState([]);

    const [categorylist, setCategorylist] = React.useState([]);
    const [category, setCategory] = React.useState("");
    const [subcategorylist, setSubcategorylist] = React.useState([]);
    const [subcategory, setSubcategory] = React.useState("");
    const [loader, setLoader] = useState(false);

    const [fbsale, setFbsale] = React.useState("");
    const [unit, setUnit] = React.useState("");

    const [pegsperbottle, setPegsperbottle] = React.useState("");
    const [mlperbottle, setmlperbottle] = React.useState("");
    const [bottlespercase, setBottlepercase] = React.useState("");
    const [purchaserate, setPurchaserate] = React.useState("");
    const [name, setName] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [code, setCode] = React.useState("");
    const [printname, setPrintname] = React.useState("");
    const [cost, setCost] = React.useState("");
    const [cess, setCess] = React.useState("");
    const [array, setArray] = React.useState([])
    const [branchlist, setBranchlist] = React.useState([]);

    const [editmodal, setEditmodal] = React.useState(false);

    const [searchname, setSearchname] = React.useState("");
    const [searchcode, setSearchcode] = React.useState("");

    const [productid, setProductid] = React.useState("");

    const [wsbottle, setWsbottle] = React.useState(0.00);
    const [wspeg, setWspeg] = React.useState(0.00);

    const [localbottle, setLocalbottle] = React.useState(0.00);
    const [localpeg, setLocalpeg] = React.useState(0.00);

    const [aclocalbottle, setAclocalbottle] = React.useState(0.00);
    const [aclocalpeg, setAclocalpeg] = React.useState(0.00);

    const [roombottle, setRoombottle] = React.useState(0.00);
    const [roompeg, setRoompeg] = React.useState(0.00);

    const [acbottle, setAcbottle] = React.useState(0.00);
    const [acpeg, setAcpeg] = React.useState(0.00);

    const [barbottle, setBarbottle] = React.useState(0.00);
    const [barpeg, setBarpeg] = React.useState(0.00);

    const [executivebottle, setExecutivebottle] = React.useState(0.00);
    const [executivepeg, setExecutivepeg] = React.useState(0.00);

    const [addedvalue, setAddedvalue] = React.useState("");

    const categoryChange = (event) => {
        setCategory(event.target.value);
    };
    const subcategoryChange = (event) => {
        setSubcategory(event.target.value);
    };

    const fbsaleChange= (event) => {
        setFbsale(event.target.value);
    };

    const unitChange= (event) => {
        setUnit(event.target.value);
    };

    const blockChange = (e,id) => {
        let isChecked = e.target.checked;
        console.log(isChecked)
        if(isChecked == true) {
            var input  = [...array]
            input.push({branchid: id})
            console.log("input", input)
            setArray(input)
        }
        else{

            var testArr = [1, 2, 3, 4, 5];
            const val = 3; // Value to remove

            for (let i = 0; i < array.length; i++) {
                if (array[i].branchid === id) {
                    const removedElements = array.splice(i, 1);
                    console.log(removedElements); // Outputs [3]
                    i--; // Since the indexes of elements following this index get updated after removal
                }
            }
        }
        console.log("array",  array)
    }

    function handleKeyPress(event) {
        if (!/\d/.test(event.key)) {
          event.preventDefault();
        }
      }
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };
    const handleChange = (event) => {
        setAge(event.target.value);
    };
    useEffect(() => {
        getData()
    }, [branchid]);


    const getData = () => {
        setLoader(true)
        var token = localStorage.getItem("token")
        console.log(token)
        fetch(global.url + "api/Products/ProductPriceList?branchId=" + branchid, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setLoader(false)
             console.log("pricelist Response", responseJson)
             setSource(responseJson)
        })
        .catch((error)=>{
            setLoader(false)
            console.log(error)
        })
    }

    const filter = (e, type) => {
        if(type == "name") {
            const keyword = e.target.value;

            if (keyword !== '') {
            const results = source.filter((user) => {
                return user.productname.toLowerCase().startsWith(keyword.toLowerCase());
                // Use the toLowerCase() method to make it case-insensitive
            });
                setSource(results);
            } else {
                getData();
            // If the text field is empty, show all users
            }
            setSearchname(keyword);
        } else{
            const keyword = e.target.value;

            if (keyword !== '') {
            const results = source.filter((user) => {
                return user.code.toLowerCase().startsWith(keyword.toLowerCase());
                // Use the toLowerCase() method to make it case-insensitive
            });
                setSource(results);
            } else {
                getData();
            // If the text field is empty, show all users
            }
            setSearchcode(keyword);
        }
};

const editClick =(id) => {
    setEditmodal(true)

    var token = localStorage.getItem("token")
    fetch(global.url + "api/Products/GetNewProduct?companyId=1&branchId=" + branchid, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
            console.log("GetNewProduct Response", responseJson)
            setCategorylist(responseJson.categorylist)
            setSubcategorylist(responseJson.subcategorylist)
    })

    .catch((error)=>{
        console.log(error)
    })

    fetch(global.url + "api/Products/GetProductByIdWithBranch?id=" + id + "&branchId=" + branchid, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
            console.log("GetProductById Response", responseJson)
            setCategory(responseJson.prdcatid)
            setAddedvalue(responseJson.addedvalue)
            setSubcategory(responseJson.subcategoryid)
            setFbsale(responseJson.bottlesaleonly)
            setUnit(responseJson.prdsubcatid)
            setPegsperbottle(responseJson.pegsperbottle)
            setmlperbottle(responseJson.mlperbottle)
            setBottlepercase(responseJson.bottlepercase)
            setPurchaserate(responseJson.prdpprice)
            setName(responseJson.prdname)
            setDescription(responseJson.prddescp)
            setCode(responseJson.prdcode)
            setPrintname(responseJson.prdprintname)
            setCost(responseJson.prdcost)
            setProductid(id)
            setWsbottle(responseJson.priceDetails.wsbottle)
            // setWspeg()
            setLocalbottle(responseJson.priceDetails.localbottle)
            setLocalpeg(responseJson.priceDetails.localpeg)
            // setAclocalbottle(responseJson.priceDetails.wsbottle)
            // setAclocalpeg(responseJson.priceDetails.wsbottle)
            setRoombottle(responseJson.priceDetails.roombottle)
            setRoompeg(responseJson.priceDetails.roompeg)
            setAcbottle(responseJson.priceDetails.acbottle)
            setAcpeg(responseJson.priceDetails.acpeg)
            setBarbottle(responseJson.priceDetails.barbottle)
            setBarpeg(responseJson.priceDetails.barpeg)
            setExecutivebottle(responseJson.priceDetails.exbottle)
            setExecutivepeg(responseJson.priceDetails.expeg)
            setCess(responseJson.priceDetails.cesspercase)
    })
    .catch((error)=>{
        console.log(error)
    })
}

const onSubmit = () => {
    console.log("array", array)
        var token = localStorage.getItem("token")
        var data = {
            "prdid": productid,
            "prdname": name,
            "prdcode": code,
            "prdprintname": printname,
            "prddescp": description,
            "prdimage": "",
            "prdblocked": false,
            "prdcatid": category,
            "prdsubcatid": unit,
            "prdcost": cost,
            "addedvalue" : branchid == 1021 ? addedvalue : "",
            "prdpprice": purchaserate,
            "pegsperbottle": pegsperbottle,
            "mlperbottle": mlperbottle,
            "bottlepercase": bottlespercase,
            "bottlesaleonly": fbsale,
            "allowedBranches": array,
            "priceDetails": {
                "PRICEID": 0,
                "PRODUCTID": 0,
                "UPDATEDBY": global.userid,
                "BRANCHID" : branchid,
                "BARPEG": barpeg,
                "BARBOTTLE": barbottle,
                "ACPEG": acpeg,
                "ACBOTTLE": acbottle,
                "EXPEG": executivepeg,
                "EXBOTTLE": executivebottle,
                "LOCALPEG": localpeg,
                "LOCALBOTTLE": localbottle,
                "WSBOTTLE": wsbottle,
                "UPDATEDON": "",
                "ROOMPEG" : roompeg,
                "ROOMBOTTLE": roombottle,
                "CODE": "",
                "PRODUCTNAME": "",
                "BOTTLEPERCASE": "",
                "PPRICE": "",
                "BottleSaleOnly": "",
                "StockBottle": "",
                "StockPeg": "",
                "CESSPERCASE" : cess
            },
            "selectedBranchId": branchid,
            "subcategoryid" : subcategory
        }
        fetch(global.url + "api/Products/SaveProduct", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
           console.log("itemsave response", responseJson)
           if(responseJson.error == false){
               alert(responseJson.message)
               window.location.reload()
           }
           else{
            alert(responseJson.message)
           }
          
        })
        .catch((error)=>{
            alert("username or password error")
            console.log(error)
        })
}
const formatDate = (datestring) => {
    function pad2(n) {
        return (n < 10 ? '0' : '') + n;
      }
      
      var date = new Date(datestring)
      var month = pad2(date.getMonth()+1);//months (0-11)
      var day = pad2(date.getDate());//day (1-31)
      var year= date.getFullYear();

      const timeString12hr = new Date(datestring)
      .toLocaleTimeString('en-US',
          {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
      );
    //   console.log("timeString12hr", timeString12hr)

      var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
      return formattedDate; //03/02/2021
}
    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>Price List</p> 
                <Row>
                    <Col xs={12}  className='columnborder'>
                        <div>
                            <Row>
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField value={searchname}
                                        onChange={(e) => filter(e, "name")} id="outlined-basic" label="Item Name" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField value={searchcode}
                                        onChange={(e) => filter(e, "code")} id="outlined-basic" label="Item Code" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={4} >
                                    <ReactToPrint
                                        trigger={() => <Button style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">Print</Button>}
                                        content={() => componentRef.current}
                                    />
                                </Col>
                            </Row>
                           
                        </div>
                    </Col> 
                </Row>
                {
                loader ?
                <div style={{display: 'flex', justifyContent: 'center'}}>
                <ThreeDots
                    height="50"
                    width="50"
                    color='#0d6efd'
                    ariaLabel='loading'
                /></div>:
                <div className='bulk-table'>
                    <TableContainer sx={{ maxHeight: 440 }}>
                    <div  className='section-to-print' ref={componentRef} style={{width: '95%'}}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead className="tableheaderexe">
                                <TableRow style={{height: 80}} >
                                    <TableCell colSpan={12}  style={{padding: 0}}>
                                        <Row>
                                            <img src={logo} style={{width: 100, position: 'absolute', top: 0}}/>
                                            
                                            <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                                            <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                                            </Col>
                                            <p className='printdate' style={{ position: 'absolute', left: '85%'}}>{formatDate(new Date)}</p>
                                        </Row>
                                    </TableCell>
                                 </TableRow>
                                 <TableRow>
                                    
                                    <TableCell colSpan={12} style={{padding: 0}}>
                                        <div style={{display: 'flex', justifyContent: 'center', fontWeight: 600}} className='captiontable'>
                                            PRICE LIST
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '10%'}}>
                                    Code
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '35%'}}>
                                    Item
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '10%'}}>
                                     Non-AC Bar Bot
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '10%'}}>
                                     Non-AC Bar Peg
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '10%'}}>
                                     AC Bar Bot
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '15%'}}>
                                     AC Bar Peg
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '15%'}}>
                                     AC Bot
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '15%'}}>
                                     AC Peg
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '15%'}}>
                                     WS Bot
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '15%'}}>
                                     Local Bot
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '15%'}}>
                                     Local Peg
                                    </TableCell>
                                    <TableCell className='tablecellPrint noprint' style={{fontWeight: 'bold', width: '15%'}}>
                                    Edit
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {source
                                .map((row) => {
                                return (
                                    
                                    <TableRow className='tablerowPrint'  tabIndex={-1} key={row.code}>
                                    <TableCell className='tablecellPrint' >
                                    {row.code}
                                    </TableCell>
                                    <TableCell className='tablecellPrint'  >
                                    {row.productname}
                                    </TableCell>
                                    <TableCell className='tablecellPrint' >
                                    {row.barbottle}
                                    </TableCell>
                        
                                    <TableCell className='tablecellPrint' >
                                    {row.barpeg}
                                    </TableCell>
                                    <TableCell  className='tablecellPrint' >
                                    {row.acbottle}
                                    </TableCell>
                                    <TableCell  className='tablecellPrint' >
                                    {row.acpeg}
                                    </TableCell>
                                    <TableCell  className='tablecellPrint' >
                                    {row.roombottle}
                                    </TableCell>
                                    <TableCell  className='tablecellPrint' >
                                    {row.roompeg}
                                    </TableCell>
                                    <TableCell  className='tablecellPrint' >
                                    {row.wsbottle}
                                    </TableCell>
                                    <TableCell  className='tablecellPrint' >
                                    {row.localbottle}
                                    </TableCell>
                                    <TableCell  className='tablecellPrint' >
                                    {row.localpeg}
                                    </TableCell>
                                    <TableCell style={{textAlign: 'right'}} className='tablecellPrint noprint' >
                                    <Button onClick={() => editClick(row.productid
                                            )} variant="contained" style={{marginTop: 10, backgroundColor: 'green', marginRight: 10}}>Edit</Button>
                                    </TableCell>
                                   
                                </TableRow>
                                );
                                })}
                            </TableBody>
                            
                        </Table>
                        </div>
                        </TableContainer>
                        {/* <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={source.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        /> */}
                </div>
                }  
            </Paper>
            <Modal
                    size="lg"
                    show={editmodal}
                    onHide={() => setEditmodal(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                    >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                    <Row>
                    <Col xs={12} md={3}>
                        <p style={{fontSize: 20}}>Liquor type</p>
                    </Col>
                    <Col xs={12} md={3}>
                        <FormControl fullWidth sx={{ width: '100%' }}>
                            <InputLabel id="demo-simple-select-label">Categories</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={category}
                            label="Age"
                            onChange={categoryChange}
                            size="small"
                            >
                                {
                                    categorylist.map((i) => {
                                return(
                                
                                        <MenuItem value={i.catid}>{i.catname}</MenuItem>
                                        )
                                    })
                                }
                           
                            </Select>
                        </FormControl>
                    </Col>
                    {category == 4 ?
                    <Col xs={12} md={3}>
                        <FormControl fullWidth sx={{width: '100%' }}>
                            <InputLabel id="demo-simple-select-label">Sub Categories</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={subcategory}
                            label="Age"
                            onChange={subcategoryChange}
                            size="small"
                            >
                                {
                                    subcategorylist.map((i) => {
                                    return(
                                    
                                            <MenuItem value={i.subid}>{i.subcatname}</MenuItem>
                                            )
                                        })
                                }
                   
                            </Select>
                        </FormControl>
                    </Col> : null}
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField value={name} onChange={(e) => setName(e.target.value)} id="outlined-basic" label="Item name" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField value={code} onChange={(e) => setCode(e.target.value)} id="outlined-basic" label="Item Code" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField value={printname} onChange={(e) => setPrintname(e.target.value)} id="outlined-basic" label="Item Printname" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField value={description} onChange={(e) => setDescription(e.target.value)} id="outlined-basic" label="Item Description" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField value={pegsperbottle} onChange={(e) => setPegsperbottle(e.target.value)} id="outlined-basic" label="Pegs Per Bottle" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" label="ML Per Bottle" value={mlperbottle} onChange={(e) => setmlperbottle(e.target.value)} variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                </Row>
                <Row>
                <Col xs={12} md={3}>
                        <FormControl fullWidth sx={{mt: 2,width: '100%' }}>
                            <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={unit}
                            label="Age"
                            onChange={unitChange}
                            size="small"
                            >
                            <MenuItem value={0}>FFF</MenuItem>
                            <MenuItem value={1}>LFS</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3}>
                        <FormControl fullWidth sx={{mt: 2,width: '100%' }}>
                            <InputLabel id="demo-simple-select-label">Full Bottle Sale</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={fbsale}
                            label="Age"
                            onChange={fbsaleChange}
                            size="small"
                            >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    {/* <Col xs={12} md={6} className="tablebranch">
                        <Table  responsive bordered >
                                <thead>
                                    <tr>
                                        <th>Branch</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        branchlist.map((i, k) => {
                                            return(
                                                <tr >
                                                    <td>{i.brname}</td>
                                                    <td>
                                                        <FormControl sx={{  width: '100%' }}>
                                                            <FormControlLabel control={
                                                            <Checkbox onChange={e => blockChange(e, i.brid)} />
                                                            } label="" />
                                                        </FormControl>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    
                                </tbody>
                        </Table>
                    </Col> */}
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" label="Bottles per case" value={bottlespercase} onChange={(e) => setBottlepercase(e.target.value)} variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" label="Purchase rate per case" value={purchaserate} onChange={(e) => setPurchaserate(e.target.value)} variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    { branchid == 1021 ? 
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField type="number" id="outlined-basic" label="Added Value" value={addedvalue} onChange={(e) => setAddedvalue(e.target.value)} variant="outlined"  size="small"/>
                        </FormControl>
                    </Col> : null }
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" label="Tax %" value={cost} onChange={(e) => setCost(e.target.value)} variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" label="CESS Per Case" value={cess} onChange={(e) => setCess(e.target.value)} variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                </Row>
                
                    


                    <Row style={{marginTop: 20, margin:10}}>
                    <Table responsive bordered className="section-to-print">
                        <thead>
                            <tr>
                                <th>BILLING RATE</th>
                                <th>CALC %</th>
                                <th>BOTTLE</th>
                                <th>CALC %</th>
                                <th>PEG</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <td>W/S</td>
                                <td>
                                    <FormControl>
                                        <TextField onKeyPress={handleKeyPress} id="outlined-basic"  value={0.00}   label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={wsbottle}  onChange={(e) => setWsbottle(e.target.value)} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={0.00} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={wspeg}  onChange={(e) => setWspeg(e.target.value)} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                            </tr>
                            <tr  >
                                <td>LOCAL</td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={0.00} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={localbottle}  onChange={(e) => setLocalbottle(e.target.value)} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td> 
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={0.00} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={localpeg}  onChange={(e) => setLocalpeg(e.target.value)} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                            </tr>
                            <tr >
                                <td>AC LOCAL</td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={0.00} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={aclocalbottle}  onChange={(e) => setAclocalbottle(e.target.value)} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td> 
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={0.00} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={aclocalpeg}  onChange={(e) => setAclocalpeg(e.target.value)}  label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                            </tr>
                            <tr  >
                                <td>ROOM</td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={0.00} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={roombottle}  onChange={(e) => setRoombottle(e.target.value)} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td> 
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={0.00} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={roompeg}  onChange={(e) => setRoompeg(e.target.value)} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                            </tr>
                            <tr >
                                <td>A/C</td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={0.00} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={acbottle}  onChange={(e) => setAcbottle(e.target.value)} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td> 
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={0.00} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={acpeg}  onChange={(e) => setAcpeg(e.target.value)} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                            </tr>
                            <tr >
                                <td>BAR</td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={0.00} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={barbottle}  onChange={(e) => setBarbottle(e.target.value)} variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td> 
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={0.00} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic"value={barpeg}  onChange={(e) => setBarpeg(e.target.value)} variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                            </tr>
                            <tr >
                                <td>EXECUTIVE</td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={0.00} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={executivebottle}  onChange={(e) => setExecutivebottle(e.target.value)} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td> 
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={0.00} label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                                <td>
                                    <FormControl>
                                        <TextField  onKeyPress={handleKeyPress} id="outlined-basic" value={executivepeg}  onChange={(e) => setExecutivepeg(e.target.value)}  label="" variant="outlined"  size="small"/>
                                    </FormControl>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Row>
                <Row>
                <Col xs={12} md={6}>
                        <Button onClick={() => onSubmit()} variant="contained" style={{marginTop: 30, backgroundColor: '#03a5fc', marginRight: 10, width: 200, color: '#fff'}}>Save</Button>
                    </Col>
                </Row>
                    </Modal.Body>
                </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(Pricelist);