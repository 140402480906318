import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Form, Table} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { connect } from "react-redux";
import Checkbox from '@mui/material/Checkbox';

function Maintenancemaster({branchid}){

    const [categorylist, setCategorylist] = React.useState([]);
    const [category, setCategory] = React.useState("");
    const [departmentlist, setDepartmentlist] = React.useState([]);
    const [department, setDepartment] = React.useState("");
    const [file, setFile] = React.useState();
    const [name, setName] = React.useState("");
    const [description, setDescription] = React.useState("");

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();

    const [date1, setDate1] = React.useState(year + '-' + month + '-' + date);

    const [tablerow, setTablerow] = useState([{
        itemdtlid: 0,
        itemid: 0,
        itemcode: "",
        purchasedate: "", 
        waranteeavailable: "",
        waranteedate: "",
        comments: "",
        serialno: "",
        imagebasE64: "",
        imageurl: ""

    }]);
    


    const categoryChange = (event) => {
        setCategory(event.target.value);
    };
    const departmentChange = (event) => {
        setDepartment(event.target.value);
    };


    useEffect(() => {
        getData()
    }, [branchid]);

    const getData = () => {
        var token = localStorage.getItem("token")
        console.log("token", token)
        fetch(global.url + "api/ServiceEntry/NewItemMaster?branchId= " + branchid, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("items", responseJson)
             setCategorylist(responseJson.categories)
             setDepartmentlist(responseJson.departments)
        })

        .catch((error)=>{
            console.log(error)
        })
    }

    const onSave = () => {
        var token = localStorage.getItem("token")
        const data = {
            "itemid": "",
            "itemname": name,
            "itemdescription": description,
            "departid": department,
            "catid": category,
            "branchid": branchid,
            "itemDetails": tablerow,
          }

        console.log("save data", data)

        fetch(global.url + "api/ServiceEntry/SaveItemMaster", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("responseJson", responseJson)
            if(responseJson) {
                alert("Success")
                window.location.reload()
            } 
        })
        .catch((error)=>{
            alert("error occured")
            console.log(error)
        })
    }

    const addRow = () => {
        var input = [...tablerow]
        setTablerow([...tablerow, 
            {
                itemdtlid: 0,
                itemid: "",
                itemcode: "",
                purchasedate: "", 
                waranteeavailable: 0,
                waranteedate: "",
                comments: "",
                serialno: "",
                imagebasE64: "",
                imageurl: "" 
            }
        ])
    }

    const codeOnChange = (event, key) => {
        var textArray1 = [...tablerow]
        textArray1[key].itemcode = event.currentTarget.value;
        textArray1[key].purchasedate = date1;
        textArray1[key].waranteedate = date1;
        setTablerow(textArray1)
    }

    const serialOnChange = (event, key) => {
        var textArray1 = [...tablerow]
        textArray1[key].serialno = event.currentTarget.value;
        setTablerow(textArray1)
    }

    const guaranteOnChange = (event, key) => {
        var textArray1 = [...tablerow]
        textArray1[key].waranteeavailable = event.currentTarget.value
        setTablerow(textArray1)
    }

    const batchfile = (e, key) => {
        // console.log("e.target.files[0]", e.target.files)
        let selectedFile = e.target.files;
            let file = null;
            let fileName = "";
            //Check File is not Empty
            if (selectedFile.length > 0) {
                // Select the very first file from list
                let fileToLoad = selectedFile[0];
                fileName = fileToLoad.name;
                // FileReader function for read the file.
                let fileReader = new FileReader();
                // Onload of file read the file content
                fileReader.onload = function(fileLoadedEvent) {
                    file = fileLoadedEvent.target.result;
                    // Print data in console
                   
                    const regex = /data:.*base64,/
                    // console.log(file.replace(regex,""));
                    setFile(file.replace(regex,""))
                    var textArray1 = [...tablerow]
                    textArray1[key].imagebasE64 = file.replace(regex,"");
                    textArray1[key].imageurl = file.replace(regex,"");
                    setTablerow(textArray1)
                };
                // Convert data to base64
                fileReader.readAsDataURL(fileToLoad);
                console.log("fileReader", fileReader);
            }
      }
      const blockChange = (e,index) => {
        let isChecked = e.target.checked;
        console.log("isChecked", isChecked)
        if(isChecked == true) {
            var input  = [...tablerow]
            input[index].waranteeavailable = 1
            setTablerow(input)
        }

        else{
            var input  = [...tablerow]
            input[index].waranteeavailable = 0
            setTablerow(input)
        }
    }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold'}}>Item master</p> 
                <Row>
                    <Col xs={12} md={3}>
                        <FormControl fullWidth sx={{ width: '100%' }}>
                            <InputLabel id="demo-simple-select-label">Categories</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={category}
                            label="Age"
                            onChange={categoryChange}
                            size="small"
                            >
                                {
                                    categorylist.map((i, k) => {
                                        return(
                                            <MenuItem value={i.itemcatid}>{i.cateoryname}</MenuItem>
                                        )
                                    
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Col>

                    <Col xs={12} md={3}>
                        <FormControl fullWidth sx={{width: '100%' }}>
                            <InputLabel id="demo-simple-select-label">Department</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={department}
                            label="Age"
                            onChange={departmentChange}
                            size="small"
                            >
                                {
                                    departmentlist.map((i, k) => {
                                        return(
                                            <MenuItem value={i.departid}>{i.deparmentname}</MenuItem>
                                        )
                                    
                                    })
                                }
                   
                            </Select>
                        </FormControl>
                    </Col>
                    <Col sm={6} lg={3}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField
                                id="datetime-local"
                                label="Date"
                                disabled
                                type="date"
                                defaultValue={year + '-' + month + '-' + date}
                                sx={{ width: '100%' }}
                                InputLabelProps={{
                                shrink: true,
                                }}
                                onChange={(newValue) => {
                                    setDate1(newValue.target.value);
                                }}
                            />
                        </FormControl>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField value={name} onChange={(e) => setName(e.target.value)} id="outlined-basic" label="Item name" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField value={description} onChange={(e) => setDescription(e.target.value)} id="outlined-basic" label="Item Description" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    
                </Row>
                <Row></Row>
                    
                <Row style={{marginTop: 20}}>
                    <Table responsive bordered className="section-to-print">
                        <thead>
                            <tr>
                                <th>Item code</th>
                                <th>Serial No</th>
                                <th>Guarantee available</th>
                                <th>Upload</th>
                               
                            </tr>
                        </thead>
                        <tbody>
                            {tablerow.map((item, index) => {
                                return(
                                    <tr>
                                        <td className='rowstyle'><input autocomplete="off" value={item.itemcode} onChange={(event) => codeOnChange(event, index)}  type="text" name="query" /></td>
                                        <td className='rowstyle'><input autocomplete="off" value={item.serialno} onChange={(event) => serialOnChange(event, index)} type="text" name="query" /></td>
                                        <td className='rowstyle'>
                                            <FormControl sx={{  width: '100%' }}>
                                                <FormControlLabel control={
                                                <Checkbox checked={item.waranteeavailable == 0 ? false : true} onChange={e => blockChange(e, index)} />
                                                } label="" />
                                            </FormControl>    
                                        </td>
                                        <td className='rowstyle'><input autocomplete="off" type="file"  onChange={(e) => batchfile(e, index)}  name="query" /></td>     
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Row>
                <Row>  
                    <Col xs={6} >
                        <ul className="list-inline">
                            <li><Button onClick={() => addRow()} variant="outlined" style={{marginTop: 30}}>Add New</Button></li>
                        </ul>
                    </Col>
                </Row>
                <Col xs={12} md={6}>
                        <Button onClick={onSave} variant="contained" style={{marginTop: 30, backgroundColor: '#03a5fc', marginRight: 10, width: 200, color: '#fff'}}>Save</Button>
                </Col>
                
            </Paper>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
    };
};

export default connect(mapStateToProps)(Maintenancemaster);