import React, {useState, useEffect} from 'react'
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { connect } from "react-redux";

const columns = [
    { id: '1', label: 'Particulars', minWidth: 50,span: false },
    { id: '2', label: 'Amount', minWidth: 50,span: false },
  ];


function ProfitLoss({branchid}){
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [source, setSource] = React.useState([]);

    const [counterlist, setCounterlist] = React.useState([]);
    const [counter, setCounter] = React.useState('');

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();

    const [date1, setDate1] = React.useState(year + '-' + month + '-' + date);
    const [date2, setDate2] = React.useState(year + '-' + month + '-' + date);
    const [ledger, setLedger] = React.useState('');
    const [mode, setMode] = React.useState('');
    const [ledglist, setLedg] =  React.useState([]);
    const [debittotal, setDebittotal] = React.useState(0);
    const [credittotal, setCredittotal] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
  
      const handleChangeRowsPerPage = (event) => {
          setRowsPerPage(+event.target.value);
          setPage(0);
        };

    const modeChange = (event) => {
        setMode(event.target.value);
      };

    useEffect(() => {
        getData()
        getLedger()
    }, [branchid]);

      const counterChange = (event) => {
        setCounter(event.target.value)
      }


    const legerChange = (event) => {
        setLedger(event.target.value)
      }

      const getLedger = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "GetCashLedgerList?branchId=" + branchid, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("ledger Response", responseJson)
             setLedg(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }


    const getData = () => {
        var token = localStorage.getItem("token")
        var data = {
            "companyId": 1,
            "branchId": branchid,
            "fromDate": date1,
            "toDate": date2,
            "createdBy": "",
            "billno": "",
            "counterId": counter,
            "userId": global.userid,
            "soldItemsOnly": "",
            "accId": ""
          }
        console.log("paymentdata", data)
        fetch(global.url + "api/ProfitLoss/GetProfitLossAccount", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("GetProfitLossAccount", responseJson)
            if(responseJson.message) {

            }
            else{
                setSource(responseJson)
            }
            
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const filterData = () => {
            getData()
    }

    useEffect(() => {
        getTotalamount()
    }, [source]);

    const getTotalamount = () => {
        var dtotalAmount = 0;
        var ctotalAmount = 0;
        for (var i = 0; i < source.length; i++) {
            dtotalAmount += parseFloat(source[i].dtdebit)
            ctotalAmount += parseFloat(source[i].dtcredit)
        }
       setDebittotal(dtotalAmount.toFixed(2))
       setCredittotal(ctotalAmount.toFixed(2))
    }


    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>Profit And Loss</p> 
                <Row>
                    <Col xs={12}  className='columnborder'>
                        <div>
                            <Row>
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ mt: 3, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="From Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setDate1(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ mt: 3, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="To Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setDate2(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col sm={6} lg={2}>
                                        <FormControl fullWidth sx={{width: '100%', mt: 3 }}>
                                            <InputLabel id="demo-simple-select-label">Counter</InputLabel>
                                            <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={counter}
                                            label="Age"
                                            onChange={(event) => counterChange(event)}
                                            size="small"
                                            >
                                                <MenuItem value="1">Bar</MenuItem>
                                                <MenuItem value="2">Lodge</MenuItem>
                                                <MenuItem value="3">Restaurant</MenuItem>
                                
                                            </Select>
                                    </FormControl>
                                </Col>                             
                                <Col xs={12} md={2}>
                                    <div style={{justifyContent: 'center', display: 'flex'}}>
                                        <Button onClick={() => filterData()} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">Search</Button>
                                    </div>
                                </Col>
                                <Col xs={12} md={2}>
                                    <div style={{ justifyContent: 'center', display: 'flex'}}>
                                        <Button style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">print</Button>
                                    </div>
                                </Col>
                            </Row>
                           
                        </div>
                    </Col> 
                </Row>
                <div style={{height: 2, width: '100%', backgroundColor: '#d3d3d3', marginTop: 30}}></div>
                <div className='bulk-table'>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                                    colSpan={column.span == true ? 2: 1}
                                >
                                    {column.label}
                                    
                                </TableCell>
                                ))}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                            source
                                .map((row, index) => {
                                return (
                                    <TableRow  tabIndex={-1} key={row.code}>
                                        <TableCell>
                                        {/* {row.accname} */}
                                        </TableCell>
                                        <TableCell>
                                    
                                        </TableCell>
                                        
                                    </TableRow>
                                );
                                })}
                                <TableRow  tabIndex={-1}>
                                        <TableCell colSpan={6}>
                                        Total
                                        </TableCell>
                                        <TableCell>
                                        {total}
                                        </TableCell>
                                    </TableRow>
                            </TableBody>
                        </Table>
                        </TableContainer>
                        
                    </div>
            </Paper>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
    };
};

export default connect(mapStateToProps)(ProfitLoss);