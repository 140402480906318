import React, {useState, useEffect, useRef} from 'react';
import Paper from '@mui/material/Paper';
import { Table , Row, Col, Modal, Container} from "react-bootstrap";
import TextField from '@mui/material/TextField';
import {useHistory, useLocation} from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import * as IconName from "react-icons/bs";
import * as GI from "react-icons/gi";
import * as BI from "react-icons/bi";
import * as RI from "react-icons/ri";
import { connect } from "react-redux";
import ReactToPrint from 'react-to-print';
import Mousetrap from 'mousetrap';
import 'mousetrap-global-bind';
import { shortcutKeys } from './shortcutKeysConfig.js';
import { ThreeDots, Rings  } from  'react-loader-spinner';
import {
	getAllProductCounterSales
  } from "../../redux/Shopping/shopping-actions";
import Searchlist from "../../components/Searchlist/searchlist";
import accessimgae from '../../assets/images/access.png'

function Countersales({branchid, branchdetails,productlist, getAllProductCounterSales}) {
    let history = useHistory();
    const [modal, setModal] = useState(false);
    const [tableloader, setTableloader] = useState(false);
    const [prodcode, setProdcode] = useState("");
    const componentRef = useRef();
    const componentRef1 = useRef();
    const componentRef2 = useRef();
    const componentRef3 = useRef();
    const componentRef4 = useRef();
    const [refresh, setRefresh] = React.useState(false);
    const [waiterlist, setWiaterlist] = React.useState([]);
    const [happyhr, setHappyhr] = React.useState(false);
    const [kotprintmodal, setKotPrintmodal] = React.useState(false);
    const [otnum, setOtnum] = React.useState(false);
    const [qtynw, setQtynw] = React.useState(false);
    const [splitnw, setSplitnw] = React.useState(false);

    const [tablerow, setTablerow] = React.useState([{
        dtid: 0,
        vid: "",
        dtprdid: "",
        dtprdbatchid: "",
        dtunitid: 0,
        dtqty: 1,
        dtprice: 0,
        dtamount: 0,
        dtdiscper: "",
        dtdiscount: "",
        dtgross: "",
        dtsgst: "",
        dtcgst: "",
        dtigst: "",
        dttax: "",
        dtsgstper: "",
        dtcgstper: "",
        dtigstper: "",
        dttaxper: "",
        dttaxid: "",
        dttotal: "",
        dtlremarks: "",
        dtgdnid: "",
        dtcost: "",
        dtsarate: 0,
        dtmrp: "",
        batchcode: "",
        barcode: "",
        procode: "",
        proname: "",
        localname: "",
        unitname: "",
        gdnname: "",
        qtyshow: 1
      }]);
      const inputRef = useRef();

      const [user, setUser] = useState("");
      const [botno, setBotno] = useState("");
      const [lastbillno, setLastbillno] = useState("");
      const [headerLoader, setHeaderLoader] = useState(false);
      
      const [waitercode, setWaitercode] = useState();
      const [table, setTable] = useState("");
      const [party, setParty] = useState("1");
      const [formid, setFormid] = React.useState("");
      const [docno, setDocno] = React.useState("");
      const [docnum, setDocnum] = React.useState("");
      const [accid, setAccid] = React.useState("");
      const [docdate, setDocdate] = React.useState("");
      const [docdate1, setDocdate1] = React.useState("");
      const [accname, setAccname] = React.useState("");
      const [subtotal, setSubtotal] = React.useState(0);

      const [counter, setCounter] = React.useState("");
      const [countername, setCountername] = React.useState("");
      const [tableid, setTableid] = React.useState("");
      const [roomid, setRoomid] = React.useState("");

      const [roomnumber, setRoomnumber] = React.useState("");

      const [loader, setLoader] = React.useState(false);
      const [otloader, setOtloader] = React.useState(false);
      const [billloader, setBillloader] = React.useState(false);
      const [makebill, setMakebill] = React.useState({});
      const [makebilllast, setMakebilllast] = React.useState({});

      const [makebilltotal, setMakebilltotal] = React.useState(0);

      const [printmodal, setPrintmodal] = React.useState(false);
      const [vid, setVid] = React.useState(0);
    const [said, setSaid] = React.useState(0);
    const [splitdetails, setSplitdetails] = React.useState();
    const [splitpopup, setSplitpopup] = React.useState(false);

    const delay = ms => new Promise(res => setTimeout(res, ms));
    const [productname, setProductname] = useState("")
    const hasResultsProduct = productname.length > 0;
    const [selectedIndex, setSelectedindex] = React.useState("")
    const location = useLocation();
    const [access,  setAccess] = useState(false)

    useEffect(() => {
        const formname = JSON.parse(localStorage.getItem("formname"));
        console.log("path", location.pathname)
        var userid1 = localStorage.getItem("userid")
        console.log("formname", formname)
        if(userid1 != 1) {
          const conditioncheck = formname.some(function(o){return o["formname"] === location.pathname})
          console.log("conditioncheck", conditioncheck)
          setAccess(conditioncheck)
        }
        else{
          setAccess(true)
        }
    }, []);

    useEffect(() => {
        console.log("tablerow", tablerow)
    }, [tablerow]);

    function handleKeyPress(event) {
        if (!/\d/.test(event.key)) {
          event.preventDefault();
        }
      }


 useEffect(() => {
        if(refresh == true) {
            window.location.reload()
            setRefresh(false)
        }
    }, [refresh]);
    

    useEffect(() => {
        // setParty("")
        setTablerow([{
            dtid: 0,
            vid: "",
            dtprdid: "",
            dtprdbatchid: "",
            dtunitid: 0,
            dtqty: 1,
            dtprice: 0,
            dtamount: 0,
            dtdiscper: "",
            dtdiscount: "",
            dtgross: "",
            dtsgst: "",
            dtcgst: "",
            dtigst: "",
            dttax: "",
            dtsgstper: "",
            dtcgstper: "",
            dtigstper: "",
            dttaxper: "",
            dttaxid: "",
            dttotal: "",
            dtlremarks: "",
            dtgdnid: "",
            dtcost: "",
            dtsarate: 0,
            dtmrp: "",
            batchcode: "",
            barcode: "",
            procode: "",
            proname: "",
            localname: "",
            unitname: "",
            gdnname: "",
            qtyshow: 1
          }])
          setSaid(0)
          setVid(0)
    }, [waitercode, table]);

    useEffect(() => {
        Mousetrap.bindGlobal(shortcutKeys.otprint, () => {
            onSave()
          });
  
          /* To alert the 'Hello World' */
          Mousetrap.bindGlobal(shortcutKeys.makebill, () => {
            onMakebill()
          });

    }, []);

    const  prodcodeChange = (event, key) => {
        console.log("event.currentTarget.value", event.currentTarget.value)
        var textArray1 = [...tablerow]
        textArray1[key].procode = event.currentTarget.value;
       
        setTablerow(textArray1)
        setSelectedindex(key)
        setProductname(event.currentTarget.value)
    }
      const handleOnChange = (procode,key) => {
      
        var textArray1 = [...tablerow]
        setTablerow(textArray1)
        getSearch(procode, key)
    }

    useEffect(() => {
        getTotalamount()
    }, [tablerow]);

    const getTotalamount = () => {
        var totalAmount = 0;
        for (var i = 0; i < tablerow.length; i++) {
            totalAmount += parseFloat(tablerow[i].dtamount)
        }
       setSubtotal(totalAmount.toFixed(2))
    }

    const formatDate = (datestring, type) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
          

          if(type == "time") {
            var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          }
          else{
            var formattedDate =  day+"/"+month+"/"+year
          }

          
          return formattedDate; //03/02/2021
    }

    useEffect(() => {
        getMakebilltotal()
    }, [makebilllast]);

    const getMakebilltotal = () => {
        var totalAmount = 0;
        for (var i = 0; i < makebilllast?.details?.length; i++) {
            totalAmount += parseFloat(makebilllast?.details[i]?.dtamount)
        }
       setMakebilltotal(totalAmount.toFixed(2))
    }

    useEffect(() => {
        getNewsale()
        getDocDate()
    }, []);

    const listClickname = (text, key) => {
        handleOnChange(text, key)
    }

    const getNewsale = () => {
        setHeaderLoader(true)
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Sales/NewSale?branchId=" + branchid, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("category view Response", responseJson)
             setFormid(responseJson.saformid)
             setDocno(responseJson.kot)
             setDocnum(responseJson.kotnum)
             setAccid(responseJson.saaccid)
            //  setDocdate(responseJson.docdate)
             setAccname(responseJson.saaccname)
             setHappyhr(responseJson.happyHour)
             setLastbillno(responseJson.lastbillno)
             setHappyhr(responseJson.happyHour)
             setWiaterlist(responseJson.waiterList)
             setHeaderLoader(false)
        })
        .catch((error)=>{
            setHeaderLoader(false)
            console.log(error)
        })
    }

    const getDocDate = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/CounterStock/GetTransactionDate?branchId=" + branchid, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("get docdate", responseJson)
             setDocdate(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }    

    const getSearch = (text, key) => {
        if(counter == "" && roomid == ""){
            return alert("Select Table")
        }
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Products/GetProductByCode?branchId=" + branchid +"&code=" + text, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("code search Response", responseJson)
             if(responseJson.error != true){
                    setQtynw(true)
                    var input = [...tablerow] 
                    input[key].procode = responseJson.code;
                    input[key].proname = responseJson.productname; 
                    input[key].dtprdid = responseJson.productid;
                    input[key].acpeg = responseJson.acpeg;
                    input[key].barpeg = responseJson.barpeg;
                    input[key].acbottle = responseJson.acbottle;
                    input[key].barbottle = responseJson.barbottle;
                    input[key].localbottle = responseJson.localbottle;
                    input[key].localpeg = responseJson.localpeg;
                    input[key].exbottle = responseJson.exbottle;
                    input[key].expeg = responseJson.expeg;
                    input[key].dtqty = 0;
                    
                    input[key].stockBottle = responseJson.stockBottle;
                    input[key].stockPeg = responseJson.stockPeg;
                    // input[key].bottlepercase = responseJson.bottlepercase
                    
                    // input[key].dtprice = responseJson.pprice;
                    // input[key].dtamount = responseJson.pprice;
                    // input[key].dttotal = responseJson.pprice;
                    
                    // if(responseJson.bottleSaleOnly == true) {
                    //     input[key].dtunitid = 2;
                    //     unitChange(2, key, responseJson.acpeg, responseJson.barpeg, responseJson.acbottle, responseJson.barbottle, responseJson.stockBottle, responseJson.stockPeg, responseJson.productid)
                    // }
                    // else{
                    //     input[key].dtunitid = 1;
                    //     unitChange(1, key, responseJson.acpeg, responseJson.barpeg, responseJson.acbottle, responseJson.barbottle, responseJson.stockBottle, responseJson.stockPeg, responseJson.productid)
                    // }
                    if(responseJson.bottleSaleOnly == true) {
                        input[key].dtunitid = 2;
                        if(countername == "AC BAR"){
                            console.log("AC BAR1")
                            input[key].dtamount = 0
                            input[key].dtsarate = responseJson.acbottle
                            input[key].dtprice = responseJson.acbottle
                        }
                        else if(countername == "BAR"){
                            console.log("BAR2")
                            input[key].dtamount = 0
                            input[key].dtsarate = responseJson.barbottle
                            input[key].dtprice = responseJson.barbottle
                        } else if(countername == "LOCAL"){
                            console.log("LOCAL")
                            input[key].dtamount = 0
                            input[key].dtsarate = responseJson.localbottle
                            input[key].dtprice = responseJson.localbottle
                        }
                        else if(countername == "EXECUTIVE"){
                            console.log("EXECUTIVE")
                            input[key].dtamount = 0
                            input[key].dtsarate = responseJson.exbottle
                            input[key].dtprice = responseJson.exbottle
                        }
                    }
                    else{
                        input[key].dtunitid = 1;
                        if(countername == "AC BAR"){
                            console.log("AC BAR2")
                            input[key].dtamount = 0
                            input[key].dtsarate = responseJson.acpeg
                            input[key].dtprice = responseJson.acpeg
                        }
                        else if(countername == "BAR"){
                            console.log("BAR1")
                            input[key].dtamount = 0
                            input[key].dtsarate = responseJson.barpeg
                            input[key].dtprice = responseJson.barpeg
                        }
                        else if(countername == "LOCAL"){
                            console.log("LOCAL")
                            input[key].dtamount = 0
                            input[key].dtsarate = responseJson.localpeg
                            input[key].dtprice = responseJson.localpeg
                        }
                        else if(countername == "EXECUTIVE"){
                            console.log("EXECUTIVE")
                            input[key].dtamount = 0
                            input[key].dtsarate = responseJson.expeg
                            input[key].dtprice = responseJson.expeg
                        }
                    }
                    
                    console.log("input", input)
                    setTablerow(input)
                    setProductname("")
             }
             
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const qtyChange = (event, dtprdid, dtqty, dtunitid, index) => {

        var textArray1 = [...tablerow]
        if(textArray1[index].dtunitid == 2) { 
            const rx_live = /^[+-]?\d*(?:[,]\d*)?$/;
            if (rx_live.test(event.target.value)) {
                var totalprice = (textArray1[index].dtsarate * event.target.value).toFixed(2)
                textArray1[index].dtqty = event.target.value
                textArray1[index].dtamount = totalprice
                // textArray1[index].dtprice = totalprice
                setTablerow(textArray1)
            }
        }
        else{
            const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
            if (rx_live.test(event.target.value)) {
                var totalprice = (textArray1[index].dtsarate * event.target.value).toFixed(2)
                textArray1[index].dtqty = event.target.value
                textArray1[index].dtamount = totalprice
                // textArray1[index].dtprice = totalprice
                setTablerow(textArray1)
            }
        }
        setTimeout(() => {
            stockCheck(dtprdid, event.target.value, dtunitid, index)
          }, 1000);
        
    }

    const unitChange = (event, index, acpeg, barpeg, acbottle, barbottle, stockBottle, stockPeg, dtprdid, localbottle, localpeg, exbottle, expeg ) => {
        if(counter == "" && roomid == ""){
            return alert("Select Table")
        }
        
        if(event == 2) {
            var token = localStorage.getItem("token")
            fetch(global.url + "api/Products/CheckOutOfStock?counterId=" + counter + "&branchId=" + branchid + "&productId=" + dtprdid + "&bottle=" + 1 + "&peg=" + 0, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("CheckOutOfStock2", responseJson)
                if(responseJson == 0){
                    alert("Out of stock")
                    console.log("index", index)
                    var input = [...tablerow]
                    input.splice(index, 1)
                    setTablerow(input)
                }
                else{
                    var input=[...tablerow]
                    input[index].dtunitid = event
                    console.log("event", event)
                    console.log("acpeg", acpeg)
                    console.log("barpeg", barpeg)
                    console.log("acbottle", acbottle)
                    console.log("barbottle", barbottle)
                    console.log("localbottle", localbottle)
                    console.log("localpeg", localpeg)
                    console.log("exbottle", exbottle)
                    console.log("expeg", expeg)

                    input[index].dtqty = 0
                    if(countername == "AC BAR" && event == 1){
                        console.log("AC BAR2")
                        input[index].dtamount = 0
                        input[index].dtsarate = acpeg
                        input[index].dtprice = acpeg
                    }
                    else if(countername == "BAR" && event == 1){
                        console.log("BAR1")
                        input[index].dtamount = 0
                        input[index].dtsarate = barpeg
                        input[index].dtprice = barpeg
                    }
                    else if(countername == "LOCAL" && event == 1){
                        console.log("LOCAL")
                        input[index].dtamount = 0
                        input[index].dtsarate = localpeg
                        input[index].dtprice = localpeg
                    }
                    else if(countername == "EXECUTIVE" && event == 1){
                        console.log("EXECUTIVE")
                        input[index].dtamount = 0
                        input[index].dtsarate = expeg
                        input[index].dtprice = expeg
                    }
                    else if(countername == "AC BAR" && event == 2){
                        console.log("AC BAR1")
                        input[index].dtamount = 0
                        input[index].dtsarate = acbottle
                        input[index].dtprice = acbottle
                    }
                    else if(countername == "BAR" && event == 2){
                        console.log("BAR2")
                        input[index].dtamount = 0
                        input[index].dtsarate = barbottle
                        input[index].dtprice = barbottle
                    }
                    else if(countername == "LOCAL" && event == 2){
                        console.log("LOCAL")
                        input[index].dtamount = 0
                        input[index].dtsarate = localbottle
                        input[index].dtprice = localbottle
                    }
                    else if(countername == "EXECUTIVE" && event == 2){
                        console.log("EXECUTIVE")
                        input[index].dtamount = 0
                        input[index].dtsarate = exbottle
                        input[index].dtprice = exbottle
                    }
                    document.getElementById("qty" + index).focus()
                    // input[index].dtunitid = event
                    setTablerow(input)
                }
                
            })
            .catch((error)=>{
                console.log(error)
            })
        }

        if(event == 1) {
            var token = localStorage.getItem("token")
            fetch(global.url + "api/Products/CheckOutOfStock?counterId=" + counter + "&branchId=" + branchid + "&productId=" + dtprdid + "&bottle=" + 0 + "&peg=" + 1, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("CheckOutOfStock", responseJson)
                if(responseJson == 0){
                    alert("Out of stock")
                    console.log("index", index)
                    var input = [...tablerow]
                    input.splice(index, 1)
                    setTablerow(input)
                     
                }
                else{
                    var input=[...tablerow]
                    input[index].dtunitid = event
                    console.log("event", event)

                    console.log("acpeg", acpeg)
                    console.log("barpeg", barpeg)
                    console.log("acbottle", acbottle)
                    console.log("barbottle", barbottle)
                    


                    if(countername == "AC BAR" && event == 1){
                        console.log("AC BAR2")
                        input[index].dtamount = acpeg
                        input[index].dtsarate = acpeg
                        input[index].dtprice = acpeg
                    }
                    else if(countername == "BAR" && event == 1){
                        console.log("BAR1")
                        input[index].dtamount = barpeg
                        input[index].dtsarate = barpeg
                        input[index].dtprice = barpeg
                    }
                    else if(countername == "LOCAL" && event == 1){
                        console.log("LOCAL")
                        input[index].dtamount = localpeg
                        input[index].dtsarate = localpeg
                        input[index].dtprice = localpeg
                    }
                    else if(countername == "EXECUTIVE" && event == 1){
                        console.log("EXECUTIVE")
                        input[index].dtamount = expeg
                        input[index].dtsarate = expeg
                        input[index].dtprice = expeg
                    }
                    else if(countername == "AC BAR" && event == 2){
                        console.log("AC BAR1")
                        input[index].dtamount = acbottle
                        input[index].dtsarate = acbottle
                        input[index].dtprice = acbottle
                    }
                    else if(countername == "BAR" && event == 2){
                        console.log("BAR2")
                        input[index].dtamount = barbottle
                        input[index].dtsarate = barbottle
                        input[index].dtprice = barbottle
                    }
                    else if(countername == "LOCAL" && event == 2){
                        console.log("LOCAL")
                        input[index].dtamount = localbottle
                        input[index].dtsarate = localbottle
                        input[index].dtprice = localbottle
                    }
                    else if(countername == "EXECUTIVE" && event == 2){
                        console.log("EXECUTIVE")
                        input[index].dtamount = exbottle
                        input[index].dtsarate = exbottle
                        input[index].dtprice = exbottle
                    }
                    // input[index].dtunitid = event
                    setTablerow(input)
                }
                
            })
            .catch((error)=>{
                console.log(error)
            })
        }
        
    }

    const stockCheck = (dtprdid, dtqty, unitid, index) => {
        setQtynw(true)
        console.log("counter", counter)
        console.log("branchid", branchid)
        console.log("dtprdid", dtprdid)
        console.log("dtqty", dtqty)
        var token = localStorage.getItem("token")
        if(unitid == 2) {
            fetch(global.url + "api/Products/CheckOutOfStock?counterId=" + counter + "&branchId=" + branchid + "&productId=" + dtprdid + "&bottle=" +  dtqty + "&peg=" + 0, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                setQtynw(false)
                console.log("CheckOutOfStock1", responseJson)
                if(responseJson == 0){
                    alert("Out of stock")
                    console.log("index", index)
                    var input = [...tablerow]
                    input.splice(index, 1)
                    setTablerow(input)
                     
                }
                
            })
            .catch((error)=>{
                console.log(error)
            })
        }
        else{
            fetch(global.url + "api/Products/CheckOutOfStock?counterId=" + counter + "&branchId=" + branchid + "&productId=" + dtprdid + "&bottle=" + 0 + "&peg=" + dtqty, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                setQtynw(false)
                console.log("CheckOutOfStock2", responseJson)
                if(responseJson == 0){
                    alert("Out of stock")
                    console.log("index", index)
                    var input = [...tablerow]
                    input.splice(index, 1)
                    setTablerow(input)
                     
                }
                
            })
            .catch((error)=>{
                console.log(error)
            })
        }
            
    }

    const leavingWaiter = () => {
        var waiterIncluded = false
        for(var i=0; i< waiterlist.length; i++){
            if(waitercode == waiterlist[i].waiterid) {
                waiterIncluded = true
            }
            else{
                
            }
        }
        if(waiterIncluded == false){
            alert("Invalid waitercode")
            setWaitercode("")
        }
    }

    const onSave = () => {

      
        for (let i = 0; i < tablerow.length - 1; i++) {
            for (let j = i + 1; j < tablerow.length; j++) {
              if (
                tablerow[i].procode === tablerow[j].procode &&
                tablerow[i].unitname === tablerow[j].unitname &&
                (tablerow[i].qtyshow !== undefined && tablerow[j].qtyshow !== undefined && tablerow[i].qtyshow === tablerow[j].qtyshow)
              ) {
                alert('Item Already Added in the List');
                return;
              }
            }
          }
          setSplitnw(true)
          setQtynw(false)
          var token = localStorage.getItem("token")
          console.log("tableid", tableid)
          if(tableid == "" && roomid == "") {
              return alert("Please select table")
          }
          if(waitercode == "") {
              return alert("Empty Waitercode")
          }
          if(party == "") {
              return alert("Empty Party")
          }
          for(var i = 0 ; i< tablerow.length; i++) {
              if(tablerow[i].dtsarate == 0 || tablerow[i].dtsarate == undefined ) {
                  alert("Amount is 0")
              }
              else{
          const exampleArray = []
          for(var i = 0 ; i< tablerow.length; i++) {
              if(tablerow[i].dtprdid == "") {
                  // tableRow.splice(i, 1)
              }
              else{
                  exampleArray.push(tablerow[i])
              } 
  
              if(tablerow[i].dtqty == 0) {
                  alert("Some items has quantity 0")
                  return
              }
          }
  
          if(exampleArray.length == 0){
              return alert("Please add item")
          }
  
          tablerow.forEach(function(v){ delete v.$id });
  
          setOtloader(true)
          const data = {
              "said": said,
              "vid": vid,
              "saformid": formid,
              "sadocdate": docdate,
              "sadocno": docno,
              "sadocnum": docnum,
              "sacaccid": accid,
              "saaccname": party,
              "saaccadD1": "",
              "saaccadD2": "",
              "saaccadD3": "",
              "sastateid": "",
              "sadistid": "",
              "sacomid": 1,
              "sabranchid": branchid,
              "samob": "",
              "saamount":  subtotal,
              "saitemdisc": "",
              "sagross":"",
              "sasgst": "",
              "sacgst": "",
              "saigst": "",
              "satax": "",
              "sadiscper": "",
              "sadiscount": "",
              "sasubtotal": subtotal,
              "sanettotal": subtotal,
              "sacreatedon": "",
              "sacreatedby": global.userid,
              "saupdatedby": global.userid,
              "printFile": "",
              "sacashpaid": "",
              "sabankpaid": "",
              "satotalpaid": "",
              "saupdatedon": "",
              "saduedate": "",
              "saremarks": "",
              "sacessper": "",
              "sacessamount": "",
              "satotal": subtotal,
              "sagodownid": 1,
              "sacode": "",
              "sagstin": "",
              "sarepid": "",
              "saispos": "",
              "sacardrefno": "",
              "sadayclose": "",
              "sacounterid": counter,
              "sadaycloseid": "",
              "sacardtype": "",
              "sadeliverycharge": "",
              "sarefcode": happyhr? 'H' : null,
              "tableid": "",
              "roomid": "",
              "billnonum": "",
              "billno": "",
              "waiterid": waitercode,
              "details": exampleArray,
              "billDate": "",
              "user": 1,
              "time": "",
              "sacash": "",
              "balance": "",
              "inWords": "",
              "billedstatus": 0,
              "tableid": tableid,
              "roomid": roomid
            }
          console.log("save data", data)
         
          fetch(global.url + "api/Sales/SaveSales", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + token
              },
              body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log('print', responseJson)
              setOtnum(responseJson.ots)
              setSplitnw(false)
              setLoader(false)
              if(responseJson) {
                  // alert("success")
                  getSalesbyid(responseJson.vid)
                  
              }
          })
          .catch((error)=>{
              alert("error occured")
              setOtloader(false)
              console.log(error)
          })
        }}
        
    }  

    const getSalesbyid = (id) => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Sales/GetSalesById?id=" + id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
        .then((response)=> response.json())
        .then(async(responseJson)=>{
            console.log('print2', responseJson)
            setLoader(false)
            if(responseJson) {
                // alert("success")
                setMakebill(responseJson)
                document.getElementById('print-button').click();
                setKotPrintmodal(true)
                setRefresh(false)
                await delay(2000);
                setRefresh(true)
            }
        })
        .catch((error)=>{
            setOtloader(false)
            alert("error occured")
            console.log(error)
        })
    }

    const tableroomChange = () => {
        setTableloader(true)
        var token = localStorage.getItem("token")
        
        fetch(global.url + "api/Tables/GetTableOrRoomByNumber?number=" + table + "&branchId=" + branchid, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("GetTableOrRoomByNumber search Response", responseJson)
             setTableid(responseJson.tableId)
             setRoomid(responseJson.roomId)
             if(responseJson.roomId != 0){
                setCounter(2)
                getCounterbyid(2)
             }
             else{
                setCounter(responseJson.counterId)
                getCounterbyid(responseJson.counterId)
             }
             
             
            //  getTablebyid(responseJson.tableId)
             onRetrieve()
              
        })
        .catch((error)=>{
            setTableloader(false)
            console.log(error)
        })
    }

    const getTablebyid =(id) => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Tables/GetTableById?id=" + id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            
             if(responseJson.vid != 0){
                onRetrieve(responseJson.vid)
             }else{
                setTableloader(false)
                setTablerow([{
                    dtid: 0,
                    vid: "",
                    dtprdid: "",
                    dtprdbatchid: "",
                    dtunitid: 0,
                    dtqty: 1,
                    dtprice: 0,
                    dtamount: 0,
                    dtdiscper: "",
                    dtdiscount: "",
                    dtgross: "",
                    dtsgst: "",
                    dtcgst: "",
                    dtigst: "",
                    dttax: "",
                    dtsgstper: "",
                    dtcgstper: "",
                    dtigstper: "",
                    dttaxper: "",
                    dttaxid: "",
                    dttotal: "",
                    dtlremarks: "",
                    dtgdnid: "",
                    dtcost: "",
                    dtsarate: 0,
                    dtmrp: "",
                    batchcode: "",
                    barcode: "",
                    procode: "",
                    proname: "",
                    localname: "",
                    unitname: "",
                    gdnname: "",
                    qtyshow: 1
                  }])
                  setSaid(0)
                  setVid(0)
             }
             
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const onRetrieve = () => {
        var token = localStorage.getItem("token")
        var data = {
            "branchId": branchid,
            "tableName":table,
            "roomNumber": table,
            "waiter": waitercode,
            "saleDate": docdate,
            "party": party
        }

        console.log("partydata", data)
        
        fetch(global.url + "api/Sales/GetOtsByTableRoomWaiter", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("GetOtsByTableRoomWaiter view Response", responseJson)
             setTableloader(false)
             if(responseJson != "No Data"){
                setMakebill(responseJson)
                setDocno(responseJson.sadocno)
                if(responseJson.details != null){
                    setTablerow(responseJson.details)
                }
                setSaid(responseJson.said)
                setVid(responseJson.vid)
             }else{
                 setTablerow([{
                    dtid: 0,
                    vid: "",
                    dtprdid: "",
                    dtprdbatchid: "",
                    dtunitid: 0,
                    dtqty: 1,
                    dtprice: 0,
                    dtamount: 0,
                    dtdiscper: "",
                    dtdiscount: "",
                    dtgross: "",
                    dtsgst: "",
                    dtcgst: "",
                    dtigst: "",
                    dttax: "",
                    dtsgstper: "",
                    dtcgstper: "",
                    dtigstper: "",
                    dttaxper: "",
                    dttaxid: "",
                    dttotal: "",
                    dtlremarks: "",
                    dtgdnid: "",
                    dtcost: "",
                    dtsarate: 0,
                    dtmrp: "",
                    batchcode: "",
                    barcode: "",
                    procode: "",
                    proname: "",
                    localname: "",
                    unitname: "",
                    gdnname: "",
                    qtyshow: 1
                  }])
                  setSaid(0)
                  setVid(0)
             }
             
        })
        .catch((error)=>{
            setTableloader(false)
            console.log(error)
        })
    }

    const addRow = () => {
      
        setTablerow([...tablerow, {
            dtid: 0,
            vid: "",
            dtprdid: "",
            dtprdbatchid: "",
            dtunitid: 0,
            dtqty: 1,
            dtprice: 0,
            dtamount: 0,
            dtdiscper: "",
            dtdiscount: "",
            dtgross: "",
            dtsgst: "",
            dtcgst: "",
            dtigst: "",
            dttax: "",
            dtsgstper: "",
            dtcgstper: "",
            dtigstper: "",
            dttaxper: "",
            dttaxid: "",
            dttotal: "",
            dtlremarks: "",
            dtgdnid: "",
            dtcost: "",
            dtsarate: 0,
            dtmrp: "",
            batchcode: "",
            barcode: "",
            procode: "",
            proname: "",
            localname: "",
            unitname: "",
            gdnname: "",
            qtyshow: 1
          }])
    }

    const getCounterbyid =(id) => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Counter/GetCounterById?id=" + id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("counter id Response", responseJson)
             setCountername(responseJson.ctrname)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const onRowdelete = (index) => {
        var input = [...tablerow]
        input.splice(index, 1)
        setTablerow(input)
    }


    const onMakebill = () => {
        for(var i = 0; i< tablerow.length; i++){
            if(tablerow[i].$id) {
                
            }
            else{
                return  alert("Please make OT first")
            }
        }
        var token = localStorage.getItem("token")
        console.log("token", token)
        if(tableid == "" && roomid == "") {
            return alert("Please select table")
        }
        if(waitercode == "") {
            return alert("Empty Waitercode")
        }
        if(party == "") {
            return alert("Empty Party")
        }
        var waitercodeinclude = false
        for(var i=0; i< waiterlist.length; i++){
            if(waitercode == waiterlist[i].waiterid) {
                waitercodeinclude = true
            }
        }
        if(waitercodeinclude == true) {
            setOtloader(true)
            const data = {
                "said": said,
                "vid": vid,
                "saformid": formid,
                "sadocdate": docdate,
                "sadocno": docno,
                "sadocnum": docnum,
                "sacaccid": accid,
                "saaccname": party,
                "saaccadD1": "",
                "saaccadD2": "",
                "saaccadD3": "",
                "sastateid": "",
                "sadistid": "",
                "sacomid": 1,
                "sabranchid": branchid,
                "samob": "",
                "saamount":  subtotal,
                "saitemdisc": "",
                "sagross":"",
                "sasgst": "",
                "sacgst": "",
                "saigst": "",
                "satax": "",
                "sadiscper": "",
                "sadiscount": "",
                "sasubtotal": subtotal,
                "sanettotal": subtotal,
                "sacreatedon": makebill.sacreatedon,
                "sacreatedby": global.userid,
                "saupdatedby": global.userid,
                "printFile": "",
                "sacashpaid": "",
                "sabankpaid": "",
                "satotalpaid": "",
                "saupdatedon": "",
                "saduedate": "",
                "saremarks": "",
                "sacessper": "",
                "sacessamount": "",
                "satotal": subtotal,
                "sagodownid": 1,
                "sacode": "",
                "sagstin": "",
                "sarepid": "",
                "saispos": "",
                "sacardrefno": "",
                "sadayclose": "",
                "sacounterid": counter,
                "sadaycloseid": "",
                "sacardtype": "",
                "sadeliverycharge": "",
                "sarefcode": happyhr? 'H' : null,
                "tableid": "",
                "roomid": "",
                "billnonum": "",
                "billno": "",
                "waiterid": waitercode,
                "details": tablerow,
                "billDate": "",
                "user": 1,
                "time": "",
                "sacash": "",
                "balance": "",
                "inWords": "",
                "billedstatus": 1,
                "tableid": tableid,
                "roomid": roomid,
                "billedstatus": true,
                "roomvid": 1,
                "creditsale": true,
            }
            console.log("save data", data)

            fetch(global.url + "api/Sales/SaveSales", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then(async(responseJson)=>{
                console.log("makebill response", responseJson)
                if(responseJson) {
                    // alert("success")
                    if(responseJson) {
                        // alert("success")
                        getMakeBillSalesbyid(responseJson.vid)
                        
                    }
                    
                }
            })
            .catch((error)=>{
                setOtloader(false)
                alert("error occured")
                console.log(error)
            })
        }
        else{
            alert("Invalid Waitercode")
        }
    }

    const getMakeBillSalesbyid = (id) => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Sales/GetSalesByIdToPrint?id=" + id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
        .then((response)=> response.json())
        .then(async(responseJson)=>{
            console.log('print2', responseJson)
            setLoader(false)
            if(responseJson) {
                // alert("success")
                setMakebilllast(responseJson)
                
                document.getElementById('print-button1').click();
                setPrintmodal(true)
                setRefresh(false)
                await delay(2000);
                setRefresh(true)
            }
        })
        .catch((error)=>{
            setOtloader(false)
            alert("error occured")
            console.log(error)
        })
    }

    useEffect(() => {
        getAllProductCounterSales()
      }, []);

    const splitClick = () => {
        for (let i = 0; i < tablerow.length - 1; i++) {
            for (let j = i + 1; j < tablerow.length; j++) {
              if (
                tablerow[i].procode === tablerow[j].procode &&
                tablerow[i].unitname === tablerow[j].unitname &&
                (tablerow[i].qtyshow !== undefined && tablerow[j].qtyshow !== undefined && tablerow[i].qtyshow === tablerow[j].qtyshow)
              ) {
                alert('Item Already Added in the List');
                return;
              }
            }
          }
      
        // for(var i = 0; i< tablerow.length; i++){
        //     if(tablerow[i].$id) {
        //         return  alert("Doesn't allow OT items")
        //     }
        //     else{
                
        //     }
        // }
        var token = localStorage.getItem("token")
        if(tableid == "" && roomid == "") {
            return alert("Please select table")
        }
        const exampleArray = []
        for(var i = 0 ; i< tablerow.length; i++) {
            if(tablerow[i].dtprdid == "") {
                // tableRow.splice(i, 1)
            }
            else{
                exampleArray.push(tablerow[i])
            } 
        }

        if(exampleArray.length == 0){
            return alert("Please add item")
        }

        tablerow.forEach(function(v){ delete v.$id });
        setSplitnw(true)
        const data = {
            "said": said,
            "vid": vid,
            "saformid": formid,
            "sadocdate": docdate,
            "sadocno": docno,
            "sadocnum": docnum,
            "sacaccid": accid,
            "saaccname": party,
            "saaccadD1": "",
            "saaccadD2": "",
            "saaccadD3": "",
            "sastateid": "",
            "sadistid": "",
            "sacomid": 1,
            "sabranchid": branchid,
            "samob": "",
            "saamount":  subtotal,
            "saitemdisc": "",
            "sagross":"",
            "sasgst": "",
            "sacgst": "",
            "saigst": "",
            "satax": "",
            "sadiscper": "",
            "sadiscount": "",
            "sasubtotal": subtotal,
            "sanettotal": subtotal,
            "sacreatedon": "",
            "sacreatedby": global.userid,
            "saupdatedby": global.userid,
            "printFile": "",
            "sacashpaid": "",
            "sabankpaid": "",
            "satotalpaid": "",
            "saupdatedon": "",
            "saduedate": "",
            "saremarks": "",
            "sacessper": "",
            "sacessamount": "",
            "satotal": subtotal,
            "sagodownid": 1,
            "sacode": "",
            "sagstin": "",
            "sarepid": "",
            "saispos": "",
            "sacardrefno": "",
            "sadayclose": "",
            "sacounterid": counter,
            "sadaycloseid": "",
            "sacardtype": "",
            "sadeliverycharge": "",
            "sarefcode": happyhr? 'H' : null,
            "tableid": "",
            "roomid": "",
            "billnonum": "",
            "billno": "",
            "waiterid": waitercode,
            "details": exampleArray,
            "billDate": "",
            "user": 1,
            "time": "",
            "sacash": "",
            "balance": "",
            "inWords": "",
            "billedstatus": 0,
            "tableid": tableid,
            "roomid": roomid
          }
        console.log("save data", data)

        fetch(global.url + "api/Sales/SaveSales", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            if(responseJson) {
                // alert("success")
                afterSplitclick(responseJson.vid)
                // setSplitnw(false)
            }
        })
        .catch((error)=>{
            alert("error occured")
            console.log(error)
        })
    }

    const afterSplitclick = (id) => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Sales/GetSplitSalesById?id=" + id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
        .then((response)=> response.json())
        .then(async(responseJson)=>{
            console.log('GetSplitSalesById', responseJson)
            setSplitdetails(responseJson)
            setSplitpopup(true)
            setRefresh(false)
            botfunction()
            await delay(2000);
            kotfunction()
            await delay(4000);
            wsfunction()
            await delay(7000);
            setRefresh(true)
        })
        .catch((error)=>{
            alert("error occured")
            console.log(error)
        })
    }

    const botfunction = () => {
        document.getElementById('print-button2').click();
    }

    const kotfunction = () => { 
        document.getElementById('print-button3').click();
    }

    const wsfunction = () => { 
        document.getElementById('print-button4').click();
    }



    useEffect(() => {
        setDocdate1(formatDate(docdate, "date"))
    }, [docdate])
    
    const listClick = () => {
        window.open("/Reports/Waitersalesreport", "_blank");
    }
    const RunningotClick = () => {
        window.open("/Reports/Runningot", "_blank");
    }
    const StocklistClick = () => {
        window.open("/Reports/Stocklist", "_blank");
    }
    const PricelistClick = () => {
        window.open("/Barmaster/FoodPricelistView", "_blank");
    }

    const saleslistClick = () => {
        window.open("/Reports/salesbilllistview", "_blank");
    }

    useEffect(() => {
        console.log("sj1234", tablerow)
    }, [tablerow])

    return(
        <div style={{maxWidth: '100%'}}>
        {
          access ?
          <>
        <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
            <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Counter Sales</p>
                {
                    happyhr ? 
                    <div className="blink_me" style={{fontSize: 20, color: 'green', fontWeight: 'bold',}}>Happy Hour</div> :
                    null
                }
            </div>
            <div style={{display: 'none'}}>
                <ReactToPrint
                        trigger={() => <Button id="print-button" variant="primary" className="side-topbtn">OT Print</Button>}
                        content={() => componentRef.current}
                    />
                <ReactToPrint
                    trigger={() => <Button id="print-button1" variant="primary" className="side-topbtn">OT Print</Button>}
                    content={() => componentRef1.current}
                />
                <ReactToPrint
                    trigger={() => <Button id="print-button2" variant="primary" className="side-topbtn">OT Print</Button>}
                    content={() => componentRef2.current}
                />
                <ReactToPrint
                    trigger={() => <Button id="print-button3" variant="primary" className="side-topbtn">OT Print</Button>}
                    content={() => componentRef3.current}
                />
                 <ReactToPrint
                    trigger={() => <Button id="print-button4" variant="primary" className="side-topbtn">OT Print</Button>}
                    content={() => componentRef4.current}
                />
            </div>
            {
            headerLoader ?
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <ThreeDots
                    height="50"
                    width="50"
                    color='#0d6efd'
                    ariaLabel='loading'
                />
            </div>: 
            <Row>
                <Col sm={12}>
                    <Row>
                        <Col sm={6} lg={3} style={{marginTop:30}}>
                            {docdate == "" ? 
                            <FormControl sx={{  width: '100%' }}>
                                <TextField value={docdate}  disabled label="Date" id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>:
                            <FormControl sx={{  width: '100%' }}>
                                <TextField value={formatDate(docdate, "date")} disabled label="Date" id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>}
                        </Col>
                        <Col sm={6} lg={2} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField value={user} onChange={(e) => setUser(e.target.value)} label="User" id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col sm={6} lg={2} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField value={docno} disabled  label="BOT NO" id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col sm={6} lg={2} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField value={lastbillno} disabled onChange={(e) => setLastbillno(e.target.value)} label="LAST BILL NO" id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        {/* <Col sm={6} lg={1} style={{marginTop:30}}>
                            <Button  variant="outlined">Search</Button>
                        </Col>
                        <Col sm={6} lg={1} style={{marginTop:30}}>
                            <Button  variant="outlined">Logout</Button>
                        </Col>
                        <Col sm={6} lg={2} style={{marginTop:30}}>
                            <Button  variant="outlined">Waiter Sales</Button>
                        </Col> */}
                    </Row>
                    <Row>
                        <Col sm={6} lg={2} style={{marginTop:30}}>
                                <FormControl sx={{  width: '100%' }}>
                                    <TextField value={waitercode} onChange={(e) => setWaitercode(e.target.value)} label="Waiter Code" onBlur={() => leavingWaiter()} id="outlined-basic" variant="outlined"  size="small"/>
                                </FormControl>
                        </Col>
                        <Col sm={6} lg={2} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField value={table} onChange={(e) => setTable(e.target.value)} onBlur={() => tableroomChange()}  label="Table/Room No" id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col sm={6} lg={2} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField value={party} onKeyPress={handleKeyPress} onChange={(e) => setParty(e.target.value)}  onBlur={() => tableroomChange()} label="Party" id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                    </Row>
                    
                </Col>
            </Row> 
            }
            {
            tableloader ?
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <ThreeDots
                    height="50"
                    width="50"
                    color='#0d6efd'
                    ariaLabel='loading'
                />
            </div>: 
            <Row style={{marginTop: 20}}>
                <Col xs={12}>
                    <Table bordered responsive className='countersaletable'>
                        <thead>
                            <tr>
                                <th>Sl No.</th>
                                <th>Code</th>
                                <th>Description</th>
                                <th>Type</th>
                                <th>Quantity</th>
                                <th>Rate</th>
                                <th>Amount</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                tablerow.map((i, k) => {
                                    return(
                                        <tr>
                                            <td className='rowstyle'>{k+1}</td>
                                            <td className='rowstyle'>
                                                <input disabled={i.$id ? true: false}  autocomplete="off" ref={inputRef} type="text" name="query" value={i.procode} onBlur={() => handleOnChange(i.procode, k)} onChange={(event) => prodcodeChange(event, k)}/>
                                                {hasResultsProduct && selectedIndex === k  && (
                                                       <Searchlist hasResultsProduct={hasResultsProduct} productlist={productlist} productname={productname} inputRef={inputRef} listClickname={listClickname} index={k}/>
                                                    )}
                                                </td>
                                            <td className='rowstyle'>{i.proname}</td>
                                            <td className='rowstyle'>
                                                <FormControl fullWidth sx={{width: '100%' }} className="countersaledropdown">
                                                       
                                                        <Select
                                                         disabled={i.$id ? true: false}
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={i.dtunitid}
                                                        
                                                        onChange={(event) => unitChange(event.target.value, k, i.acpeg, i.barpeg, i.acbottle, i.barbottle, i.stockBottle, i.stockPeg, i.dtprdid, i.localbottle, i.localpeg, i.exbottle, i.expeg)}
                                                        size="small"
                                                        >
                                                            <MenuItem value={"2"}>F</MenuItem>
                                                            <MenuItem value={"1"}>L</MenuItem>
                                                        </Select>
                                                </FormControl>
                                            </td>
                                            <td className='rowstyle'><input disabled={i.$id ? true: false}  type="text"
                                                            id={"qty" + k}
                                                            maxLength={9}
                                                            pattern="[+-]?\d+(?:[,]\d+)?"  autocomplete="off" value={i.dtqty}  onChange={(event) => qtyChange(event, i.dtprdid, i.dtqty, i.dtunitid, k)}/></td>
                                            
                                            <td className='rowstyle'>{i.dtsarate}</td>
                                            <td className='rowstyle'>{i.dtamount}</td>
                                            <td className='rowstyle'>
                                                {i.$id ? null: <Button onClick={() => onRowdelete(k)}  variant="outlined" style={{padding: 'unset'}}>Delete</Button>}</td>
                                        </tr>
                                    )
                                })
                            }
                           
                        </tbody>
                    </Table>
                </Col>
            </Row>
            }
            <Row style={{marginTop: 20}}>
                    <Col sm={6} lg={2} style={{marginTop:30}}>
                        <FormControl sx={{  width: '100%' }}>
                            <TextField value={subtotal} disabled label="Total" id="outlined-basic" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={6} >
                        <ul className="list-inline">
                            <li><Button onClick={() => addRow()} variant="outlined" style={{marginTop: 30}}>Add New</Button></li>
                        </ul>
                    </Col>
                </Row>
            <div style={{marginTop: 30}}>
                
                <Row style={{marginTop: 20}}>
                    <Col xs={12} >
                    {qtynw == false ?
                        <div >
                            {splitnw == false ?
                            <div>
                                {
                                    otloader ?
                                    <Rings
                                        height="50"
                                        width="50"
                                        color='#0d6efd'
                                        ariaLabel='loading'
                                    />:
                                    <ul className="list-inline">
                                  
                                        <li><Button style={{marginTop: 8}} variant="outlined" onClick={() => onSave()}>OT Print(CTRL + F2)</Button></li> 
                                       
                                            <li><Button style={{marginTop: 8}} onClick={() => onMakebill()} variant="outlined">Make Bill(CTRL + F3)</Button></li> :
                                           
                                     
                                        <li><Button style={{marginTop: 8}} variant="outlined" onClick={() => splitClick()}>Split OT</Button></li>
                                        {/* <li><Button variant="outlined">Credit Sales</Button></li> */}
                                        <li><Button style={{marginTop: 8}} onClick={listClick} variant="outlined">List</Button></li>
                                        <li><Button style={{marginTop: 8}} onClick={RunningotClick} variant="outlined">Running OT</Button></li>
                                        <li><Button style={{marginTop: 8}} onClick={StocklistClick} variant="outlined">Stock List</Button></li>
                                        <li><Button style={{marginTop: 8}} onClick={PricelistClick} variant="outlined">Food Pricelist</Button></li>
                                        <li><Button style={{marginTop: 8}} onClick={saleslistClick} variant="outlined">Sales Bill list</Button></li>
                                    </ul>
                                }
                                </div> : null}
                        </div> : null}
                        
                    </Col>
                </Row>
            </div>
            
          </Paper> 
          <div className='posprint testprint' ref={componentRef}>
                <p style={{fontSize: 13, fontWeight: 'bold', textAlign: "center", marginBottom: 'unset', wordBreak: 'break-word', width: 275}}>{branchdetails.brname}</p>
                <p style={{fontSize: 11, fontWeight: "bold", textAlign: "center", marginBottom: 'unset', wordBreak: 'break-word', width: 275}}>{branchdetails.braddresS1}, {branchdetails.braddresS2}, {branchdetails.braddresS3}</p>
                <p style={{fontSize: 11, fontWeight: "bold", textAlign: "center", marginBottom: 'unset'}}>Date&Time {makebill.billDate}</p>
                <div style={{width: '100%', height: 1, backgroundColor: "#d3d3d3", marginTop: 5, marginBottom: 5}}></div>
                <Table responsive className='posprinttable'>
                    <tbody>
                    <tr>
                            <th>Sl</th>
                            <th>Description</th>
                            <th>Unit</th>
                            <th>Qty</th>
                            
                        </tr>
                        
                        {  
                            makebill?.details?.map((i, k) => {
                                return(
                                    i.dtgdnid === null ?
                                    <tr>
                                        <td>{k+1}</td>
                                        <td style={{fontSize:12}}>{i.proname}</td>
                                        <td>{i.dtunitid == 1 ? "L": "F"}</td>
                                        <td>{i.dtqty}</td>
                                    </tr>:
                                    null
                                )
                            }) 
                        }
                        
                    </tbody>
                </Table>
                <div>
                    <p style={{fontSize: 11, marginBottom: 'unset', fontWeight: 'bold',  wordBreak: 'break-word', width: 275}}>OT No : {otnum}</p>
                    <p style={{fontSize: 11, marginBottom: 'unset', fontWeight: 'bold',}}>Party : {makebill.saaccname}</p>
                    <p style={{fontSize: 11, marginBottom: 'unset', fontWeight: 'bold',}}>Table/Room No:{makebill.roomNumber != null ? makebill.roomNumber: makebill.tableNumber}</p>
                    <p style={{fontSize: 11, marginBottom: 'unset', fontWeight: 'bold',}}>Waiter : {makebill.waiterid}-{makebill.waitername}</p>
                </div>
            </div>
           
            <div className='posprint testprint' ref={componentRef1}>
                <p style={{fontSize: 13, fontWeight: 'bold', textAlign: "center", marginBottom: 'unset', wordBreak: 'break-word', width: 275}}>{branchdetails.brname}</p>
                <p style={{fontSize: 11, fontWeight: "bold", textAlign: "center", marginBottom: 'unset', wordBreak: 'break-word', width: 275}}>{branchdetails.braddresS1}, {branchdetails.braddresS2}, {branchdetails.braddresS3}</p>
                <p style={{fontSize: 11, fontWeight: "bold", textAlign: "center", marginBottom: 'unset'}}>Bill No. {makebilllast.billno}  Date&Time {makebilllast.billDate}</p>
                <div style={{width: '100%', height: 1, backgroundColor: "#d3d3d3", marginTop: 5, marginBottom: 5}}></div>
                <Table responsive className='posprinttable'>
                    <tbody>
                    <tr>
                            <th>Sl</th>
                            <th>Description</th>
                            <th>Qty</th>
                            <th>Rate</th>
                            <th>Amt</th>
                        </tr>
                        
                        {  
                            makebilllast?.details?.map((i, k) => {
                                return(
                                    <tr>
                                        <td>{k+1}</td>
                                        <td style={{fontSize:12}}>{i.proname}</td>
                                        <td>{i.dtqty}</td>
                                        <td style={{textAlign: 'right', fontSize:11}}>{i.dtprice.toFixed(2)}</td>
                                        <td style={{textAlign: 'right', fontSize:11}}>{i.dtamount.toFixed(2)}</td>
                                    </tr>
                                )
                            }) 
                        }
                        
                        <tr>
                            <td colSpan={4} style={{textAlign: 'right',fontWeight: 'bold',}}><span >Total Amount</span></td>
                            <td style={{textAlign: 'right', fontWeight: 'bold',}}>{makebilltotal}</td>
                        </tr>
                        <tr>
                            <td colSpan={4} style={{textAlign: 'right', fontWeight: 'bold',}}><span >Round Off</span></td>
                            <td style={{textAlign: 'right', fontWeight: 'bold',}}>00.00</td>
                        </tr>
                        <tr>
                            <td colSpan={4} style={{textAlign: 'right',fontWeight: 'bold', fontSize:13}}><span>Net Amount</span></td>
                            <td style={{textAlign: 'right', fontWeight: 'bold', fontSize:13}}>{makebilltotal}</td>
                        </tr>
                    </tbody>
                </Table>
                <div>
                    <p style={{fontSize: 11, marginBottom: 'unset',fontWeight: 'bold',  wordBreak: 'break-word', width: 275}}>OT No : {makebilllast.sadocno}</p>
                    <p style={{fontSize: 11, marginBottom: 'unset', fontWeight: 'bold',}}>Party : {makebilllast.saaccname}</p>
                    <p style={{fontSize: 11, marginBottom: 'unset', fontWeight: 'bold',}}>Waiter : {makebilllast.waiterid}-{makebilllast.waitername}</p>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <p style={{fontSize:  11, marginBottom: 'unset', fontWeight: 'bold',}}>Total Items : {tablerow.length}</p>
                        <p style={{fontSize: 11, marginBottom: 'unset', fontWeight: 'bold',}}>Table/Room No. {makebilllast.roomNumber != null ? makebilllast.roomNumber: makebilllast.tableNumber}</p>
                    </div>
                    <p style={{fontSize: 11, marginBottom: 'unset', fontWeight: 600, marginTop: 10, textAlign: 'center'}}>Thank You Visit Again.</p>
                </div>
            </div>

            <div   >
                {
                    splitdetails?.kotItems.length == 0 ? null:
                    <div className='posprint testprint' ref={componentRef2}>
                        <p style={{fontSize: 13, fontWeight: 'bold', textAlign: "center", marginBottom: 'unset', wordBreak: 'break-word', width: 275}}>{branchdetails.brname}</p>
                        <p style={{fontSize: 11, fontWeight: "bold", textAlign: "center", marginBottom: 'unset', wordBreak: 'break-word', width: 275}}>{branchdetails.braddresS1}, {branchdetails.braddresS2}, {branchdetails.braddresS3}</p>
                        <p style={{fontSize: 11, fontWeight: "bold", textAlign: "center", marginBottom: 'unset'}}>Date&Time {splitdetails?.billDate}</p>
                        <div style={{width: '100%', height: 1, backgroundColor: "#d3d3d3", marginTop: 5, marginBottom: 5}}></div>
                        <Table responsive className='posprinttable'>
                            <tbody>
                            <tr>
                                    <th>Sl</th>
                                    <th>Description</th>
                                    <th>Unit</th>
                                    <th>Qty</th>
                                   
                                </tr>
                                
                                {  
                                    splitdetails?.kotItems.map((i, k) => {
                                        return(
                                            i.dtgdnid === null ?
                                            <tr>
                                                <td>{k+1}</td>
                                                <td>{i.proname}</td>
                                                <td>{i.dtunitid == 1 ? "L": "F"}</td>
                                                <td>{i.dtqty}</td>
                                            </tr> :
                                            null
                                        )
                                    }) 
                                }
                                
                            </tbody>
                        </Table>
                        <div>
                            <p style={{fontSize: 11, marginBottom: 'unset', fontWeight: 'bold',  wordBreak: 'break-word', width: 275}}>OT No : {splitdetails?.sadocno}</p>
                            <p style={{fontSize: 11, marginBottom: 'unset', fontWeight: 'bold'}}>Party : {splitdetails?.saaccname}</p>
                            <p style={{fontSize: 11, marginBottom: 'unset', fontWeight: 'bold'}}>Waiter : {splitdetails?.waiterid}-{splitdetails?.waitername}</p>
                            <p style={{fontSize: 11, marginBottom: 'unset', fontWeight: 'bold'}}>Table/Room No: {splitdetails?.roomNumber != null ? splitdetails?.roomNumber: splitdetails?.tableNumber}</p>
                        </div>
                    </div> 
                }
                </div>
                <div >
                {
                    splitdetails?.botItems.length == 0 ? null:
                    
                    <div className='posprint testprint' ref={componentRef3}>
                        <p style={{fontSize: 13, fontWeight: 'bold', textAlign: "center", marginBottom: 'unset', wordBreak: 'break-word', width: 275}}>{branchdetails.brname}</p>
                        <p style={{fontSize: 11, fontWeight: 'bold', textAlign: "center", marginBottom: 'unset', wordBreak: 'break-word', width: 275}}>{branchdetails.braddresS1}, {branchdetails.braddresS2}, {branchdetails.braddresS3}</p>
                        <p style={{fontSize: 11, fontWeight: 'bold', textAlign: "center", marginBottom: 'unset'}}>Date&Time {splitdetails?.billDate}</p>
                        <div style={{width: '100%', height: 1, backgroundColor: "#d3d3d3", marginTop: 5, marginBottom: 5}}></div>
                        <Table responsive className='posprinttable'>
                            <tbody>
                            <tr>
                                    <th>Sl</th>
                                    <th>Description</th>
                                    <th>Unit</th>
                                    <th>Qty</th>
                                   
                                </tr>
                                
                                {  
                                    splitdetails?.botItems.map((i, k) => {
                                        return(
                                            <tr>
                                                <td>{k+1}</td>
                                                <td style={{fontSize:12}}>{i.proname}</td>
                                                <td>{i.dtunitid == 1 ? "L": "F"}</td>
                                                <td>{i.dtqty}</td>
                                            </tr>
                                        )
                                    }) 
                                }
                                
                            </tbody>
                        </Table>
                        <div>
                            
                            <p style={{fontSize: 11, marginBottom: 'unset', fontWeight: 'bold',  wordBreak: 'break-word', width: 275}}>OT No : {splitdetails?.sadocno}</p>
                            <p style={{fontSize: 11, marginBottom: 'unset', fontWeight: 'bold',}}>Party : {splitdetails?.saaccname}</p>
                            <p style={{fontSize: 11, marginBottom: 'unset', fontWeight: 'bold',}}>Waiter : {splitdetails?.waiterid}-{splitdetails?.waitername}</p>
                            <p style={{fontSize: 11, marginBottom: 'unset', fontWeight: 'bold',}}>Table/Room No: {splitdetails?.roomNumber != null ? splitdetails?.roomNumber: splitdetails?.tableNumber}</p>
                        </div>
                    </div>
                }
            </div>
            <div >
                {
                    splitdetails?.wsItems.length == 0 ? null:
                    
                    <div className='posprint testprint' ref={componentRef4}>
                        <p style={{fontSize: 13, fontWeight: 'bold', textAlign: "center", marginBottom: 'unset', wordBreak: 'break-word', width: 275}}>{branchdetails.brname}</p>
                        <p style={{fontSize: 11, fontWeight: 'bold', textAlign: "center", marginBottom: 'unset', wordBreak: 'break-word', width: 275}}>{branchdetails.braddresS1}, {branchdetails.braddresS2}, {branchdetails.braddresS3}</p>
                        <p style={{fontSize: 11, fontWeight: 'bold', textAlign: "center", marginBottom: 'unset'}}>Date&Time {splitdetails?.billDate}</p>
                        <div style={{width: '100%', height: 1, backgroundColor: "#d3d3d3", marginTop: 5, marginBottom: 5}}></div>
                        <Table responsive className='posprinttable'>
                            <tbody>
                            <tr>
                                    <th>Sl</th>
                                    <th>Description</th>
                                    <th>Unit</th>
                                    <th>Qty</th>
                                   
                                </tr>
                                
                                {  
                                    splitdetails?.wsItems.map((i, k) => {
                                        return(
                                            <tr>
                                                <td>{k+1}</td>
                                                <td style={{fontSize:12}}>{i.proname}</td>
                                                <td>{i.dtunitid == 1 ? "L": "F"}</td>
                                                <td>{i.dtqty}</td>
                                            </tr>
                                        )
                                    }) 
                                }
                                
                            </tbody>
                        </Table>
                        <div>
                            
                            <p style={{fontSize: 11, marginBottom: 'unset', fontWeight: 'bold',  wordBreak: 'break-word', width: 275}}>OT No : {splitdetails?.sadocno}</p>
                            <p style={{fontSize: 11, marginBottom: 'unset', fontWeight: 'bold',}}>Party : {splitdetails?.saaccname}</p>
                            <p style={{fontSize: 11, marginBottom: 'unset', fontWeight: 'bold',}}>Waiter : {splitdetails?.waiterid}-{splitdetails?.waitername}</p>
                            <p style={{fontSize: 11, marginBottom: 'unset', fontWeight: 'bold',}}>Table/Room No: {splitdetails?.roomNumber != null ? splitdetails?.roomNumber: splitdetails?.tableNumber}</p>
                        </div>
                    </div>
                }
            </div>
            </>:
            <div style={{marginTop: '90px', marginLeft: '500px'}}>
            <img src={accessimgae} alt='' />
          </div>
        }
      </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
		getAllProductCounterSales: () => {
			dispatch(getAllProductCounterSales())
		},
    };
  };

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
        productlist: state.shop.productlist
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Countersales);