import React, {useState, useEffect} from 'react';
import './Dashboard.css'
import {Container, Row, Col, Button} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
    {
      name: 'Jan',
      Income: 4000,
      Expense: 2400,
      amt: 2400,
    },
    {
      name: 'Fab',
      Income: 3000,
      Expense: 1398,
      amt: 2210,
    },
    {
      name: 'Mar',
      Income: 2000,
      Expense: 9800,
      amt: 2290,
    },
    {
      name: 'Apr',
      Income: 2780,
      Expense: 3908,
      amt: 2000,
    },
    {
      name: 'May',
      Income: 1890,
      Expense: 4800,
      amt: 2181,
    },
    {
      name: 'Jun',
      Income: 2390,
      Expense: 3800,
      amt: 2500,
    },
    {
      name: 'Jul',
      Income: 3490,
      Expense: 2300,
      amt: 2100,
    },
    {
        name: 'Aug',
        Income: 3900,
        Expense: 4300,
        amt: 2100,
      },
      {
        name: 'Sep',
        Income: 2090,
        Expense: 3500,
        amt: 2100,
      },
      {
        name: 'Oct',
        Income: 3490,
        Expense: 2400,
        amt: 2100,
      },
      {
        name: 'Nov',
        Income: 1490,
        Expense: 4300,
        amt: 2100,
      },
      {
        name: 'Dec',
        Income: 2490,
        Expense: 400,
        amt: 2100,
      },
  ];

function Dashboard () {

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();

    return(
        <div className="main-content">
            <div>
                <Row>
                    <Col xs={12} md={4}>
                        <FormControl fullWidth sx={{mt: 2,width: '100%' }}>
                            <InputLabel id="demo-simple-select-label">Branch</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Age"
                            size="small"
                            >
                            <MenuItem value="cash">Branch1</MenuItem>
                            <MenuItem value="cash">Branch2</MenuItem>
                            <MenuItem value="cash">Branch3</MenuItem>
                            <MenuItem value="cash">Branch4</MenuItem>
                            <MenuItem value="cash">Branch5</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={4}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField
                                id="datetime-local"
                                label="From Date"
                                type="date"
                                defaultValue={year + '-' + month + '-' + date}
                                sx={{ width: '100%' }}
                                InputLabelProps={{
                                shrink: true,
                                }}
                                // onChange={(newValue) => {
                                //     setDate1(newValue.target.value);
                                // }}
                            />
                        </FormControl>
                    </Col>
                    <Col xs={12} md={4}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField
                                id="datetime-local"
                                label="To Date"
                                type="date"
                                defaultValue={year + '-' + month + '-' + date}
                                sx={{ width: '100%' }}
                                InputLabelProps={{
                                shrink: true,
                                }}
                                // onChange={(newValue) => {
                                //     setDate2(newValue.target.value);
                                // }}
                            />
                        </FormControl>
                    </Col>
                </Row>
            </div>
            <div style={{marginTop: 30}}>
                <Row>
                    <Col xs={12} md={2}>
                        <div className='dashdiv'>
                            <p style={{fontSize: 14, fontWeight: "bold" , textAlign: 'center', marginBottom: 'unset'}}>No. of Rooms</p>
                            <p style={{fontSize: 18, fontWeight: "bold" , textAlign: 'center', marginBottom: 'unset'}}>20</p>
                        </div>
                    </Col>
                    <Col xs={12} md={2}>
                        <div className='dashdiv'>
                            <p style={{fontSize: 14, fontWeight: "bold" , textAlign: 'center', marginBottom: 'unset'}}>Occupied Rooms</p>
                            <p style={{fontSize: 18, fontWeight: "bold" , textAlign: 'center', marginBottom: 'unset'}}>10</p>
                        </div>
                    </Col>
                    <Col xs={12} md={2}>
                        <div className='dashdiv'>
                            <p style={{fontSize: 14, fontWeight: "bold" , textAlign: 'center', marginBottom: 'unset'}}>Maintainance</p>
                            <p style={{fontSize: 18, fontWeight: "bold" , textAlign: 'center', marginBottom: 'unset'}}>5</p>
                        </div>
                    </Col>
                    <Col xs={12} md={2}>
                        <div className='dashdiv'>
                            <p style={{fontSize: 14, fontWeight: "bold" , textAlign: 'center', marginBottom: 'unset'}}>Cleaning</p>
                            <p style={{fontSize: 18, fontWeight: "bold" , textAlign: 'center', marginBottom: 'unset'}}>5</p>
                        </div>
                    </Col>
                    <Col xs={12} md={2}>
                        <div className='dashdiv'>
                            <p style={{fontSize: 14, fontWeight: "bold" , textAlign: 'center', marginBottom: 'unset'}}>Stock Value</p>
                            <p style={{fontSize: 18, fontWeight: "bold" , textAlign: 'center', marginBottom: 'unset'}}>20</p>
                        </div>
                    </Col>
                </Row>
            </div>
            <div style={{marginTop: 30}}>
                <Row>
                    <Col xs={12} md={3}>
                        <div className='dashdiv'>
                            <p style={{fontSize: 14, fontWeight: "bold" , textAlign: 'center', marginBottom: 'unset'}}>Cash in Hands</p>
                            <p style={{fontSize: 18, fontWeight: "bold" , textAlign: 'center', marginBottom: 'unset'}}>20k</p>
                        </div>
                    </Col>
                    <Col xs={12} md={3}>
                        <div className='dashdiv'>
                            <p style={{fontSize: 14, fontWeight: "bold" , textAlign: 'center', marginBottom: 'unset'}}>Cash in Bank</p>
                            <p style={{fontSize: 18, fontWeight: "bold" , textAlign: 'center', marginBottom: 'unset'}}>10k</p>
                        </div>
                    </Col>
                    <Col xs={12} md={3}>
                        <div className='dashdiv'>
                            <p style={{fontSize: 14, fontWeight: "bold" , textAlign: 'center', marginBottom: 'unset'}}>Income</p>
                            <p style={{fontSize: 18, fontWeight: "bold" , textAlign: 'center', marginBottom: 'unset'}}>50k</p>
                        </div>
                    </Col>
                    <Col xs={12} md={3}>
                        <div className='dashdiv'>
                            <p style={{fontSize: 14, fontWeight: "bold" , textAlign: 'center', marginBottom: 'unset'}}>Expense</p>
                            <p style={{fontSize: 18, fontWeight: "bold" , textAlign: 'center', marginBottom: 'unset'}}>20k</p>
                        </div>
                    </Col>
                </Row>
            </div>
            
            <div style={{marginTop: 30}}>
                <Row>
                    <Col xs={12} lg={9}>
                        {/* <ResponsiveContainer width="100%" height="100%"> */}
                        <BarChart
                            width={800}
                            height={450}
                            data={data}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                            >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="Expense" stackId="a" fill="#8884d8" />
                            <Bar dataKey="Income" stackId="a" fill="#82ca9d" />
                        </BarChart>
                        {/* </ResponsiveContainer> */}
                    </Col>
                    <Col xs={12} lg={3}>
                        <div style={{marginTop: 30}}>
                            <Row>
                                <Col xs={12}>
                                    <div className='dashdiv'>
                                        <p style={{fontSize: 14, fontWeight: "bold" , textAlign: 'center', marginBottom: 'unset'}}>Total Sale</p>
                                        <p style={{fontSize: 18, fontWeight: "bold" , textAlign: 'center', marginBottom: 'unset'}}>20k</p>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className='dashdiv' style={{marginTop: 10}}>
                                        <p style={{fontSize: 14, fontWeight: "bold" , textAlign: 'center', marginBottom: 'unset'}}>Total Purchase</p>
                                        <p style={{fontSize: 18, fontWeight: "bold" , textAlign: 'center', marginBottom: 'unset'}}>10k</p>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className='dashdiv' style={{marginTop: 10}}>
                                        <p style={{fontSize: 14, fontWeight: "bold" , textAlign: 'center', marginBottom: 'unset'}}>Lodge Income</p>
                                        <p style={{fontSize: 18, fontWeight: "bold" , textAlign: 'center', marginBottom: 'unset'}}>50k</p>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className='dashdiv' style={{marginTop: 10}}>
                                        <p style={{fontSize: 14, fontWeight: "bold" , textAlign: 'center', marginBottom: 'unset'}}>Profit/Loss</p>
                                        <p style={{fontSize: 18, fontWeight: "bold" , textAlign: 'center', marginBottom: 'unset'}}>20k</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                
            </div>
        </div>
    )
}

export default Dashboard;