import * as actionTypes from "./shopping-types";

export const addToCart = (itemID) => {
  return {
    type: actionTypes.ADD_TO_CART,
    payload: {
      id: itemID,
    },
  };
};

export const saveBranch = (itemID) => {
  return {
    type: actionTypes.SAVE_BRANCH,
    payload: {
      id: itemID,
    },
  };
};

export const adminCheck = (itemID) => {
  return {
    type: actionTypes.ADMIN_CHECK,
    payload: {
      id: itemID,
    },
  };
};

export const setPos = (itemID) => {
  return {
    type: actionTypes.POS_CHECK,
    payload: {
      id: itemID,
    },
  };
};

export const getAllProduct = () => {
  return dispatch => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Products/GetProductListAll", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("GetProductListAll", responseJson)
             dispatch({
              type: "PRODUCTLIST_SUCCESS",
              payload: responseJson
            });
        })
        .catch((error)=>{
            console.log(error)
        })
  }
};

export const getAllProductCounterSales = () => {
  return dispatch => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Products/GetProductListWithFood", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("GetProductListAll", responseJson)
             dispatch({
              type: "PRODUCTLIST_SUCCESS",
              payload: responseJson
            });
        })
        .catch((error)=>{
            console.log(error)
        })
  }
};


