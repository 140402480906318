import React, {useState, useEffect} from 'react'
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Form, Button} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { connect } from "react-redux";

function ExtraPerson({branchid}){
    const [grc, setGrc] = useState("")
    const [room, setRoom] = useState("")
    const [person, setPerson] = useState("")
    const [tariff, setTariff] = useState(0)
    const [sgst, setSgst] = useState(0)
    const [cgst, setCgst] = useState(0)
    const [total, setTotal] = useState(0)

    useEffect(() => {
        const total = parseFloat(tariff) + parseFloat(sgst) + parseFloat(cgst)
        setTotal(total.toFixed(2))
    }, [tariff, sgst, cgst])

    function handleKeyPress(event) {
        if (!/^\d*\.?\d*$/.test(event.key)) {
        event.preventDefault();
        }
    }

    const onSave = () => {
        var token = localStorage.getItem("token")
        const data = {
            "extraperid": 0,
            "vid": 0,
            "branchid": branchid,
            "grc": grc,
            "roomno": room,
            "tariff": tariff,
            "sgst": sgst,
            "cgst": cgst,
            "person": person
          }
        fetch(global.url + "api/Booking/SaveExtraPerson", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            alert(responseJson.message)
            window.location.reload()
        })
        .catch((error)=>{
            alert("error occured")
            console.log(error)
        })
    }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold'}}>Add Extra Person</p> 
                <Row> 
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField onKeyPress={handleKeyPress} value={grc} onChange={(e) => setGrc(e.target.value)} id="outlined-basic" label="GRC" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField value={room} onChange={(e) => setRoom(e.target.value)} id="outlined-basic" label="Room No." variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField value={person} onChange={(e) => setPerson(e.target.value)} id="outlined-basic" label="Person" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                </Row>
                <Row> 
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField onKeyPress={handleKeyPress} value={tariff} onChange={(e) => setTariff(e.target.value)} id="outlined-basic" label="Tariff"variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField onKeyPress={handleKeyPress} value={sgst} onChange={(e) => setSgst(e.target.value)} id="outlined-basic" label="SGST" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField onKeyPress={handleKeyPress} value={cgst} onChange={(e) => setCgst(e.target.value)} id="outlined-basic" label="CGST" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                </Row>
                <Row> 
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField value={total} disabled id="outlined-basic" label="Total" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={3} style={{marginTop: 20}}>
                        <Button onClick={onSave} style={{backgroundColor: '#03a5fc', color: '#fff'}} variant="contained">Save</Button>
                    </Col>
                </Row>
            </Paper>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
    };
};

export default connect(mapStateToProps)(ExtraPerson);