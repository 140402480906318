import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Form, Button} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { BiSearch } from "react-icons/bi";
import { connect } from "react-redux";
import { ThreeDots, Rings  } from  'react-loader-spinner';

function EditAddress({branchid}){

    const [age, setAge] = React.useState('');
    const [mobile, setMobile] = React.useState("")
    const [guestname, setGuestname] = React.useState("")
    const [guestid, setGuestid] = React.useState("")
    const [grc, setGrc] = React.useState("")
    const [guestage, setGuestage] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [gender, setGender] = React.useState("")
    const [address, setAddress] = React.useState("")
    const [city, setCity] = React.useState("")
    const [state, setState] = React.useState("")
    const [pincode, setPincode] = React.useState("")
    const [panno, setPanno] = React.useState("")
    const [nationality, setNationality] = React.useState("")
    const [addressproof, setAddressproof] = React.useState("")
    const [guestgst, setGuestgst] = React.useState("")
    const [rvrsecharge, setRvrsecharge] = React.useState(0)
    const [purpose, setPurpose] = React.useState("")
    const [customerid, setCustomerid] = React.useState("")
    const [statelist, setStatelist] = React.useState([])
    const [district, setDistrict] = React.useState("")
    const [searchmobile, setSearchmobile] = React.useState("")
    const [file, setFile] = React.useState();
    const [loader, setLoader] = React.useState(false);

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    useEffect(() => {
        getData()
    }, []);

    const stateChange = (event) =>  {
        setState(event.target.value)
      }

      const getData = () => {
        getStatelist()
    }

    const onSubmit = () => {

        if(mobile == ""){
            return alert("Mobile Empty")
        }
        var token = localStorage.getItem("token")
        console.log("token", token)
        setLoader(true)
        const data = {
            "customerid": customerid,
            "customername": guestname,
            "grc" : searchmobile,
            "guestid" : guestid,
            "branchid" : branchid,
            "addresS1": address,
            "addresS2": address,
            "addresS3": address,
            "state": state,
            "district": city,
            "phone": mobile,
            "mobile": mobile,
            "email": email,
            "gstin": guestgst,
            "other": "",
            "panno": "",
            "proofid": "",
            "proofnumber": addressproof,
            "purpose": purpose,
            "nationality": nationality,
            "zipcode": pincode,
            "status": "",
            "gender": gender,
            "age": guestage,
            "proof": file,
            "proofurl": file
          }

        console.log("save data", data)

        fetch(global.url + "api/Booking/EditCustomerDetails", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("EditCustomerDetails Response", responseJson)
            setLoader(false)
            if(responseJson.error == false){
                alert(responseJson.message)
                window.location.reload()
            }
        })
        .catch((error)=>{
            alert("Something went wrong")
            console.log(error)
        })
    }

    const mobileSearch = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Booking/GetCustomerByGRC?branchId=" + branchid + "&grc= " + searchmobile, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("Getgrc response", responseJson)
            if(responseJson.customerid > 0) {
                setGuestname(responseJson.customername)
                setGuestage(responseJson.age)
                setGender(responseJson.gender)
                setEmail(responseJson.email)
                setAddress(responseJson.addresS1)
                setCity(responseJson.district)
                setState(responseJson.state)  
                setMobile(responseJson.mobile)
                setGuestid(responseJson.guestid)
                setPincode(responseJson.zipcode) 
                setPanno(responseJson.panno)
                setNationality(responseJson.nationality) 
                setAddressproof(responseJson.proofnumber) 
                setGuestgst(responseJson.gstin)  
                setPurpose(responseJson.purpose) 
                setCustomerid(responseJson.customerid)
                setDistrict(responseJson.district)
            } 
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getStatelist = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/State/GetStateList?countryId=102", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("GetStateList Response", responseJson)
            if(responseJson) {
                setStatelist(responseJson)
            }
            else{
                setStatelist([])
            }
           
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const batchfile = (e) => {
        console.log("e.target.files[0]", e.target.files)
        let selectedFile = e.target.files;
            let file = null;
            let fileName = "";
            //Check File is not Empty
            if (selectedFile.length > 0) {
                // Select the very first file from list
                let fileToLoad = selectedFile[0];
                fileName = fileToLoad.name;
                // FileReader function for read the file.
                let fileReader = new FileReader();
                // Onload of file read the file content
                fileReader.onload = function(fileLoadedEvent) {
                    file = fileLoadedEvent.target.result;
                    // Print data in console
                   
                    const regex = /data:.*base64,/
                    console.log(file.replace(regex,""));
                    setFile(file.replace(regex,""))
                };
                // Convert data to base64
                fileReader.readAsDataURL(fileToLoad);
                console.log("fileReader", fileReader);
            }
      }

      function handleKeyPress(event) {
        if (!/\d/.test(event.key)) {
        event.preventDefault();
        }
    }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold'}}>Edit Address</p> 
                <Row>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" label="GRC No." value={searchmobile} onChange={(e) => setSearchmobile(e.target.value)} variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={4} md={2}>
                        <a onClick={mobileSearch} style={{cursor: 'pointer'}}>
                            <BiSearch color="#000" size={30} style={{ marginTop: 20}} />
                        </a>
                    </Col>
                   
                </Row>
                <p style={{fontSize: 20, fontWeight: 'bold', marginTop: 20}}>Guest Details</p> 
                <Row>
                {/* <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" label="GRC" value={grc} onChange={(e) => setGrc(e.target.value)} variant="outlined"  size="small"/>
                        </FormControl>
                    </Col> */}
                    <Col xs={12} md={4}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" label="Guest Id" value={guestid} onChange={(e) => setGuestid(e.target.value)} variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={4}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" label="Guest Name" value={guestname} onChange={(e) => setGuestname(e.target.value)} variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" label="Age (Yrs)" value={guestage} onChange={(e) => setGuestage(e.target.value)} variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={4}>
                        <FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
                            <InputLabel id="demo-simple-select-label">Sex</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={gender}
                            label="Age"
                            onChange={(e) => setGender(e.target.value)}
                            size="small"
                            >
                            <MenuItem value={"Male"}>Male</MenuItem>
                            <MenuItem value={"Female"}>Female</MenuItem>
                            <MenuItem value={"Other"}>Other</MenuItem>
                           
                            </Select>
                        </FormControl>
                    </Col>
                </Row>
                <Row> 
                    <Col xs={12} md={4}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" onKeyPress={handleKeyPress} label="Mobile" value={mobile} onChange={(e) => setMobile(e.target.value)} variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={4}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" label="Email" value={email} onChange={(e) => setEmail(e.target.value)} variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={8}>
                        <TextareaAutosize 
                            minRows={2}
                            placeholder="Address"
                            value={address} onChange={(e) => setAddress(e.target.value)}
                            style={{background: 'transparent', width: '100%', marginTop: 25}}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" label="District" value={city} onChange={(e) => setCity(e.target.value)} variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={4}>
                        <FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
                                        <InputLabel id="demo-simple-select-label">State</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={state}
                                        label="Age"
                                        onChange={stateChange}
                                        size="small"
                                        >
                                        {
                                            statelist.map((item, index) => {
                                                return(
                                                    <MenuItem value={item.stid}>{item.stname}</MenuItem>
                                                )
                                            })
                                        }
                                        </Select>
                                    </FormControl>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" label="Pincode" onKeyPress={handleKeyPress} value={pincode} onChange={(e) => setPincode(e.target.value)} variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={4}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" label="Nationality" value={nationality} onChange={(e) => setNationality(e.target.value)} variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" label="GST" variant="outlined" value={guestgst} onChange={(e) => setGuestgst(e.target.value)}  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={4}>
                        <FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
                            <InputLabel id="demo-simple-select-label">Address Proof</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={addressproof}
                            label="Age"
                            onChange={(e) => setAddressproof(e.target.value)}
                            size="small"
                            >
                            <MenuItem value={"Aadhar"}>Aadhar</MenuItem>
                            <MenuItem value={"Passport"}>Passport</MenuItem>
                            <MenuItem value={"Voterid"}>Voter id</MenuItem>
                            <MenuItem value={"drivinglicense"}>Driving License</MenuItem>
                            <MenuItem value={"Other"}>Other</MenuItem>
                            
                            </Select>
                        </FormControl>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" label="Purpose" value={purpose} onChange={(e) => setPurpose(e.target.value)} variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={4}>
                        <div style={{marginTop: 15}}>
                            <input type="file"  onChange={(e) => batchfile(e)}  style={{marginTop: 10}}/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={3} style={{marginTop: 30}}>
                        {loader ?
                        <Rings
                            height="50"
                            width="50"
                            color='#0d6efd'
                            ariaLabel='loading'
                        /> :
                        <Button onClick={() => onSubmit()} style={{backgroundColor: '#03a5fc', color: '#fff'}} variant="contained">Update</Button>}
                    </Col>
                </Row>
            </Paper>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(EditAddress);
