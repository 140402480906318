import React, {useState, useEffect} from 'react';
import {Container, Row, Col, Form, Button} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { connect } from "react-redux";
import { BiSearch } from "react-icons/bi";

function Checkin(props){
    const [age, setAge] = React.useState('');
    const [value, setValue] = React.useState(new Date('2022-02-28T21:11:54'));
    const [value2, setValue2] = React.useState('1');
    const [file, setFile] = React.useState();

    const [roomno, setRoomno] = React.useState("")
    const [floor, setFloor] = React.useState("")
    const [type, setType] = React.useState("")
    const [source, setSource] = React.useState("")
    const [tariff, setTariff] = React.useState(0)
    const [discount, setDiscount] = React.useState("")
    const [discountamt, setDiscountamt] = React.useState("")
    const [code, setCode] = React.useState("")
    
    const [cgst, setCgst] = React.useState(0)
    const [sgst, setSgst] = React.useState(0)
    const [cgstper, setCgstper] = React.useState(6)
    const [sgstper, setSgstper] = React.useState(6)

    const [adult, setAdult] = React.useState("")
    const [children, setChildren] = React.useState("")
    const [hrs, setHrs] = React.useState("")
    const [guestid, setGuietid] = React.useState("")
    const [guestname, setGuestname] = React.useState("")
    const [guestage, setGuestage] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [mobile, setMobile] = React.useState("")
    const [gender, setGender] = React.useState("")
    const [address, setAddress] = React.useState("")
    const [city, setCity] = React.useState("")
    const [state, setState] = React.useState("")
    const [pincode, setPincode] = React.useState("")
    const [panno, setPanno] = React.useState("")
    const [bookingid, setBookingid] = React.useState("")
    const [extrabed, setExtrabed] = React.useState(0)

    const [nationality, setNationality] = React.useState("")
    const [addressproof, setAddressproof] = React.useState("")
    const [guestgst, setGuestgst] = React.useState("")
    const [rvrsecharge, setRvrsecharge] = React.useState(0)
    const [purpose, setPurpose] = React.useState("")
    const [password, setPassword] = React.useState("")

    const [paymode, setPaymode] = React.useState("")
    const [rcptno, setRcptno] = React.useState("")
    const [note, setNote] = React.useState("")
    const [advance, setAdvance] = React.useState("")
    const [roomid, setRoomid] = React.useState("")
    const [sourceid, setSourceid] = React.useState("")
    const [docno, setDocno] = React.useState("")
    const [docnum, setDocnum] = React.useState("")

    const [sourcelist, setSourcelist] = React.useState([])
    const [statelist, setStatelist] = React.useState([])
    
    const [discountmasterlist, setDiscountmasterlist] = React.useState([])

   const [roomtypeid,  setRoomtypeid] = React.useState("")

   const [tarifcalc, setTarifcalc] = React.useState(0)

   const [savehide, setSavehide] = React.useState(false);
    

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();

    const [earlycheckin, setEarlycheckin] = React.useState(year + '-' + month + '-' + date + "T" + hours + ":"  + minutes)
    const [departure, setDeparture] = React.useState(year + '-' + month + '-' + date + "T" + hours + ":"  + minutes)
    const [arrival, setArrival] = React.useState(year + '-' + month + '-' + date + "T" + hours + ":"  + minutes)

    function handleKeyPress(event) {
        if (!/^\d*\.?\d*$/.test(event.key)) {
        event.preventDefault();
        }
    }

  const handleChange2 = (event, newValue) => {
    setValue2(newValue);
  };

  const handleChange1 = (newValue) => {
    setValue(newValue);
  };

  const extrabedChange = (event) => {
      setExtrabed(event.target.value)
  }

  const stateChange = (event) =>  {
    setState(event.target.value)
  }

  

  const discountChange = (event) => {
      setDiscount(event.target.value)
  }

  const hrsChange = (event) => {
    setHrs(event.target.value)
  }

  const genderChange = (event) => {
      setGender(event.target.value)
  }

  const addressproofChange = (event) => {
    setAddressproof(event.target.value)
  }

  const paymodeChange = (event) => {
    setPaymode(event.target.value)
  }

  const rvrsechargeChange = (event) => {
    setRvrsecharge(event.target.value)
  }


  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const batchfile = (e) => {
    console.log("e.target.files[0]", e.target.files)
    let selectedFile = e.target.files;
        let file = null;
        let fileName = "";
        //Check File is not Empty
        if (selectedFile.length > 0) {
            // Select the very first file from list
            let fileToLoad = selectedFile[0];
            fileName = fileToLoad.name;
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function(fileLoadedEvent) {
                file = fileLoadedEvent.target.result;
                // Print data in console
               
                const regex = /data:.*base64,/
                console.log(file.replace(regex,""));
                setFile(file.replace(regex,""))
            };
            // Convert data to base64
            fileReader.readAsDataURL(fileToLoad);
            console.log("fileReader", fileReader);
        }
  }

  useEffect(() => {
        getData()

        console.log("userid", global.userid)
    }, []);

    useEffect(() => {
        if(rvrsecharge == 0) {
            
                console.log("tariff >", tariff)
                var sum = parseFloat(tariff)  + (tariff/100 * (parseFloat(cgstper)+parseFloat(sgstper)))
                setCgst((tariff/100 * parseFloat(cgstper)).toFixed(2))
                setSgst((tariff/100 * parseFloat(sgstper)).toFixed(2))
                setTarifcalc(sum.toFixed(2))
            
            // else{
            //     console.log("tariff<", tariff)
            //     setCgst(0)
            //     setSgst(0)
            //     setTarifcalc(tariff)
            // }
            
        }
        else{
            // var sum = Math.round(parseFloat(tariff)  + (tariff/(100 + parseFloat(cgstper)+parseFloat(sgstper)) * 12))
            // setTarifcalc(sum)
        }
        
    }, [tariff, rvrsecharge]);

    useEffect(() => {
        if(rvrsecharge == 0) {
            
                var sum = parseFloat(tariff)  + (tariff/100 * (parseFloat(cgstper)+parseFloat(sgstper)))
                setCgst((tariff/100 * parseFloat(cgstper)).toFixed(2))
                setSgst((tariff/100 * parseFloat(sgstper)).toFixed(2))
                setTarifcalc(sum.toFixed(2))
            
        }
        else{
            
                var sum = parseFloat(tarifcalc)  - (tarifcalc/(100 + parseFloat(cgstper)+parseFloat(sgstper)) * 12)
                console.log("sum", sum)
                setTariff(sum.toFixed(2))
                setCgst(((tarifcalc/(100 + parseFloat(cgstper)+parseFloat(sgstper)) * 12)/2).toFixed(2))
                setSgst(((tarifcalc/(100 + parseFloat(cgstper)+parseFloat(sgstper)) * 12)/2).toFixed(2))
            }
           
        
    }, [tarifcalc, rvrsecharge]);




    

    const convertToBase64 = () => {
        
    }

    const getData = () => {
        console.log("bookingdetails", props.bookingdetails)
        setRoomno(props.bookingdetails.roomno)
        setFloor(props.bookingdetails.floor)
        setType(props.bookingdetails.roomtype)
        setSourcelist(props.bookingdetails.source)
        setRoomtypeid(props.bookingdetails.roomtypeid)
        setRoomid(props.roomid)
        setDocno(props.bookingdetails.docno)
        setDocnum(props.bookingdetails.docnonum)
        getDiscountmaster()
        getStatelist()
    }

    const getStatelist = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/State/GetStateList?countryId=102", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("GetStateList Response", responseJson)
            if(responseJson) {
                setStatelist(responseJson)
            }
            else{
                setStatelist([])
            }
           
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getDiscountmaster = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/DiscountMaster/GetDiscountMasterList", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("source Response", responseJson)
            if(responseJson) {
                setDiscountmasterlist(responseJson)
            }
            else{
                setDiscountmasterlist([])
            }
           
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const sourceChange = (event) => {
        setSourceid(event.target.value)
        var token = localStorage.getItem("token")
        fetch(global.url + "api/RoomTariff/GetRoomTariffBySourceId?sourceId=" + event.target.value + "&roomTypeId=" + roomtypeid + "&branchId="+ props.branchid, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("source Response", responseJson)
            if(responseJson == "No Data") {
                setTariff(0)
            }
            else{
                setTariff(responseJson.tariff)
            }
           
        })
        .catch((error)=>{
            console.log(error)
        })
    
      }

  const onSubmit = () => {
        if (new Date(arrival) > new Date(departure)){
            return alert('Checkin Time Should be less than Departure')
        }
        if(tariff == 0){
           return alert('Tariff Empty')
        } 
        if(tarifcalc == 0){
            return alert('Total empty')
        }
        if(guestid == "") {
            return alert('Guest id empty')
        }
        if(guestname == ""){
            return alert('Customer name empty')
        } 
        if(mobile == ""){
            return alert('Mobile Empty')
        }  
        if(address == ""){
            return alert('Address Empty')
        }  
        if(file == ""){
            return alert('File Not selected')
        }

        if(sourceid == ""){
            return alert('Source not selected')
        }
        setSavehide(true)
        var token = localStorage.getItem("token")
        console.log("token", token)
        const data = {
            "bookingid": 0,
            "roomid": roomid,
            "sourceid": sourceid,
            "tariff": tariff,
            "discountid": discount,
            "discount": discountamt,
            "extrabed": extrabed,
            "bedcode": code,
            "earlycheckin": earlycheckin,
            "sgst":sgst,
            "cgst": cgst,
            "adult": adult,
            "children": children,
            "extratime": hrs,
            "arrival": arrival,
            "departure": departure,
            "status": 17,
            "customerid": "",
            "branchid": props.branchid,
            "advance": advance,
            "notes": note,
            "formid": "",
            "docno": docno,
            "docnum": docnum,
            "createdby": global.userid,
            "createdon": "",
            "updatedby": global.userid,
            "updatedon": "",
            "paymode": paymode,
            "refno": "",
            "payhours": 1,
            "vid": 1,
            "guestid": guestid,
            "billno": "",
            "billnonum": 1,
            "reversecharge": rvrsecharge,
            "billdate": "",
            "customerDetails": {
                "customerid": 0,
                "customername": guestname,
                "addresS1": address,
                "addresS2": city,
                "addresS3": "",
                "state": state,
                "district": city,
                "zipcode" : pincode,
                "phone": mobile,
                "mobile": mobile,
                "email": email,
                "gstin": guestgst,
                "other": "",
                "panno": panno,
                "proofid": file,
                "proofnumber": addressproof,
                "purpose": purpose,
                "nationality": nationality,
                "status": "",
                "gender": gender
            },
            "roomDetails": [
                {
                "roomid": roomid,
                }
            ],
            "billDetails": [
                {
                "bookedroomid": "",
                "vid": "",
                "roomid": roomid,
                "roomguest": "",
                "roomrate": "",
                "sgst": sgst,
                "cgst": cgst,
                "noofdays": 1,
                "startdate": arrival,
                "enddate":  departure
                }
            ],
            "amountsInWords": "",
            "createdUser": 1,
            "taxDetails": {
                "hsn": "",
                "tariff": tariff,
                "sgst": sgst,
                "cgst": cgst,
                "sales":"",
                "total": tarifcalc
            },
            "branchDetails": {
                "brid": props.branchid,
            }
            }
    
        console.log("save data", data)
    
        fetch(global.url + "api/Booking/SaveBooking", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setSavehide(false)
            console.log("Booking Response", responseJson)
            if(responseJson){
                alert("success")
                props.parentCallback()
            }
        })
        .catch((error)=>{
            alert("username or password error")
            console.log(error)
        })
    }

    // const mobileChange = (text) => {
    //     setMobile(text)
    //     var token = localStorage.getItem("token")
    //     fetch(global.url + "api/Booking/GetCustomerByPhoneNumber?phoneNumber="+ text, {
    //         method: "GET",
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + token
    //         },
            
    //     })
    //     .then((response)=> response.json())
    //     .then((responseJson)=>{
    //         console.log("GetCustomerByPhoneNumber response", responseJson)
    //         if(responseJson.customerid > 0) {
    //             setGuestname(responseJson.customername)
    //             setGuestage(responseJson.age)
    //             setGender(responseJson.gender)
    //             setEmail(responseJson.email)
    //             setAddress(responseJson.addresS1)
    //             setCity(responseJson.customername)
    //             setState(responseJson.state)
                
    //             setPanno(responseJson.panno)
    //             setNationality(responseJson.nationality)
                
    //             setGuestgst(responseJson.gstin)
                
                
    //             setNote(responseJson.customername)
    //         }
            
    //     })
    //     .catch((error)=>{
    //         console.log(error)
    //     })
    // }

    const mobileSearch = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Booking/GetCustomerByPhoneNumber?phoneNumber="+ mobile, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("GetCustomerByPhoneNumber response", responseJson)
            if(responseJson.customerid > 0) {
                setGuestname(responseJson.customername)
                setGuestage(responseJson.age)
                setGender(responseJson.gender)
                setEmail(responseJson.email)
                setAddress(responseJson.addresS1)
                setCity(responseJson.district)
                setState(responseJson.state)  
                // setPincode(responseJson.state) 
                setPanno(responseJson.panno)
                setNationality(responseJson.nationality) 
                setAddressproof(responseJson.proof) 
                setGuestgst(responseJson.gstin)  
                setPurpose(responseJson.purpose) 
            } 
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    return(
        <div>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value2}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange2} aria-label="lab API tabs example">
                        <Tab label="Room Details" value="1" />
                        <Tab label="Guest Details" value="2" />
                        <Tab label="Payment Details" value="3" />
                    </TabList>
                    </Box>
                    <TabPanel value="1">
                        <p style={{fontSize: 25, fontWeight: 'bold', color: '#000', textAlign: 'center', marginBottom: 'unset'}}>Room Details</p>
                        <div>
                            <Row>
                                <Col xs={12} md={6}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField disabled value={roomno} onChange={(e) => setRoomno(e.target.value)} id="outlined-basic" label="Room No." variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField disabled value={floor} onChange={(e) => setFloor(e.target.value)} id="outlined-basic" label="Floor" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField disabled value={type} onChange={(e) => setType(e.target.value)} id="outlined-basic" label="Type" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
                                        <InputLabel id="demo-simple-select-label">Reverse Charge</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={rvrsecharge}
                                        label="Age"
                                        onChange={rvrsechargeChange}
                                        size="small"
                                        >
                                        <MenuItem value={0}>NO</MenuItem>
                                        <MenuItem value={1}>YES</MenuItem>
                    
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
                                        <InputLabel id="demo-simple-select-label">Source</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={sourceid}
                                        label="Age"
                                        onChange={sourceChange}
                                        size="small"
                                        >
                                            {
                                                sourcelist?.map((i) => {
                                                    return(
                                                        <MenuItem value={i.sourceid}>{i.sourcename}</MenuItem>
                                                    )
                                                })
                                            }
                                       
                                        </Select>
                                    </FormControl>
                                    <p style={{fontSize: 12, color: '#4a4a4a', marginBottom: 'unset', marginTop: 5}}>Required*</p>
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField onKeyPress={handleKeyPress} value={tariff} onChange={(e) => setTariff(e.target.value)} id="outlined-basic" label="Actual Tarif" variant="outlined"  size="small"/>
                                    </FormControl>
                                    <p style={{fontSize: 12, color: '#4a4a4a', marginBottom: 'unset', marginTop: 5}}>Required*</p>
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField onKeyPress={handleKeyPress} value={tarifcalc} onChange={(e) => setTarifcalc(e.target.value)} id="outlined-basic" label="Total" variant="outlined"  size="small"/>
                                    </FormControl>
                                    <p style={{fontSize: 12, color: '#4a4a4a', marginBottom: 'unset', marginTop: 5}}>Required*</p>
                                </Col>
                                
                                <Col xs={12} md={6}>
                                    <FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
                                        <InputLabel id="demo-simple-select-label">Discount</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={discount}
                                        label="Age"
                                        onChange={discountChange}
                                        size="small"
                                        >
                                        {
                                            discountmasterlist.map((i) => {
                                                return(
                                                    <MenuItem value={i.discid}>{i.discname}</MenuItem>
                                                )
                                            })
                                        }
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField onKeyPress={handleKeyPress} value={discountamt} onChange={(e) => setDiscountamt(e.target.value)} id="outlined-basic" label="Discount Amount" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                            </Row>
                        </div>
                        <p style={{fontSize: 18, fontWeight: 'bold', color: '#000', marginTop: 20, marginBottom: 'unset'}}>Tarif After Discount {tarifcalc} Rs</p>
                        <div>
                            <Row>
                                <Col xs={12} md={4}>
                                    <FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
                                        <InputLabel id="demo-simple-select-label">Extra Bed</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={extrabed}
                                        label="Age"
                                        onChange={extrabedChange}
                                        size="small"
                                        >
                                        <MenuItem value={0}>0</MenuItem>
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {/* <p style={{fontSize: 12, color: '#4a4a4a', marginBottom: 'unset', marginTop: 5}}>Required*</p> */}
                                </Col>
                                <Col xs={12} md={4}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField value={code} onChange={(e) => setCode(e.target.value)} id="outlined-basic" label="Code" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={4}>
                                    <FormControl sx={{ mt: 3, width: '100%' }}>
                                            <TextField
                                                id="datetime-local"
                                                label="Early checkin"
                                                type="datetime-local"
                                                value={earlycheckin}
                                                sx={{ width: 250 }}
                                                InputLabelProps={{
                                                shrink: true,
                                                }}
                                                onChange={(newValue) => {
                                                    setEarlycheckin(newValue.target.value);
                                                }}
                                            />
                                        </FormControl>
                                </Col>
                                <Col xs={12} md={4}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField onKeyPress={handleKeyPress} value={cgst} onChange={(e) => setCgst(e.target.value)} id="outlined-basic" label="CGST 0.00%" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={4}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField onKeyPress={handleKeyPress} value={sgst} onChange={(e) => setSgst(e.target.value)} id="outlined-basic" label="SGST 0.00%" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                            </Row>
                        </div>
                        <p style={{fontSize: 18, fontWeight: 'bold', color: '#000', marginTop: 20, marginBottom: 'unset'}}>Total Rent Per Day {tarifcalc} Rs</p>
                        <div>
                            <Row>
                                <Col xs={12} md={4}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField onKeyPress={handleKeyPress} value={adult} onChange={(e) => setAdult(e.target.value)} id="outlined-basic" label="Adult" variant="outlined"  size="small"/>
                                    </FormControl>
                                    
                                </Col>
                                <Col xs={12} md={4}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField onKeyPress={handleKeyPress} value={children} onChange={(e) => setChildren(e.target.value)} id="outlined-basic" label="Children" variant="outlined"  size="small"/>
                                    </FormControl>
                                   
                                </Col>
                                <Col xs={12} md={4}>
                                    <FormControl f ullWidth sx={{ mt: 2, width: '100%' }}>
                                        <InputLabel id="demo-simple-select-label">HRS</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={hrs}
                                        label="Age"
                                        onChange={hrsChange}
                                        size="small"
                                        >
                                        <MenuItem value={12}>12</MenuItem>
                                        <MenuItem value={24}>24</MenuItem>
                                        </Select>
                                    </FormControl>
                                    
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormControl sx={{ mt: 3, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="Arrival"
                                            type="datetime-local"
                                            value={arrival}
                                            sx={{ width: 250 }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setArrival(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                   
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormControl sx={{ mt: 3, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="Departure"
                                            type="datetime-local"
                                            
                                            sx={{ width: 250 }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            defaultValue={departure}
                                            onChange={(newValue) => {
                                            setDeparture(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                    
                                </Col>
                            </Row>
                        </div>
                    </TabPanel>
                    <TabPanel value="2">
                        <p style={{fontSize: 25, fontWeight: 'bold', color: '#000', textAlign: 'center', marginBottom: 'unset'}}>Guest Details</p>
                        <div>
                            <Row>
                                <Col xs={12} md={6}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField  maxLength={50} value={guestid} onChange={(e) => setGuietid(e.target.value)} id="outlined-basic" label="Guest Id" variant="outlined"  size="small"/>
                                    </FormControl>
                                    <p style={{fontSize: 12, color: '#4a4a4a', marginBottom: 'unset', marginTop: 5}}>Required*</p>
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField value={guestname} onChange={(e) => setGuestname(e.target.value)} id="outlined-basic" label="Name" variant="outlined"  size="small"/>
                                    </FormControl>
                                    <p style={{fontSize: 12, color: '#4a4a4a', marginBottom: 'unset', marginTop: 5}}>Required*</p>
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField onKeyPress={handleKeyPress} value={guestage} onChange={(e) => setGuestage(e.target.value)} id="outlined-basic" label="Age" variant="outlined"  size="small"/>
                                    </FormControl>
                                  
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
                                        <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={gender}
                                        label="Age"
                                        onChange={genderChange}
                                        size="small"
                                        >
                                        <MenuItem value={"Male"}>Male</MenuItem>
                                        <MenuItem value={"Female"}>Female</MenuItem>
                                        <MenuItem value={"Other"}>Other</MenuItem>
                                        </Select>
                                    </FormControl>
                                   
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField value={email} onChange={(e) => setEmail(e.target.value)} id="outlined-basic" label="Email" variant="outlined"  size="small"/>
                                    </FormControl>
                                   
                                </Col>
                                <Col xs={12} md={6}>
                                    <Row>
                                        <Col xs={8} md={10}>
                                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                                <TextField type="number" value={mobile} onChange={(e) => setMobile(e.target.value)} id="outlined-basic" label="Mobile" variant="outlined"  size="small"/>
                                            </FormControl>
                                            <p style={{fontSize: 12, color: '#4a4a4a', marginBottom: 'unset', marginTop: 5}}>Required*</p>
                                        </Col>
                                        <Col xs={4} md={2}>
                                            <a onClick={mobileSearch} style={{cursor: 'pointer'}}>
                                                <BiSearch color="#000" size={30} style={{ marginTop: 20}} />
                                            </a>
                                        </Col>
                                    </Row>
                                    
                                </Col>
                                <Col xs={12}>
                                    <TextareaAutosize 
                                        minRows={2}
                                        maxLength={200}
                                        placeholder="Address"
                                        value={address} onChange={(e) => setAddress(e.target.value)}
                                        style={{background: 'transparent', width: '100%', marginTop: 20}}
                                    />
                                    <p style={{fontSize: 12, color: '#4a4a4a', marginBottom: 'unset', marginTop: 5}}>Required*</p>
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField value={city} onChange={(e) => setCity(e.target.value)} id="outlined-basic" label="District" variant="outlined"  size="small"/>
                                    </FormControl>
                                   
                                </Col>
                                
                                <Col xs={12} md={4}>
                                    <FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
                                        <InputLabel id="demo-simple-select-label">State</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={state}
                                        label="Age"
                                        onChange={stateChange}
                                        size="small"
                                        >
                                        {
                                            statelist.map((item, index) => {
                                                return(
                                                    <MenuItem value={item.stid}>{item.stname}</MenuItem>
                                                )
                                            })
                                        }
                                        </Select>
                                    </FormControl>
                                   
                                </Col>
                                  
                                <Col xs={12} md={6}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField type="number" value={pincode} onChange={(e) => setPincode(e.target.value)} id="outlined-basic" label="Pincode" variant="outlined"  size="small"/>
                                    </FormControl>
                                   
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField value={panno} onChange={(e) => setPanno(e.target.value)} id="outlined-basic" label="PAN No." variant="outlined"  size="small"/>
                                    </FormControl>
                                   
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField value={nationality} onChange={(e) => setNationality(e.target.value)} id="outlined-basic" label="Nationality" variant="outlined"  size="small"/>
                                    </FormControl>
                                    
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
                                        <InputLabel id="demo-simple-select-label">Address Proof</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={addressproof}
                                        label="Age"
                                        onChange={addressproofChange}
                                        size="small"
                                        >
                                        <MenuItem value={"Aadhar"}>Aadhar</MenuItem>
                                        <MenuItem value={"Passport"}>Passport</MenuItem>
                                        <MenuItem value={"Voterid"}>Voter id</MenuItem>
                                        <MenuItem value={"drivinglicense"}>Driving License</MenuItem>
                                        <MenuItem value={"Other"}>Other</MenuItem>
                                        </Select>
                                    </FormControl>
                                   
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField value={guestgst} onChange={(e) => setGuestgst(e.target.value)} id="outlined-basic" label="Guest GST No." variant="outlined"  size="small"/>
                                    </FormControl>
                                   
                                </Col>
                                
                                <Col xs={12} md={6}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField value={purpose} onChange={(e) => setPurpose(e.target.value)} id="outlined-basic" label="Purpose" variant="outlined"  size="small"/>
                                    </FormControl>
                                    
                                </Col>
                                <Col xs={12} md={6}>
                                    <div style={{marginTop: 15}}>
                                        <input type="file"  onChange={(e) => batchfile(e)}  style={{marginTop: 10}}/>
                                    </div>
                                    <p style={{fontSize: 12, color: '#4a4a4a', marginBottom: 'unset', marginTop: 5}}>Required*</p>
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col xs={12} md={6}>
                                    <FormControl sx={{ mt: 4, width: '100%' }}>
                                        <TextField value={password} onChange={(e) => setPassword(e.target.value)} id="outlined-basic" label="Password" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                            </Row> */}
                            
                        </div>
                    </TabPanel>
                    <TabPanel value="3">
                        <p style={{fontSize: 25, fontWeight: 'bold', color: '#000', textAlign: 'center', marginBottom: 'unset'}}>Payment Details</p>
                        <div>
                            <Row>
                                <Col xs={12} md={4}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField onKeyPress={handleKeyPress} value={advance} onChange={(e) => setAdvance(e.target.value)} id="outlined-basic" label="Advance" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={4}>
                                    <FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
                                        <InputLabel id="demo-simple-select-label">Payment Mode</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={paymode}
                                        label="Age"
                                        onChange={paymodeChange}
                                        size="small"
                                        >
                                        <MenuItem value={10}>Cash</MenuItem>
                                        <MenuItem value={20}>Online</MenuItem>
                                     
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={4}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField value={rcptno} onChange={(e) => setRcptno(e.target.value)} id="outlined-basic" label="Receipt Number" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                <Col xs={12}>
                                    <TextareaAutosize 
                                        minRows={2}
                                        placeholder="Note"
                                        value={note} onChange={(e) => setNote(e.target.value)}
                                        style={{background: 'transparent', width: '100%', marginTop: 20}}
                                    />
                                </Col>
                            </Row>
                            {savehide == true ? null :
                            <Button onClick={() => onSubmit()} variant="contained" style={{marginTop: 30, backgroundColor: '#03a5fc', marginRight: 10, width: 200, color: '#fff'}}>Book</Button>}
                        </div>
                    </TabPanel>
                    
                </TabContext>
            </Box>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
    };
};

export default connect(mapStateToProps)(Checkin);