import React, {useState, useEffect,useRef} from 'react';
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button, Modal} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TimePicker from '@mui/lab/TimePicker';
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { connect } from "react-redux";
import ReactToPrint from 'react-to-print';
import { Rings  } from  'react-loader-spinner';
import logo from '../../assets/images/logo.jpeg';

const columns = [
    { id: '1', label: 'Sl No#', minWidth: 50,span: false },
    { id: '1', label: 'Invoice', minWidth: 50,span: false },
    { id: '2', label: 'Location', minWidth: 50,span: false },
    { id: '3', label: 'Building', minWidth: 50,span: false },
    { id: '4', label: 'Street', minWidth: 50,span: false },
    { id: '5', label: 'Zone', minWidth: 50,span: false },   
    { id: '6', label: 'Mobile', minWidth: 50,span: false },
    { id: '7', label: 'Rate', minWidth: 50,span: false },
    { id: '8', label: 'Item, Colour, Size, Quantity', minWidth: 50,span: false },  
    // { id: '9', label: 'Description', minWidth: 50,span: false },
    { id: '10', label: 'By', minWidth: 50,span: false },
    { id: '11', label: 'Remarks', minWidth: 50,span: false },  
    { id: '7', label: 'Order Time', minWidth: 50,span: false },
  ];

  

  function Purchasereport({branchid, branchdetails}) {

    const [source, setSource] = React.useState([])
    const componentRef = useRef();
    

    const [counterlist, setCounterlist] = React.useState([]);
    const [counter, setCounter] = useState("");
    const [grandtotal, setGrandtotal] = useState(0);
    const [loader, setLoader] = useState(false);

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();


    const [fromdate, setFromdate] = React.useState(year + '-' + month + '-' + date);
    const [todate, setTodate] = React.useState(year + '-' + month + '-' + date);
  

    const counterChange = (event) => {
        setCounter(event.target.value)
      }

    useEffect(() => {
        getData()
        getCounter()
    }, [branchid]);

    useEffect(() => {
        getTotalammount()
    }, [source]);

    const getTotalammount = () => {
        var totalAmount = 0;
        
        for (var i = 0; i < source.length; i++) {
            for (var j = 0; j < source[i].details.length; j++) {
                    totalAmount += parseFloat(source[i].details[j].dttotal)
            }
       }
          setGrandtotal(totalAmount.toFixed(2))
    }

    const getCounter = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Counter/GetCounterlist?companyId=1&branchId="+ branchid + "&includeBlocked=false", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("counterlist response", responseJson)
              setCounterlist(responseJson)
          })
          .catch((error)=>{
              console.log(error)
          })
      }


    const getData = () => {
        var token = localStorage.getItem("token")
        console.log(token)
        var data = {
            "companyId": 1,
            "branchId": branchid,
            "fromDate":  fromdate,
            "toDate": todate,
            "createdBy": global.userid,
            "billno": "",
            "counterId": counter
          }
          console.log("data", data)
        fetch(global.url + "api/Purchase/GetPurchaseList", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("purchase report Response", responseJson)
             setSource(responseJson)
        })
        .catch((error)=>{
            setLoader(false)
            console.log(error)
        })
    }

    // const editClick = (data) => {
    //     setDetailstable(data)
    //     setModal(true)
    // }

    const formatDate = (datestring) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
          console.log("timeString12hr", timeString12hr)

          var formattedDate =  day+"/"+month+"/"+year
          return formattedDate; //03/02/2021
    }

    const formatDate2 = (datestring) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
        //   console.log("timeString12hr", timeString12hr)

          var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          return formattedDate; //03/02/2021
    }

      return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2 }}>
            <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>Purchase Report</p> 
            <Row>
                    <Col xs={12}  className='columnborder'>
                        <div>
                            <Row>
                            <Col sm={6} lg={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="From Date"
                                            type="date"
                                            value={fromdate}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setFromdate(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                    
                                </Col>
                                <Col sm={6} lg={3}>
                                <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="To Date"
                                            type="date"
                                            value={todate}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setTodate(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={2}>
                                    <div style={{justifyContent: 'center', display: 'flex'}}>
                                            {
                                                loader ?
                                                <Rings
                                                    height="50"
                                                    width="50"
                                                    color='#0d6efd'
                                                    ariaLabel='loading'
                                                />: 
                                                <Button onClick={getData} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 17, width: '50%'}} variant="contained">Search</Button>
                                            }
                                        </div>
                                </Col>
                                <Col xs={12} md={4} >
                                    <ReactToPrint
                                        trigger={() => <Button style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 17, width: '50%'}} variant="contained">Print this out!</Button>}
                                        content={() => componentRef.current}
                                    />
                                </Col>
                            </Row>
                           
                        </div>
                    </Col> 
                </Row>
                <div style={{margin: 20,}}>
                    <TableContainer sx={{ maxHeight: 440, marginTop: 2 }}>
                        <div  className='section-to-print' ref={componentRef} style={{width: '95%'}}>
                        <Table id="table-to-xls" stickyHeader aria-label="sticky table" >
                        <TableHead className="tableheaderexe">
                            <TableRow style={{height: 80}} >
                                <TableCell colSpan={8}  style={{padding: 0}}>
                                    <Row>
                                        <img src={logo} style={{width: 100, position: 'absolute', top: 0}}/>
                                        
                                        <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                                        <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                                        </Col>
                                        <p className='printdate' style={{ position: 'absolute', left: '85%'}}>{formatDate2(new Date)}</p>  
                                    </Row>
                                </TableCell>
                                </TableRow>
                                <TableRow>
                                
                                <TableCell colSpan={8} style={{padding: 0}}>
                                    <div style={{display: 'flex', justifyContent: 'center', fontWeight: 600}} className='captiontable'>
                                        PURCHASE REPORT FOR {fromdate.split("-").reverse().join("-")} TO {todate.split("-").reverse().join("-")}
                                    </div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Particulars</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Case</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Bots</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Total</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Rate</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Amount</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Tax</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Bill Total</TableCell>
                                <TableCell className='tablecellPrint noprint' style={{fontWeight: 'bold',width: "10%", textAlign: 'center'}}>
                                    Edit
                                </TableCell>
                                </TableRow>
                            </TableHead>
                        {
                            source.map((i, k) => {
                                return (
                                    <TableBody>
                                        <TableRow>
                                        <TableCell colSpan={9} className='tablecellPrint'>PO Date:{formatDate(i.pudocdate)}, Invoice No: {i.puinvoiceno}, Warehouse: {i.warehouse}</TableCell>
                                        </TableRow>
                                        {i.details.map((item, index) => {
                                            return(
                                                <TableRow>
                                                    <TableCell className='tablecellPrint'>{item.proname}</TableCell>
                                                    <TableCell className='tablecellPrint'>{item.caseno}</TableCell>
                                                    <TableCell className='tablecellPrint'>{item.bottle}</TableCell>
                                                    <TableCell className='tablecellPrint' >{item.dtqty}</TableCell>
                                                    <TableCell className='tablecellPrint' style={{textAlign: 'right'}}>{item.dtprice.toFixed(2)}</TableCell>
                                                    <TableCell className='tablecellPrint' style={{textAlign: 'right'}}>{item.dtamount.toFixed(2)}</TableCell>
                                                    <TableCell className='tablecellPrint' style={{textAlign: 'right'}}>{item.dttax.toFixed(2)}</TableCell>
                                                    <TableCell className='tablecellPrint' style={{textAlign: 'right'}}>{item.dttotal.toFixed(2)}</TableCell>
                                                    {index == 0 ? 
                                                <TableCell className='tablecellPrint noprint' style={{textAlign: 'center'}} rowSpan={i.details.length}>
                                                    <a href={'/Transaction/Purchase/' + i.vid} >
                                                        <FaIcons.FaEdit fontSize={20} color="blue" />
                                                    </a>
                                                </TableCell>: null
                                            } 
                                                </TableRow>
                                            )
                                        })}
                                        <TableRow>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 600}}>Total</TableCell>
                                            <TableCell  className='tablecellPrint' style={{fontWeight: 600}}>{i.totalcase}</TableCell>
                                            <TableCell  className='tablecellPrint' style={{fontWeight: 600}}>{i.totalbottle}</TableCell>
                                            <TableCell colSpan={2} className='tablecellPrint'></TableCell>
                                            <TableCell className='tablecellPrint' style={{fontWeight: 600,}}>{branchid == 1021 ? 'TCS' : 'CESS'}</TableCell>
                                            <TableCell className='tablecellPrint' style={{textAlign: 'right', fontWeight: 600}}>{i.pucessamount == null ? '0.00' : i.pucessamount.toFixed(2)}</TableCell>
                                            <TableCell className='tablecellPrint noprint'></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={6} className='tablecellPrint' ></TableCell>
                                            <TableCell className='tablecellPrint'  style={{fontWeight: 600,}}>Total</TableCell>
                                            <TableCell className='tablecellPrint' style={{textAlign: 'right', fontWeight: 600}}>{i.puamount.toFixed(2)}</TableCell>
                                            {/* <TableCell className='tablecellPrint noprint'></TableCell> */}
                                        </TableRow>
                                        </TableBody>
                                )
                                })
                            }
                             <TableBody>
                                <TableRow>
                                    <TableCell colSpan={6} className='tablecellPrint'></TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Grand Total</TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}}>{grandtotal}</TableCell>
                                    {/* <TableCell className='tablecellPrint noprint'></TableCell> */}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                    </TableContainer>
                </div>
            </Paper> 
        </div>
      );
  }

  const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(Purchasereport);
