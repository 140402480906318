import React from 'react'
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button, Table} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

function ReservedRooms(){
    const [age, setAge] = React.useState('');
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>Reserved Rooms</p> 
                <Row>
                    <Col xs={12}  className='columnborder'>
                        <div>
                            <Row>
                                <Col xs={12} md={3}>
                                    <FormControl fullWidth sx={{ mt: 3, width: '100%' }}>
                                        <InputLabel id="demo-simple-select-label">Source</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={age}
                                        label="Age"
                                        onChange={handleChange}
                                        size="small"
                                        >
                                        <MenuItem value={10}>room</MenuItem>
                                
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ mt: 3, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="Start Date"
                                            type="datetime-local"
                                            defaultValue="2017-06-24T10:30"
                                            sx={{ width: 250 }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ mt: 3, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="End Date"
                                            type="datetime-local"
                                            defaultValue="2017-05-24T10:30"
                                            sx={{ width: 250 }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                            </Row> 
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={3}>
                        <div style={{justifyContent: 'center', display: 'flex'}}>
                            <Button style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">Search</Button>
                        </div>
                    </Col>
                    <Col xs={12} md={3}>
                        <div style={{justifyContent: 'center', display: 'flex'}}>
                            <Button style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">Print</Button>
                        </div>
                    </Col>
                </Row>
            </Paper>
        </div>
    );
}

export default ReservedRooms;