   import React, {useState, useEffect,useRef} from 'react';
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button, Modal} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TimePicker from '@mui/lab/TimePicker';
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { connect } from "react-redux";
import ReactToPrint from 'react-to-print';
import { Rings  } from  'react-loader-spinner';
import logo from '../../assets/images/logo.jpeg';

const columns = [
    { id: '1', label: 'Sl No#', minWidth: 50,span: false },
    { id: '1', label: 'Invoice', minWidth: 50,span: false },
    { id: '2', label: 'Location', minWidth: 50,span: false },
    { id: '3', label: 'Building', minWidth: 50,span: false },
    { id: '4', label: 'Street', minWidth: 50,span: false },
    { id: '5', label: 'Zone', minWidth: 50,span: false },   
    { id: '6', label: 'Mobile', minWidth: 50,span: false },
    { id: '7', label: 'Rate', minWidth: 50,span: false },
    { id: '8', label: 'Item, Colour, Size, Quantity', minWidth: 50,span: false },  
    // { id: '9', label: 'Description', minWidth: 50,span: false },
    { id: '10', label: 'By', minWidth: 50,span: false },
    { id: '11', label: 'Remarks', minWidth: 50,span: false },  
    { id: '7', label: 'Order Time', minWidth: 50,span: false },
  ];

  

  function Purchasereport({branchid, branchdetails}) {
    const [age, setAge] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [source, setSource] = React.useState([])
    const [modal, setModal] = React.useState(false)
    const [detailstable, setDetailstable] = React.useState([])
    const [countername, setCountername] = React.useState("")
    
    const componentRef = useRef();
    

    const [counterlist, setCounterlist] = React.useState([]);
    const [counter, setCounter] = useState(1);
    const [loader, setLoader] = useState(false);

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();


    const [date1, setDate1] = React.useState(year + '-' + month + '-' + date);
    const [date2, setDate2] = React.useState('');
    
    const [openCre, setOpenCre] = React.useState(0);
    const [openDeb, setOpenDeb] = React.useState(0);
    const [TotCre, setTotCre] = React.useState(0);
    const [TotDeb, setTotDeb] = React.useState(0);
    const [balCre, setBalCre] = React.useState(0);
    const [balDeb, setBalDeb] = React.useState(0);

    const formatDate = (datestring, type) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
          

          if(type == "time") {
            var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          }
          else{
            var formattedDate =  day+"/"+month+"/"+year
          }
          return formattedDate; //03/02/2021
    }
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const counterChange = (event) => {
        setCounter(event.target.value)
        if(event.target.value == '1'){
            setCountername("Bar")
        }
        if(event.target.value == '2'){
            setCountername("Lodge")
        }
        if(event.target.value == '3'){
            setCountername("Restaurant")
        }
      }

    useEffect(() => {
        getData()
    }, [branchid, date1, counter]);

    useEffect(() => {
        setCountername("Bar")
    }, []);

    const getData = () => {
        setLoader(true)
        var token = localStorage.getItem("token")
        console.log(token)
        var data = {
            "companyId": 1,
            "branchId": branchid,
            "fromDate": date1,
            "toDate": date1,
            "createdBy": "",
            "billno": "",
            "counterId": counter,
            "userId": "",
            "soldItemsOnly": "",
            "accId": ""
          }
        fetch(global.url + "api/DayBook/GetVoucherDetailed", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("daybook Response", responseJson)
            setSource(responseJson.vouchers)
            setOpenCre(responseJson.openingcredit)
            setOpenDeb(responseJson.openingdebit)
            setTotCre(responseJson.creditsum)
            setTotDeb(responseJson.debitsum)
            setBalCre(responseJson.creditbal)
            setBalDeb(responseJson.debitbal)
            setDate2(responseJson.vouchers[0].vchdate)
            setLoader(false)
        })
        .catch((error)=>{ 
            setLoader(false)
            console.log(error)
        })
    }
    const formatDate2 = (datestring) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
        //   console.log("timeString12hr", timeString12hr)

          var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          return formattedDate; //03/02/2021
    }

      return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2 }}>
            <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>Daybook Details</p> 
            <Row>
                    <Col xs={12}  className='columnborder'>
                        <div>
                            <Row>
                                <Col sm={6} lg={3}>
                                    <FormControl sx={{ mt: 3, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setDate1(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                {/* <Col sm={6} lg={3}>
                                    <FormControl sx={{ mt: 3, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="To"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setDate2(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col> */}
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ mt: 3, width: '100%' }}>
                                            <InputLabel id="demo-simple-select-helper-label">Counter</InputLabel>
                                            <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            label="Age"
                                            size="small"
                                            value={counter}
                                            onChange={counterChange}
                                            >
                                            <MenuItem value='1'>Bar</MenuItem>
                                            <MenuItem value='2'>Lodge</MenuItem>
                                            <MenuItem value='3'>Restaurant</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={2}>
                                    <div style={{justifyContent: 'center', display: 'flex'}}>
                                            {
                                                loader ?
                                                <Rings
                                                    height="50"
                                                    width="50"
                                                    color='#0d6efd'
                                                    ariaLabel='loading'
                                                />: 
                                                <Button onClick={getData} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">Search</Button>
                                            }
                                        </div>
                                </Col>
                                <Col xs={12} md={4} >
                                    <ReactToPrint
                                        trigger={() => <Button style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">Print this out!</Button>}
                                        content={() => componentRef.current}
                                    />
                                </Col>
                            </Row>
                           
                        </div>
                    </Col> 
                </Row>
                <div style={{margin: 20,}}>
                    <TableContainer sx={{ maxHeight: 440, marginTop: 2 }}>
                        <div  className='section-to-print' ref={componentRef} style={{width: '93%', marginLeft: 50}}>
                            {/* <Row> 
                                
                                <img src={require("../../assets/images/logo.jpeg").default} style={{width: 100, position: 'absolute'}}/>
                                
                                <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                                <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                                </Col>
                            </Row> */}
                        <Table id="table-to-xls" className="daybook" stickyHeader aria-label="sticky table">
                        {/* <caption className='captiontable'>Daybook Details of {countername} for {date1.split("-").reverse().join("-")}</caption> */}
                        <TableHead className="tableheaderexe">
                        <TableRow style={{height: 80}} >
                                    <TableCell colSpan={4}  style={{padding: 0}}>
                                        <Row>
                                            <img src={logo} style={{width: 100, position: 'absolute', top: 0}}/>
                                            
                                            <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                                            <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                                            </Col>
                                            <p className='printdate' style={{ position: 'absolute', left: '85%'}}>{formatDate2(new Date)}</p>  
                                        </Row>
                                    </TableCell>
                                 </TableRow>
                                 <TableRow>
                                    
                                    <TableCell colSpan={4} style={{padding: 0}}>
                                        <div style={{display: 'flex', justifyContent: 'center', fontWeight: 600}} className='captiontable'>
                                            DAYBOOK DETAILS OF {countername} FOR {date1.split("-").reverse().join("-")}
                                        </div>
                                    </TableCell>
                                </TableRow>
                        <TableRow>
                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '10%'}}>Account</TableCell>
                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '60%'}}>Description</TableCell>
                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right', width: '15%'}}>Debit</TableCell>
                            <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right', width: '15%'}}>Credit</TableCell>
                        </TableRow>
                        </TableHead>
                            <TableBody>
                                    <TableRow>
                                        <TableCell style={{fontWeight: 'bold'}} colSpan={2} className='tablecellPrint'>Opening Balance</TableCell>
                                        <TableCell style={{fontWeight: 'bold', textAlign: 'right'}} className='tablecellPrint'>{openDeb == 0 ? null : openDeb.toFixed(2)}</TableCell>
                                        <TableCell style={{fontWeight: 'bold', textAlign: 'right'}} className='tablecellPrint'>{openCre == 0 ? null : openCre.toFixed(2)}</TableCell>
                                    </TableRow>
                                    {date2 == "" ? null :
                                    <TableRow>
                                        <TableCell colSpan={4} className='tablecellPrint'>{formatDate(date2, "date")}</TableCell>
                                    </TableRow>}
                                    {source?.map((item, index) => {
                                        return(
                                            item.details?.map((i, k) =>(
                                                <>
                                                {i.accname == "" ? null :
                                                    <TableRow>
                                                        <TableCell style={{fontWeight: 'bold'}} className="unboarder">{k == 0 ? i.acccode : null}</TableCell>
                                                        <TableCell style={{fontWeight: 'bold'}} className="unboarder">{i.accname}</TableCell>
                                                        <TableCell style={{fontWeight: 'bold', textAlign: 'right'}} className="unboarder">{k==0 ? (item.debit1 == 0 ? null : item.debit1.toFixed(2)):null}</TableCell>
                                                        <TableCell style={{fontWeight: 'bold', textAlign: 'right'}} className="unboarder">{k==0 ? (item.credit1 == 0 ? null : item.credit1.toFixed(2)):null}</TableCell>
                                                    </TableRow>
                                                    }
                                                    {i.dtnarration == "" ? null :
                                                    <TableRow>
                                                        <TableCell style={{fontWeight: 'bold'}} className="unboarder"></TableCell>
                                                        <TableCell  className=" spacereduce" style={{padding: 7, borderBottom: 'none'}}>
                                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                                <p className="unboarder1" style={{marginBottom: 'unset', fontSize: 12, lineHeight: 1.5}}>{i.dtnarration}</p>
                                                                <p className="unboarder1" style={{marginBottom: 'unset', fontSize: 12, lineHeight: 1.5}}>{i.dtcredit == 0 ? i.dtdebit.toFixed(2) : i.dtcredit.toFixed(2)}</p>
                                                            </div>
                                                           
                                                            {
                                                                item.details.length == k + 1 ?
                                                                <div>
                                                                    <hr style={{margin: '7px 0px 7px 0px'}}></hr>
                                                                    <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                                                        <p className="unboarder1" style={{marginBottom: 'unset', fontSize: 14}}>Total</p>
                                                                        <p className="unboarder1" style={{marginBottom: 'unset', fontSize: 14, fontWeight: 'bold'}}>{item.credit == 0 ? item.debit.toFixed(2) : item.credit.toFixed(2)}</p>
                                                                    </div>
                                                                    <hr style={{margin: '7px 0px 7px 0px'}}></hr>
                                                                </div>:
                                                                null
                                                            }
                                                            
                                                        </TableCell>
                                                        <TableCell style={{fontWeight: 'bold', textAlign: 'right'}} className="unboarder"></TableCell>
                                                        <TableCell style={{fontWeight: 'bold', textAlign: 'right'}} className="unboarder"></TableCell>
                                                    </TableRow>}
                                                </>   
                                            ))   
                                        )
                                    })}
                                    <TableRow>
                                        <TableCell style={{fontWeight: 'bold'}} colSpan={2} className='tablecellPrint'>Balance C/D</TableCell>
                                        <TableCell style={{fontWeight: 'bold', textAlign: 'right'}} className='tablecellPrint'>{balDeb == 0 ? null : balDeb.toFixed(2)}</TableCell>
                                        <TableCell style={{fontWeight: 'bold', textAlign: 'right'}} className='tablecellPrint'>{balCre == 0 ? null : balCre.toFixed(2)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell style={{fontWeight: 'bold'}} colSpan={2} className='tablecellPrint'>Grand Total</TableCell>
                                    <TableCell style={{fontWeight: 'bold', textAlign: 'right'}} className='tablecellPrint'>{TotDeb == 0 ? null : TotDeb.toFixed(2)}</TableCell>
                                    <TableCell style={{fontWeight: 'bold', textAlign: 'right'}} className='tablecellPrint'>{TotCre == 0 ? null : TotCre.toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{fontWeight: 'bold'}} colSpan={2} className='tablecellPrint'>Balance B/D</TableCell>
                                    <TableCell style={{fontWeight: 'bold', textAlign: 'right'}} className='tablecellPrint'>{balCre == 0 ? null : balCre.toFixed(2)}</TableCell>
                                    <TableCell style={{fontWeight: 'bold', textAlign: 'right'}} className='tablecellPrint'>{balDeb == 0 ? null : balDeb.toFixed(2)}</TableCell>
                                </TableRow>
                                    
                            </TableBody>
                            {/* {source?.map((j, index) => (
                                <TableBody style={{marginTop: 20}} className="bodytable">
                                    {
                                        j.details?.map((i, index) =>(
                                            <TableRow>
                                                <TableCell style={{fontWeight: 'bold'}} className="unboarder">{i.acccode}</TableCell>
                                                <TableCell style={{fontWeight: 'bold'}} className="unboarder">{i.accname}</TableCell>
                                                <TableCell style={{fontWeight: 'bold', textAlign: 'right'}} className="unboarder">{i.dtdebit}</TableCell>
                                                <TableCell style={{fontWeight: 'bold', textAlign: 'right'}} className="unboarder">{i.dtcredit}</TableCell>
                                            </TableRow>
                                        ))   
                                    }
                                    <TableRow>
                                        <TableCell style={{fontWeight: 'bold'}} colSpan={2} className='tablecellPrint'>Grand Total</TableCell>
                                        <TableCell style={{fontWeight: 'bold', textAlign: 'right'}} className='tablecellPrint'>{TotDeb.toFixed(2)}</TableCell>
                                        <TableCell style={{fontWeight: 'bold', textAlign: 'right'}} className='tablecellPrint'>{TotCre.toFixed(2)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            ))} */}
                            {/* <TableBody>
                                <TableRow>
                                    <TableCell style={{fontWeight: 'bold'}} colSpan={2} className='tablecellPrint'>Balance C/D</TableCell>
                                    <TableCell style={{fontWeight: 'bold', textAlign: 'right'}} className='tablecellPrint'>{balDeb}</TableCell>
                                    <TableCell style={{fontWeight: 'bold', textAlign: 'right'}} className='tablecellPrint'>{balCre}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{fontWeight: 'bold'}} colSpan={2} className='tablecellPrint'>Grand Total</TableCell>
                                    <TableCell style={{fontWeight: 'bold', textAlign: 'right'}} className='tablecellPrint'>{TotDeb}</TableCell>
                                    <TableCell style={{fontWeight: 'bold', textAlign: 'right'}} className='tablecellPrint'>{TotCre}</TableCell>
                                </TableRow>
                            </TableBody> */}
                        </Table>
                    </div>
                    </TableContainer>
                </div>
            </Paper> 
        </div>
      );
  }

  const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(Purchasereport);
