import React, { useState, useEffect } from "react";
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button, Modal} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { connect } from "react-redux";

const columns = [
    { id: '1', label: 'Group Name', minWidth: 50,span: false },
    { id: '2', label: 'Group Code', minWidth: 50,span: false },
    { id: '3', label: 'Main Group', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 100,
      span: true
    },
  ];

const source = [{}, {}]

function Group({branchid}){

    const [age, setAge] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [modal, setModal] = React.useState(false);

    const [source, setSource] = React.useState([]);
    const [maingrplist, setMaingrplist] = React.useState([]);

    


    const [grpname, setGrpname] = React.useState('');
    const [grpcode, setGrpcode] = React.useState('');
    const [maingrp, setMaingrp] = React.useState('');
    const [grpid, setGrpid] = React.useState('');
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };
    const handleChange = (event) => {
        setMaingrp(event.target.value);
    };

    useEffect(() => {
        getData()
        getMaingrp()
    }, [branchid]);

    const getMaingrp = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/MainGroup/GetMainGroupList", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("branch view Response", responseJson)
             setMaingrplist(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }


    const getData = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Groups/GetGrouplist?orderby=Name&companyId=1&branchId=" + branchid + "&includeBlocked=False", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("branch view Response", responseJson)
             setSource(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const onSubmit = () => {
        var token = localStorage.getItem("token")
        console.log("token", token)
        const data = {
            "grpid": grpid,
            "grpname": grpname,
            "grpmainid": maingrp,
            "grpblocked": 64,
            "grpcode": grpcode,
            "grpcomid": 1,
            "grpbranchid": branchid,
        }

        console.log("save data", data)

        fetch(global.url + "api/Groups/SaveGroup", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("Group Response", responseJson)
            if(responseJson.error == false) {
                alert(responseJson.message)
                setModal(false)
                getData()
            }
            else{
                alert(responseJson.message)
            }
        })
        .catch((error)=>{
            alert("Something went wrong")
            console.log(error)
        })
    }

    const editClick =(
        groupid,
        grpname,
        grpcode,
        grpmainid
    ) => {
        setModal(true)
        setGrpid(groupid)
        setGrpname(grpname)
        setGrpcode(grpcode)
        setMaingrp(grpmainid)
    }

    const onAdd = () => {
        setModal(true)
        setGrpid("")
        setGrpname("")
        setGrpcode("")
        setMaingrp("")
    }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
                <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Group</p>
                    <Button variant="primary" onClick={() => onAdd()} >Add</Button>
                </div>
                <div className='bulk-table'>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                                    colSpan={column.span == true ? 2: 1}
                                >
                                    {column.label}
                                    
                                </TableCell>
                                ))}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {source
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                return (
                                    <TableRow  tabIndex={-1} key={row.code}>
                                        <TableCell>
                                            {row.grpname}
                                        </TableCell>
                                        <TableCell>
                                            {row.grpcode}
                                        </TableCell>
                                        <TableCell>
                                            {row.mainGroup}
                                        </TableCell>                                        
                                        <TableCell >
                                            <a href="javascript:void(0)"  onClick={() => editClick(row.grpid,
                                                row.grpname,
                                                row.grpcode,
                                                row.grpmainid)}>
                                                <FaIcons.FaEdit fontSize={20} color="blue" />
                                            </a>
                                        </TableCell>
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
                        </TableContainer>
                        <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={source.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
            </Paper>
            <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Branch Creation
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={grpname} onChange={(e) => setGrpname(e.target.value)} id="outlined-basic" label="Group Name" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={grpcode} onChange={(e) => setGrpcode(e.target.value)} id="outlined-basic" label="Group Code" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl fullWidth sx={{width: '100%', mt: 3 }}>
                                <InputLabel id="demo-simple-select-label">Main Group</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={maingrp}
                                label="OYO TYPE"
                                onChange={handleChange}
                                size="small"
                                >
                                {
                                    maingrplist.map((i, k) => {
                                        return(
                                        <MenuItem value={i.migrpid}>{i.migrpname}</MenuItem>
                                        )
                                    })
                                }
                                </Select>
                            </FormControl>
                    </Col>
                    <Col xs={5}>
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                                <FormControlLabel control={
                                <Checkbox  />
                                } label="Enabled" />
                          </FormControl>
                    </Col>
                    <Col xs ={12} style={{marginTop:30}}>
                      <Button onClick={() => onSubmit()} style={{float: 'right'}} variant="primary">Submit</Button>
                      <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="danger">Cancel</Button>
                    </Col>
                    
                  </Row>
              </Modal.Body>
           </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
    };
};

export default connect(mapStateToProps)(Group);