import React, {useEffect, useState} from 'react'
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Form, Button, Modal} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import * as FaIcons from "react-icons/fa";
import { connect } from "react-redux";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

const columns = [
    { id: '1', label: 'Sl No.', minWidth: 50,span: false },
    { id: '1', label: 'Waiter Id', minWidth: 50,span: false },
    { id: '2', label: 'Waiter Name', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 100,
      span: true
    },
  ];

function Waitermaster({branchid}){
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [modal, setModal] = React.useState(false);
    const [source, setSource] = React.useState([]);
    const [name, setName] = React.useState('');
    const [nameId, setNameId] = React.useState('');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
      const handleChangeRowsPerPage = (event) => {
          setRowsPerPage(+event.target.value);
          setPage(0);
        };
        
    useEffect(() => {
        getData()
    }, [branchid]);

    const getData = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Waiter/GetWaiterList?branchId=" + branchid, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("GetWaiterList Response", responseJson)
             setSource(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const onAdd = () => {
        setModal(true)
        setName('')
        setNameId("")
    }

    const editClick =(
        id,
        name,
    ) => {
        setModal(true)
        setName(name)
        setNameId(id)
    }

    const onSubmit = () => {
        var token = localStorage.getItem("token")
        const data = {
            "waiterid": nameId,
            "waitername": name,
            "branchid": branchid,
            "blocked": true
          }

        fetch(global.url + "api/Waiter/SaveWaiter", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("employee Response", responseJson)
            if(responseJson.error == false) {
                alert(responseJson.message)
                setModal(false)
                getData()
            }
            else{
                alert(responseJson.message)
            }
        })
        .catch((error)=>{
            alert("Something went wrong")
            console.log(error)
        })
    }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
                    <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Waiter Master</p>
                        <Button variant="primary" onClick={onAdd} >Add</Button>
                    </div>
                    <div className='bulk-table'>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                                        colSpan={column.span == true ? 2: 1}
                                    >
                                        {column.label}
                                        
                                    </TableCell>
                                    ))}
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {source
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                    return (
                                        <TableRow  tabIndex={-1} key={row.code}>
                                            <TableCell>
                                                {index + 1}
                                            </TableCell>
                                            <TableCell>
                                                {row.waiterid}
                                            </TableCell>
                                            <TableCell>
                                                {row.waitername}
                                            </TableCell>
                                            <TableCell >
                                                <a href="javascript:void(0)" onClick={() => editClick(
                                                        row.waiterid,
                                                        row.waitername,
                                                )}
                                                    >
                                                    <FaIcons.FaEdit fontSize={20} color="blue" />
                                                </a>
                                            </TableCell>
                                        </TableRow>
                                    );
                                    })}
                                </TableBody>
                            </Table>
                            </TableContainer>
                            <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={source.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                </Paper>
                <Modal
                size="lg"
                show={modal}
                onHide={() => setModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    Waiter Creation
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12} >
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                <TextField id="outlined-basic" value={name} onChange={(e) => setName(e.target.value)} label="Name" variant="outlined"  size="small" />
                            </FormControl>
                        </Col>
                        <Col xs={5}>
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                    <FormControlLabel control={
                                    <Checkbox  />
                                    } label="Enabled" />
                            </FormControl>
                        </Col>
                        <Col xs ={12} style={{marginTop:30}}>
                        <Button onClick={() => onSubmit()} style={{float: 'right'}} variant="primary">Submit</Button>
                        <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="danger">Cancel</Button>
                        </Col>
                        
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
    };
};

export default connect(mapStateToProps)(Waitermaster);