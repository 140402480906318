import React, {useState, useEffect, useRef} from 'react';
import './BulkBook.css'
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Form, Button} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { connect } from "react-redux";
import { BiSearch } from "react-icons/bi";
import { toBePartiallyChecked } from '@testing-library/jest-dom/dist/matchers';

const columns = [
    { id: '1', label: 'Rooms', minWidth: 50,span: false },
    { id: '2', label: 'Floor', minWidth: 50,span: false },
    { id: '3', label: 'Room Type', minWidth: 50,span: false },
    { id: '3', label: 'Guest', minWidth: 50,span: false },
    { id: '4', label: 'Actual Tarif', minWidth: 50,span: false },
    { id: '7', label: 'CGST', minWidth: 50,span: false },
    { id: '8', label: 'SGST', minWidth: 50,span: false },
    { id: '9', label: 'Sub Total', minWidth: 50,span: false },
  ];

function Bulkbooking({branchid}){
    const [age, setAge] = React.useState('');
    const [source, setSource] = React.useState('');
    const [sourcelist, setSourcelist] = React.useState([]);
    const [tariff, setTariff] = React.useState(0);
    const [guestid, setGuestid] = React.useState("")
    const [file, setFile] = React.useState();

    const [roomlist, setRoomlist] = React.useState([]);
    const [discountmasterlist, setDiscountmasterlist] = React.useState([])
    const [bookingdetails, setBookingdetails] = React.useState({})

    const [gender, setGender] = React.useState('');
    const [addressproof, setAddressproof] = React.useState('');
    const [reversecharge, setReversecharge] = React.useState('');
    const [paymode, setPaymode] = React.useState('');
    const [discount, setDiscount] = React.useState('');
    const [statelist, setStatelist] = React.useState([])

    const [guestname, setGuestname] = React.useState('');
    const [mobile, setMobile] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [city, setCity] = React.useState('');
    const [state, setState] = React.useState('');
    const [pincode, setPincode] = React.useState('');
    const [panno, setPanno] = React.useState('');
    const [nationality, setNationality] = React.useState('');
    const [guestgst, setGuestgst] = React.useState('');
    const [purpose, setPurpose] = React.useState('');
    const [advance, setAdvance] = React.useState('');
    const [recieptno, setRecieptno] = React.useState('');
    const [code, setCode] = React.useState('');

    const [cgstper, setCgstper] = React.useState(6)
    const [sgstper, setSgstper] = React.useState(6)

    const [availableroom, setAvailableroom] = React.useState([]);
    const [cgst, setCgst] = React.useState('');
    const [sgst, setSgst] = React.useState('');
    const [total, setTotal] = React.useState(0);

    const [array, setArray] = React.useState([]);

    const [docno, setDocno] = React.useState('');
    const [docnonum, setDocnonum] = React.useState('');
    const [rvrsecharge, setRvrsecharge] = React.useState(0);
    const [extrabed, setExtrabed] = React.useState(0)

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();

    const [arrivaldate, setArrivaldate] = React.useState(year + '-' + month + '-' + date +  'T' + hours + ":" + minutes);
    const [departuredate, setDeparturedate] = React.useState(year + '-' + month + '-' + date +  'T' + hours + ":" + minutes);


    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [savehide, setSavehide] = React.useState(false);

    function handleKeyPress(event) {
        if (!/^\d*\.?\d*$/.test(event.key)) {
        event.preventDefault();
        }
    }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const rvrsechargeChange = (event) => {
    setRvrsecharge(event.target.value)
  }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };

      useEffect(() => {
        getRoomlist()
        getNewbooking()
        getDiscountmaster()
        getStatelist()
    }, [branchid]);

    const batchfile = (e) => {
        console.log("e.target.files[0]", e.target.files)
        let selectedFile = e.target.files;
            let file = null;
            let fileName = "";
            //Check File is not Empty
            if (selectedFile.length > 0) {
                // Select the very first file from list
                let fileToLoad = selectedFile[0];
                fileName = fileToLoad.name;
                // FileReader function for read the file.
                let fileReader = new FileReader();
                // Onload of file read the file content
                fileReader.onload = function(fileLoadedEvent) {
                    file = fileLoadedEvent.target.result;
                    // Print data in console
                   
                    const regex = /data:.*base64,/
                    console.log(file.replace(regex,""));
                    setFile(file.replace(regex,""))
                };
                // Convert data to base64
                fileReader.readAsDataURL(fileToLoad);
                console.log("fileReader", fileReader);
            }
      }

    const getStatelist = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/State/GetStateList?countryId=102", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("GetStateList Response", responseJson)
            if(responseJson) {
                setStatelist(responseJson)
            }
            else{
                setStatelist([])
            }
           
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const stateChange = (event) =>  {
        setState(event.target.value)
      }
    

    const getDiscountmaster = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/DiscountMaster/GetDiscountMasterList", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("source Response", responseJson)
            if(responseJson) {
                setDiscountmasterlist(responseJson)
            }
            else{
                setDiscountmasterlist([])
            }
           
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getNewbooking = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Booking/NewBulkBooking?branchId=" + branchid, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("NewBulkBooking Response", responseJson)
            setDocno(responseJson.docno)
            setDocnonum(responseJson.docnonum)
            setSourcelist(responseJson.source)
            var input = [...responseJson.availableRooms]
            for(var i =0; i< input.length; i++) {
                input[i].tariff = 0;
                input[i].roomrate = 0;
                input[i].noofguest = 0;
                input[i].subtotal = 0;
                input[i].cgst = 0;
                input[i].sgst = 0;
            }
            setAvailableroom(input)
           
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getRoomlist = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Room/GetRoomList?branchId=" + branchid, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("GetRoomList Response", responseJson)
            setRoomlist(responseJson)
           
        })
        .catch((error)=>{
            console.log(error)
        })
    }

  const sourceChange = (event) => {
    setSource(event.target.value);
    
  };

  const extrabedChange = (event) => {
    setExtrabed(event.target.value)
    }


  const genderChange = (event) => {
    setGender(event.target.value)
  }

  const addressproofChange = (event) => {
    setAddressproof(event.target.value)
  }
  const reversechargeChange = (event) => {
    setReversecharge(event.target.value)
  }
  const paymodeChange = (event) => {
    setPaymode(event.target.value)
  }
  const discountChange = (event) => {
    setDiscount(event.target.value)
  }

  const onSave = () => {
    if (new Date(arrivaldate) > new Date(departuredate)){
        return alert('Checkin Time Should be less than Departure')
    }
    if(tariff == 0){
       return alert('Tariff Empty')
    } 
    if(total == 0){
        return alert('Total empty')
    }
    if(guestid == "") {
        return alert('Guest id empty')
    }
    if(guestname == ""){
        return alert('Customer name empty')
    } 
    if(mobile == ""){
        return alert('Mobile Empty')
    }  
    if(address == ""){
        return alert('Address Empty')
    }  
    if(file == ""){
        return alert('File Not selected')
    }

    if(source == ""){
        return alert('Source not selected')
    }

    // if(extrabed == ""){
    //     return alert('Extra bed not selected')
    // }
    setSavehide(true)
    var token = localStorage.getItem("token")
    
    console.log("token", token)
    const data = {
        "bookingid": 0,
        "roomid": 0,
        "sourceid": source,
        "tariff": tariff,
        "discountid": 5,
        "discount": 6.0,
        "extrabed": extrabed,
        "bedcode": "",
        "earlycheckin": "",
        "sgst": sgst,
        "cgst": cgst,
        "adult": 0,
        "children": 0,
        "extratime": 0,
        "arrival": arrivaldate,
        "departure":departuredate,
        "status": 0,
        "customerid": 0,
        "branchid": branchid,
        "advance": 0,
        "notes": "",
        "formid": 1,
        "docno": docno,
        "docnum": docnonum,
        "createdby": global.userid,
        "createdon": "",
        "updatedby": global.userid,
        "updatedon": "",
        "paymode": paymode,
        "refno": "",
        "payhours": 1,
        "vid": 0,
        "guestid": guestid,
        "billno": "",
        "billnonum": 1,
        "reversecharge": reversecharge,
        "billdate": "",
        "customerDetails": {
          "customerid": 0,
          "customername": guestname,
          "addresS1": address,
          "addresS2":city,
          "addresS3": "",
          "state": state,
          "district": city,
          "zipcode" : pincode,
          "phone": mobile,
          "mobile": mobile,
          "email": email,
          "gstin": guestgst,
          "other": "",
          "panno": panno,
          "proofid": file,
          "proofnumber": "",
          "purpose": purpose,
          "nationality": "",
          "status": 1,
          "gender": gender,
          "age": age
        },
        "roomDetails": [],
        "billDetails": array,
        "amountsInWords": "",
        "createdUser": global.userid,
        "taxDetails": {},
        "total": total
      }

    console.log("save data", data)

    fetch(global.url + "api/Booking/BulkBooking", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        
        setSavehide(false)
        console.log("Booking Response", responseJson)
        if(responseJson.bookingid > 0){
    
            alert("success")
            window.location.reload()
        }
    })
    .catch((error)=>{
        alert("username or password error")
        console.log(error)
    })
  }

  

  const blockChange = (e,id, roomname, floorid, id1, roomstatus, roomtypeid, roomno, tariffid, currentvid, salesvid, roomtype, floor, tarifftype, noofguest, index) => {
    let isChecked = e.target.checked;
    console.log(isChecked)
    if(isChecked == true) {
        var input1= [...availableroom]
        input1[index].ticked = true
        input1[index].cgst = 0
        input1[index].sgst = 0
        input1[index].noofguest =0
        input1[index].subtotal = 0
        input1[index].tariff = 0
        input1[index].roomrate = 0
        setAvailableroom(input1)

        var input  = [...array]
        input.push({roomid: id,
            roomname: roomname,
            floorid: floorid,
            branchid: id1,
            roomstatus: roomstatus,
            roomtypeid: roomtypeid,
            roomno: roomno,
            tariffid: tariffid,
            currentvid: currentvid,
            salesvid: salesvid,
            roomtype: roomtype,
            floor: floor,
            tarifftype: tarifftype
         })
        setArray(input)
    }
    if(isChecked == false){
        var input1= [...availableroom]
        input1[index].ticked = false
        input1[index].tariff = 0
        input1[index].noofguest = 0
        input1[index].roomrate = 0
        input1[index].cgst = 0
        input1[index].sgst = 0
        input1[index].subtotal = 0
        setAvailableroom(input1)
        var inputArray= [...array]
        for(var i=0; i<inputArray.length; i++){
            if(id == inputArray[i].roomid){
                inputArray[i].cgst = 0
                inputArray[i].sgst = 0
                inputArray[i].total = 0
            }
        }
        var testArr = [1, 2, 3, 4, 5];
        const val = 3; // Value to remove

        var filterlist = inputArray.filter(item => {
            return item.roomid != id
        })
        setArray(filterlist)

        // for (let i = 0; i < array.length; i++) {
        //     if (array[i].roomid === id) {
        //         const removedElements = array.splice(i, 1);
        //         console.log(removedElements); // Outputs [3]
        //         i--; // Since the indexes of elements following this index get updated after removal
        //     }
        // }
    }
    console.log("array",  array)
}

const mobileSearch = () => {
    var token = localStorage.getItem("token")
    fetch(global.url + "api/Booking/GetCustomerByPhoneNumber?phoneNumber="+ mobile, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        console.log("GetCustomerByPhoneNumber response", responseJson)
        if(responseJson.customerid > 0) {
            setGuestname(responseJson.customername)
            setAge(responseJson.age)
            setGender(responseJson.gender)
            setEmail(responseJson.email)
            setAddress(responseJson.addresS1)
            setCity(responseJson.district)
            setState(responseJson.state)  
            // setPincode(responseJson.state) 
            setPanno(responseJson.panno)
            setNationality(responseJson.nationality) 
            setAddressproof(responseJson.proof) 
            setGuestgst(responseJson.gstin)  
            setPurpose(responseJson.purpose) 
        } 
    })
    .catch((error)=>{
        console.log(error)
    })

    
}

  

    const tariffOnChange = (event, key, roomid) => {
        var input = [...availableroom]
        var inputArray = [...array]
        if(rvrsecharge == 0) {
            
                input[key].tariff = event.target.value
                input[key].roomrate = event.target.value
                input[key].cgst = (event.target.value/100 * parseFloat(6)).toFixed(2)
                input[key].sgst = (event.target.value/100 * parseFloat(6)).toFixed(2)
                var sum = parseFloat(event.target.value)  + (event.target.value/100 * (parseFloat(6)+parseFloat(6)))
                input[key].subtotal = sum.toFixed(2)

                for(var i=0; i<inputArray.length; i++){
                    if(roomid == inputArray[i].roomid){
                        inputArray[i].cgst = (event.target.value/100 * parseFloat(6)).toFixed(2)
                        inputArray[i].sgst = (event.target.value/100 * parseFloat(6)).toFixed(2)
                        inputArray[i].total = event.target.value
                    }
                }
            
        }
        setAvailableroom(input)
        setArray(inputArray)
    }

    const guestOnChange = (event, key, roomid) => {
        var input = [...availableroom]  
        var inputArray = [...array]
        if(rvrsecharge == 0) {
        input[key].noofguest = event.target.value
                for(var i=0; i<inputArray.length; i++){
                    if(roomid == inputArray[i].roomid){
                        inputArray[i].noofguest = event.target.value
                    }
                }  
        }
        setAvailableroom(input)
        setArray(inputArray)
    }


    const subTotalChange = (event, key, roomid) => {
        var input = [...availableroom]
        var inputArray = [...array]
        if(rvrsecharge == 0) {
            
                input[key].cgst = (input[key].tariff/100 * parseFloat(6)).toFixed(2)
                input[key].sgst = (input[key].tariff/100 * parseFloat(6)).toFixed(2)
                var sum = parseFloat(input[key].tariff)  + (input[key].tariff/100 * (parseFloat(6)+parseFloat(6)))
                input[key].subtotal = sum.toFixed(2)

                for(var i=0; i<inputArray.length; i++){
                    if(roomid == inputArray[i].roomid){
                        inputArray[i].cgst = (input[key].tariff/100 * parseFloat(6)).toFixed(2)
                        inputArray[i].sgst = (input[key].tariff/100 * parseFloat(6)).toFixed(2)
                        // inputArray[i].total = sum.toFixed(2)
                    }
                }
            
        }
        else{
            console.log("reverse")
            
                input[key].subtotal = event.target.value
                var sum = parseFloat(event.target.value)  - (event.target.value/(100 + parseFloat(6)+parseFloat(6)) * 12)
                console.log("sum", sum)
                input[key].tariff = sum.toFixed(2)
                input[key].roomrate =  sum.toFixed(2)
                input[key].cgst = ((event.target.value/(100 + parseFloat(6)+parseFloat(6)) * 12)/2).toFixed(2)
                input[key].sgst = ((event.target.value/(100 + parseFloat(6)+parseFloat(6)) * 12)/2).toFixed(2)

                for(var i=0; i<inputArray.length; i++){
                    if(roomid == inputArray[i].roomid){
                        inputArray[i].cgst = ((event.target.value/(100 + parseFloat(6)+parseFloat(6)) * 12)/2).toFixed(2)
                        inputArray[i].sgst = ((event.target.value/(100 + parseFloat(6)+parseFloat(6)) * 12)/2).toFixed(2)
                        inputArray[i].total = sum.toFixed(2)
                    }
                }
           
        }
        setAvailableroom(input)
        setArray(inputArray)
    }

    useEffect(() => {
        getTotaloftable()
    }, [availableroom]);


    const getTotaloftable = () => {
        var tariffTot = 0;
        var cgstTot = 0;
        var sgstTot = 0;
        var subtotTot = 0;
        for (var i = 0; i < availableroom?.length; i++) {
            tariffTot += parseFloat(availableroom[i].tariff)
            cgstTot += parseFloat(availableroom[i].cgst)
            sgstTot += parseFloat(availableroom[i].sgst)
            subtotTot += parseFloat(availableroom[i].subtotal)
        }

        setTariff(tariffTot.toFixed(2))
        setSgst(sgstTot.toFixed(2))
        setCgst(cgstTot.toFixed(2))
        setTotal(subtotTot.toFixed(2))
      
    }



  
    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold'}}>Bulk Booking</p> 
                <div>
                    <Row>
                        <Col xs={12} md={4}>
                            <FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
                                <InputLabel id="demo-simple-select-label">Source</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={source}
                                label="Age"
                                onChange={sourceChange}
                                size="small"
                                >
                                {
                                    sourcelist.map((i,k) => {
                                        return(
                                            <MenuItem value={i.sourceid}>{i.sourcename}</MenuItem>
                                        )
                                       
                                    })
                                }
                                </Select>
                            </FormControl>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <TextField
                                    id="datetime-local"
                                    label="Arrival"
                                    type="datetime-local"
                                    defaultValue={arrivaldate}
                                    sx={{ width: 250 }}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                    onChange={(newValue) => {
                                        setArrivaldate(newValue.target.value);
                                    }}
                                />
                            </FormControl>
                        </Col>
                        <Col xs={12} md={4}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <TextField
                                    id="datetime-local"
                                    label="Departure"
                                    type="datetime-local"
                                    defaultValue={departuredate}
                                    sx={{ width: 250 }}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                    onChange={(newValue) => {
                                        setDeparturedate(newValue.target.value);
                                    }}
                                />
                            </FormControl>
                        </Col>
                    </Row>
                </div>
                <div style={{height: 2, width: '100%', backgroundColor: '#d3d3d3', marginTop: 30}}></div>
                <div style={{marginTop: 20}}>
                    <p style={{fontSize: 20, fontWeight: 'bold'}}>Guest Details</p> 
                    <Row>
                        <Col xs={12} md={6}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField value={guestid} onChange={(e) => setGuestid(e.target.value)} id="outlined-basic" label="Guest Id" variant="outlined"  size="small"/>
                            </FormControl>
                            <p style={{fontSize: 12, color: '#4a4a4a', marginBottom: 'unset', marginTop: 5}}>Required*</p>
                        </Col>
                        <Col xs={12} md={4}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField  value={guestname} onChange={(e) => setGuestname(e.target.value)}  id="outlined-basic" label="Guest Name" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={4}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField value={age} onChange={(e) => setAge(e.target.value)}  id="outlined-basic" label="Age (yrs)" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={4}>
                            <FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
                                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={gender}
                                label="Age"
                                onChange={genderChange}
                                size="small"
                                >
                                <MenuItem value={"Male"}>Male</MenuItem>
                                <MenuItem value={"Female"}>Female</MenuItem>
                                <MenuItem value={"Other"}>Other</MenuItem>
                             
                                </Select>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={4}>
                            <Row>
                                <Col xs={8} md={10}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField onKeyPress={handleKeyPress} value={mobile} onChange={(e) => setMobile(e.target.value)} id="outlined-basic" label="Mobile" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                <Col xs={4} md={2}>
                                    <a onClick={() => mobileSearch()} style={{cursor: 'pointer'}}>
                                        <BiSearch color="#000" size={30} style={{ marginTop: 20}} />
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={4}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField value={email} onChange={(e) => setEmail(e.target.value)} id="outlined-basic" label="Email" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12}>
                            <TextareaAutosize 
                                minRows={2}
                                value={address} onChange={(e) => setAddress(e.target.value)}
                                placeholder="Address"
                                style={{background: 'transparent', width: '100%', marginTop: 20}}
                            />
                        </Col>
                        <Col xs={12} md={4}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField  value={city} onChange={(e) => setCity(e.target.value)} id="outlined-basic" label="District" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={4}>
                                    <FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
                                        <InputLabel id="demo-simple-select-label">State</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={state}
                                        label="Age"
                                        onChange={stateChange}
                                        size="small"
                                        >
                                        {
                                            statelist.map((item, index) => {
                                                return(
                                                    <MenuItem value={item.stid}>{item.stname}</MenuItem>
                                                )
                                            })
                                        }
                                        </Select>
                                    </FormControl>
                                   
                                </Col>
                        <Col xs={12} md={4}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField onKeyPress={handleKeyPress} value={pincode} onChange={(e) => setPincode(e.target.value)} id="outlined-basic" label="Pincode" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={4}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField value={panno} onChange={(e) => setPanno(e.target.value)} id="outlined-basic" label="Pan No." variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={4}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField value={nationality} onChange={(e) => setNationality(e.target.value)} id="outlined-basic" label="Nationality" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={4}>
                                    <FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
                                        <InputLabel id="demo-simple-select-label">Extra Bed</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={extrabed}
                                        label="Age"
                                        onChange={extrabedChange}
                                        size="small"
                                        >
                                        <MenuItem value={0}>0</MenuItem>
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <p style={{fontSize: 12, color: '#4a4a4a', marginBottom: 'unset', marginTop: 5}}>Required*</p>
                                </Col>
                        <Col xs={12} md={4}>
                            <FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
                                <InputLabel id="demo-simple-select-label">Address Proof</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={addressproof}
                                label="Age"
                                onChange={addressproofChange}
                                size="small"
                                >
                                <MenuItem value={"Aadhar"}>Aadhar</MenuItem>
                                <MenuItem value={"Passport"}>Passport</MenuItem>
                                <MenuItem value={"Voterid"}>Voter id</MenuItem>
                                <MenuItem value={"drivinglicense"}>Driving License</MenuItem>
                                <MenuItem value={"Other"}>Other</MenuItem>
                               
                                </Select>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={4}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField value={guestgst} onChange={(e) => setGuestgst(e.target.value)} id="outlined-basic" label="Guest GST No." variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={4}>
                            <FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
                                <InputLabel id="demo-simple-select-label">Reverse Charge</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={rvrsecharge}
                                label="Age"
                                onChange={rvrsechargeChange}
                                size="small"
                                >
                                <MenuItem value={0}>NO</MenuItem>
                                <MenuItem value={1}>YES</MenuItem>
            
                                </Select>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={4}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField value={purpose} onChange={(e) => setPurpose(e.target.value)} id="outlined-basic" label="Purpose" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={6}>
                                    <div style={{marginTop: 15}}>
                                        <input type="file"  onChange={(e) => batchfile(e)}  style={{marginTop: 10}}/>
                            </div>
                            <p style={{fontSize: 12, color: '#4a4a4a', marginBottom: 'unset', marginTop: 5}}>Required*</p>
                        </Col>
                        <Col xs={12} md={4}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField  value={advance} onChange={(e) => setAdvance(e.target.value)} id="outlined-basic" label="Advance" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={4}>
                            <FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
                                <InputLabel id="demo-simple-select-label">Payment Mode</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={paymode}
                                label="Age"
                                onChange={paymodeChange}
                                size="small"
                                >
                                <MenuItem value={"Cash"}>Cash</MenuItem>
                                <MenuItem value={"Online"}>Online</MenuItem>
                
                                </Select>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={4}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField value={recieptno} onChange={(e) => setRecieptno(e.target.value)} id="outlined-basic" label="Receipt No." variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                    </Row>
                    <div style={{height: 2, width: '100%', backgroundColor: '#d3d3d3', marginTop: 30}}></div>
                    <Row style={{marginTop: 20}}>
                        <Col xs={12} md={4}>
                            <FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
                                <InputLabel id="demo-simple-select-label">Discount</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={discount}
                                label="Age"
                                onChange={discountChange}
                                size="small"
                                >
                                {
                                    discountmasterlist.map((i) => {
                                        return(
                                            <MenuItem value={i.discid}>{i.discname}</MenuItem>
                                        )
                                    })
                                }
                                
                                </Select>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={4}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField value={code} onChange={(e) => setCode(e.target.value)} id="outlined-basic" label="Code" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col xs={6} md={3} >
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <FormControlLabel control={
                                <Checkbox  />
                                } label="Apply GST" />
                            </FormControl>
                        </Col>
                    </Row> */}
                    <div className='bulk-table'>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                                    colSpan={column.span == true ? 2: 1}
                                >
                                    {column.label}
                                    
                                </TableCell>
                                ))}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {availableroom
                                .map((row, k) => {
                                return (
                                    <TableRow  tabIndex={-1} key={row.code}>
                                        <TableCell>
                                            <div style={{display: 'flex'}}>
                                                <FormControl sx={{  width: '100%' }}>
                                                    <FormControlLabel control={
                                                    <Checkbox onChange={e => blockChange(e, row.roomid,
                                                        row.roomname,
                                                        row.floorid,
                                                        row.branchid,
                                                        row.roomstatus,
                                                        row.roomtypeid,
                                                        row.roomno,
                                                        row.tariffid,
                                                        row.currentvid,
                                                        row.salesvid,
                                                    
                                                        row.roomtype,
                                                        row.floor,
                                                        row.tarifftype,
                                                        row.noofguest,
                                                        k)} />
                                                    } label="" />
                                                </FormControl>
                                                <p style={{marginTop: 10}}>{row.roomname}</p>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                        {row.roomtype}
                                        </TableCell>
                                        <TableCell>
                                        {row.roomtype}
                                        </TableCell>
                                        <TableCell>
                                        <input disabled={row.ticked ? false: true} autocomplete="off"  onKeyPress={handleKeyPress}  value={row.noofguest} onChange={(event) => guestOnChange(event, k, row.roomid)} />
                                        </TableCell>
                                        <TableCell >
                                        <input disabled={row.ticked ? false: true} autocomplete="off"  onKeyPress={handleKeyPress}  value={row.tariff} onChange={(event) => tariffOnChange(event, k, row.roomid)} />
                                        </TableCell>
                                        <TableCell >
                                        <input  autocomplete="off" disabled    value={row.cgst} />
                                        </TableCell>
                                        <TableCell >
                                        <input autocomplete="off" disabled   value={row.sgst}/>
                                        </TableCell>
                                        <TableCell >
                                        <input disabled={row.ticked ? false: true} autocomplete="off"  onKeyPress={handleKeyPress}  value={row.subtotal} onChange={(event) => subTotalChange(event, k, row.roomid)} />
                                        </TableCell>
                                       
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
                        </TableContainer>
                    </div>
                    <div style={{marginTop: 30}}>
                        <Row>
                            <Col xs={6} md={3}>
                                <FormControl sx={{ width: '100%' }}>
                                    <TextField disabled value={tariff} onChange={(e) => setTariff(e.target.value)} id="outlined-basic" type={"number"} label="Tarif" variant="outlined"  size="small"/>
                                </FormControl>
                            </Col>
                            <Col xs={6} md={3}>
                                <FormControl sx={{ width: '100%' }}>
                                    <TextField disabled value={cgst} onChange={(e) => setCgst(e.target.value)} id="outlined-basic" label="CGST" variant="outlined"  size="small"/>
                                </FormControl>
                            </Col>

                            <Col xs={6} md={3}>
                                <FormControl sx={{ width: '100%' }}>
                                    <TextField disabled value={sgst} onChange={(e) => setSgst(e.target.value)} id="outlined-basic" label="SGST" variant="outlined"  size="small"/>
                                </FormControl>
                            </Col>
                            
                            
                        </Row>
                        <Row style={{marginTop: 30}}>
                            <Col xs={6} md={3}>
                                <FormControl sx={{ width: '100%' }}>
                                    <TextField disabled value={total} onChange={(e) => setTotal(e.target.value)} id="outlined-basic" label="Total" variant="outlined"  size="small"/>
                                </FormControl>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col xs={12} md={3}>
                                <FormControl sx={{ mt:3, width: '100%' }}>
                                    <TextField id="outlined-basic" label="Password" variant="outlined"  size="small"/>
                                </FormControl>
                            </Col>
                        </Row> */}
                        {savehide == true ? null :
                        <Row>
                            <Col xs={12} md={3} style={{marginTop: 30}}>
                                <Button onClick={()=> onSave()} style={{backgroundColor: '#03a5fc', color: '#fff'}} variant="contained">Save</Button>
                            </Col>
                        </Row>}
                    </div>
                </div>
            </Paper>
            
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(Bulkbooking);