import React, { useState, useEffect } from "react";
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button, Modal, Table} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
// import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { connect } from "react-redux";

const columns = [
    { id: '1', label: 'Code', minWidth: 50,span: false },
    { id: '1', label: 'Name', minWidth: 50,span: false },
    { id: '1', label: 'Account', minWidth: 50,span: false },
    { id: '2', label: 'Address', minWidth: 50,span: false },
    { id: '3', label: 'GSTIN', minWidth: 50,span: false },
    { id: '4', label: 'Mobile', minWidth: 50,span: false },
    { id: '5', label: 'Email', minWidth: 50,span: false },
    { id: '6', label: 'Website', minWidth: 50,span: false },
    { id: '7', label: 'Whatsapp No.', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 100,
      span: true
    },
  ];


function User({branchid}){

    const [age, setAge] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [modal, setModal] = React.useState(false);
    const [source, setSource] = React.useState([]);

    const [empId, setEmpid] = React.useState('');
    const [empCode, setEmpcode] = React.useState('');
    const [empName, setEmpName] = React.useState('');
    const [empAccnt, setEmpaccnt] = React.useState('');
    const [empAddrs, setEmpaddrs] = React.useState('');
    const [empGst, setEmpgst] = React.useState('');
    const [empMobile, setEmpmobile] = React.useState('');
    const [empEmail, setEmpemail] = React.useState('');
    const [empPass, setEmpPass] = React.useState('');
    const [empWeb, setEmpweb] = React.useState('');
    const [empwhats, setEmpwhats] = React.useState('');
    const [branchlist, setBranchlist] = React.useState([]);
    const [confirmpassword, setConfirmpassword] = React.useState("");
    const [loginuser, setLoginuser] = React.useState(0);
    const [emailErr, setEmailErr] = useState("");
    const [passworderr, setPassworderr] = useState("");

    const [blocked, setBlocked] = useState(0);
    

    const [array, setArray] = React.useState([])
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };


    useEffect(() => {
        getData()
        getBranches()
    }, [branchid]);

    const getBranches = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Branch/GetBranchList?orderBy=name&companyId=1&includeBlocked=false", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("getBranches Response", responseJson)
             setBranchlist(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }
    

    const blockChange = (e,id) => {
        let isChecked = e.target.checked;
        console.log(isChecked)
        if(isChecked == true) {
            var input  = [...array]
            input.push({branchid: id})
            console.log("input", input)
            setArray(input)
        }
        else{

            var testArr = [1, 2, 3, 4, 5];
            const val = 3; // Value to remove

            for (let i = 0; i < array.length; i++) {
                if (array[i].branchid === id) {
                    const removedElements = array.splice(i, 1);
                    console.log(removedElements); // Outputs [3]
                    i--; // Since the indexes of elements following this index get updated after removal
                }
            }
        }
        console.log("array",  array)
    }

    const getData = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Employee/GetEmployeeList?orderBy=Name&companyId=1&branchId=" + branchid + "&includeBlocked=False", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("user Response", responseJson)
             setSource(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }
    
    const onAdd = () => {
        setModal(true)
        setEmpid("")
        setEmpcode("")
        setEmpName("")
        setEmpaccnt("")
        setEmpaddrs("")
        setEmpgst("")
        setEmpmobile("")
        setEmpemail("")
        setEmpweb("")
        setEmpwhats("")
        setEmpPass('')
    }

    useEffect(() => {
       setEmpemail("")
       setEmpPass("")
       setConfirmpassword("")
    }, [loginuser]);

    const editClick =(
        empid,
        empcode,
        empname,
        empaccid,
        empaddresS1,
        empgstin,
        empmobile,
        empemail,
        empwebsite,
        empwts
    ) => {
        setModal(true)
        setEmpid(empid)
        setEmpcode(empcode)
        setEmpName(empname)
        setEmpaccnt(empaccid)
        setEmpaddrs(empaddresS1)
        setEmpgst(empgstin)
        setEmpmobile(empmobile)
        setEmpemail(empemail)
        setEmpweb(empwebsite)
        setEmpwhats(empwts)
    }

    const onSubmit = () => {
        let isValid = true;
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!regex.test(empEmail)){
           
            setEmailErr('Email id is not valid');
            isValid = false;
        }
        else{
            setEmailErr('');
        }

        if (empPass != confirmpassword){
           
            setPassworderr('password and confirm password are not same');
            isValid = false;
        }
        else{
            setPassworderr('')
        }
        
        if(isValid == false) {
            return
        }
        var token = localStorage.getItem("token")
        console.log("token", token)
        const data = {
            "empid": empId,
            "empname": empName,
            "empledger": empAccnt,
            "empaddresS1": empAddrs,
            "empaddresS2": "",
            "empaddresS3": "",
            "empcntid": "",
            "empstid": "",
            "empgstin": empGst,
            "empmobile": empMobile,
            "empemail": empEmail,
            "empwebsite": empWeb,
            "empwts": empwhats,
            "empprfx": "",
            "empdistid": '',
            "empopening": "",
            "empdrcr": "",
            "empblocked": blocked,
            "empseq": "",
            "emplact": "",
            "emplong": "",
            "empgroupid": "",
            "empbranchid": branchid,
            "empcode": empCode,
            "empcomid": 1,
            "empdesid": "",
            "empaccid": "",
            "empshftid": "",
            "empisuser": loginuser,
            "empuserid": "",
            "allowedBranches": array,
            "userName": empEmail,
            "password": empPass
         }

        console.log("save data", data)

        fetch(global.url + "api/Employee/SaveEmployee", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("employee Response", responseJson)
            if(responseJson.error == false) {
                alert(responseJson.message)
                setModal(false)
                getData()
            }
            else{
                alert(responseJson.message)
            }
        })
        .catch((error)=>{
            alert("Something went wrong")
            console.log(error)
        })
    }

    const checkChange = (e) => {
        let isChecked = e.target.checked;
        if(isChecked == true) {
          setBlocked(1)
        }
        else {
          setBlocked(0)
        }
        // do whatever you want with isChecked value
      }

      const loginuserChange = (e) => {
        let isChecked = e.target.checked;
        if(isChecked == true) {
          setLoginuser(1)
        }
        else {
            setLoginuser(0)
        }
        // do whatever you want with isChecked value
      }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
                <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Employees</p>
                    <Button variant="primary" onClick={onAdd} >Add</Button>
                </div>
                <div className='bulk-table'>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                                    colSpan={column.span == true ? 2: 1}
                                >
                                    {column.label}
                                    
                                </TableCell>
                                ))}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {source
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                return (
                                    <TableRow  tabIndex={-1} key={row.code}>
                                        <TableCell>
                                            {row.empcode}
                                        </TableCell>
                                        <TableCell>
                                        {row.empname}
                                        </TableCell>
                                        <TableCell>
                                        {row.empaccid}
                                        </TableCell>
                                        <TableCell>
                                        {row.empaddresS1}
                                        </TableCell>
                                        <TableCell>
                                        {row.empgstin}
                                        </TableCell>
                                        <TableCell>
                                        {row.empmobile}
                                        </TableCell>
                                        <TableCell>
                                        {row.empemail}
                                        </TableCell>
                                        <TableCell>
                                        {row.empwebsite}
                                        </TableCell>
                                        <TableCell>
                                        {row.empwts}
                                        </TableCell>
                                        <TableCell >
                                            <a href="javascript:void(0)"
                                                onClick={() => editClick(
                                                    row.empid,
                                                    row.empcode,
                                                    row.empname,
                                                    row.empaccid,
                                                    row.empaddresS1,
                                                    row.empgstin,
                                                    row.empmobile,
                                                    row.empemail,
                                                    row.empwebsite,
                                                    row.empwts
                                            )} >
                                                <FaIcons.FaEdit fontSize={20} color="blue" />
                                            </a>
                                        </TableCell>
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
                        </TableContainer>
                        <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={source.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
            </Paper>
            <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Employee Creation
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField id="outlined-basic" value={empCode} onChange={(e) => setEmpcode(e.target.value)} label="Code" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField id="outlined-basic" value={empName} onChange={(e) => setEmpName(e.target.value)} label="Name" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField id="outlined-basic" value={empAccnt} onChange={(e) => setEmpaccnt(e.target.value)} label="Account" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField id="outlined-basic" value={empEmail} onChange={(e) => setEmpemail(e.target.value)} label="Username" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField id="outlined-basic" value={empAddrs} onChange={(e) => setEmpaddrs(e.target.value)} label="Address" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField id="outlined-basic" value={empGst} onChange={(e) => setEmpgst(e.target.value)} label="GSTIN" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField id="outlined-basic" value={empMobile} onChange={(e) => setEmpmobile(e.target.value)} label="Mobile" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField id="outlined-basic" value={empWeb} onChange={(e) => setEmpweb(e.target.value)} label="Website" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField id="outlined-basic" value={empwhats} onChange={(e) => setEmpwhats(e.target.value)} label="Whatsapp" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>

                    <Col xs={5} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <FormControlLabel control={
                            <Checkbox checked={blocked == 0? false : true} onChange={e => checkChange(e)} />
                            } label="Enabled" />
                        </FormControl>
                    </Col>
                    <Col xs={5}>
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <FormControlLabel control={
                            <Checkbox checked={loginuser == 0? false : true} onChange={e => loginuserChange(e)} />
                            } label="IsLoginUser" />
                        </FormControl>
                    </Col>
                    {
                        loginuser ? 
                            <Row>
                                <Row>
                                    <Col xs={4} >
                                        <FormControl sx={{ mt: 2, width: '100%' }}>
                                            <TextField id="outlined-basic" value={empEmail} onChange={(e) => setEmpemail(e.target.value)} label="Email" variant="outlined"  size="small" />
                                        </FormControl>
                                        
                                        <div style={{color: 'red', fontSize: 12}}>{emailErr}</div>
                                                
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={4} >
                                        <FormControl sx={{ mt: 2, width: '100%' }}>
                                            <TextField id="outlined-basic" onChange={(e) => setEmpPass(e.target.value)} label="Password" variant="outlined" type="password" size="small" />
                                        </FormControl>
                                    </Col>
                                    
                                </Row>
                                <Row>
                                    <Col xs={4} >
                                        <FormControl sx={{ mt: 2, width: '100%' }}>
                                            <TextField id="outlined-basic" value={confirmpassword} onChange={(e) => setConfirmpassword(e.target.value)} type="password" label="Confirm Password" variant="outlined"  size="small" />
                                        </FormControl>
                                    </Col>
                                    <div style={{color: 'red', fontSize: 12}}>{passworderr}</div>
                                </Row>
                            </Row>:
                            null
                    }
                    
                   
                    
                    <Col xs={12} style={{marginTop: 20}} className="tablebranch">
                        <Table  responsive bordered >
                                <thead>
                                    <tr>
                                        <th>Branch</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        branchlist.map((i, k) => {
                                            return(
                                                <tr >
                                                    <td>{i.brname}</td>
                                                    <td>
                                                        <FormControl sx={{  width: '100%' }}>
                                                            <FormControlLabel control={
                                                            <Checkbox onChange={e => blockChange(e, i.brid)} />
                                                            } label="" />
                                                        </FormControl>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    
                                </tbody>
                        </Table>
                    </Col>
                    <Col xs ={12} style={{marginTop:30}}>
                      <Button onClick={onSubmit} style={{float: 'right'}} variant="primary">Submit</Button>
                      <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="danger">Cancel</Button>
                    </Col>
                    
                  </Row>
              </Modal.Body>
           </Modal>
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
    };
};

export default connect(mapStateToProps)(User);