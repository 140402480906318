import React, { useEffect } from 'react'
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Occupancy from '../Counter/Occupancy/Occupancy';
import { connect } from "react-redux";

const columns = [
    { id: '1', label: 'GRC', minWidth: 50,span: false },
    { id: '2', label: 'Guest Name', minWidth: 50,span: false },
    { id: '3', label: 'Old Room', minWidth: 50,span: false },
    { id: '4', label: 'New Room', minWidth: 50,span: false },
    { id: '5', label: 'Date', minWidth: 50,span: false },
    { id: '6', label: 'User', minWidth: 50,span: false },
  ];

function RoomChange({branchid}){
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [source, setSource] = React.useState([]);

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();


    const [fromdate, setFromdate] = React.useState(year + '-' + month + '-' + date);
    const [todate, setTodate] = React.useState(year + '-' + month + '-' + date);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        var token = localStorage.getItem("token")
        var data = {
            "companyId": 1,
            "branchId": branchid,
            "fromDate": fromdate,
            "toDate": todate,
            "createdBy": "",
            "billno": "",
            "counterId": "",
            "userId": "",
            "soldItemsOnly": "",
            "accId": "",
            "otnumber": "",
            "itemcode": ""
              
        }
        console.log("data", data)
        fetch(global.url + "api/BookingReports/GetRoomChangeHistory", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("change Response", responseJson)
             setSource(responseJson)
            
        })
        .catch((error)=>{
            console.log(error)
        })
    }



    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>Room Change History</p> 
                <Row>
                    <Col xs={12}  className='columnborder'>
                        <div>
                            <Row>
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="From Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setFromdate(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="To Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setTodate(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={2}>
                                    <div style={{justifyContent: 'center', display: 'flex'}}>
                                        <Button onClick={getData} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 17, width: '50%'}} variant="contained">Search</Button>
                                    </div>
                                </Col>
                            </Row>
                           
                        </div>
                    </Col> 
                </Row>
                <div className='bulk-table'>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                                    colSpan={column.span == true ? 2: 1}
                                >
                                    {column.label}
                                    
                                </TableCell>
                                ))}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {source
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                return (
                                    <TableRow  tabIndex={-1} key={row.code}>
                                        <TableCell>
                                         {row.grc}
                                        </TableCell>
                                        <TableCell>
                                        {row.guestname}
                                        </TableCell>
                                        <TableCell>
                                        {row.oldroom}
                                        </TableCell>
                                        <TableCell>
                                        {row.newroom}
                                        </TableCell>
                                        <TableCell>
                                        {row.changedate}
                                        </TableCell>
                                        <TableCell>
                                        {row.user}
                                        </TableCell>
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
                        </TableContainer>
                        <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={source.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                </div>
            </Paper>
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(RoomChange);