import React, {useState, useEffect, useRef} from 'react';
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Form, Button} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { connect } from "react-redux";
import ReactToPrint from 'react-to-print';
import logo from '../../../assets/images/logo.jpeg';

function Occupancy({branchid, branchdetails}){
    const componentRef = useRef();
    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();

    const [source, setSource] = React.useState([]);
    const [fromdate, setFromdate] = React.useState(year + '-' + month + '-' + date);
    const [todate, setTodate] = React.useState(year + '-' + month + '-' + date);

    useEffect(() => {
        getData()
        
    }, [branchid]);

    const getData = () => {
        var token = localStorage.getItem("token")
        var data = {
            "companyId": 1,
            "branchId": branchid,
            "fromDate": fromdate,
            "toDate": todate,
            "createdBy": "",
            "billno": "",
            "counterId": "",
            "userId": "",
            "soldItemsOnly": "",
            "accId": ""
          }
        fetch(global.url + "api/BookingReports/GetOccupancyList", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("GetCounterStockList view Response", responseJson)
             
             setSource(responseJson)
        })
        .catch((error)=>{
         
            console.log(error)
        })
    }

    const formatDate = (datestring, type) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
        }
    
        const zeroDate = "0001-01-01T00:00:00";
        if (datestring === zeroDate) {
            return ""; // or any other representation for this special case, e.g., return "N/A";
        }
    
        var date = new Date(datestring);
        var month = pad2(date.getMonth() + 1);
        var day = pad2(date.getDate());
        var year = date.getFullYear();
    
        const timeString12hr = new Date(datestring)
            .toLocaleTimeString('en-US', { timeZone: 'Asia/Kolkata', hour12: true, hour: 'numeric', minute: 'numeric' });
    
        if (type === "time") {
            var formattedDate = day + "/" + month + "/" + year + " " + timeString12hr;
        } else {
            var formattedDate = year + "-" + month + "-" + day + " " + timeString12hr;
        }
    
        return formattedDate;
    };

    const formatDate2 = (datestring) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
        //   console.log("timeString12hr", timeString12hr)

          var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          return formattedDate; //03/02/2021
    }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold'}}>Current Occupancy</p> 
                <div>
                    <Row>
                        <Col xs={12} md={3}>
                            <ReactToPrint
                                trigger={() => <Button  style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 17, width: '50%'}} variant="contained">Print this out!</Button>}
                                content={() => componentRef.current}
                            />
                        </Col>
                    </Row>
                </div>
                <div>
                    <TableContainer sx={{ maxHeight: 440 }}>
                    <div className='bulk-table section-to-print' ref={componentRef} style={{width: '95%'}}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead> 
                            <TableRow style={{height: 80}} >
                                    <TableCell colSpan={7}  style={{padding: 0}}>
                                        <Row>
                                            <img src={logo} style={{width: 100, position: 'absolute', top: 0}}/>
                                            
                                            <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                                            <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                                            </Col>
                                            <p className='printdate' style={{ position: 'absolute', left: '85%'}}>{formatDate2(new Date)}</p> 
                                        </Row>
                                    </TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell colSpan={7} style={{padding: 0}}>
                                        <div style={{display: 'flex', justifyContent: 'center', fontWeight: 600}} className='captiontable'>
                                            CURRENT OCCUPANCY
                                        </div>
                                    </TableCell>
                                </TableRow>
                            <TableRow>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '10%'}}>Room No.</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '10%'}}>GRC</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '10%'}}>Date</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '10%'}}>Guest Name</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '10%'}}>Place</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {source
                                .map((i) => {
                                return (
                                    <TableRow  tabIndex={-1} key={i.code}>
                                        <TableCell className='tablecellPrint'>
                                            {i.room}
                                        </TableCell>
                                        <TableCell className='tablecellPrint'>
                                            {i.grc}
                                        </TableCell>
                                        <TableCell className='tablecellPrint'>
                                        {formatDate(i.checkin, "time")}
                                        </TableCell>
                                        <TableCell className='tablecellPrint'>
                                            {i.name}
                                        </TableCell>
                                        <TableCell className='tablecellPrint'>
                                        {i.address}
                                        </TableCell>
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
                        </div>
                    </TableContainer>
                </div>
            </Paper>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(Occupancy);