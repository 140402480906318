import React, {useState, useEffect,useRef} from 'react';
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button, Modal} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
// import Loader from '../loader/loader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TimePicker from '@mui/lab/TimePicker';
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { connect } from "react-redux";
import ReactToPrint from 'react-to-print';
import { Rings  } from  'react-loader-spinner';
import logo from '../../assets/images/logo.jpeg';

const columns = [
    { id: '1', label: 'Sl No#', minWidth: 50,span: false },
    { id: '1', label: 'Invoice', minWidth: 50,span: false },
    { id: '2', label: 'Location', minWidth: 50,span: false },
    { id: '3', label: 'Building', minWidth: 50,span: false },
    { id: '4', label: 'Street', minWidth: 50,span: false },
    { id: '5', label: 'Zone', minWidth: 50,span: false },   
    { id: '6', label: 'Mobile', minWidth: 50,span: false },
    { id: '7', label: 'Rate', minWidth: 50,span: false },
    { id: '8', label: 'Item, Colour, Size, Quantity', minWidth: 50,span: false },  
    // { id: '9', label: 'Description', minWidth: 50,span: false },
    { id: '10', label: 'By', minWidth: 50,span: false },
    { id: '11', label: 'Remarks', minWidth: 50,span: false },  
    { id: '7', label: 'Order Time', minWidth: 50,span: false },
  ];

 

  function Salesbilllist({branchid, branchdetails}) {
    const componentRef = useRef();
    const [age, setAge] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [source, setSource] = React.useState([])
    const [modal, setModal] = React.useState(false)
    const [detailstable, setDetailstable] = React.useState([])
    const [grandtotal, setGrandtotal] = useState(0);
    const componentRef1 = useRef();
    const [refresh, setRefresh] = React.useState(false);

    const [loader, setLoader] = useState(false);
    const [makebilllast, setMakebilllast] = React.useState({});
    const [makebilltotal, setMakebilltotal] = React.useState(0);

    const delay = ms => new Promise(res => setTimeout(res, ms));


    const [counterlist, setCounterlist] = React.useState([]);
    const [counter, setCounter] = useState("");
    const [total, setTotal] = useState(0);

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();


    const [date1, setDate1] = React.useState(year + '-' + month + '-' + date);
    const [date2, setDate2] = React.useState(year + '-' + month + '-' + date);
    const [billno, setBillno] = React.useState("");
    const [otno, setOtno] = React.useState("");
    const [itemcode, setItemcode] = React.useState("");
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    useEffect(() => {
        if(refresh == true) {
            window.location.reload()
            setRefresh(false)
        }
    }, [refresh]);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const counterChange = (event) => {
        setCounter(event.target.value)
      }

      useEffect(() => {
        getMakebilltotal()
    }, [makebilllast]);

    const getMakebilltotal = () => {
        var totalAmount = 0;
        for (var i = 0; i < makebilllast?.details?.length; i++) {
            totalAmount += parseFloat(makebilllast?.details[i]?.dtamount)
        }
       setMakebilltotal(totalAmount.toFixed(2))
    }

    useEffect(() => {
        getData()
        getCounter()
    }, [branchid]);

    const onFilter =()=> {

    }

    useEffect(() => {
        getTotalamount()
    }, [detailstable]);

    const getTotalamount = () => {
        var totalAmount = 0;
        for (var i = 0; i < detailstable.length; i++) {
            totalAmount += parseFloat(detailstable[i].dtamount)
        }
       setTotal(totalAmount.toFixed(2))
    }

    const getCounter = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Counter/GetCounterlist?companyId=1&branchId="+ branchid + "&includeBlocked=false", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("counterlist response", responseJson)
              setCounterlist(responseJson)
          })
          .catch((error)=>{
              console.log(error)
          })
      }


    const getData = () => {
        setLoader(true)
        var token = localStorage.getItem("token")
        var data = {
            "companyId": 1,
            "branchId": branchid,
            "fromDate": date1,
            "toDate": date2,
            "createdBy": global.userid,
            "billno": billno,
            "table": otno,
            "waitercode": itemcode,
            "counterId": counter,
            "userId": "",
            "soldItemsOnly": "",
            "accId": ""
          }
          console.log("data", data)
        fetch(global.url + "api/SalesReport/SalesBillList", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),  
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setLoader(false)
             console.log("api/SalesReport/SalesBillList", responseJson)
             setSource(responseJson)
        })
        .catch((error)=>{
            setLoader(false)
            console.log(error)
        })
    }


    useEffect(() => {
        var totalAmount = 0;
        for (var i = 0; i < source.length; i++) {
            totalAmount += parseFloat(source[i].saamount)
        }
        
       setGrandtotal(totalAmount.toFixed(2))
    }, [source]);

    const onViewclick = (item) => {
        setModal(true)
        setDetailstable(item)
    }

    const onDeleteclick = (id, vid) => {
        if(window.confirm('Are you want to delete?')){
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Sales/CancelSalesItemsByDtId?dtId=" + id + "&vid=" + vid + "&userId=" +  global.userid, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
           if(responseJson.error == false) {
               alert(responseJson.message)
               window.location.reload()
           }
           else{
            alert(responseJson.message)
           }
           console.log("response", responseJson)
        })
        .catch((error)=>{
           
            console.log(error)
        })}
    }

    const updateClick = (index) => {
        if(window.confirm('Are you Want to update?')){
        var token = localStorage.getItem("token")
        var input = [...detailstable]

        const data = {
            "dtid": input[index].dtid,
            "vid": input[index].vid,
            "dtprdid": input[index].dtprdid,
            "dtprdbatchid": input[index].dtprdbatchid,
            "dtunitid": input[index].dtunitid,
            "dtqty": input[index].dtqty,
            "dtprice": input[index].dtprice,
            "dtamount": input[index].dtamount,
            "dtdiscper": input[index].dtdiscper,
            "dtdiscount": input[index].dtdiscount,
            "dtgross": input[index].dtgross,
            "dtsgst": input[index].dtsgst,
            "dtcgst": input[index].dtcgst,
            "dtigst": input[index].dtigst,
            "dttax": input[index].dttax,
            "dtsgstper": input[index].dtsgstper,
            "dtcgstper": input[index].dtcgstper,
            "dtigstper": input[index].dtigstper,
            "dttaxper": input[index].dttaxper,
            "dttaxid": input[index].dttaxid,
            "dttotal": input[index].dttotal,
            "dtlremarks": input[index].dtlremarks,
            "dtgdnid": input[index].dtgdnid,
            "dtcost": input[index].dtcost,
            "dtsarate": input[index].dtsarate,
            "dtmrp": input[index].dtmrp,
            "batchcode": input[index].batchcode,
            "barcode": input[index].barcode,
            "procode": input[index].procode,
            "proname": input[index].proname,
            "localname": input[index].localname,
            "unitname": input[index].unitname,
            "gdnname": input[index].gdnname,
            "sl": input[index].sl,
            "UserId" :  global.userid
          }
        console.log("save data", data)

        fetch(global.url + "api/Sales/EditSalesItemsByDtId", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log('print', responseJson)
            if(responseJson.error== false){
                alert(responseJson.message)
                window.location.reload()
            }
            else{
                alert(responseJson.message)
            }
            
        })
        .catch((error)=>{
            alert("error occured")
            console.log(error)
        })
        }
    }

    const qtyChange = (event, index) => {
        var textArray1 = [...detailstable]
        if(textArray1[index].dtunitid == 2) { 
            const rx_live = /^[+-]?\d*(?:[,]\d*)?$/;
            if (rx_live.test(event.target.value)) {
                var totalprice = (textArray1[index].dtsarate * event.target.value).toFixed(2)
                textArray1[index].dtqty = event.target.value
                textArray1[index].dtamount = totalprice
                // textArray1[index].dtprice = totalprice
                setDetailstable(textArray1)
            }
        }
        else{
            const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
            if (rx_live.test(event.target.value)) {
                var totalprice = (textArray1[index].dtsarate * event.target.value).toFixed(2)
                textArray1[index].dtqty = event.target.value
                textArray1[index].dtamount = totalprice
                // textArray1[index].dtprice = totalprice
                setDetailstable(textArray1)
            }
        }
        
    }

    const rateChange = (event, index) => {
        var textArray1 = [...detailstable]
        var totalprice = (event.target.value * textArray1[index].dtqty).toFixed(2)
        textArray1[index].dtsarate = event.target.value
        textArray1[index].dtamount = totalprice
        setDetailstable(textArray1)
    }

    const formatDate = (datestring) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
        //   console.log("timeString12hr", timeString12hr)

          var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          return formattedDate; //03/02/2021
    }

    const onPrintClick = (id) => {
        

        var token = localStorage.getItem("token")

        fetch(global.url + "api/Sales/GetSalesByIdToPrint?id=" + id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
        .then((response)=> response.json())
        .then(async(responseJson)=>{
            console.log('print', responseJson)
            setMakebilllast(responseJson)
            setRefresh(false)
            document.getElementById('print-button1').click();
            await delay(2000);
            setRefresh(true)
        })
        .catch((error)=>{
            alert("error occured")
            console.log(error)
        })
    }

      return(
        <div className="main-content">
            <div style={{display: 'none'}}>
            <ReactToPrint
                    trigger={() => <Button id="print-button1" variant="primary" className="side-topbtn">OT Print</Button>}
                    content={() => componentRef1.current}
                />
                </div>
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2 }}>
            <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>Sales Bill list</p> 
            <Row>
                    <Col xs={12}  className='columnborder'>
                        <div>
                            <Row>
                                <Col sm={6} lg={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="From Bill Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setDate1(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col sm={6} lg={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="To Bill Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setDate2(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={2}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField id="outlined-basic" value={billno} onChange={(e) => setBillno(e.target.value)} label="Bill No."  variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={2}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField id="outlined-basic" value={otno} onChange={(e) => setOtno(e.target.value)} label="Table No."  variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={2}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField id="outlined-basic" value={itemcode} onChange={(e) => setItemcode(e.target.value)} label="Waiter Code"  variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={2}>
                                    <div style={{justifyContent: 'center', display: 'flex'}}>
                                        {
                                            loader ?
                                            <Rings
                                                height="50"
                                                width="50"
                                                color='#0d6efd'
                                                ariaLabel='loading'
                                            />: 
                                            <Button onClick={getData} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 17, width: '50%'}} variant="contained">Search</Button>
                                        }
                                        </div>
                                </Col>
                                {/* <Col xs={12} md={2}>
                                    <div style={{ justifyContent: 'center', display: 'flex'}}>
                                        <Button  onClick={() => onPrint()}  style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">print</Button>
                                    </div>
                                </Col> */}
                                <Col xs={12} md={4} >
                                    <ReactToPrint
                                        trigger={() => <Button style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 17, width: '50%'}} variant="contained">Print this out!</Button>}
                                        content={() => componentRef.current}
                                    />
                                </Col>
                            </Row>
                           
                        </div>
                    </Col> 
                </Row>
                <div style={{marginTop: 20}}>
                {/* {loader ? <Loader /> : */}
                    <TableContainer sx={{ maxHeight: 440, marginTop: 2 }}>
                    <div  className='section-to-print' ref={componentRef} style={{width: '95%'}}>
                            
                        <Table id="table-to-xls"  stickyHeader aria-label="sticky table">
                       
                            <TableHead className="tableheaderexe">
                            <TableRow style={{height: 80}} >
                                    <TableCell colSpan={8}  style={{padding: 0}}>
                                        <Row>
                                            <img src={logo} style={{width: 100, position: 'absolute', top: 0}}/>
                                            
                                            <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                                            <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                                            </Col>
                                            <p className='printdate' style={{ position: 'absolute', left: '85%'}}>{formatDate(new Date)}</p>  
                                        </Row>
                                    </TableCell>
                                 </TableRow>
                                 <TableRow>
                                    
                                    <TableCell colSpan={8} style={{padding: 0}}>
                                        <div style={{display: 'flex', justifyContent: 'center', fontWeight: 600}} className='captiontable'>
                                            SALES BILL LIST FROM {date1.split("-").reverse().join("-")} TO  {date2.split("-").reverse().join("-")}
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '10%'}}>
                                    SL No
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '10%'}}>
                                    BILL NO
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '10%'}}>
                                    WAITER CODE
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '10%'}}>
                                    TABLE/ ROOM NO
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '10%'}}>
                                    TOTAL ITEMS
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '15%'}}>
                                    AMOUNT
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '25%'}}>
                                    DATE
                                    </TableCell>
                                    
                                    <TableCell className='tablecellPrint noprint' style={{fontWeight: 'bold', width: '10%'}}>
                                    VIEW
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody style={{marginTop: 20}} className="bodytable">
                            {
                            
                            source.map((item, index) => {
                                return (
                                     
                                         <TableRow className='tablerowPrint'  tabIndex={-1} key={item.code}>
                                        <TableCell className='tablecellPrint' >
                                        {index+1}
                                        </TableCell>
                                        <TableCell className='tablecellPrint'  >
                                        {item.billno}
                                        </TableCell>
                                        <TableCell className='tablecellPrint' >
                                        {item.waiterid}
                                        </TableCell>
                                        {item.tableNumber == "" ?
                                        <TableCell className='tablecellPrint' >
                                        {item.roomNumber}
                                        </TableCell>:
                                        <TableCell className='tablecellPrint' >
                                        {item.tableNumber}
                                        </TableCell>}
                                        <TableCell  className='tablecellPrint' >
                                        {item.totalItems}
                                        </TableCell>
                                        <TableCell style={{textAlign: 'right'}} className='tablecellPrint' >
                                        {item.saamount.toFixed(2)}
                                        </TableCell>
                                        <TableCell style={{textAlign: 'center'}} className='tablecellPrint' >
                                        {item.billDate}
                                        </TableCell>
                                        <TableCell style={{textAlign: 'right'}} className='tablecellPrint noprint' >
                                        <Button style={{backgroundColor: '#03a5fc', color: '#fff', fontSize:10}} variant="contained" onClick={() => onViewclick(item.details)}>View</Button>
                                        <Button style={{backgroundColor: '#03a5fc', color: '#fff', fontSize:10, marginTop:10}} variant="contained" onClick={() => onPrintClick(item.vid)}>Duplicate</Button>
                                        </TableCell>
                                    </TableRow>
                        
                        );
                        })}
                        <TableRow className='tablerowPrint'>
                                <TableCell colSpan={5} style={{fontWeight: 600}} className='tablecellPrint' >
                                    Total
                                </TableCell>
                                <TableCell  style={{textAlign: 'right', fontWeight: 600}}  className='tablecellPrint' >
                                    {grandtotal}
                                </TableCell>
                                <TableCell colSpan={2} style={{textAlign: 'right'}} className='tablecellPrint' >
         
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        </Table>
                        </div>
                    </TableContainer>
                         
                </div>
            </Paper> 
            <Modal
                    size="xl"
                    show={modal}
                    onHide={() => setModal(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                    >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                        Details
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row style={{marginTop: 20}}>
                        <Table responsive bordered>
                        <TableHead>
                        <TableRow>
                            <TableCell className='tablecellPrint' >OT</TableCell>
                            <TableCell className='tablecellPrint' >Code</TableCell>
                            <TableCell className='tablecellPrint' >Product Name</TableCell>
                            <TableCell className='tablecellPrint' >Unit</TableCell>
                            <TableCell className='tablecellPrint' >Quantity</TableCell>
                            <TableCell className='tablecellPrint' >Rate</TableCell>
                            <TableCell className='tablecellPrint' >Amount</TableCell>
                            <TableCell className='tablecellPrint'  colSpan={2}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                                detailstable.map((i, k) => {
                                    return(
                                        <TableRow>
                                            <TableCell className='tablecellPrint'>{i.barcode}</TableCell>
                                            <TableCell className='tablecellPrint'>{i.procode}</TableCell>
                                            <TableCell className='tablecellPrint' >{i.proname}</TableCell>
                                            <TableCell className='tablecellPrint' >{i.dtunitid == 2 ? "Bottle" : "Peg"}</TableCell>
                                            <TableCell className='tablecellPrint' ><input autocomplete="off" style={{width: 100}}  type="text" name="query" value={i.dtqty} onChange={(event) => qtyChange(event, k)}/></TableCell>
                                            <TableCell className='tablecellPrint' ><input autocomplete="off" style={{width: 150}}   type="text" name="query" value={i.dtsarate} onChange={(event) => rateChange(event, k)}/></TableCell>
                                            <TableCell className='tablecellPrint'  style={{textAlign: 'right'}}>{i.dtamount}</TableCell>
                                            <TableCell className='tablecellPrint'  style={{textAlign: 'right'}}><Button style={{backgroundColor: '#03a5fc', color: '#fff', fontSize:10}} variant="contained" onClick={() => updateClick(k)}>Update</Button></TableCell>
                                            <TableCell className='tablecellPrint'  style={{textAlign: 'right'}}><Button style={{backgroundColor: '#03a5fc', color: '#fff', fontSize:10}} variant="contained" onClick={() => onDeleteclick(i.dtid, i.vid)}>Delete</Button></TableCell>
                                        </TableRow>
                                    )
                                })
                            }

                            <TableRow>
                                
                                <td colSpan={4} style={{fontWeight: 'bold'}}>Total</td>
                                <td style={{textAlign: 'right',fontWeight: 'bold'}}>{total}</td>
                            </TableRow>
                        
                    </TableBody>
                            </Table>
                        </Row>
                    </Modal.Body>
           </Modal>

           <div className='posprint testprint salesbilllist' ref={componentRef1}>
                <p style={{fontSize: 13, fontWeight: 'bold', textAlign: "center", marginBottom: 'unset'}}>{branchdetails.brname}</p>
                <p style={{fontSize: 11, fontWeight: 500, textAlign: "center", marginBottom: 'unset'}}>{branchdetails.braddresS1}, {branchdetails.braddresS2}, {branchdetails.braddresS3}</p>
                <p style={{fontSize: 11, fontWeight: 500, textAlign: "center", marginBottom: 'unset'}}>DUPLICATE BILL NO. {makebilllast.billno}  Date&Time {makebilllast.billDate}</p>
                <div style={{width: '100%', height: 1, backgroundColor: "#d3d3d3", marginTop: 5, marginBottom: 5}}></div>
                <Table responsive className='posprinttable'>
                    <tbody>
                    <tr>
                            <th>Sl</th>
                            <th>Description</th>
                            <th>Qty</th>
                            <th>Rate</th>
                            <th>Amt</th>
                        </tr>
                        
                        {  
                            makebilllast?.details?.map((i, k) => {
                                return(
                                    <tr>
                                        <td>{k+1}</td>
                                        <td style={{fontSize:12}}>{i.proname}</td>
                                        <td>{i.dtqty}</td>
                                        <td style={{textAlign: 'right', fontSize:11}}>{i.dtprice.toFixed(2)}</td>
                                        <td style={{textAlign: 'right', fontSize:11}}>{i.dtamount.toFixed(2)}</td>
                                    </tr>
                                )
                            }) 
                        }
                        
                        <tr>
                            <td colSpan={4} style={{textAlign: 'right'}}><span >Total Amount</span></td>
                            <td style={{textAlign: 'right'}}>{makebilltotal}</td>
                        </tr>
                        <tr>
                            <td colSpan={4} style={{textAlign: 'right'}}><span >Round Off</span></td>
                            <td style={{textAlign: 'right'}}>00.00</td>
                        </tr>
                        <tr>
                            <td colSpan={4} style={{textAlign: 'right',fontWeight: 'bold', fontSize:13}}><span>Net Amount</span></td>
                            <td style={{textAlign: 'right', fontWeight: 'bold', fontSize:13}}>{makebilltotal}</td>
                        </tr>
                    </tbody>
                </Table>
                <div>
                    <p style={{fontSize: 11, marginBottom: 'unset',  wordBreak: 'break-word', width: 275}}>OT No : {makebilllast.sadocno}</p>
                    <p style={{fontSize: 11, marginBottom: 'unset'}}>Party : {makebilllast.saaccname}</p>
                    <p style={{fontSize: 11, marginBottom: 'unset'}}>Waiter : {makebilllast.waiterid}-{makebilllast.waitername}</p>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <p style={{fontSize:  11, marginBottom: 'unset'}}>Total Items : {makebilllast?.details?.length}</p>
                        <p style={{fontSize: 11, marginBottom: 'unset'}}>Table No. {makebilllast.roomNumber != null ? makebilllast.roomNumber: makebilllast.tableNumber}</p>
                    </div>
                    <p style={{fontSize: 11, marginBottom: 'unset', fontWeight: 600, marginTop: 10, textAlign: 'center'}}>Thank You Visit Again.</p>
                </div>
            </div>
        </div>
      );
  }

  const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(Salesbilllist);
