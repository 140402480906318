import React, {Component, useEffect, useState} from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import DashboardLayoutRoute from '../src/components/layout/dashboardLayout'
import AuthLayoutRoute from '../src/components/layout/authLayout'
import Counter from '../src/components/Counter/Counter';
import Login from '../src/components/Login/Login.js';
import BulkBooking from './components/Counter/BulkBooking/BulkBooking';
import RoomStatus from './components/Counter/RoomStatus/RoomStatus';
import Reservation from './components/Counter/Revervation/ReservedRoom';
import ReservationLists from './components/Counter/ReservationList/ReservationList';
import DayClose from './components/Counter/DayClose/DayClose';
import GuestLaundry from './components/Counter/GuestLaundry/GuestLaundry';
import DuplicateBill from './components/Counter/DuplicateBill/DuplicateBill';
import Occupancy from './components/Counter/Occupancy/Occupancy';
import Voucher from './components/Counter/Voucher/Voucher';
import BillSummary from './components/Counter/BillSummary/BillSummary';
import EditRoom from './components/Counter/EditRoom/EditRoom';
import EditAddress from './components/Counter/EditAddress/EditAddress';
import CreditSale from './components/Counter/CreditSale/CreditSale';
import ExtraPerson from './components/Counter/ExtraPerson/ExtraPerson';

import Discountmaster from './components/Master/discountmaster';
import Incentivemaster from './components/Master/incentivemaster';
import Floormaster from './components/Master/floormaster';
import Sourcemaster from './components/Master/sourcemaster';
import Roomtypemaster from './components/Master/roomtypemaster';
import Roomtariffmaster from './components/Master/roomtariffmaster';
import Room from './components/Master/rooom';
import Luxary from './components/Master/luxary';
import Addressproof from './components/Master/addressproof';

import Itemmaster from './components/Barmaster/itemmaster';
import Waitermaster from './components/Barmaster/waitermaster';
import Tablemaster from './components/Barmaster/tablemaster';
import Category from './components/Barmaster/Category';
import SubCategory from './components/Barmaster/Subcategory';
import Pricelist from './components/Barmaster/Pricelist';

import Countersale from './components/Transaction/countersales';
import Godown from './components/Transaction/godown_issue';
import Purchase from './components/Transaction/purchase';
import Contra from './components/Transaction/contra';
import Transferentry from './components/Transaction/transfer-entry';
import MiscellaneousPurchase from './components/Transaction/miscellsneous';
import BarDayEntry from './components/Reports/Bar/bardayentry';

import Payment from './components/Transaction/payment';
import Reciept from './components/Transaction/reciept';
import Opening from './components/Transaction/opening';
import Updatesales from './components/Transaction/updatesales';

import CashStatement from './components/Reports/CashStatement';
import RentAndTax from './components/Reports/RentAndTax';
import ForeignersList from './components/Reports/ForeignersList';
import OccupancyReg from './components/Reports/OccupancyRegister';
import CheckoutStatement from './components/Reports/CheckoutStatement';
import Tariff from './components/Reports/TarifStatement';
import IncentiveReport from './components/Reports/IncentiveReport';
import ReservedRooms from './components/Reports/ReservedRooms';
import RoomChange from './components/Reports/RoomChange';
import FrontOffice from './components/Reports/FrontOffice';
import Waitersalesreport from './components/Reports/Waitersalesreport';
import Stockvalue from './components/Reports/stockvaluereport';
import Godownstock from './components/Reports/godownstock';
import Salesbilllist from './components/Reports/salesbillList';
import Runningbilllist from './components/Reports/runningbilllist';


import Excisestatementreport from './components/Reports/Bar/Excisestatementreport';
import Salestatementreport from './components/Reports/Bar/Salestatementreport';
import Bardayentry from './components/Reports/Bar/bardayentry';
import Counterstock from './components/Reports/Bar/counterstock';
import Actualstalestatement from './components/Reports/Bar/Actualsalestatement';

import PaymentReport from './components/Reports/Bar/PaymentReport';
import ReceiptReport from './components/Reports/Bar/ReceiptReport';
import Godownreport from './components/Reports/GodownReport';
import Purchasereport from './components/Reports/purchasereport';
import Godowntransfer from './components/Reports/godowntransferreport';

import Branch from './components/Admin/Branch';
import User from './components/Admin/User';

import Group from './components/Accounts/Group';
import Ledger from './components/Accounts/Ledger';
import Daybook from './components/Accounts/DayBook';
import LedgerReport from './components/Accounts/LedgerReport'
import TrailBalance from './components/Accounts/TrailBalance';
import ProfitLoss from './components/Accounts/ProfitLoss';
import BalanceSheet from './components/Accounts/BalanceSheet';
import POS from './components/POS/pos';
import Bill from './components/POS/Bill';
import Bills from './components/Bill/Bills';
import Cashbook from './components/Reports/Bar/Cashbook';
import Bankbook from './components/Reports/Bar/Bankbook';
import Dashboard from './components/Dashboard/Dashboard';
import Journal from './components/Transaction/Journal';
import Postdaysales from './components/Transaction/Postdaysales';

import Block from './components/Block/block';
import MiscellaneousReport from './components/Reports/MiscellaneousReport';
import cancelledbilllist from './components/Reports/cancelledbilllist';
import FoodPriceList from './components/Barmaster/FoodPriceList';
import FoodSales from './components/Reports/FoodSales';
import EditGrc from './components/Counter/GRC/EditGrc';
import UserRights from './components/Admin/UserRights';

import Maintenancemaster from './components/Maintenance/Maintenancemaster';
import Serviceentry from './components/Maintenance/Serviceentry';
import Serviceview from './components/Maintenance/Serviceview';
import Userlogs from './components/Admin/Userlogs';
import Stocklist from './components/Maintenance/Stocklist';
import Consolidate from './components/Reports/Bar/Consolidate';
import RunningOt from './components/Reports/RunningOt';
import Stocklistsale from './components/Reports/Stocklist';
import FoodPricelistOt from './components/Barmaster/FoodPricelistOt';
import Kot from './components/Reports/Kot';
import SalesBillListView from './components/Reports/SalesBillListView';
import WsReport from './components/Reports/WsReport';


function AppNavigator() { 
  return (
      <Router basename='/'>
        <Switch>
          <POS exact path='/pos' component={POS} />
          <Countersale exact path="/Transaction/Countersale" component={Countersale} />

          <Bill exact path='/bill/:id' component={Bill} />
          <Bills exact path='/bills' component={Bills} />
          <Block exact path='/menu' component={Block} />
          <AuthLayoutRoute exact path='/' component={Login} />
          <DashboardLayoutRoute exact path="/Counter/Counter" component={Counter} />
          <DashboardLayoutRoute exact path="/Counter/BulkBooking" component={BulkBooking} />
          <DashboardLayoutRoute exact path="/Counter/RoomStatus" component={RoomStatus} />
          <DashboardLayoutRoute exact path="/Counter/Reservation" component={Reservation} />
          <DashboardLayoutRoute exact path="/Counter/ReservationLists" component={ReservationLists} />
          <DashboardLayoutRoute exact path="/Counter/DayClose" component={DayClose} />
          <DashboardLayoutRoute exact path="/Counter/GuestLaundry" component={GuestLaundry} />
          <DashboardLayoutRoute exact path="/Counter/DuplicateBill" component={DuplicateBill} />
          <DashboardLayoutRoute exact path="/Counter/Occupancy" component={Occupancy} />
          <DashboardLayoutRoute exact path="/Counter/RegisterOccupancy" component={OccupancyReg} />
          <DashboardLayoutRoute exact path="/Counter/grc" component={EditGrc} />
          
          <DashboardLayoutRoute exact path="/Counter/Voucher" component={Voucher} />
          <DashboardLayoutRoute exact path="/Counter/BillSummary" component={BillSummary} />
          <DashboardLayoutRoute exact path="/Counter/EditRoom" component={EditRoom} />
          <DashboardLayoutRoute exact path="/Counter/EditAddress" component={EditAddress} />
          <DashboardLayoutRoute exact path="/Counter/CreditSale" component={CreditSale} />
          <DashboardLayoutRoute exact path="/Counter/ExtraPerson" component={ExtraPerson} />

          <DashboardLayoutRoute exact path="/Master/Discountmaster" component={Discountmaster} />
          <DashboardLayoutRoute exact path="/Master/Incetivemaster" component={Incentivemaster} />
          <DashboardLayoutRoute exact path="/Master/Floormaster" component={Floormaster} />
          <DashboardLayoutRoute exact path="/Master/Sourcemaster" component={Sourcemaster} />
          <DashboardLayoutRoute exact path="/Master/Roomtype" component={Roomtypemaster} />
          <DashboardLayoutRoute exact path="/Master/Roomtafriffmaster" component={Roomtariffmaster} />
          <DashboardLayoutRoute exact path="/Master/Room" component={Room} />
          <DashboardLayoutRoute exact path="/Master/LuxaryTax" component={Luxary} />
          <DashboardLayoutRoute exact path="/Master/Addressproof" component={Addressproof} />

          <DashboardLayoutRoute exact path="/Transaction/contra" component={Contra} />
          <DashboardLayoutRoute exact path="/Transaction/journal" component={Journal} />

          <DashboardLayoutRoute exact path="/Accounts/Group" component={Group} />
          <DashboardLayoutRoute exact path="/Accounts/Ledger" component={Ledger} />
          <DashboardLayoutRoute exact path="/Accounts/Daybook" component={Daybook} />
          <DashboardLayoutRoute exact path="/Accounts/LedgerReport" component={LedgerReport} />
          <DashboardLayoutRoute exact path="/Accounts/TrailBalance" component={TrailBalance} />
          <DashboardLayoutRoute exact path="/Accounts/ProfitLoss" component={ProfitLoss} />
          <DashboardLayoutRoute exact path="/Accounts/BalanceSheet" component={BalanceSheet} />

          <DashboardLayoutRoute exact path="/Barmaster/Itemmaster" component={Itemmaster} />
          <DashboardLayoutRoute exact path="/Barmaster/Waitermaster" component={Waitermaster} />
          <DashboardLayoutRoute exact path="/Barmaster/Tablemaster" component={Tablemaster} />
          <DashboardLayoutRoute exact path="/Barmaster/Category" component={Category} />
          <DashboardLayoutRoute exact path="/Barmaster/SubCategory" component={SubCategory} />
          <DashboardLayoutRoute exact path="/Barmaster/Pricelist" component={Pricelist} />
          <DashboardLayoutRoute exact path="/Barmaster/FoodPricelist" component={FoodPriceList} />
          <DashboardLayoutRoute exact path="/Barmaster/FoodPricelistView" component={FoodPricelistOt} />
          
          <DashboardLayoutRoute exact path="/Transaction/Godown" component={Godown} />
          <DashboardLayoutRoute exact path="/Transaction/Purchase/:id" component={Purchase} />
          <DashboardLayoutRoute exact path="/Transaction/Transferentry" component={Transferentry} />
          <DashboardLayoutRoute exact path="/Transaction/MiscellaneousPurchase/:id" component={MiscellaneousPurchase} />
          <DashboardLayoutRoute exact path="/Transaction/BarDayEntry" component={BarDayEntry} />

          <DashboardLayoutRoute exact path="/Transaction/payment/:id" component={Payment} />
          <DashboardLayoutRoute exact path="/Transaction/reciept/:id" component={Reciept} />
          <DashboardLayoutRoute exact path="/Transaction/Opening" component={Opening} />
          <DashboardLayoutRoute exact path="/Transaction/updatedaysales" component={Updatesales} />
          <DashboardLayoutRoute exact path="/Transaction/postdaysales" component={Postdaysales} />

         
          <DashboardLayoutRoute exact path="/Reports/CashStatement" component={CashStatement} />
          <DashboardLayoutRoute exact path="/Reports/RentAndTax" component={RentAndTax} />
          <DashboardLayoutRoute exact path="/Reports/ForeignersLists" component={ForeignersList} />
          <DashboardLayoutRoute exact path="/Reports/OccupancyRegister" component={OccupancyReg} />
          <DashboardLayoutRoute exact path="/Reports/CheckoutStatement" component={CheckoutStatement} />
          <DashboardLayoutRoute exact path="/Reports/Tariff" component={Tariff} />
          <DashboardLayoutRoute exact path="/Reports/IncentiveReport" component={IncentiveReport} />
          <DashboardLayoutRoute exact path="/Reports/ReservedRooms" component={ReservedRooms} />
          <DashboardLayoutRoute exact path="/Reports/RoomChange" component={RoomChange} />
          <DashboardLayoutRoute exact path="/Reports/FrontOffice" component={FrontOffice} />
          <DashboardLayoutRoute exact path="/Reports/Waitersalesreport" component={Waitersalesreport} />
          <DashboardLayoutRoute exact path="/Reports/salesbilllist" component={Salesbilllist} />
          <DashboardLayoutRoute exact path="/Reports/salesbilllistview" component={SalesBillListView} />
          <DashboardLayoutRoute exact path="/Reports/runningbilllist" component={Runningbilllist} />
          <DashboardLayoutRoute exact path="/Reports/cancelledbilllist" component={cancelledbilllist} />
          <DashboardLayoutRoute exact path="/Reports/foodsalesreport" component={FoodSales} />
          <DashboardLayoutRoute exact path="/Reports/Runningot" component={RunningOt} />
          <DashboardLayoutRoute exact path="/Reports/Stocklist" component={Stocklistsale} />
          <DashboardLayoutRoute exact path="/Reports/kotbot" component={Kot} />

          <DashboardLayoutRoute exact path="/Admin/Branch" component={Branch} />
          <DashboardLayoutRoute exact path="/Admin/User" component={User} />
          <DashboardLayoutRoute exact path="/Admin/UserRights" component={UserRights} />
          <DashboardLayoutRoute exact path="/Admin/Userlogs" component={Userlogs} />

          <DashboardLayoutRoute exact path="/dashboard" component={Dashboard} />

          <DashboardLayoutRoute exact path="/Reports/Excisestatementreport" component={Excisestatementreport} />
          <DashboardLayoutRoute exact path="/Reports/Exciseconsolidate" component={Consolidate} />
          <DashboardLayoutRoute exact path="/Reports/Salestatementreport" component={Salestatementreport} />
          <DashboardLayoutRoute exact path="/Reports/Wsreport" component={WsReport} />
          <DashboardLayoutRoute exact path="/Reports/Bardayentry" component={Bardayentry} />
          <DashboardLayoutRoute exact path="/Reports/Counterstock" component={Counterstock} />
          <DashboardLayoutRoute exact path="/Reports/Actualstalestatement" component={Actualstalestatement} />
          
          <DashboardLayoutRoute exact path="/Reports/PaymentReport" component={PaymentReport} />
          <DashboardLayoutRoute exact path="/Reports/ReceiptReport" component={ReceiptReport} />
          <DashboardLayoutRoute exact path="/Reports/Cashbook" component={Cashbook} />
          <DashboardLayoutRoute exact path="/Reports/Bankbook" component={Bankbook} />
          <DashboardLayoutRoute exact path="/Reports/Miscellaneous" component={MiscellaneousReport} />
          <DashboardLayoutRoute exact path="/Reports/Godownreport" component={Godownreport} />
          <DashboardLayoutRoute exact path="/Reports/Purchasereport" component={Purchasereport} />
          <DashboardLayoutRoute exact path="/Reports/Godowntransfer" component={Godowntransfer} />
          <DashboardLayoutRoute exact path="/Reports/Stockvalue" component={Stockvalue} />
          <DashboardLayoutRoute exact path="/Reports/Godownstock" component={Godownstock} />

          <DashboardLayoutRoute exact path="/Maintenance/maintenancemaster" component={Maintenancemaster} />
          <DashboardLayoutRoute exact path="/Maintenance/serviceentry" component={Serviceentry} />
          <DashboardLayoutRoute exact path="/Maintenance/serviceview" component={Serviceview} />
          <DashboardLayoutRoute exact path="/Maintenance/stocklist" component={Stocklist} />


          <DashboardLayoutRoute exact path='/pos' component={POS} />
          <Bill exact path='/bill/:id' component={Bill} />

        </Switch>
      </Router>
  );
}

export default AppNavigator;
