import React, { useEffect } from 'react'
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Form, Button} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { connect } from "react-redux";

function EditRoom({branchid}){

    const [grc, setGrc] = React.useState("");
    const [oldroom, setOldroom] = React.useState("");
    const [room, setRoom] = React.useState("");
    const [roomlist, setRoomlist] = React.useState([]);

    const handleChange = (event) => {
        setRoom(event.target.value);
    };

    useEffect(() => {
        getAvailablerooms()
    },[])

    const getAvailablerooms = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Booking/GetAvailableRoomsOnly?branchId="+ branchid, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
        .then((response)=> response.json())
        .then(async(responseJson)=>{
            console.log("respo", responseJson)
            setRoomlist(responseJson)
        })
        .catch((error)=>{
            alert("error occured")
            console.log(error)
        })
    }

    const onSave = () => {
        var token = localStorage.getItem("token")
        var userdata = JSON.parse(localStorage.getItem("userid"))
        fetch(global.url + "api/Booking/SaveRoomChange?branchId="+ branchid + "&grcNumber=" + grc + "&oldRoomNumber=" + oldroom + "&newRoomNumber=" + room + "&userId=" + userdata, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
        .then((response)=> response.json())
        .then(async(responseJson)=>{
            alert(responseJson.message)
            window.location.reload()
        })
        .catch((error)=>{
            alert("error occured")
            console.log(error)
        })
    }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold'}}>Edit Room Details</p> 
                <Row>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField value={grc} onChange={(e) => setGrc(e.target.value)} id="outlined-basic" label="Booking No."  variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField value={oldroom} onChange={(e) => setOldroom(e.target.value)} id="outlined-basic" label="Room No."  variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4}>
                        <FormControl fullWidth sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-label">New Room No.</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={room}
                            label="Age"
                            onChange={handleChange}
                            size="small"
                            >
                             {
                                roomlist?.map((i) => {
                                    return(
                                        <MenuItem value={i.roomid}>{i.roomname}</MenuItem>
                                    )
                                })
                            }
                            </Select>
                        </FormControl>
                    </Col>
                </Row>
                {/* <p style={{fontSize: 20, fontWeight: 'bold', marginTop: 20}}>Room Details</p> 
                <Row>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" label="Room No." variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" label="Floor" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" label="Type" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3}>
                        <FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
                            <InputLabel id="demo-simple-select-label">Source</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={age}
                            label="Age"
                            onChange={handleChange}
                            size="small"
                            >
                            <MenuItem value={10}>room1</MenuItem>
                          
                            </Select>
                        </FormControl>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" label="Rate" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" label="GST" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" label="Guests" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" label="Children" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={3}>
                        <FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
                            <InputLabel id="demo-simple-select-label">Extra Bed</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={age}
                            label="Age"
                            onChange={handleChange}
                            size="small"
                            >
                            <MenuItem value={10}>1</MenuItem>
                            <MenuItem value={20}>2</MenuItem>
                            <MenuItem value={30}>3</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" label="Total" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 5, width: '100%' }}>
                            <TextField id="outlined-basic" label="Password" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                </Row> */}
                <Row>
                    <Col xs={12} md={3} style={{marginTop: 30}}>
                        <Button onClick={onSave} style={{backgroundColor: '#03a5fc', color: '#fff'}} variant="contained">Update</Button>
                    </Col>
                </Row>
            </Paper>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
    };
};

export default connect(mapStateToProps)(EditRoom);