import React from 'react'
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Occupancy from '../Counter/Occupancy/Occupancy';

const columns = [
    { id: '1', label: 'Sl No.', minWidth: 50,span: false },
    { id: '2', label: 'User', minWidth: 50,span: false },
    { id: '3', label: 'Oyo', minWidth: 50,span: false },
    { id: '4', label: 'GRC', minWidth: 50,span: false },
    { id: '5', label: 'Room No.', minWidth: 50,span: false },
    { id: '6', label: 'Check In', minWidth: 50,span: false },
    { id: '7', label: 'Check Out', minWidth: 50,span: false },
    { id: '8', label: 'Tarif', minWidth: 50,span: false },
    { id: '9', label: 'Days', minWidth: 50,span: false },
  ];
const source = [{}, {}]

function IncentiveReport(){
    const [age, setAge] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>Incentive Report</p> 
                <Row>
                    <Col xs={12}  className='columnborder'>
                        <div>
                            <Row>
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="Start Date"
                                            type="datetime-local"
                                            defaultValue="2017-06-24T10:30"
                                            sx={{ width: 250 }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="End Date"
                                            type="datetime-local"
                                            defaultValue="2017-05-24T10:30"
                                            sx={{ width: 250 }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={2}>
                                    <div style={{justifyContent: 'center', display: 'flex'}}>
                                        <Button style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 17, width: '50%'}} variant="contained">Search</Button>
                                    </div>
                                </Col>
                                <Col xs={12} md={2}>
                                    <div style={{ justifyContent: 'center', display: 'flex'}}>
                                        <Button style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 17, width: '50%'}} variant="contained">print</Button>
                                    </div>
                                </Col>
                            </Row>
                           
                        </div>
                    </Col> 
                </Row>
                <div className='bulk-table'>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                                    colSpan={column.span == true ? 2: 1}
                                >
                                    {column.label}
                                    
                                </TableCell>
                                ))}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {source
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                return (
                                    <TableRow  tabIndex={-1} key={row.code}>
                                        <TableCell>
                                            1
                                        </TableCell>
                                        <TableCell>
                                           Fo
                                        </TableCell>
                                        <TableCell>
                                           OYO Single
                                        </TableCell>
                                        <TableCell>
                                            4235
                                        </TableCell>
                                        <TableCell>
                                            24
                                        </TableCell>
                                        <TableCell>
                                            27-12-2021 02:59 PM
                                        </TableCell>
                                        <TableCell>
                                            27-12-2021 02:59 PM
                                        </TableCell>
                                        <TableCell>
                                            1200
                                        </TableCell>
                                        <TableCell>
                                            1
                                        </TableCell>
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
                        </TableContainer>
                        <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={source.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                </div>
            </Paper>
        </div>
    );
}

export default IncentiveReport;