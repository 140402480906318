import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Form, Button, Table} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { connect } from "react-redux";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

function Serviceentry({branchid}){

    const [status, setStatus] = React.useState("");
    const [priority, setPriority] = React.useState("");
    const [doc, setDoc] = React.useState("");
    const [issue, setIssue] = React.useState("");
    const [repon, setRepon] = React.useState("");
    const [repby, setRepby] = React.useState("");
    const [item, setItem] = React.useState("");

    const [itemlist, setItemlist] = React.useState([]);
    const [itemdata, setItemdata] = React.useState('');


    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();

    const [date1, setDate1] = React.useState(year + '-' + month + '-' + date);
    const [date2, setDate2] = React.useState(year + '-' + month + '-' + date);

    const [tablerow, setTablerow] = useState([]);

    useEffect(() => {
        getData()
        getlist()
    }, [branchid])

    
    const itemListChange = (event) => {
        setItemdata(event.target.value);
        getAddviaList(event.target.value)
    };

    const priorityChange = (event) => {
        setPriority(event.target.value);
    };

    const statusChange = (event) => {
        setStatus(event.target.value);
    };

    const getAddviaList = (id) => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/ServiceEntry/StockItemByCode?code=" + id + "&branchId=" + branchid, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response 123", responseJson)
            setTablerow([...tablerow, responseJson])
            
        })

        .catch((error)=>{
            console.log(error)
        })
    }

    const getData = () => {
        var token = localStorage.getItem("token")
        console.log("token", token)
        fetch(global.url + "api/ServiceEntry/NewServiceEntry?branchId=" + branchid, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("doc no.", responseJson)
             setDoc(responseJson.docno)
        })

        .catch((error)=>{
            console.log(error)
        })
    }

    const getlist = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/ServiceEntry/ItemList?branchId=" + branchid, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("item list", responseJson)
             setItemlist(responseJson)
        })

        .catch((error)=>{
            console.log(error)
        })
    }

    const addItem = () => {
        if(item == "" ){
            alert("Please enter item code")
        }else{
        var token = localStorage.getItem("token")
        fetch(global.url + "api/ServiceEntry/StockItemByCode?code=" + item + "&branchId=" + branchid, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response", responseJson)
            setTablerow([...tablerow, responseJson])
            
        })

        .catch((error)=>{
            console.log(error)
        })
    }
    }

    
    const onSubmit = () => {
        tablerow.forEach(function(v){ delete v.$id });
        for(var i = 0 ; i< tablerow.length; i++) {
            if(tablerow[i].itemdtlid != 0) {
               
                tablerow[i].itemdtlid = 0
                setTablerow(tablerow)
            }
        }
        var token = localStorage.getItem("token")
        var data = {
            "serviceid": "",
            "docdate": date1,
            "docno": doc,
            "priority": priority,
            "status": status,
            "issues": issue,
            "reportedby": repon,
            "reportedon": date2,
            "imagebasE64": "",
            "imageurl": "",
            "createdon": "",
            "createdby": "",
            "updatedon": "",
            "updatedby": "",
            "branchid": branchid,
            "vid": "",
            "details": tablerow
        }
        console.log("data service entry", data)
        fetch(global.url + "api/ServiceEntry/SaveService", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
           console.log("itemsave response", responseJson)
           if(responseJson.error == false){
               alert(responseJson.message)
               window.location.reload()
           }
           else{
            alert(responseJson.message)
           }
          
        })
        .catch((error)=>{
            alert("username or password error")
            console.log(error)
        })
    }



    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold'}}>Service entry</p> 
                <Row>
                    <Col sm={6} lg={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField
                                id="datetime-local"
                                label="Date"
                                type="date"
                                defaultValue={year + '-' + month + '-' + date}
                                sx={{ width: '100%' }}
                                InputLabelProps={{
                                shrink: true,
                                }}
                                onChange={(newValue) => {
                                    setDate1(newValue.target.value);
                                }}
                            />
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3}>
                        <FormControl fullWidth sx={{width: '100%', mt: 2 }}>
                            <InputLabel id="demo-simple-select-label">Priority</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={priority}
                            label="Age"
                            onChange={priorityChange}
                            size="small"
                            >
                                <MenuItem value="high">High</MenuItem>
                                <MenuItem value="medium">Medium</MenuItem>
                                <MenuItem value="low">Low</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField value={doc} disabled onChange={(e) => setDoc(e.target.value)} id="outlined-basic" label="Doc No" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormControl fullWidth sx={{width: '100%', mt: 2 }}>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={status}
                            label="Age"
                            onChange={statusChange}
                            size="small"
                            >
                                <MenuItem value="reported">Reported</MenuItem>
                                <MenuItem value="inprogress">In progress</MenuItem>
                                <MenuItem value="completed">Completed</MenuItem>
                                <MenuItem value="closed">closed</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12}>
                    <TextareaAutosize 
                            value={issue} 
                            onChange={(e) => setIssue(e.target.value)}
                            minRows={3}
                            placeholder="Issue"
                            style={{background: 'transparent', width: '100%', marginTop: 30}}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField value={repon} onChange={(e) => setRepon(e.target.value)} id="outlined-basic" label="Reported By" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={6} >
                        <FormControl sx={{ width: '100%', mt: 2 }}>
                            <TextField
                                id="datetime-local"
                                label="Reported On"
                                type="date"
                                defaultValue={year + '-' + month + '-' + date}
                                sx={{ width: '100%' }}
                                InputLabelProps={{
                                shrink: true,
                                }}
                                onChange={(newValue) => {
                                    setDate2(newValue.target.value);
                                }}
                            />
                        </FormControl>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6} >
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField value={item} onChange={(e) => setItem(e.target.value)} id="outlined-basic" label="Item code" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    {/* <Col xs={12} md={3}>
                        <FormControl fullWidth sx={{width: '100%', mt: 2 }}>
                            <InputLabel id="demo-simple-select-label">Item List</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={itemdata}
                            label="Age"
                            onChange={itemListChange}
                            size="small"
                            >
                                {
                                    itemlist.map((i, k) => {
                                        return(
                                            i.itemDetails.map((i, k) => (
                                                <MenuItem value={i.itemcode}>{i.itemcode}</MenuItem>
                                            ))  
                                        )
                                    })
                                }
                   
                            </Select>
                        </FormControl>
                    </Col> */}
                </Row>
                <Row>
                    <Col xs={12} md={6} >
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <Button onClick={addItem}  variant="contained" style={{ backgroundColor: '#03a5fc', marginRight: 10, width: 200, color: '#fff'}}>Add Item</Button>
                        </FormControl>
                    </Col>
                </Row>
                <Row style={{marginTop: 20}}>
                    <Table responsive bordered className="section-to-print">
                        <thead>
                            <tr>
                                <th>Item code</th>
                                <th>Serial No</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tablerow.map((i, index) => {
                                return(
                                    <tr>
                                        <td className='rowstyle'>{i.itemcode}</td>
                                        <td className='rowstyle'>{i.serialno}</td>   
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Row>
                    <Col xs={12} md={6}>
                        <Button onClick={onSubmit} variant="contained" style={{marginTop: 30, backgroundColor: '#03a5fc', marginRight: 10, width: 200, color: '#fff'}}>Save</Button>
                    </Col>
            </Paper>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
    };
};

export default connect(mapStateToProps)(Serviceentry);