import React, {useState, useEffect, useRef} from 'react';
import Paper from '@mui/material/Paper';
import { Table , Row, Col, Modal, Container} from "react-bootstrap";
import TextField from '@mui/material/TextField';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DatePicker from '@mui/lab/DatePicker';
import { useParams, useHistory } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import * as IconName from "react-icons/bs";
import * as GI from "react-icons/gi";
import * as BI from "react-icons/bi";
import * as RI from "react-icons/ri";
import { connect } from "react-redux";
import { Rings  } from  'react-loader-spinner'
import {
	getAllProduct
  } from "../../redux/Shopping/shopping-actions";
import Searchlist from "../../components/Searchlist/searchlist";
  

function Purchase({branchid, getAllProduct, productlist}) {
    const {id} = useParams()
    const history = useHistory()
    const [modal, setModal] = useState(false);
    const [textArray, setTextarray] = React.useState([{key: "", value: ""}])
    const [selectedIndex, setSelectedindex] = React.useState("")
    const [code, setCode] = useState("");
    const hasResults = code.length > 0;
    const [loader, setLoader] = useState(false);
    const [billtypelist, setBilltypelist] = React.useState([]);
    const [docnum, setDocnum] = React.useState("");
    const [docno, setDocno] = React.useState("");

    const [billType, setBilltype] = React.useState("");
    const [suppliername, setSuppliername] = React.useState("");
    const [supplierid, setSupplierid] = React.useState("");
    const [suppliercode, setSuppliercode] = React.useState("");
    const [supplierlist, setSupplierlist] = React.useState([]);
    const [groupid, setGroupid] = React.useState("")
    const [vid, setVid] = React.useState(0);
    const [puid, setPuid] = React.useState(0);
    const [pucreatedon, setPucreatedon] = React.useState("");

    const [warehouse, setWarehouse] = React.useState("")
    const [permit, setPermit] = React.useState("")
    const [permitdate, setPermitdate] = React.useState("")
    const [chalanno, setChalanno] = React.useState("")
    const [chalandate, setChalandate] = React.useState("")
    const [subtotal, setSubtotal] = React.useState(0)
    const [tax, setTax] = React.useState("")
    const [place, setPlace] = React.useState("")
    const [licensy, setLicency] = React.useState("")
    const [invoice, setInvoice] = React.useState("")
    const [total, setTotal] = React.useState(0)
    const [cessper, setCessper] = React.useState(0)
    const [grandtotal, setGrandtotal] = React.useState(0)
    const [totaltax, setTotaltax] = React.useState(0)
    const [editmode, setEditmode] = React.useState(false);

   const [productname, setProductname] = useState("")
    const hasResultsProduct = productname.length > 0;

    function handleKeyPress(event) {
        if (!/^\d*\.?\d*$/.test(event.key)) {
            event.preventDefault();
        }
      }

    const [suppliermodal, setSuppliermodal] = React.useState(false)

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();
    
    
    const [docdate, setDocdate] = React.useState(year + '-' + month + '-' + date)
 
    const [tablerow, setTablerow] = useState([{
    dtid: 0,
    dtprdid: 0,
    vid: vid,
    dtprdbatchid: 0,
    dtunitid: 0,
    dtqty: 0.0,
    dtprice: 0.0,
    dtamount: 0.0,
    dtdiscper: 0.0,
    dtdiscount: 0.0,
    dtgross: 0.0,
    dtsrate: 0.0,
    dtsgst: 0.0,
    dtcgst: 0.0,
    dtigst: 0.0,
    dttax: 0.0,
    dtsgstper: 0.0,
    dtcgstper: 0.0,
    dtigstper: 0.0,
    dttaxper: 0.0,
    dttaxid: 0,
    dttotal: 0.0,
    dtremarks: "",
    dtgdnid: 0,
    dtcost: 0,
    dtsarate: 0,
    dtmrp: 0,
    dtrqstqty: 0,
    barcode: "",
    dtamteditable: true,
    caseno: 0,
    bottle: 0,
    proname: "",
    procode: "",
    unitname: "",
    gdnname: "",
    batchLength: 0,
    bottlepercase: 0,
    sl: 0
}]);

const [childRow, setChildrow] = useState([{
    pucessid: 0,
    pucases: 0,
    pubottles: 0,
    puaddedvalue: 0,
    putaxper: 0,
    puamount: 0,
    vid: 0

}]);


useEffect(()=>{
    transformedArray1()
}, [tablerow])

const transformedArray1 = () => {
    const transformedArray = tablerow.reduce((result, currentItem) => {
        const existingItem = result.find(item => item.putaxper == currentItem.dttaxper)
        if (existingItem) {
            existingItem.pubottles += parseFloat(currentItem.bottle);
            existingItem.pucases += parseFloat(currentItem.caseno);
            existingItem.puaddedvalue += parseFloat(currentItem.dtcost);
            existingItem.puamount += parseFloat(currentItem.dttax);
            existingItem.putaxper = parseFloat(currentItem.dttaxper);
            existingItem.pucessid = 0;
            existingItem.vid = 0;

        } else {
            result.push({
                pubottles: parseFloat(currentItem.bottle),
                pucases: parseFloat(currentItem.caseno),
                puaddedvalue: parseFloat(currentItem.dtcost),
                putaxper: parseFloat(currentItem.dttaxper),
                puamount: parseFloat(currentItem.dttax),
                pucessid: 0,
                vid: 0
              });
        }
        
        return result;
      }, []);
      setChildrow(transformedArray)  
}
    const inputRef = useRef();

    const handleOnChange = (event, key) => {
        console.log("event.currentTarget.value", event.currentTarget.value)
        var textArray1 = [...tablerow]
        textArray1[key].procode = event.currentTarget.value;
        setTablerow(textArray1)
        setSelectedindex(key)
        setProductname(event.currentTarget.value)
        setCode(event.currentTarget.value);
        
    }

    

    const getSearch = (text, key) => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Products/GetProductByCode?branchId=" + branchid +"&code=" + text, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("code search Response", responseJson)
             if(responseJson.error != true){
                var input = [...tablerow]
                input[key].procode = responseJson.code;
                input[key].proname = responseJson.productname;
                input[key].dtprdid = responseJson.productid;
                input[key].bottlepercase = responseJson.bottlepercase;
                input[key].pprice = responseJson.pprice;
                input[key].dttaxper = responseJson.taxPer; 
                input[key].dtsarate = responseJson.addedvalue;

                console.log("input", input)
                setTablerow(input)
                setProductname("")
             }
             
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    useEffect(() => {
        inputRef.current.focus();

        getAllProduct()
      }, []);

    const caseChange = (event, key, bpercase, addedv) => { 
        var input = [...tablerow]
        input[key].caseno = event.target.value
        input[key].dtqty = (parseFloat(event.target.value)  * bpercase)+ input[key].bottle
        input[key].dtamount = parseFloat((input[key].pprice/bpercase) * input[key].dtqty).toFixed(2)
        input[key].dtprice = ((input[key].dtamount) / input[key].dtqty).toFixed(2)
        // input[key].dttax  ((input[key].dtamount * input[key].dttaxper)/100).toFixed(2)
        input[key].dtcost = parseFloat((((input[key].caseno) * bpercase)+ parseFloat(input[key].bottle)))*(addedv/bpercase).toFixed(2)
        if(input[key].dttaxper == 58){
            input[key].dttax = ((input[key].dtamount * input[key].dttaxper)/100).toFixed(2)
        }else{
            input[key].dttax = ((input[key].dtcost * input[key].dttaxper)/100).toFixed(2)
        }
        input[key].dttotal = parseFloat(parseFloat(input[key].dttax) + parseFloat(input[key].dtamount)).toFixed(2)
        console.log("input", input)
        setTablerow(input)
    }

    const bottleChange = (event, key, bpercase, addedvalue) => {
        var input = [...tablerow]
        input[key].bottle =event.target.value
        input[key].dtqty = parseFloat(event.target.value) + (input[key].caseno * input[key].bottlepercase)
        input[key].dtamount = parseFloat((input[key].pprice/bpercase) * input[key].dtqty).toFixed(2)
        // input[key].dttax = ((input[key].dtamount * input[key].dttaxper)/100).toFixed(2)
        input[key].dttotal = parseFloat(parseFloat(input[key].dttax) + parseFloat(input[key].dtamount)).toFixed(2)
        input[key].dtprice = ((input[key].dtamount) / input[key].dtqty).toFixed(2)
        // input[key].dtcost = parseFloat((input[key].caseno) * addedvalue).toFixed(2)
        input[key].dtcost = parseFloat((((input[key].caseno) * bpercase)+ parseFloat(input[key].bottle)))*(addedvalue/bpercase).toFixed(2)
        if(input[key].dttaxper == 58){
            input[key].dttax = ((input[key].dtamount * input[key].dttaxper)/100).toFixed(2)
        }else{
            input[key].dttax = ((input[key].dtcost * input[key].dttaxper)/100).toFixed(2)
        }
        setTablerow(input)
    }

    const priceChange = (event, key, bpercase) => {
        var input = [...tablerow]
        input[key].pprice = event.target.value
        input[key].dtamount = parseFloat(event.target.value * input[key].dtqty).toFixed(2)
        // input[key].dttotal = parseFloat(event.target.value * input[key].dtqty).toFixed(2)
        input[key].dtprice = ((input[key].dtamount) / input[key].dtqty).toFixed(2)
        
        setTablerow(input)
    }

    // const addedValueChange = (event, key, bpercase) => {
    //     var input = [...tablerow]
    //     input[key].dtcost = parseFloat(event.target.value)
    //     setTablerow(input)
    // }

    useEffect(() => {
        if(id == "add") {
            getBilltype()
            setEditmode(false)
            
        }
        else{
           getBilltype()
           getDetailsbyid(id)
           setEditmode(true)
        }
      }, [branchid]);

      const getDetailsbyid = (id) => {
        var token = localStorage.getItem("token")
        console.log("token", token)
        fetch(global.url + "api/Purchase/GetPurchaseById?id=" + id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            console.log("GetPurchaseById response", responseJson)
              setTablerow(responseJson.details)
              setVid(responseJson.vid)
              setPuid(responseJson.puid)
              setDocno(responseJson.pudocno)
              setDocnum(responseJson.pudocnum)
              setBilltype(responseJson.puformid)
              setWarehouse(responseJson.warehouse)
              setPlace(responseJson.place)
              setLicency(responseJson.licensy)
              setSuppliercode(responseJson.puacccode)
              setSupplierid((responseJson.pucaccid))
              setInvoice(responseJson.puinvoiceno)
              setDocdate(responseJson.pudocdate.split('T')[0])
              setPermit(responseJson.permit)
              setPermitdate(responseJson.permitdate)
              setChalanno(responseJson.challanno)
              setChalandate(responseJson.challandate.split('T')[0])
              setPucreatedon(responseJson.pucreatedon.split('T')[0])
          })
          .catch((error)=>{
              console.log(error)
          })
            
      }

      const getBilltype = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/BillSettings/GetBillTypeByForm?companyId=1&branchId=" + branchid + "&formType=PU", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("billSettigsList response", responseJson)
              setBilltypelist(responseJson.billSettigsList)
          })
          .catch((error)=>{
              console.log(error)
          })
      }


    const taxChange = (event, key, bpercase) => {
        var input = [...tablerow]
        input[key].dttaxper = event.target.value
        input[key].dttax = (event.target.value * ((input[key].dtamount)/100))
        // input[key].dttotal = parseFloat(event.target.value * ((input[key].dtamount)/100) + (input[key].dtamount))
        setTablerow(input)
    }
    
    const onSave = () => {

        for(var i = 0 ; i< tablerow.length; i++) {
            if(tablerow[i].dttotal == 0 ) {
                return alert("Purchase rate is zero")
           
            }}
      
        if(supplierid == "") {
            return alert("Supplier not selected")
        }

        if(billType == "") {
            return alert("Bill Type not selected")
        }

        if(warehouse == "") {
            return alert("warehouse empty")
        }
        if(licensy == "") {
            return alert("licensy empty")
        }
        if(invoice == "") {
            return alert("invoice empty")
        }
        if(place == "") {
            return alert("place empty")
        }
        if(permit == "") {
            return alert("permit empty")
        }
        if(chalanno == "") {
            return alert("chalan no empty")
        }
        

        var examplearray = []

        for(var i=0; i< tablerow.length ; i++) {
            if(tablerow[i].dtprdid == 0) {

            }
            else{
                examplearray.push(tablerow[i])
            }
        }

        if(examplearray.length == 0) {
            return alert("No item")
        }
        tablerow.forEach(function(v){ delete v.$id });
        setLoader(true)
    
        var token = localStorage.getItem("token")
        const data = {
                "puid": puid,
                "vid": vid,
                "puformid": billType,
                "pudocdate": docdate,
                "pudocno": docno,
                "pudocnum": docnum,
                "pucaccid": supplierid,
                "puaccname": suppliername,
                "puaccadD1": "",
                "puaccadD2": "",
                "puaccadD3": "",
                "pustateid": "",
                "pudistid": "",
                "pucomid": 1,
                "pubranchid": branchid,
                "pumob": "",
                "puamount": total,
                "puitemdisc": "",
                "pugross": "",
                "pusgst": "",
                "pucgst": "",
                "puigst":"",
                "putax": totaltax,
                "pudiscper": "",
                "pudiscount": "",
                "pusubtotal": total,
                "punettotal": grandtotal,
                "pucreatedon": pucreatedon,
                "pucreatedby": global.userid,
                "puupdatedby": global.userid,
                "puupdatedon": "",
                "puduedate": "",
                "puremarks": "",
                "pucessper": "",
                "pucessamount": cessper,
                "putotal": "",
                "pugodownid": 1,
                "puacccode": suppliercode,
                "pugstin": "",
                "pustockadded": true,
                "puinvoiceno": invoice,
                "povid": "",
                "podocno": "",
                "puinternational": true,
                "pumode": 40,
                "warehouse": warehouse,
                "licensy": licensy,
                "place": place,
                "permit": permit,
                "permitdate": permitdate,
                "challanno": chalanno,
                "challandate": chalandate,
                "details": tablerow,
                "cessDetails" : childRow,
                "batchLength": "",
                "printFile": "",
                "inWords": "",
                "payMode": ""
              
          }

        console.log("save data", data)

        fetch(global.url + "api/Purchase/SavePurchase", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("responseJson", responseJson)
            setLoader(false)
            if(responseJson) {
                if(editmode == true){
                    alert(responseJson.message)
                    history.push("/Reports/Purchasereport") 
                }else{
                    alert(responseJson.message)
                    window.location.reload()
                }
                
            }
            
            
        })
        .catch((error)=>{
            setLoader(false)
            alert("error occured")
            console.log(error)
        })
    }

    const billtypeChange = (event) => {
        setBilltype(event.target.value);
        getInvoiceno(event.target.value)
      };

      const getInvoiceno = (id) => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/BillSettings/GetNextBillNo?billId=" + id , {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("response", responseJson)
              setDocno(responseJson.billprefix + responseJson.billno)
              setDocnum(responseJson.billno)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const clickSupplier = () => {
          setSuppliermodal(true)
          var token = localStorage.getItem("token")
          fetch(global.url + "api/Settings/GetMainSettings?companyId=1&branchId=" + branchid , {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              setGroupid(responseJson.grpsuppid)
              getSupplierlist(responseJson.grpsuppid)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const getSupplierlist = (id) => {
          var token = localStorage.getItem("token")
        fetch(global.url + "api/Accounts/GetLedgerByGroupId?companyId=1&branchId=" + branchid + "&id=" + id + "&limit=0&searchBy=Name&searchKey=" + "" , {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log(responseJson)
              setSupplierlist(responseJson)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const selectSupplier = (id,code) => {
        setSupplierid(id)
        setSuppliercode(code)
        setSuppliermodal(false)
        setSupplierlist([])
      }

      const getTotalammount = () => {
        var totalAmount = 0;
        var tax = 0;
        for (var i = 0; i < tablerow.length; i++) {
          totalAmount += parseFloat(tablerow[i].dtamount)
          tax += parseFloat(tablerow[i].dttax)
       }
          setSubtotal(totalAmount.toFixed(2))
          setTotaltax(tax.toFixed(2))
          const totals = parseFloat(totalAmount) + parseFloat(tax)
          setTotal(totals.toFixed(2))
    }

    useEffect(() => {
        getTotalammount()
      },[tablerow])

      const addRow = () => {
        var input = [...tablerow]
        setTablerow([...tablerow, 
            {
                dtid: 0,
                dtprdid: 0,
                vid: vid,
                dtprdbatchid: 0,
                dtunitid: 0,
                dtqty: 0.0,
                dtprice: 0.0,
                dtamount: 0.0,
                dtdiscper: 0.0,
                dtdiscount: 0.0,
                dtgross: 0.0,
                dtsrate: 0.0,
                dtsgst: 0.0,
                dtcgst: 0.0,
                dtigst: 0.0,
                dttax: 0.0,
                dtcost: 0,
                dtsgstper: 0.0,
                dtcgstper: 0.0,
                dtigstper: 0.0,
                dttaxper: 0.0,
                dttaxid: 0,
                dttotal: 0.0,
                dtremarks: "",
                dtgdnid: 0,
                dtsarate: 0,
                dtmrp: 0,
                dtrqstqty: 0,
                barcode: "",
                dtamteditable: true,
                caseno: 0,
                bottle: 0,
                proname: "",
                procode: "",
                unitname: "",
                gdnname: "",
                batchLength: 0,
                bottlepercase: 0,
                sl: 0
            }
        ])
    }

    const onRowdelete = (index) => {
        var input = [...tablerow]
        input.splice(index, 1)
        setTablerow(input)
    }

    const listClickname = (text, key) => {
            getSearch(text, key)
    }
    
    useEffect(()=> {
        var totalAmount = 0;
        for (var i = 0; i < tablerow.length; i++) {
          totalAmount += parseFloat(tablerow[i].dtcost)
       }
       const totals = (parseFloat(total))/100
        setCessper(totals.toFixed(2))
    },[tablerow, total])

    useEffect(()=> {
       const totals = (parseFloat(total) +  parseFloat(cessper))
       setGrandtotal(totals.toFixed(2))
    },[tablerow, cessper, total])

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
            <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Purchase</p>
            </div>
            <Row>
                <Col sm={4} style={{marginTop:30}}>
                    <FormControl sx={{  width: '100%' }}>
                            <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Bill Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={billType}
                                disabled={editmode ? true: false}
                                label="Age"
                                onChange={billtypeChange}
                                size="small"
                                
                            >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {billtypelist.map((row, index) => {
                        return (
                            <MenuItem value={row.btypeid}>{row.formname}</MenuItem>
                        )})}
                            </Select>
                    </FormControl>
                </Col>
                <Col sm={6} lg={2} style={{marginTop:30}}>
                    <FormControl sx={{  width: '100%' }}>
                        <TextField value={warehouse}  onChange={(e) => setWarehouse(e.target.value)}  label="Warehouse" id="outlined-basic" variant="outlined"  size="small"/>
                    </FormControl>
                </Col>
                <Col sm={6} lg={2} style={{marginTop:5}}>
                    <FormControl sx={{ mt: 3, width: '100%' }}>
                        <TextField
                            id="date"
                            label="Bill Date"
                            type="date"
                            defaultValue={year + '-' + month + '-' + date}
                            sx={{ width: '100%' }}
                            disabled={editmode ? true: false}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            value={docdate}
                            onChange={(newValue) => {
                                setDocdate(newValue.target.value);
                            }}
                        />
                    </FormControl>
                </Col>
                <Col sm={6} lg={2} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField  value={invoice}  onChange={(e) => setInvoice(e.target.value)} label="Invoice NO" id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                <Col sm={12}>
                    <Row>
                        <Col sm={6} lg={2} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField disabled  value={suppliercode}  label="Supplier" id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col sm={6} lg={3} style={{marginTop:30}}>
                            <Button onClick={() => clickSupplier()} variant="outlined">SELECT SUPPLIER</Button>
                        </Col>
                        
                        <Col sm={6} lg={2} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField value={licensy}  onChange={(e) => setLicency(e.target.value)} label="Licensy" id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col sm={6} lg={2} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField value={place}  onChange={(e) => setPlace(e.target.value)}  label="Place" id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                    </Row>
                </Col>
            </Row> 
            <Row style={{marginTop: 20}}>
                <Col xs={12}>
                    <Table bordered responsive>
                        <thead>
                            <tr>
                                <th>Sl No.</th>
                                <th>Code</th>
                                <th>Name</th>
                                <th>Case(S)</th>
                                <th>Bottle</th>
                                <th>TOT Bottle</th>
                                <th>Rate Case</th>
                                <th>Added Values Amount</th>
                                {/* <th>Tax amt</th> */}
                                <th>TAX %</th>
                                <th>Amount</th>
                                {/* <th>TOTAL</th> */}
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                tablerow.map((item, index) => {
                                    return(
                                        <tr>
                                            <td>{index+1}</td>
                                            <td> 
                                                <input style={{width: '100%'}} autocomplete="off" ref={inputRef} type="text" name="query" value={item.procode} onBlur={() => getSearch(item.procode, index)} onChange={(event) => handleOnChange(event, index)} />
                                                {hasResultsProduct && selectedIndex === index  && (
                                                       <Searchlist hasResultsProduct={hasResultsProduct} productlist={productlist} productname={productname} inputRef={inputRef} listClickname={listClickname} index={index}/>
                                                    )}
                                            </td>
                                            <td>{item.proname}</td>
                                            <td><input style={{width: '100%'}} value={item.caseno} autocomplete="off"  onKeyPress={handleKeyPress} onChange={(event) => caseChange(event, index, item.bottlepercase, item.dtsarate)}/></td>
                                            <td><input style={{width: '100%'}} value={item.bottle} autocomplete="off"  onKeyPress={handleKeyPress}  onChange={(event) => bottleChange(event, index, item.bottlepercase, item.dtsarate)}/></td>
                                            <td>{item.dtqty}</td>
                                            {/* <td><input style={{width: '100%'}} value={item.pprice} disabled  autocomplete="off"  onKeyPress={handleKeyPress}  onChange={(event) => priceChange(event, index)}/></td> */}
                                            <td>{item.pprice}</td>
                                            {/* <td><input style={{width: '100%'}} value={item.dtcost} disabled  autocomplete="off"  onKeyPress={handleKeyPress}  /></td> */}
                                            <td>{item.dtcost?.toFixed(2)}</td>
                                            {/* <td>{item.dttax}</td> */}
                                            <td><input style={{width: 85}} value={item.dttaxper} autocomplete="off" disabled  onKeyPress={handleKeyPress}  onChange={(event) => taxChange(event, index)}/></td>
                                            <td>{item.dtamount}</td>
                                            {/* <td>{item.dttotal}</td> */}
                                            <td><Button onClick={() => onRowdelete(index)} variant="outlined">Delete</Button></td>
                                        </tr>
                                    )
                                })
                            }
                            
                            
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                    
                    <Col xs={6} >
                        <ul className="list-inline">
                            <li><Button onClick={() => addRow()} variant="outlined" style={{marginTop: 30}}>Add New</Button></li>
                        </ul>
                    </Col>
                </Row>
                
            <div>
                
                <Row>
                    <Col xs={12} md={2} style={{marginTop: 30}}>
                        <FormControl sx={{width: '100%' }}>
                            <TextField value={permit}  onChange={(e) => setPermit(e.target.value)} id="outlined-basic" label="Permit No" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={2} style={{marginTop: 30}}>
                            <FormControl sx={{ width: '100%' }}>
                                <TextField
                                     id="date"
                                     label="Permit Date"
                                     type="date"
                                     defaultValue={year + '-' + month + '-' + date}
                                     sx={{ width: '100%' }}
                                     InputLabelProps={{
                                     shrink: true,
                                     }}
                                     value={permitdate}
                                    onChange={(newValue) => {
                                        setPermitdate(newValue.target.value);
                                    }}
                                />
                            </FormControl>
                    </Col>
                    <Col xs={12} md={2} style={{marginTop: 30}}>
                        <FormControl sx={{width: '100%' }}>
                            <TextField value={chalanno}  onChange={(e) => setChalanno(e.target.value)} id="outlined-basic" label="Chalan No" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={2} style={{marginTop: 30}}>
                        <FormControl sx={{width: '100%' }}>
                            <TextField
                                id="date"
                                label="Chalan Date"
                                type="date"
                                defaultValue={year + '-' + month + '-' + date}
                                sx={{ width: '100%' }}
                                InputLabelProps={{
                                shrink: true,
                                }}
                                value={chalandate}
                                onChange={(newValue) => {
                                    setChalandate(newValue.target.value);
                                }}
                            />
                        </FormControl>
                    </Col>
                    <Col xs={12} md={2} style={{marginTop: 30}}>
                        <FormControl sx={{width: '100%' }}>
                            <TextField value={subtotal} disabled  id="outlined-basic" label="Total" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                  
                </Row>
                <Row style={{marginTop: 20}}>
                <Col xs={12}>
                    <Table bordered responsive>
                        <thead>
                            <tr>
                                <th>Sales Tax</th>
                                <th>Cases</th>
                                <th>Bottles</th>
                                <th>Added Value</th>
                                <th>Tax %</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {childRow?.map((item, index) => (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.pucases}</td>
                                    <td>{item.pubottles}</td>
                                    <td>{item.puaddedvalue.toFixed(2)}</td>
                                    <td>{item.putaxper}</td>
                                    <td style={{textAlign: 'right'}}>{item.puamount.toFixed(2)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={2} style={{marginTop: 20}}>
                    <FormControl sx={{width: '100%' }}>
                        <TextField value={totaltax} disabled id="outlined-basic" label="Sales Tax" variant="outlined"  size="small"/>
                    </FormControl>
                </Col>
                <Col xs={12} md={2} style={{marginTop: 20}}>
                    <FormControl sx={{width: '100%' }}>
                        <TextField value={total} disabled id="outlined-basic" label="Net Total" variant="outlined"  size="small"/>
                    </FormControl>
                </Col>
                <Col xs={12} md={4} style={{marginTop: 20}}>
                    <FormControl sx={{width: '100%' }}>
                        <TextField value={cessper} disabled id="outlined-basic" label="IT TCS @1.00 on Invoice Amount" variant="outlined"  size="small"/>
                    </FormControl>
                </Col>
                <Col xs={12} md={2} style={{marginTop: 20}}>
                    <FormControl sx={{width: '100%' }}>
                        <TextField value={grandtotal}  disabled id="outlined-basic" label="Grand Total" variant="outlined"  size="small"/>
                    </FormControl>
                </Col>
             
            </Row>
            <Row style={{marginTop: 20}}>
                <Col xs={6} >
                    <div style={{ display: 'flex'}}>
                        {
                            loader ?
                            <Rings
                                height="50"
                                width="50"
                                color='#0d6efd'
                                ariaLabel='loading'
                            />:<Button onClick={() => onSave()} variant="outlined">Save</Button>
                        }
                        </div>
                </Col>
            </Row>
            </div>
            
          </Paper> 
          <Modal
              size="lg"
              show={suppliermodal}
              onHide={() => setSuppliermodal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                  <div>
                      <Container>
                        <Row>
                            <Col xs={12}>
                                <p style={{fontSize: 16, marginTop: 10, marginBottom: 10}}>Suppliers</p>
                                <Table bordered responsive>
                                <thead>
                                        <tr>
                                            <th>Sl No.</th>
                                            <th>Supplier</th>
                                            <th>Supplier Code</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            supplierlist.map((i, k) => {
                                                return (
                                                    <tr onClick={() => selectSupplier(i.accid,i.acccode)}>
                                                        <td>{k+1}</td>
                                                        <td>{i.acccode}</td>
                                                        <td>{i.accname}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        
                                    </tbody>
                                </Table>
                            </Col>
                            
                        </Row>
                      </Container>
                  </div>
              </Modal.Body>
           </Modal>
      </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
		getAllProduct: () => {
			dispatch(getAllProduct())
		},
    };
  };

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
        productlist: state.shop.productlist
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Purchase);