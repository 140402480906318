import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button, Table, Modal} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { connect } from "react-redux";
import { Rings  } from  'react-loader-spinner'


const columns = [
    { id: '1', label: 'Code', minWidth: 50,span: false },
    { id: '2', label: 'Description', minWidth: 50,span: false },
    { id: '3', label: 'Opening', minWidth: 50,span: false },
    { id: '4', label: 'RCVD', minWidth: 50,span: false },
    { id: '5', label: 'TRFD', minWidth: 50,span: false },
    { id: '6', label: 'TOTAL', minWidth: 50,span: false },
    { id: '7', label: 'Sales', minWidth: 50,span: false },
    { id: '8', label: 'Closing', minWidth: 50,span: false },
    { id: '9', label: 'Amount', minWidth: 50,span: false },
  ];
const source = [{}, {}]

function Updatesales({branchid}){
    const [age, setAge] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [source, setSource] = React.useState([])
    const [modal, setModal] = React.useState(false)
    const [detailstable, setDetailstable] = React.useState([])
    const [loader, setLoader] = useState(false);
    const [grandtotal, setGrandtotal] = useState(0);

    const [counterlist, setCounterlist] = React.useState([]);
    const [counter, setCounter] = useState("");

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();


    const [date1, setDate1] = React.useState(year + '-' + month + '-' + date);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const counterChange = (event) => {
        setCounter(event.target.value)
      }

    useEffect(() => {
        getData()
        getCounter()
    }, [branchid]);

    const onFilter =()=> {

    }

    const getCounter = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Counter/GetCounterlist?companyId=1&branchId="+ branchid + "&includeBlocked=false", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("counterlist response", responseJson)
              setCounterlist(responseJson)
          })
          .catch((error)=>{
              console.log(error)
          })
      }


    const getData = () => {
        setLoader(true)
        var token = localStorage.getItem("token")
        var data = {
            "companyId": 1,
            "branchId": branchid,
            "fromDate":  date1,
            "toDate": date1,
            "createdBy": global.userid,
            "billno": "",
            "counterId": counter
          }
        fetch(global.url + "api/CounterStock/GetPostSalesList", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setLoader(false)
             console.log("GetPostSalesList view Response", responseJson)
             setSource(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    useEffect(() => {
        var totalAmount = 0;
        for (var i = 0; i < source.length; i++) {
            totalAmount += parseFloat(source[i].totalsales)
        }
       setGrandtotal(totalAmount.toFixed(2))
    }, [source]);

    // const editClick = (data) => {
    //     setDetailstable(data)
    //     setModal(true)
    // }

    const updateSales = () => {
        if(grandtotal == 0) {
            return alert("Amount Zero")
        }
        setLoader(true)
        var token = localStorage.getItem("token")
        var data = {
            "companyId": 1,
            "branchId": branchid,
            "fromDate":  date1,
            "toDate": date1,
            "createdBy": global.userid,
            "billno": "",
            "counterId": counter
          }
        fetch(global.url + "api/CounterStock/PostSales", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setLoader(false)
             console.log("UpdateSales view Response", responseJson)
             if(responseJson.error == false){
                 alert(responseJson.message)
             }
             else{
                alert(responseJson.message)
             }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>Post Day Sales</p> 
                <Row>
                    <Col xs={12}  className='columnborder'>
                        <div>
                            <Row>
                            <Col sm={6} lg={3}>
                                    <FormControl sx={{ mt: 3, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="Bill Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setDate1(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col sm={6} lg={2}>
                                        <FormControl fullWidth sx={{width: '100%',  mt: 3 }}>
                                                <InputLabel id="demo-simple-select-label">Counter</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={counter}
                                                label="Age"
                                                onChange={(event) => counterChange(event)}
                                                size="small"
                                                >
                                                    <MenuItem value={1}>BAR</MenuItem>
                                                    <MenuItem value={2}>LODGE</MenuItem>
                                                    <MenuItem value={3}>RESTAURANT</MenuItem>
                                                </Select>
                                        </FormControl>
                            </Col>
                               
                                <Col xs={12} md={2}>
                                    <div style={{justifyContent: 'center', display: 'flex'}}>
                                        {
                                            loader ?
                                            <Rings
                                                height="50"
                                                width="50"
                                                color='#0d6efd'
                                                ariaLabel='loading'
                                            />: 
                                            <Button onClick={getData} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">Check</Button>
                                        }
                                    </div>
                                </Col>
                                {/* <Col xs={12} md={2}>
                                    <div style={{ justifyContent: 'center', display: 'flex'}}>
                                        <Button style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">print</Button>
                                    </div>
                                </Col> */}
                            </Row>
                           
                        </div>
                    </Col> 
                </Row>
                
                <div className='bulk-table'>
                <Table responsive bordered>
                    <thead>
                        <tr>
                            <th>Particular</th>
                            <th>Amount</th>
                                         
                    </tr>
                    </thead>
                    <tbody>
                        {
                                source.map((i, k) => {
                                    return(
                                        <tr>
                                        
                                            <td>{i.countername}</td>
                                            <td style={{textAlign: 'right'}}>{i.totalsales.toFixed(2)}</td>
                                           
                                        </tr>
                                    )
                                })
                            }
                             <tr>  
                                <td style={{fontWeight: 'bold'}}>Total amount</td>
                                <td style={{fontWeight: 'bold', textAlign: 'right'}}>{grandtotal}</td>
                            </tr>
                        
                    </tbody>
                    </Table>
                </div>
                <Row>
                <Col xs={12} md={4}>
                    <div style={{ justifyContent: 'center', display: 'flex'}}>
                        {
                            loader ?
                            <Rings
                                height="50"
                                width="50"
                                color='#0d6efd'
                                ariaLabel='loading'
                            />: 
                            <Button onClick={() => updateSales()} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">Post to Accounts</Button>
                        }
                    </div>
                </Col>
                </Row>
            </Paper>
            
            <Modal
                    size="lg"
                    show={modal}
                    onHide={() => setModal(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                    >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                        Details
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                        <Table responsive bordered>
                        <thead>
                        <tr>
                            <th>Code</th>
                            <th>Product Name</th>
                            <th>Bottles</th>
                            <th>Pegs</th>
                            <th>PUR Total</th>
                            <th>Sales Tax</th>
                            <th>CESS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                                detailstable.map((i, k) => {
                                    return(
                                        <tr>
                                        
                                            <td>{i.procode}</td>
                                            <td>{i.proname}</td>
                                            <td>{i.cases}</td>
                                            <td>{i.bottle}</td>
                                            <td>{i.dtqty}</td>
                                            <td>{i.dtprice}</td>
                                            <td>{i.dttotal}</td>
                                        </tr>
                                    )
                                })
                            }
                        
                    </tbody>
                            </Table>
                        </Row>
                    </Modal.Body>
           </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(Updatesales);