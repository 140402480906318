import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useLocation } from 'react-router-dom';
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";

const SidebarLink = styled(Link)`
display: flex;
color: #000;
justify-content: space-between;
align-items: center;
padding: 20px;
list-style: none;
height: 60px;
text-decoration: none;
font-size: 15px;

&:hover {
	background: #f7f7f7;
	cursor: pointer;
	color: #333333;
}
`;

const SidebarLabel = styled.span`
margin-left: 16px;
&:hover {
	color: #333333;
}
`;

const DropdownLink = styled(Link)`
height: 40px;
padding-left: 3rem;
display: flex;
align-items: center;
text-decoration: none;
color: #333333;
font-size: 14px;

&:hover {
	background: #f7f7f7;
	cursor: pointer;
	color: '#333333'
}
`;

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
	  width,
	  height
	};
  }

const SubMenu = (props) => {
	const [subnav, setSubnav] = useState(false);
	const [currentmenu, setCurrentmenu] = useState("");
	const location = useLocation();
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	const showSubnav = () => {
		setSubnav(!subnav)
		
	};

	const linkClick = () => {
		if(windowDimensions.width < 600) {
			props.parent(false)
		}
	}

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		var blockmenu = localStorage.getItem("blockmenu")
		setCurrentmenu(blockmenu)
	},[currentmenu])

	return (
		<>
		{
			currentmenu == props.item.id ? 
			<SidebarLink className={props.item.path == location.pathname ? "activeItem hoveritem" : "hoveritem"} to={props.item.path == "" ? location.pathname : props.item.path}
				onClick={props.item.subNav && showSubnav}>
				<div>
				{props.item.icon}
				<SidebarLabel>{props.item.title}</SidebarLabel>
				</div>
				<div>
				{props.item.subNav && subnav
					? props.item.iconOpened
					: props.item.subNav
					? props.item.iconClosed
					: null}
				</div>
			</SidebarLink>: 
			currentmenu == "All" ? 
			<SidebarLink className={props.item.path == location.pathname ? "activeItem hoveritem" : "hoveritem"} to={props.item.path == "" ? location.pathname : props.item.path}
				onClick={props.item.subNav && showSubnav}>
				<div>
				{props.item.icon}
				<SidebarLabel>{props.item.title}</SidebarLabel>
				</div>
				<div>
				{props.item.subNav && subnav
					? props.item.iconOpened
					: props.item.subNav
					? props.item.iconClosed
					: null}
				</div>
			</SidebarLink>:
			null
		}
		
		{subnav &&
			props.item.subNav.map((item, index) => {
			return (
				<DropdownLink onClick={() => linkClick(false)} className={item.path == location.pathname ? "subactiveItem hoveritem" : "hoveritem"} to={item.path} key={index}>
					{item.icon}
					<SidebarLabel>{item.title}</SidebarLabel>
				</DropdownLink>
			);
			})}
		</>
	);
};

export default SubMenu;
