import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Form, Button} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { connect } from "react-redux";

function RoomStatus({branchid}){

    const [room, setRoom] = React.useState('');
    const [status, setStatus] = React.useState('');
    const [roomlist, setRoomlist] = React.useState([]);

    const [roomname, setRoomname] = React.useState('');
    const [floorid, setFloorid] = React.useState('');
    const [branchid1, setBranchid1] = React.useState('');
    const [roomtypeid, setRoomtypeid] = React.useState('');
    const [roomno, setRoomno] = React.useState('');
    const [tarrifid, setTarrifid] = React.useState('');
    const [currentvid, setCurrentvid] = React.useState('');

    const roomChange = (event) => {
        setRoom(event.target.value);
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Room/GetRoomById?id=" + event.target.value, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("single room response", responseJson)
            setRoomname(responseJson.roomname)
            setFloorid(responseJson.floorid)
            setRoomtypeid(responseJson.roomtypeid)
            setRoomno(responseJson.roomno)
            setTarrifid(responseJson.tarrifid)
            setCurrentvid(responseJson.currentvid)
            
        })
        .catch((error)=>{
            console.log(error)
        })
    };
    const statusChange = (event) => {
        setStatus(event.target.value);
    };

    useEffect(() => {
        getData()
    }, [branchid]);

    const getData = () => {
        console.log("branchid in room", branchid)
        var token = localStorage.getItem("token")
        console.log("roomid")
        fetch(global.url + "api/Room/GetRoomNotOccupiedList?branchId=" + branchid, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response", responseJson)
            setRoomlist(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const onUpdate = () => {
        var token = localStorage.getItem("token")
        // var data = {
        //     "roomid": room,
        //     "roomname": roomname,
        //     "floorid": floorid,
        //     "branchid": branchid,
        //     "roomstatus": status,
        //     "roomtypeid": roomtypeid,
        //     "roomno": roomno,
        //     "tariffid": tarrifid,
        //     "currentvid": currentvid
        //   }

          fetch(global.url + "api/Room/UpdateRoomStatus?roomStatus=" + status + "&roomId=" + room, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("save room Response", responseJson)
            if(responseJson.error == false){
                alert(responseJson.message)
                window.location.reload()
            }
            
        })
        .catch((error)=>{
            alert("Something went wrong")
            console.log(error)
        })
    }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold'}}>Edit Room Status</p> 
                <div style={{marginTop: 20}}>
                    <Row>
                        <Col xs={6} md={3}>
                            <p style={{marginTop: 10}}>Select Room No.</p>
                        </Col>
                        <Col xs={6} md={3}>
                            <FormControl fullWidth sx={{width: '100%' }}>
                                {/* <InputLabel id="demo-simple-select-label">Source</InputLabel> */}
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={room}
                                label=""
                                onChange={roomChange}
                                size="small"
                                >
                                    {
                                        roomlist.map((i) => {
                                            return(
                                                <MenuItem value={i.roomid}>{i.roomname}</MenuItem>
                                            )
                                            
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Col>
                    </Row>
                    <Row style={{marginTop: 20}}>
                        <Col xs={6} md={3}>
                            <p style={{marginTop: 10}}>Select</p>
                        </Col>
                        <Col xs={6} md={3}>
                            <FormControl fullWidth sx={{width: '100%' }}>
                                {/* <InputLabel id="demo-simple-select-label"></InputLabel> */}
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={status}
                                label=""
                                onChange={statusChange}
                                size="small"
                                >
                                <MenuItem value={0}>Maintenance</MenuItem>
                                <MenuItem value={1}>Available</MenuItem>
                                <MenuItem value={2}>Booked</MenuItem>
                                <MenuItem value={3}>Cleaning</MenuItem>
                                <MenuItem value={4}>Reserved</MenuItem>
                                
                                </Select>
                            </FormControl>
                        </Col>
                    </Row>
                    <div style={{width: '50%', justifyContent: 'center', display: 'flex'}}>
                        <Button onClick={() => onUpdate()} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20}} variant="contained">Update</Button>
                    </div>
                </div>
            </Paper>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
    };
};

export default connect(mapStateToProps)(RoomStatus);
