import React, {useState, useEffect, useRef} from 'react'
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { connect } from "react-redux";
import { Rings  } from  'react-loader-spinner';
import ReactToPrint from 'react-to-print';
import logo from '../../../assets/images/logo.jpeg';


function Consolidate({branchid, branchdetails}){
    const componentRef = useRef();
    const [loader, setLoader] = useState(false);
    const [source, setSource] = React.useState([]);
    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();

    const [date1, setDate1] = React.useState(year + '-' + month + '-' + date);
    const [date2, setDate2] = React.useState(year + '-' + month + '-' + date);
    const [length, setLength] = useState()

    useEffect(() => {
        getData()
    }, []);


    const getData = () => {
        setLoader(true)
        var token = localStorage.getItem("token")
        var data = {
                "companyId": 1,
                "branchId": branchid,
                "fromDate": date1,
                "toDate": date2,
                "createdBy": "",
                "billno": "",
                "counterId": "",
                "userId": "",
                "soldItemsOnly": "",
                "accId": ""
        }
        console.log("paymentdata", data)
        fetch(global.url + "api/SalesReport/GetExciseConsolStatementList", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("consolidate", responseJson)
             setSource(responseJson)
             setLength(responseJson.length-1)
             setLoader(false)
        })
        .catch((error)=>{
            setLoader(false)
            console.log(error)
        })
    }

    const filterData = () => {
        getData()
    }

    const formatDate = (datestring) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
        //   console.log("timeString12hr", timeString12hr)

          var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          return formattedDate; //03/02/2021
    }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>Excise Consolidate Report</p> 
                <Row>
                    <Col xs={12}  className='columnborder'>
                        <div>
                            <Row>
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="From Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setDate1(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="To Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setDate2(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={2}>
                                    <div style={{justifyContent: 'center', display: 'flex'}}>
                                    {
                                        loader ? 
                                        <Rings
                                            height="50"
                                            width="50"
                                            color='#0d6efd'
                                            ariaLabel='loading'
                                        /> : 
                                        <Button onClick={() => filterData()} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">Search</Button>
                                    }
                                        </div>
                                </Col>
                                <Col xs={12} md={2} >
                                    <ReactToPrint
                                        trigger={() => <Button style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">Print</Button>}
                                        content={() => componentRef.current}
                                    />
                                </Col>
                            </Row>
                           
                        </div>
                    </Col> 
                </Row>
                <div style={{height: 2, width: '100%', backgroundColor: '#d3d3d3', marginTop: 30}}></div>
                <div className='bulk-table'>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <div  className='section-to-print' style={{width: '95%'}} ref={componentRef}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead className="tableheaderexe">
                            <TableRow style={{height: 80}} >
                                    <TableCell colSpan={6}  style={{padding: 0}}>
                                        <Row>
                                            <img src={logo} style={{width: 100, position: 'absolute', top: 0}}/>
                                            
                                            <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                                            <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                                            </Col>
                                            <p className='printdate' style={{ position: 'absolute', left: '85%'}}>{formatDate(new Date)}</p>  
                                        </Row>
                                    </TableCell>
                                 </TableRow>
                                 <TableRow>
                                    
                                    <TableCell colSpan={6} style={{padding: 0}}>
                                        <div style={{display: 'flex', justifyContent: 'center', fontWeight: 600}} className='captiontable'>
                                        EXCISE CONSOLIDATE REPORT  FROM {date1.split("-").reverse().join("-")} TO {date2.split("-").reverse().join("-")}
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: "10%"}}>
                                    TYPE
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: "10%"}}>
                                    OP. LTRS
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold',  width: "10%"}}>
                                    PUR. LTRS
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold',  width: "10%"}}>
                                    TOTAL LTRS
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold',  width: "10%"}}>
                                    SALE LTRS
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold',width: "10%"}}>
                                    CLOSE LTRS
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                            source.map((row, k) => {
                                return (
                                    <TableRow className='tablerowPrint'  tabIndex={-1} key={k}>
                                        {length == k ?   
                                        <TableCell className='tablecellPrint' style={{ fontWeight: 'bold'}}  >
                                         {row.catname}
                                        </TableCell>:
                                        <TableCell className='tablecellPrint'  >
                                        {row.catname}
                                       </TableCell>}
                                       {length == k ?  
                                        <TableCell className='tablecellPrint' style={{textAlign: 'right', fontWeight: 'bold'}} >
                                        {row.opltr.toFixed(2)}
                                        </TableCell>:
                                        <TableCell className='tablecellPrint' style={{textAlign: 'right'}} >
                                        {row.opltr.toFixed(2)}
                                        </TableCell>}
                                        {length == k ?  
                                        <TableCell className='tablecellPrint' style={{textAlign: 'right', fontWeight: 'bold'}} >
                                        {row.pultr.toFixed(2)}
                                        </TableCell>:
                                        <TableCell className='tablecellPrint' style={{textAlign: 'right'}} >
                                        {row.pultr.toFixed(2)}
                                        </TableCell>}
                                        {length == k ?  
                                        <TableCell className='tablecellPrint' style={{textAlign: 'right',  fontWeight: 'bold'}}>
                                        {row.totltr.toFixed(2)}
                                        </TableCell>:
                                        <TableCell className='tablecellPrint' style={{textAlign: 'right'}}>
                                        {row.totltr.toFixed(2)}
                                        </TableCell>}
                                        {length == k ?  
                                        <TableCell className='tablecellPrint' style={{textAlign: 'right', fontWeight: 'bold'}} >
                                        {row.salltr.toFixed(2)}
                                        </TableCell>:
                                        <TableCell className='tablecellPrint' style={{textAlign: 'right'}} >
                                        {row.salltr.toFixed(2)}
                                        </TableCell>}
                                        {length == k ?  
                                        <TableCell className='tablecellPrint' style={{textAlign: 'right', fontWeight: 'bold'}} >
                                        {row.cloltr.toFixed(2)}
                                        </TableCell>:
                                        <TableCell className='tablecellPrint' style={{textAlign: 'right'}} >
                                        {row.cloltr.toFixed(2)}
                                        </TableCell>}
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
                        </div>
                        </TableContainer>
                        
                    </div>
            </Paper>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(Consolidate);