import React from 'react'
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Form, Button} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

function CreditSale(){

    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold'}}>Credit Sale</p> 
                <Row> 
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" label="Bill No." variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                            <TextField id="outlined-basic" label="Room No." variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={3} style={{marginTop: 30}}>
                        <Button style={{backgroundColor: '#03a5fc', color: '#fff'}} variant="contained">Save</Button>
                    </Col>
                </Row>
            </Paper>
        </div>
    );
}

export default CreditSale;