import React, {useState, useEffect, useRef} from 'react';
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ReactToPrint from 'react-to-print';
import { connect } from "react-redux";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import logo from '../../assets/images/logo.jpeg';


function RentAndTax({branchid, branchdetails}){
    const componentRef = useRef();
    const [source, setSource] = React.useState([])
    const [sourceid, setSourceid] = React.useState('')
    const [sourcelist, setSourcelist] = React.useState([])
    const [corperateBill, setCorperatebill] = React.useState(0)
    const [type, setType] = React.useState(0)

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();

    const [date1, setDate1] = React.useState(year + '-' + month + '-' + date);
    const [date2, setDate2] = React.useState(year + '-' + month + '-' + date);

    const handleChange = (event) => {
        setSourceid(event.target.value);
    };

    const typeChange = (event) => {
        setType(event.target.value)
    }

    useEffect(() => {
        getData()
        getSourceList()
    }, [branchid]);

    const getSourceList = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Source/GetSourceList", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            setSourcelist(responseJson)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

    const getData = () => {
        var token = localStorage.getItem("token")
        const data = {
            "sourceId": sourceid,
            "branchId": branchid,
            "isbanquet": type,
            "fromDate": date1,
            "toDate": date2,
            "CorporateBillsOnly": corperateBill
          }
        fetch(global.url + "api/Booking/GetRentAndTaxList", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),  
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("GetRentAndTaxList Response", responseJson)
             for (var i = 0; i < responseJson.length; i++) {
               
                var collectedtariff = (responseJson[i].collectedtariff - Math.floor(responseJson[i].collectedtariff)) !== 0; 
                var sgst = (responseJson[i].sgst - Math.floor(responseJson[i].sgst)) !== 0; 
                var cgst = (responseJson[i].cgst - Math.floor(responseJson[i].cgst)) !== 0; 
                var discount = (responseJson[i].discount - Math.floor(responseJson[i].discount)) !== 0; 
                var total = (responseJson[i].total - Math.floor(responseJson[i].total)) !== 0; 
                var closeltr = (responseJson[i].closeltr - Math.floor(responseJson[i].closeltr)) !== 0; 

                if (collectedtariff){
                responseJson[i].collectedtariff = responseJson[i].collectedtariff.toFixed(2);
                }
                else {
                    responseJson[i].collectedtariff = (Math.round(responseJson[i].collectedtariff * 100) / 100).toFixed(2);
                } 

                if (sgst){
                responseJson[i].sgst = responseJson[i].sgst.toFixed(2);
                }
                else {
                    responseJson[i].sgst = (Math.round(responseJson[i].sgst * 100) / 100).toFixed(2);
                }

                if (cgst){
                responseJson[i].cgst = responseJson[i].cgst.toFixed(2);
                }
                else {
                    responseJson[i].cgst = (Math.round(responseJson[i].cgst * 100) / 100).toFixed(2);
                }

                if (discount){
                responseJson[i].discount = responseJson[i].discount.toFixed(2);
                }
                else {
                    responseJson[i].discount = (Math.round(responseJson[i].discount * 100) / 100).toFixed(2);
                }

                if (total){
                responseJson[i].total = responseJson[i].total.toFixed(2);
                }
                else {
                    responseJson[i].total = (Math.round(responseJson[i].total * 100) / 100).toFixed(2);
                }
            }
             setSource(responseJson)
             
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const checkChange = (e) => {
        let isChecked = e.target.checked;
        if(isChecked == true) {
            setCorperatebill(1)
        }
        else {
            setCorperatebill(0)
        }
    }

    const formatDate = (datestring) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
        //   console.log("timeString12hr", timeString12hr)

          var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          return formattedDate; //03/02/2021
    }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>Rent And Tax Statement</p> 
                <Row>
                    <Col xs={12}  className='columnborder'>
                        <div>
                            <Row>
                                <Col xs={12} md={3}>
                                    <FormControl fullWidth sx={{ mt: 3, width: '100%' }}>
                                        <InputLabel id="demo-simple-select-label">Source</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={sourceid}
                                        label="Age"
                                        onChange={handleChange}
                                        size="small"
                                        >
                                            <MenuItem value='0'>All</MenuItem>
                                            {
                                                sourcelist.map((i, k) => {
                                                    return(
                                                        <MenuItem value={i.sourceid}>{i.sourcename}</MenuItem>
                                                    )
                                                
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={3}>
                                    <FormControl fullWidth sx={{ mt: 3, width: '100%' }}>
                                        <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={type}
                                        label="Age"
                                        onChange={typeChange}
                                        size="small"
                                        >
                                            <MenuItem value='0'>Room</MenuItem>
                                            <MenuItem value='1'>Banquet</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ mt: 3, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="From Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setDate1(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ mt: 3, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="To Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setDate2(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                
                            </Row> 
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={2} >
                                    <FormControl sx={{ mt:2,  width: '100%' }}>
                                        <FormControlLabel control={
                                        <Checkbox  onChange={e => checkChange(e)} />
                                        } label="Corporate Bills" />
                                    </FormControl>
                                </Col>
                    <Col xs={12} md={3}>
                        <div style={{justifyContent: 'center', display: 'flex'}}>
                            <Button onClick={getData} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">Search</Button>
                        </div>
                    </Col>
                    <Col xs={12} md={4}>
                        <ReactToPrint
                            trigger={() => <Button style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">Print this out!</Button>}
                            content={() => componentRef.current}
                        />
                    </Col>

                </Row>
                <div style={{height: 2, width: '100%', backgroundColor: '#d3d3d3', marginTop: 30}}></div>
                <div>
                    <TableContainer sx={{ maxHeight: 440, marginTop: 2 }}>
                        <div  className='section-to-print' ref={componentRef} style={{width: '95%'}}>
                        {/* <Row> 
                                
                                <img src={require("../../assets/images/logo.jpeg").default} style={{width: 100, position: 'absolute'}}/>
                                
                                <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                                <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                                </Col>
                            </Row> */}
                        <Table id="table-to-xls" stickyHeader aria-label="sticky table">
                        {/* <caption className='captiontable'>Rent and Tax Statement From:  {date1.split("-").reverse().join("-")} To {date2.split("-").reverse().join("-")}</caption> */}
                        <TableHead className="tableheaderexe">
                        <TableRow style={{height: 80}} >
                                    <TableCell colSpan={16}  style={{padding: 0}}>
                                        <Row>
                                            <img src={logo} style={{width: 100, position: 'absolute', top: 0}}/>
                                            
                                            <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                                            <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                                            </Col>
                                            <p className='printdate' style={{ position: 'absolute', left: '85%'}}>{formatDate(new Date)}</p> 
                                        </Row>
                                    </TableCell>
                                 </TableRow>
                                 <TableRow>
                                    
                                    <TableCell colSpan={16} style={{padding: 0}}>
                                        <div style={{display: 'flex', justifyContent: 'center', fontWeight: 600}} className='captiontable'>
                                            RENT AND TAX STATEMENT FROM {date1.split("-").reverse().join("-")} TO {date2.split("-").reverse().join("-")}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            <TableRow>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Source</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Guest No.</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>GRC No.</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Bill No.</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>{type == 1 ? 'Banquet Hall' : 'Room'} No.</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>{type == 1 ? 'Banquet Hall Rent' : 'Tariff'}</TableCell>
                                {type == 1 ? null : <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Pax</TableCell>}
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Check In</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Check Out</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Period</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>{type == 1 ? 'Banquet Hall Rent Coll' : 'Tariff Coll'}</TableCell>
                                {type == 1 ? null :<TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Extra Person</TableCell>}
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>CGST</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>SGST</TableCell>
                                {/* <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Discount</TableCell> */}
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                                {source.map((i, index) => {
                                    return(                                            
                                        i.source != null ?
                                        <TableRow>
                                            <TableCell className='tablecellPrint'>{i.source}</TableCell>
                                            <TableCell className='tablecellPrint'>{i.guestid}</TableCell>
                                            <TableCell className='tablecellPrint'>{i.grcno}</TableCell>
                                            <TableCell className='tablecellPrint'>{i.billno}</TableCell>
                                            <TableCell className='tablecellPrint'>{i.roomno}</TableCell>
                                            <TableCell className='tablecellPrint' style={{textAlign: 'right'}}>{i.tariff == 0 ? null : i.tariff.toFixed(2)}</TableCell>
                                            {type == 1 ? null : <TableCell className='tablecellPrint'>{i.tax == 0 ? null : i.tax}</TableCell>}
                                            <TableCell className='tablecellPrint'>{i.chechin}</TableCell>
                                            <TableCell className='tablecellPrint'>{i.checkout}</TableCell>
                                            <TableCell className='tablecellPrint'>{i.period}</TableCell>
                                            <TableCell className='tablecellPrint' style={{textAlign: 'right'}}>{i.collectedtariff}</TableCell>
                                            {type == 1 ? null : <TableCell className='tablecellPrint'>{i.extraperson}</TableCell>}
                                            <TableCell className='tablecellPrint' style={{textAlign: 'right'}}>{i.cgst}</TableCell>
                                            <TableCell className='tablecellPrint' style={{textAlign: 'right'}}>{i.sgst}</TableCell>
                                            {/* <TableCell className='tablecellPrint' style={{textAlign: 'right'}}>{i.discount}</TableCell> */}
                                            <TableCell className='tablecellPrint' style={{textAlign: 'right'}}>{i.total}</TableCell>
                                        </TableRow> :
                                        <TableRow>
                                            <TableCell className='tablecellPrint'>{i.source}</TableCell>
                                            <TableCell className='tablecellPrint'>{i.guestid}</TableCell>
                                            <TableCell className='tablecellPrint'>{i.grcno}</TableCell>
                                            <TableCell className='tablecellPrint'>{i.billno}</TableCell>
                                            <TableCell className='tablecellPrint'>{i.roomno}</TableCell>
                                            <TableCell className='tablecellPrint' style={{textAlign: 'right'}}>{i.tariff == 0 ? null : i.tariff.toFixed(2)}</TableCell>
                                            {type == 1 ? null : <TableCell className='tablecellPrint'>{i.tax == 0 ? null : i.tax}</TableCell>}
                                            <TableCell className='tablecellPrint'>{i.chechin}</TableCell>
                                            <TableCell className='tablecellPrint'>{i.checkout}</TableCell>
                                            <TableCell className='tablecellPrint'>{i.period}</TableCell>
                                            <TableCell className='tablecellPrint' style={{textAlign: 'right', fontWeight: 'bold'}}>{i.collectedtariff}</TableCell>
                                            {type == 1 ? null : <TableCell className='tablecellPrint'>{i.extraperson}</TableCell>}
                                            <TableCell className='tablecellPrint' style={{textAlign: 'right', fontWeight: 'bold'}}>{i.cgst}</TableCell>
                                            <TableCell className='tablecellPrint' style={{textAlign: 'right', fontWeight: 'bold'}}>{i.sgst}</TableCell>
                                            {/* <TableCell className='tablecellPrint' style={{textAlign: 'right', fontWeight: 'bold'}}>{i.discount}</TableCell> */}
                                            <TableCell className='tablecellPrint' style={{textAlign: 'right', fontWeight: 'bold'}}>{i.total}</TableCell>
                                        </TableRow>
                                    )
                                    })}
                            </TableBody>
                        </Table>
                    </div>
                    </TableContainer>
                </div>
            </Paper>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(RentAndTax);