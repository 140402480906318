import * as actionTypes from "./shopping-types";

const INITIAL_STATE = {
  products: [],
  cart: [],
  currentItem: null,
  branchid: "",
  branchdetails: {},
  isAdmin: false,
  isPosuser: false,
  productlist: []
};

const shopReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.ADD_TO_CART:
      // Great Item data from products array
      // const item = state.products.find(
      //   (product) =>  action.payload
      // );
      const item = action.payload
      
      // // Check if Item is in cart already

      return {
        ...state,
        branchid: item.id,
      };

      case actionTypes.SAVE_BRANCH:
      
     
      const branchdt = action.payload
      return {
        ...state,
        branchdetails: branchdt.id,
      };

      case actionTypes.ADMIN_CHECK:
    
      const admin = action.payload
      return {
        ...state,
        isAdmin: admin.id,
      };

      case actionTypes.POS_CHECK:
    
      const pos = action.payload
      return {
        ...state,
        isPosuser: pos.id,
      };

      case "PRODUCTLIST_SUCCESS": {
        return { ...state, productlist: action.payload}
      }

    default:
      return state;
  }
};

export default shopReducer;
