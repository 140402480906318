import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { connect } from "react-redux";

const columns = [
    { id: '1', label: 'Date', minWidth: 50,span: false },
    { id: '2', label: 'Voc No.', minWidth: 50,span: false },
    { id: '3', label: 'Amount', minWidth: 50,span: false },
    { id: '4', label: 'Rec/Pay', minWidth: 50,span: false },
    { id: '5', label: 'Entered By', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Edit',
      minWidth: 100,
      span: true
    },
  ];
const source = [{}, {}]

function DayClose({branchid}){

    const [age, setAge] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [source, setSource] = React.useState([])
    const [voucherno, setVoucherno] = React.useState("")
    const [type, setType] = React.useState("")
    const [amount, setAmount] = React.useState([])
    const [lodgedayid, setLodgedayid] = React.useState(0)

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();

    const [voucherdate, setVoucherdate] = React.useState(year + '-' + month + '-' + date)
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };
    const handleChange = (event) => {
        setType(event.target.value);
    };

    useEffect(() => {
        getData()
        console.log("branch", branchid)
    }, [branchid]);

    const getData = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Booking/GetNewLodgeDayClose?branchId=" + branchid, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("GetNewLodgeDayClose Response", responseJson)
             setSource(responseJson.dayCloseList)
             setVoucherno(responseJson.voucherno)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const onSave = () => {
        var token = localStorage.getItem("token")
        var data = {
            "lodgedayid": lodgedayid,
            "voucherno": voucherno,
            "vouchernum": "",
            "voucherdate": voucherdate,
            "vouchertype": type,
            "amount": amount,
            "notes": "",
            "branchid": branchid,
            "createdby": global.userid,
            "createdon": "",
            "dayCloseList": [],
            "username": ""
          }
          console.log("id", data)
        fetch(global.url + "api/Booking/SaveLodgeDayClose", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("SaveLodgeDayClose Response", responseJson)
             alert("success")
             window.location.reload()
            
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const editClick = (no,date1 , amount,type, id) => {
       
        var date = new Date(date1)
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
        var month = pad2(date.getMonth()+1);//months (0-11)
        var day = pad2(date.getDate());//day (1-31)
        var year= date.getFullYear();
        console.log("date", year + "-" + month+ "-"+ day)
        setVoucherdate(year + "-" + month+ "-"+ day)
        setVoucherno(no)
        setAmount(amount)
        setType(type)
        setLodgedayid(id)
    }

    const formatDate = (datestring, type) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
          

          if(type == "time") {
            var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          }
          else{
            var formattedDate =  day+"/"+month+"/"+year
          }

          
          return formattedDate; //03/02/2021
    }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>Front Office Day Close Entry</p> 
                <div>
                    <Row>
                        <Col xs={12} md={4}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField value={voucherno} onChange={(e) => setVoucherno(e.target.value)} id="outlined-basic" label="Voucher No." variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={4}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField
                                    
                                    label="Date"
                                    type="date"
                                    value={voucherdate}
                                    sx={{ width: 250 }}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                    onChange={(newValue) => {
                                        setVoucherdate(newValue.target.value);
                                    }}
                                />
                            </FormControl>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField value={amount} onChange={(e) => setAmount(e.target.value)} id="outlined-basic" label="Amount" type="number" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={4}>
                            <FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
                                <InputLabel id="demo-simple-select-label">Rec/Pay</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={type}
                                label="Age"
                                onChange={handleChange}
                                size="small"
                                >
                                <MenuItem value={"Rec"}>Rec</MenuItem>
                                <MenuItem value={"Pay"}>Pay</MenuItem>
                               
                                </Select>
                            </FormControl>
                        </Col>
                    </Row>
                    <div style={{width: '50%', justifyContent: 'center', display: 'flex'}}>
                        <Button onClick={() => onSave()} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20}} variant="contained">Save</Button>
                    </div>
                </div>
                <div style={{height: 2, width: '100%', backgroundColor: '#d3d3d3', marginTop: 30}}></div>
                <p style={{fontSize: 17, marginTop: 30}}>Last Entry</p>
                <div className='bulk-table'>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                                    colSpan={column.span == true ? 2: 1}
                                >
                                    {column.label}
                                    
                                </TableCell>
                                ))}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {source
                                .map((row) => {
                                return (
                                    <TableRow  tabIndex={-1}>
                                        <TableCell>
                                            {formatDate(row.voucherdate,"date")}
                                        </TableCell>
                                        <TableCell>
                                            {row.voucherno}
                                        </TableCell>
                                        <TableCell >
                                            {row.amount}
                                        </TableCell>
                                        <TableCell >
                                        {row.vouchertype}
                                        </TableCell>
                                        <TableCell >
                                        {row.username}
                                        </TableCell>
                                        <TableCell >
                                            <a href="javascript:void(0)" onClick={()=> editClick(row.voucherno, row.voucherdate, row.amount, row.vouchertype, row.lodgedayid)}>
                                                <FaIcons.FaEdit fontSize={20} color="blue" />
                                            </a>
                                        </TableCell>
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
                        </TableContainer>
                    </div>
            </Paper>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(DayClose);