import React, { useState, useEffect } from "react";
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button, Modal} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { connect } from "react-redux";
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { setDate } from "date-fns";
import { Rings  } from  'react-loader-spinner'


const columns = [
    { id: '1', label: 'Counter', minWidth: 50,span: false },
    { id: '2', label: 'Code', minWidth: 50,span: false },
    { id: '3', label: 'Name in Account', minWidth: 50,span: false },
    { id: '4', label: 'Group', minWidth: 50,span: false },
    // { id: '5', label: 'Address', minWidth: 50,span: false },
    // { id: '5', label: 'GSTIN', minWidth: 50,span: false },
    // { id: '6', label: 'Mobile', minWidth: 50,span: false },
    // { id: '7', label: 'Email', minWidth: 50,span: false },
    // { id: '7', label: 'Whatsapp', minWidth: 50,span: false },
    // { id: '7', label: 'Website', minWidth: 50,span: false },
    {
        id: 'Action',
        label: 'Action',
        minWidth: 100,
        span: true
      },
  ];


function Ledger({branchid}){
    const [counter, setCounter] = useState("");
    const [age, setAge] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [modal, setModal] = React.useState(false);
    const [source, setSource] = React.useState([]);
    const [decr, setDecr] = React.useState(0);
    const [ledgCode, setLedgcode] = React.useState(''); 
    const [ledgName, setLedgname] = React.useState(''); 
    const [ledgGroup, setLedggroup] = React.useState(''); 
    const [ledgAddrs, setLedgaddrs] = React.useState(''); 
    const [ledgMobile, setLedgmobile] = React.useState(''); 
    const [ledgEmail, setLedgemail] = React.useState(''); 
    const [ledgWhats, setLedgwhats] = React.useState(''); 
    const [ledgWeb, setLedgweb] = React.useState(''); 
    const [opening, setOpening] = React.useState(''); 
    const [ledgGst, setLedggst] = React.useState(''); 
    const [ledgId, setLedgid] = React.useState(0);
    const [grouplist, setGrouplist] = React.useState([]);
    const [searchname, setSearchname] = React.useState([])
    const [loader, setLoader] = useState(false);

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();


    const [date1, setDate1] = React.useState(year + '-' + month + '-' + date + "T" + hours + ':' + minutes + ':' + "00");
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };

    useEffect(() => {
        getData()
        console.log("date", date1)
    }, [branchid, date1]);

    const filter = (e) => {
        const keyword = e.target.value;

        if (keyword !== '') {
        const results = source.filter((user) => {
            return user.accname.toLowerCase().startsWith(keyword.toLowerCase());
            // Use the toLowerCase() method to make it case-insensitive
        });
            setSource(results);
        } else {
            getData()
        // If the text field is empty, show all users
        }

            setSearchname(keyword);
    };

    const groupChange = (event) => {
        setLedggroup(event.target.value);
      };

      const getGroup = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Groups/GetGrouplist?orderby=Name&companyId=1&branchId=" + branchid + "&includeBlocked=False", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("branch view Response", responseJson)
             setGrouplist(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }


    const getData = () => {
        setLoader(true)
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Accounts/GetLedgerList?orderBy=Name&companyId=1&branchId=" + branchid + "&includeBlocked=False", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("ledger Response", responseJson)
             setSource(responseJson)
             setLoader(false)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const radioChange = (event) => {
        // console.log(event.target.value, "event.target.value")
        if(event.target.value == "credit") {
           setDecr(1)
        }
        else {
          setDecr(0)
        }
      }

    const onAdd = () => {
        setModal(true)
        getGroup()
        setLedgid(0)
        setLedgcode("")
        setLedgname("")
        setLedggroup("")
        setLedgaddrs("")
        setLedgmobile("")
        setLedgemail("")
        setLedgwhats("")
        setLedgweb("")
        setOpening("")
        setDate1(year + '-' + month + '-' + date + "T" + hours + ':' + minutes + ':' + "00")
        setDecr("")
    }

    const editClick =(
        id,
        code,
        name,
        group,
        addresS1,
        gstin,
        mobile,
        email,
        website,
        wts,
        opening,
        licdate,
        debitcredit
    ) => {
        setModal(true)
        getGroup()
        setLedgid(id)
        setLedgcode(code)
        setLedgname(name)
        setLedggroup(group)
        setLedgaddrs(addresS1)
        setLedggst(gstin)
        setLedgmobile(mobile)
        setLedgemail(email)
        setLedgwhats(website)
        setLedgweb(wts)
        setOpening(opening)
        setDate1(licdate)
        setDecr(debitcredit)
    }

    const onSubmit = () => {
        var token = localStorage.getItem("token")
        console.log("token", token)
        const data = {
            "accid": ledgId,
            "accname": ledgName,
            "accledger": "",
            "accaddresS1": ledgAddrs,
            "accaddresS2": "",
            "accaddresS3": "",
            "acccntid": "",
            "accstid": 0,
            "accgstin": ledgGst,
            "accmobile": ledgMobile,
            "accemail": ledgEmail,
            "accwebsite": ledgWeb,
            "accwts": ledgWeb,
            "accprfx": "",
            "accrepid": "",
            "accproid": "",
            "accrouteid": "",
            "accareaid": counter,
            "accopening": opening,
            "accdrcr": decr,
            "acccreditlimit": "",
            "accisbillwise": "",
            "accblocked": "",
            "accseq": "",
            "acclact": "",
            "acclong": "",
            "accgroupid": ledgGroup,
            "accbranchid": branchid,
            "acccode": ledgCode,
            "acccomid": "",
            "accdistid": "",
            "accdiscid": "",
            "accpricelvl": 0,
            "licenseexp": date1,
            "licenseno": "",
            "empisuser": "",
            "empuserid": "",
            "accountbal": "",
            "groupName": "",
            "country": "",
            "state": "",
            "district": "",
            "balance": ""
          }

        console.log("save data", data)

        fetch(global.url + "api/Accounts/SaveLedger", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("employee Response", responseJson)
            if(responseJson.error == false) {
                alert(responseJson.message)
                setModal(false)
                getData()
            }
            else{
                alert(responseJson.message)
            }
        })
        .catch((error)=>{
            alert("Something went wrong")
            console.log(error)
        })
    }

    const counterChange = (event) => {
        setCounter(event.target.value)
      }

    const deleteClick = (id) => {
        if(window.confirm('Are you Want to delete?')){
            var token = localStorage.getItem("token")
            fetch(global.url + "api/Accounts/DeleteLedgerById?id=" + id, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
                
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
               if(responseJson.error == false) {
                   alert(responseJson.message)
                   window.location.reload()
               }
               else{
                alert(responseJson.message)
               }
              
            })
            .catch((error)=>{
                console.log(error)
            })}
    }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
                    <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Ledger</p>
                    
                    <input
                            type="search"
                            value={searchname}
                            onChange={filter}
                            className="input"
                            placeholder="Search"
                        />
                    <Button variant="primary" onClick={onAdd} >Add</Button>
                </div>
                {
                                loader ?
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Rings
                                    height="50"
                                    width="50"
                                    color='#0d6efd'
                                    ariaLabel='loading'
                                />
                                </div>: 
                <div className='bulk-table'>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                                    colSpan={column.span == true ? 2: 1}
                                >
                                    {column.label}
                                    
                                </TableCell>
                                ))}
                            </TableRow>
                            </TableHead>
                            
                            <TableBody>
                            {source
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                return (
                                    <TableRow  tabIndex={-1} key={row.code}>
                                        <TableCell>
                                            {row.accareaid == 1 ? 'Bar' : row.accareaid == 2 ? 'Lodge' : row.accareaid == 3 ? 'Restaurant' : null }
                                        </TableCell>
                                        <TableCell>
                                            {row.acccode}
                                        </TableCell>
                                        <TableCell>
                                           {row.accname}
                                        </TableCell>    
                                        <TableCell>
                                         {row.groupName}
                                        </TableCell>  
                                        {/* <TableCell>
                                        {row.accaddresS1}
                                        </TableCell>   
                                        <TableCell>
                                        {row.accgstin}
                                        </TableCell>  
                                        <TableCell>
                                        {row.accmobile}
                                        </TableCell>  
                                        <TableCell>
                                         {row.accemail}
                                        </TableCell>  
                                        <TableCell>
                                           {row.accwts}
                                        </TableCell>  
                                        <TableCell>
                                          {row.accwebsite}
                                        </TableCell>    */}
                                        <TableCell >
                                            <a href="javascript:void(0)"
                                                onClick={() => editClick(
                                                    row.accid,
                                                    row.acccode,
                                                    row.accname,
                                                    row.accgroupid,
                                                    row.accaddresS1,
                                                    row.accgstin,
                                                    row.accmobile,
                                                    row.accemail,
                                                    row.accwebsite,
                                                    row.accwts,
                                                    row.accopening,
                                                    row.licenseexp,
                                                    row.accdrcr
                                            )} >
                                                <FaIcons.FaEdit fontSize={20} color="blue" />
                                            </a>
                                            <a href="javascript:void(0)"
                                                style={{marginLeft: 20}}
                                                onClick={() => deleteClick(row.accid)} >
                                                <RiIcons.RiDeleteBin5Line fontSize={20} color="red" />
                                            </a>
                                        </TableCell>                                  
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        
                        </Table>
                        </TableContainer>
                        <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={source.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                }
            </Paper>
            <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Ledger Creation
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={ledgCode} onChange={(e) => setLedgcode(e.target.value)} id="outlined-basic" label="Code" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={ledgName} onChange={(e) => setLedgname(e.target.value)} id="outlined-basic" label="Name in account" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs={12}>
                        <FormControl sx={{ mt: 3, width: '100%' }}>
                                <InputLabel id="demo-simple-select-helper-label">Group</InputLabel>
                                <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={ledgGroup}
                                label="Age"
                                onChange={groupChange}
                                size="small"
                                
                                >
                                <MenuItem value="">
                                <em>None</em>
                                </MenuItem>
                                {grouplist.map((item, index) => (
                                    <MenuItem value={item.grpid}>{item.grpname}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 3, width: '100%' }}>
                                <InputLabel id="demo-simple-select-helper-label">Counter</InputLabel>
                                <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                label="Age"
                                size="small"
                                value={counter}
                                onChange={counterChange}
                                >
                                <MenuItem value="">
                                <em>None</em>
                                </MenuItem>
                                {/* <MenuItem value='0'>All</MenuItem> */}
                                <MenuItem value='1'>Bar</MenuItem>
                                <MenuItem value='2'>Lodge</MenuItem>
                                <MenuItem value='3'>Restaurant</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    {/* <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={ledgAddrs} onChange={(e) => setLedgaddrs(e.target.value)} id="outlined-basic" label="Address" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={ledgGst} onChange={(e) => setLedggst(e.target.value)} id="outlined-basic" label="GST" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={ledgMobile} onChange={(e) => setLedgmobile(e.target.value)} id="outlined-basic" label="Mobile" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={ledgEmail} onChange={(e) => setLedgemail(e.target.value)} id="outlined-basic" label="Email" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={ledgWhats} onChange={(e) => setLedgwhats(e.target.value)} id="outlined-basic" label="Whatsapp" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={ledgWeb} onChange={(e) => setLedgweb(e.target.value)} id="outlined-basic" label="Website" variant="outlined"  size="small" />
                        </FormControl>
                    </Col> */}
                    <Col xs={12}>
                        <FormControl sx={{ mt: 4}} component="fieldset">
                            <RadioGroup
                                defaultValue="credit"
                                name="radio-buttons-group"
                                onClick={radioChange}
                            >
                                <FormControlLabel value="credit"  control={<Radio checked={decr == 0?  false: true}/>} label="Credit" color = '#999'  />
                                <FormControlLabel value="debit" color= '#999' control={<Radio checked={decr == 0?  true: false}/>} label="Debit" />
                            </RadioGroup>
                        </FormControl>
                    </Col>
                    <Col sm={6} lg={3}>
                        <FormControl sx={{ mt: 3, width: '100%' }}>
                            <TextField
                                id="date"
                                label="Opening Date"
                                type="datetime-local"
                                defaultValue={date1}
                                sx={{ width: '100%' }}
                                InputLabelProps={{
                                shrink: true,
                                }}
                                onChange={(newValue) => {
                                    setDate1(newValue.target.value);
                                }}
                            />
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={opening} onChange={(e) => setOpening(e.target.value)} id="outlined-basic" label="Opening Balance" variant="outlined" type="number"  size="small" />
                        </FormControl>
                    </Col>
                    
                    <Col xs={5}>
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                                <FormControlLabel control={
                                <Checkbox  />
                                } label="Enabled" />
                          </FormControl>
                    </Col>
                    <Col xs ={12} style={{marginTop:30}}>
                      <Button onClick={() => onSubmit()} style={{float: 'right'}} variant="primary">Submit</Button>
                      <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="danger">Cancel</Button>
                    </Col>
                    
                  </Row>
              </Modal.Body>
           </Modal>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
    };
};

export default connect(mapStateToProps)(Ledger);
