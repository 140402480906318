import React, {useState, useEffect} from 'react';
import { Modal, Container, Row, Col  } from "react-bootstrap";
import  './Counter.css';
import Button from '@mui/material/Button';
import { RiMenuUnfoldLine } from "react-icons/ri";
import { connect } from "react-redux";
import { RotatingLines } from  'react-loader-spinner'
import Checkin from './Checkin/Checkin';
import TaxInvoice from './Invoice/TaxInvoice';

function Counter({props, branchid, isAdmin, branchdetails}) {
    const [checkinmodal, setCheckinmodal] = useState(false);
    const [modal, setModal] = useState(false);
    const [style, setStyle] = useState({display: 'none'});
    const [source, setSource] = React.useState([]);
    const [bookingdetails, setBookingdetails] = React.useState({})
    const [checkoutdetails, setCheckoutdetails] = React.useState({})

    const [loader, setLoader] = useState(false)
    
    const [roomid, setRoomid] = React.useState("")
    const [dashboard, setDashboard] = React.useState({})

    useEffect(() => {
        getData()
        getDashboard()
        console.log("branch", branchid)
    }, [checkinmodal,modal, branchid]);

    const getDashboard = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Booking/GetReceptionDashBoard?branchId=" + branchid, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setLoader(false)
             console.log("GetReceptionDashBoard Response", responseJson)
             setDashboard(responseJson)
             
        })
        .catch((error)=>{
            setLoader(false)
            console.log(error)
        })
    }


    const getData = () => {
        setLoader(true)
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Floor/GetFloorList?orderBy=Name&companyId=1&branchId=" + branchid + "&includeBlocked=False", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setLoader(false)
             console.log("room view Response", responseJson)
             if(responseJson.message){
                setSource([])
             }
             else{
                setSource(responseJson)
             }
        })
        .catch((error)=>{
            setLoader(false)
            console.log(error)
        })
    }

    const setCheckin = (roomid) => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Booking/NewBooking?roomId="+ roomid, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("NewBooking response", responseJson)
            setBookingdetails(responseJson)
            setRoomid(roomid)
            setCheckinmodal(true)
            
        })
        .catch((error)=>{
            console.log(error)
        })
        
    }

    const redClick = (roomid) => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Booking/NewBooking?roomId="+ roomid, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("NewBooking response", responseJson)
            setBookingdetails(responseJson)
            setRoomid(roomid)
            
        })
        .catch((error)=>{
            console.log(error)
        })
        console.log("roomid", roomid)
        fetch(global.url + "api/Booking/RoomCheckOut?roomId="+ roomid, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log('cuto',responseJson )
            setCheckoutdetails(responseJson)
            setRoomid(roomid)
            
        })
        .catch((error)=>{
            console.log(error)
        })

    }

    const parentCallback = () => {
        setCheckinmodal(false)
        setModal(false)
    }

    return(
        <div className="main-content">
            <Container>
                <Row style={{marginBottom: 20}}>
                    <Col xs={12} md={2}>
                       
                            <div className='counter-firstrow'>
                                <p style={{color: '#000', fontSize: 14, fontWeight: 'bold'}}>GRC No: {dashboard.grc}</p>
                                <div>
                                    <p className='counter-firstdivpara'>ROOMS - {dashboard.rooms}</p>
                                    <p className='counter-firstdivpara'>OCCUPIED - {dashboard.occupied}</p>
                                    <p className='counter-firstdivpara'>AVAILABLE - {dashboard.available}</p>
                                    <p className='counter-firstdivpara'>CLEANING - {dashboard.cleaning}</p>
                                    <p className='counter-firstdivpara'>RESERVED - {dashboard.reserved}</p>
                                    <p className='counter-firstdivpara'>MAINTENANCE - {dashboard.maintenance}</p>
                                </div>
                                <div style={{marginTop: 15}}>
                                    <div style={{display: 'flex'}}>
                                        <div style={{width: 12, height: 12, backgroundColor: 'green', marginRight: 5, marginTop: 5}}></div>
                                        <p className='counter-firstdivpara'>AVAILABLE</p>
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <div style={{width: 12, height: 12, backgroundColor: 'red', marginRight: 5, marginTop: 5}}></div>
                                        <p className='counter-firstdivpara'>OCCUPIED</p>
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <div style={{width: 12, height: 12, backgroundColor: '#87f1ff', marginRight: 5, marginTop: 5}}></div>
                                        <p className='counter-firstdivpara'>CLEANING</p>
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <div style={{width: 12, height: 12, backgroundColor: '#fccf19', marginRight: 5, marginTop: 5}}></div>
                                        <p className='counter-firstdivpara'>RESERVED</p>
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <div style={{width: 12, height: 12, backgroundColor: 'gray', marginRight: 5, marginTop: 5}}></div>
                                        <p className='counter-firstdivpara'>MAINTENANCE</p>
                                    </div>
                                </div>
                            </div>
                        
                        {/* <div className='counter-firstrow2'>
                            <div className='counter-firstrow2-div'>
                                <p style={{fontSize: 15, color: '#fff', textAlign: 'center'}}>BULK BOOKING</p>
                            </div>
                            <div className='counter-firstrow2-div'>
                                <p style={{fontSize: 15, color: '#fff', textAlign: 'center'}}>ROOM STATUS</p>
                            </div>
                            <div className='counter-firstrow2-div'>
                                <p style={{fontSize: 15, color: '#fff', textAlign: 'center'}}>RESERVATION</p>
                            </div>
                            <div className='counter-firstrow2-div'>
                                <p style={{fontSize: 15, color: '#fff', textAlign: 'center'}}>RESERVATION LIST</p>
                            </div>
                            <div className='counter-firstrow2-div'>
                                <p style={{fontSize: 15, color: '#fff', textAlign: 'center'}}>DAY CLOSE</p>
                            </div>
                            <div className='counter-firstrow2-div'>
                                <p style={{fontSize: 15, color: '#fff', textAlign: 'center'}}>GUEST LAUNDRY</p>
                            </div>
                            <div className='counter-firstrow2-div'>
                                <p style={{fontSize: 15, color: '#fff', textAlign: 'center'}}>DUPLICATE BILL</p>
                            </div>
                            <div className='counter-firstrow2-div'>
                                <p style={{fontSize: 15, color: '#fff', textAlign: 'center'}}>OCCUPANCY</p>
                            </div>
                        </div> */}
                    </Col>
                    <Col xs={12} md={6}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        {
                            loader ?
                            
                            <RotatingLines
                                height="50"
                                width="50"
                                color='#0d6efd'
                                ariaLabel='loading'
                            />: 
                            <div className='counter-secondrow'>
                                {
                                    source.map((i, k) => {
                                        return(
                                            <div>
                                                <p style={{fontSize: 18, fontWeight: 500, color: '#929292', marginBottom: 'unset', marginTop: 10}}>{i.floorname}</p>
                                                <div>
                                                    {
                                                        i.roomList.map((subitem, subindex) => {
                                                            return(
                                                                subitem.roomstatus == 1 ? 
                                                                <Button onClick={() => setCheckin(subitem.roomid)} variant="contained" style={{marginTop: 10, backgroundColor: 'green', marginRight: 10}}>{subitem.roomname}</Button>:
                                                                subitem.roomstatus == 2 ? 
                                                                <Button onClick={() => redClick(subitem.roomid)} variant="contained" style={{marginTop: 10, backgroundColor: 'red', marginRight: 10}}>{subitem.roomname}</Button>:
                                                                subitem.roomstatus == 3 ? 
                                                                <Button variant="contained" style={{marginTop: 10, backgroundColor: '#87f1ff', marginRight: 10}}>{subitem.roomname}</Button>:
                                                                subitem.roomstatus == 4 ? 
                                                                <Button variant="contained" style={{marginTop: 10, backgroundColor: '#fccf19', marginRight: 10}}>{subitem.roomname}</Button>:
                                                                <Button variant="contained" style={{marginTop: 10, backgroundColor: '#808080', marginRight: 10}}>{subitem.roomname}</Button>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
                    </Col>
                    <Col xs={12} md={4}>
                        <div className='counter-firstrow'>
                            <p style={{fontSize: 16, fontWeight: 'bold', textAlign: 'center'}}>ROOM DETAILS</p>
                            <Row>
                                <Col xs={5}><p style={{fontSize: 12, fontWeight: 600, color: '#000', marginBottom: 'unset'}}>ROOM NO.</p></Col>
                                <Col xs={7}><p style={{fontSize: 12, color: '#000', marginBottom: 'unset'}}>: {bookingdetails.roomno}</p></Col>
                            </Row>
                            <Row>
                                <Col xs={5}><p style={{fontSize: 12, fontWeight: 600, color: '#000', marginBottom: 'unset'}}>BOOKING NO.</p></Col>
                                <Col xs={7}><p style={{fontSize: 12, color: '#000', marginBottom: 'unset'}}>: {checkoutdetails.docno}</p></Col>
                            </Row>
                            <Row>
                                <Col xs={5}><p style={{fontSize: 12, fontWeight: 600, color: '#000', marginBottom: 'unset'}}>ROOM TYPE</p></Col>
                                <Col xs={7}><p style={{fontSize: 12, color: '#000', marginBottom: 'unset'}}>: {bookingdetails.roomtype}</p></Col>
                            </Row>
                            <Row>
                                <Col xs={5}><p style={{fontSize: 12, fontWeight: 600, color: '#000', marginBottom: 'unset'}}>FLOOR</p></Col>
                                <Col xs={7}><p style={{fontSize: 12, color: '#000', marginBottom: 'unset'}}>: </p></Col>
                            </Row>
                            <Row>
                                <Col xs={5}><p style={{fontSize: 12, fontWeight: 600, color: '#000', marginBottom: 'unset'}}>GUEST NAME</p></Col>
                                <Col xs={7}><p style={{fontSize: 12, color: '#000', marginBottom: 'unset'}}>: {checkoutdetails.customerDetails ? checkoutdetails.customerDetails.customername: null}</p></Col>
                            </Row>
                            <Row>
                                <Col xs={5}><p style={{fontSize: 12, fontWeight: 600, color: '#000', marginBottom: 'unset'}}>AGE (yrs)</p></Col>
                                <Col xs={7}><p style={{fontSize: 12, color: '#000', marginBottom: 'unset'}}>: </p></Col>
                            </Row>
                            <Row>
                                <Col xs={5}><p style={{fontSize: 12, fontWeight: 600, color: '#000', marginBottom: 'unset'}}>GENDER</p></Col>
                                <Col xs={7}><p style={{fontSize: 12, color: '#000', marginBottom: 'unset'}}>: {checkoutdetails.customerDetails ? checkoutdetails.customerDetails.gender: null}</p></Col>
                            </Row>
                            <Row>
                                <Col xs={5}><p style={{fontSize: 12, fontWeight: 600, color: '#000', marginBottom: 'unset'}}>MOBILE</p></Col>
                                <Col xs={7}><p style={{fontSize: 12, color: '#000', marginBottom: 'unset'}}>: {checkoutdetails.customerDetails ? checkoutdetails.customerDetails.mobile: null}</p></Col>
                            </Row>
                            <Row>
                                <Col xs={5}><p style={{fontSize: 12, fontWeight: 600, color: '#000', marginBottom: 'unset'}}>CITY</p></Col>
                                <Col xs={7}><p style={{fontSize: 12, color: '#000', marginBottom: 'unset'}}>: {checkoutdetails.customerDetails ? checkoutdetails.customerDetails.state:null}</p></Col>
                            </Row>
                            <Row>
                                <Col xs={5}><p style={{fontSize: 12, fontWeight: 600, color: '#000', marginBottom: 'unset'}}>ARRIVAL</p></Col>
                                {checkoutdetails.departure ? 
                                <Col xs={7}><p style={{fontSize: 12, color: '#000', marginBottom: 'unset'}}>: {(new Date(checkoutdetails.arrival)).toLocaleDateString()}</p></Col> : null }
                            </Row>
                            <Row>
                                <Col xs={5}><p style={{fontSize: 12, fontWeight: 600, color: '#000', marginBottom: 'unset'}}>DEPARTURE</p></Col>
                                {checkoutdetails.arrival ? 
                                <Col xs={7}><p style={{fontSize: 12, color: '#000', marginBottom: 'unset'}}>: {(new Date(checkoutdetails.departure)).toLocaleDateString()}</p></Col> : null}
                            </Row>
                            {/* <Row>
                                <Col xs={5}><p style={{fontSize: 12, fontWeight: 600, color: '#000', marginBottom: 'unset'}}>NO. GUEST</p></Col>
                                <Col xs={7}><p style={{fontSize: 12, color: '#000', marginBottom: 'unset'}}>: 2</p></Col>
                            </Row>
                            <Row>
                                <Col xs={5}><p style={{fontSize: 12, fontWeight: 600, color: '#000', marginBottom: 'unset'}}>EXTRA PERSON</p></Col>
                                <Col xs={7}><p style={{fontSize: 12, color: '#000', marginBottom: 'unset'}}>: 0</p></Col>
                            </Row> */}
                            <Row>
                                <Col xs={5}><p style={{fontSize: 12, fontWeight: 600, color: '#000', marginBottom: 'unset'}}>NOTE</p></Col>
                                <Col xs={7}><p style={{fontSize: 12, color: '#000', marginBottom: 'unset'}}>: {checkoutdetails.customerDetails ? checkoutdetails.notes: null}</p></Col>
                            </Row>
                            <div style={{marginTop: 15, marginBottom: 15, display: 'flex', justifyContent: 'center'}}>
                                <Button onClick={() => setModal(true)} variant="contained" style={{marginTop: 10, backgroundColor: '#03a5fc', color: '#fff', fontSize: 14}}>View & Checkout</Button>
                            </div>
                        </div>
                        {/* <div className='counter-firstrow2'>
                            <div className='counter-firstrow2-div'>
                                <p style={{fontSize: 14, color: '#fff', textAlign: 'center'}}>VOUCHER</p>
                            </div>
                            <div className='counter-firstrow2-div'>
                                <p style={{fontSize: 14, color: '#fff', textAlign: 'center'}}>BILL SUMMARY</p>
                            </div>
                            <div className='counter-firstrow2-div'>
                                <p style={{fontSize: 14, color: '#fff', textAlign: 'center'}}>CHANGE ROOM</p>
                            </div>
                            <div className='counter-firstrow2-div'>
                                <p style={{fontSize: 14, color: '#fff', textAlign: 'center'}}>EDIT ADDRESS</p>
                            </div>
                            <div className='counter-firstrow2-div'>
                                <p style={{fontSize: 14, color: '#fff', textAlign: 'center'}}>CREDIT SALE</p>
                            </div>
                            <div className='counter-firstrow2-div'>
                                <p style={{fontSize: 14, color: '#fff', textAlign: 'center'}}>EXTRA PERSON</p>
                            </div>
                            
                        </div> */}
                    </Col>
                </Row>
                {/*check in  modal*/}
                <Modal
                    size="lg"
                    show={checkinmodal}
                    onHide={() => setCheckinmodal(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                    >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Check In Form
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Checkin parentCallback={parentCallback} branchid={branchid} branchdetails={branchdetails} bookingdetails = {bookingdetails} roomid = {roomid}/>
                    </Modal.Body>
                </Modal>
                <Modal
                    size="lg"
                    show={modal}
                    onHide={() => setModal(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                    >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <TaxInvoice parentCallback={parentCallback} branchid={branchid} branchdetails={branchdetails} isAdmin={isAdmin} checkoutdetails ={checkoutdetails} roomid = {roomid}/>
                    </Modal.Body>
                </Modal>
            </Container>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
        isAdmin: state.shop.isAdmin,
    };
};

export default connect(mapStateToProps)(Counter);