import React, {useState, useEffect, useRef} from 'react'
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { connect } from "react-redux";
import { Rings  } from  'react-loader-spinner';
import ReactToPrint from 'react-to-print';
import logo from '../../../assets/images/logo.jpeg';

function ReceiptReport({branchid, branchdetails}){

    const [source, setSource] = React.useState([]);
    const componentRef = useRef();
    const [loader, setLoader] = useState(false);
    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();

    const [date1, setDate1] = React.useState(year + '-' + month + '-' + date);
    const [date2, setDate2] = React.useState(year + '-' + month + '-' + date);
    const [ledger, setLedger] = React.useState('');
    const [ledglist, setLedg] =  React.useState([]);
    const [counter, setCounter] = React.useState('');

    const counterChange = (event, index) => {
        setCounter(event.target.value)
        if(index.props.children == "Bar" ){
            getLedger(1)
        }else if(index.props.children == "Lodge"){
            getLedger(2)
        }
        else if(index.props.children == "Restaurant"){
            getLedger(3)
        }
      }

    useEffect(() => {
        getData()
    }, []);

    const legerChange = (event) => {
        setLedger(event.target.value)
      }

      const getLedger = (id) => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Accounts/GetAllLedgerListNew?branchId=" + branchid + "&counterId=" + id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("ledger Response", responseJson)
             setLedg(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getData = () => {
        setLoader(true)
        var token = localStorage.getItem("token")
        var data = {
                "counterId": counter,
                "companyId": 1,
                "branchId": branchid,
                "fromDate": date1,
                "toDate": date2,
                "createdBy": "",
                "accId": ledger,
                "godownId": "",
                "productId": "",
                "unitId": "",
                "voucherType": "",
                "bankGroupId": "",
                "sectionId": "",
                "deparmentId": "",
                "categoryId": "",
                "subCategoryId": "",
                "color": "",
                "sizeId": "",
                "userId": "",
                "cashGroupId": "",
                "posOnly": "",
                "groupId": "",
                "supplierGroupId": "",
                "deliveryAccId": "",
                "discountAccId": "",
                "directSaleOnly": "",
                "salesAccountAccId": "",
                "contryId": "",
                "waiterId": ""
        }
        console.log("paymentdata", data)
        fetch(global.url + "api/ReceiptReport/ReceiptReport", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("receipt", responseJson)
             setSource(responseJson)
             setLoader(false)
        })
        .catch((error)=>{
            setLoader(false)
            console.log(error)
        })
    }

    const filterData = () => {
        getData()
    }

    const onDeleteclick = (id) => {
        if(window.confirm('Are you want to delete?')){
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Receipt/DeleteReceiptById?id=" + id, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
           if(responseJson.error == false) {
               alert(responseJson.message)
               window.location.reload()
           }
           else{
            alert(responseJson.message)
           }
           console.log("response", responseJson)
        })
        .catch((error)=>{
           
            console.log(error)
        })}
    }
    const formatDate = (datestring) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
        //   console.log("timeString12hr", timeString12hr)

          var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          return formattedDate; //03/02/2021
    }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>Receipt Report</p> 
                <Row>
                    <Col xs={12}  className='columnborder'>
                        <div>
                            <Row>
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="From Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setDate1(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="To Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setDate2(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={3}>
                                    <FormControl fullWidth sx={{width: '100%', mt: 2 }}>
                                        <InputLabel id="demo-simple-select-label">Counter</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={counter}
                                        label="Age"
                                        onChange={(event, index) => counterChange(event, index)}
                                        size="small"
                                        >
                                            <MenuItem value="1">Bar</MenuItem>
                                            <MenuItem value="2">Lodge</MenuItem>
                                            <MenuItem value="3">Restaurant</MenuItem>
                            
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={3}>
                                    <FormControl fullWidth sx={{width: '100%', mt: 2 }}>
                                            <InputLabel id="demo-simple-select-label">Ledger</InputLabel>
                                            <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={ledger}
                                            label="Age"
                                            onChange={legerChange}
                                            size="small"
                                            >
                                                <MenuItem value="0">All</MenuItem>
                                                {
                                                    ledglist.map((i, k) => {
                                                        return(
                                                            <MenuItem value={i.accid}>{i.accname}</MenuItem>
                                                        )
                                                    
                                                    })
                                                }
                                
                                            </Select>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={2}>
                                    <div style={{justifyContent: 'center', display: 'flex'}}>
                                    {
                                        loader ? 
                                        <Rings
                                            height="50"
                                            width="50"
                                            color='#0d6efd'
                                            ariaLabel='loading'
                                        /> : 
                                        <Button onClick={() => filterData()} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">Search</Button>
                                    }
                                    </div>
                                </Col>
                                <Col xs={12} md={2} >
                                    <ReactToPrint
                                        trigger={() => <Button style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">Print</Button>}
                                        content={() => componentRef.current}
                                    />
                                </Col>
                            </Row>
                           
                        </div>
                    </Col> 
                </Row>
                <div style={{height: 2, width: '100%', backgroundColor: '#d3d3d3', marginTop: 30}}></div>
                <div className='bulk-table'>
                    <TableContainer sx={{ maxHeight: 440 }}>
                    <div  className='section-to-print' ref={componentRef} style={{width: '95%'}}>
                            {/* <Row> 
                                
                                <img src={require("../../../assets/images/logo.jpeg").default} style={{width: 100, position: 'absolute'}}/>
                                
                                <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                                <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                                </Col>
                            </Row> */}
                        <Table stickyHeader aria-label="sticky table">
    
                            <TableHead className="tableheaderexe">
                                <TableRow style={{height: 80}} >
                                    <TableCell colSpan={10}  style={{padding: 0}}>
                                        <Row>
                                            <img src={logo} style={{width: 100, position: 'absolute', top: 0}}/>
                                            
                                            <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                                            <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                                            </Col>
                                            <p className='printdate' style={{ position: 'absolute', left: '85%'}}>{formatDate(new Date)}</p>  
                                        </Row>
                                    </TableCell>
                                 </TableRow>
                                 <TableRow>
                                    
                                    <TableCell colSpan={10} style={{padding: 0}}>
                                        <div style={{display: 'flex', justifyContent: 'center', fontWeight: 600}} className='captiontable'>
                                            RECEIPT REPORT FROM {date1.split("-").reverse().join("-")} TO {date2.split("-").reverse().join("-")}
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: "5%"}}>
                                    Sl No.
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: "10%"}}>
                                    Date
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold',  width: "10%"}}>
                                    Bill No.
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold',  width: "8%"}}>
                                    Pay Mode
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold',width: "22%"}}>
                                    Ledger
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold',width: "10%", textAlign: 'center'}}>
                                    Amount
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold',width: "15%", textAlign: 'center'}}>
                                    Narration
                                    </TableCell>
                                    <TableCell className='tablecellPrint noprint' style={{fontWeight: 'bold',width: "10%", textAlign: 'center'}}>
                                    Edit
                                    </TableCell>
                                    <TableCell className='tablecellPrint noprint' style={{fontWeight: 'bold',width: "10%", textAlign: 'center'}}>
                                    Delete
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                            {
                            source.map((row, k) => {
                                return (
                                    row.details.map((item, index) => (
                                        <TableRow className='tablerowPrint'  tabIndex={-1} key={k}>    
                                            {index == 0 ? 
                                                <TableCell className='tablecellPrint' rowSpan={row.details.length} >
                                                {k + 1}
                                                </TableCell>: null
                                            } 
                                            {index == 0 ? 
                                                <TableCell className='tablecellPrint' rowSpan={row.details.length} >
                                                    { (new Date(row.rvdate)).toLocaleDateString() }
                                                </TableCell>: null
                                            } 
                                            {index == 0 ? 
                                                <TableCell className='tablecellPrint' rowSpan={row.details.length} >
                                                    {row.rvno}
                                                </TableCell>: null
                                            } 
                                            {index == 0 ? 
                                                <TableCell className='tablecellPrint' rowSpan={row.details.length} >
                                                     {row.rvpaymode}
                                                </TableCell>: null
                                            } 
                                            <TableCell className='tablecellPrint' >
                                                {item.account}
                                            </TableCell>
                                            <TableCell className='tablecellPrint' style={{textAlign: 'right'}} >
                                                {item.dtamount.toFixed(2)}
                                            </TableCell>
                                            <TableCell className='tablecellPrint' style={{fontSize: 11}} >
                                                {item.dtnarration}
                                            </TableCell>
                                            {index == 0 ? 
                                                <TableCell className='tablecellPrint noprint' style={{textAlign: 'center'}} rowSpan={row.details.length} >
                                                    <a href={'/Transaction/reciept/' + row.vid} >
                                                        <FaIcons.FaEdit fontSize={20} color="blue" />
                                                    </a>
                                                </TableCell>: null
                                            } 
                                            {index == 0 ? 
                                                <TableCell className='tablecellPrint noprint' style={{textAlign: 'center'}} rowSpan={row.details.length} >
                                                     <a href="javascript:void(0)" onClick={() => onDeleteclick(row.vid)}>
                                                        <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                                    </a>
                                                </TableCell>: null
                                            } 
                                    </TableRow>
                                    ))
                                    
                                );
                                })}
                            </TableBody>
                        </Table>
                    </div>
                    </TableContainer>
                </div>
            </Paper>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails
    };
};

export default connect(mapStateToProps)(ReceiptReport);