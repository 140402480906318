import React, {useState, useEffect, useRef} from 'react';
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button, Table, Modal} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { connect } from "react-redux";
import { Rings  } from  'react-loader-spinner'
import {useHistory, useParams} from 'react-router-dom';


const columns = [
    { id: '1', label: 'Code', minWidth: 50,span: false },
    { id: '2', label: 'Description', minWidth: 50,span: false },
    { id: '3', label: 'Opening', minWidth: 50,span: false },
    { id: '4', label: 'RCVD', minWidth: 50,span: false },
    { id: '5', label: 'TRFD', minWidth: 50,span: false },
    { id: '6', label: 'TOTAL', minWidth: 50,span: false },
    { id: '7', label: 'Sales', minWidth: 50,span: false },
    { id: '8', label: 'Closing', minWidth: 50,span: false },
    { id: '9', label: 'Amount', minWidth: 50,span: false },
  ];


function Stocklistsale({branchid}){
    const [age, setAge] = React.useState('');
    const id = useParams();

    const [counterlist, setCounterlist] = React.useState([]);
    const [counter, setCounter] = React.useState('');
    const [tablerow, setTablerow] = React.useState([])
    const [source, setSource] = React.useState([])
    const [modal, setModal] = React.useState(false)
    const [popupdetails, setPopupdetails] = React.useState([])

    const [formid, setFormid] = React.useState('');
    const [docno, setDocno] = React.useState('');
    const [docnum, setDocnum] = React.useState('');
    const [accid, setAccid] = React.useState('');
    const [docdate, setDocdate] = React.useState('');
    const [docdate1, setDocdate1] = React.useState('');
    const [accname, setAccname] = React.useState('');
    const [happyhr, setHappyhr] = React.useState('');
    const [subtotal, setSubtotal] = React.useState(0);
    const [grandtotal, setGrandtotal] = React.useState(0);

    const [directsaletotal, setDirectsaletotal] = React.useState(0);
    const [indirectsaletotal, setIndirectsaletotal] = React.useState(0);

    const [checkloader, setCheckloader] = useState(false);
    const [saveloader, setSaveloader] = useState(false);

    
   

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();


    const [date1, setDate1] = React.useState(year + '-' + month + '-' + date);
  

    const counterChange = (event) => {
        setCounter(event.target.value)
      }

    useEffect(() => {
        getCounter()
        getDocDate()
      }, [branchid]);

      const getDocDate = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/CounterStock/GetTransactionDate?branchId=" + branchid, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("get docdate", responseJson)
             setDocdate(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }  

    const getData = () => {
        var token = localStorage.getItem("token")
        var date1 = formatDate(docdate, "date")
        console.log("date2", date1)
        console.log("docdate", token)
        var data = {
            "companyId": 1,
            "branchId": branchid ,
            "fromDate": date1,
            "toDate": date1,
            "createdBy": "",
            "billno": "",
            "counterId": counter,
            "userId": "",
            "soldItemsOnly": "",
            "accId": ""
          }
          console.log("data", data)
        fetch(global.url + "api/SalesReport/GetStockListInSales", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data), 
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("counterlist response", responseJson)
              setSource(responseJson.responsevalue)
          })
          .catch((error)=>{
              console.log(error)
          })
    }

    const getCounter = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Counter/GetCounterlist?companyId=1&branchId="+ branchid + "&includeBlocked=false", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("counterlist response", responseJson)
              setCounterlist(responseJson)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const formatDate = (datestring, type) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
          

          if(type == "time") {
            var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          }
          else{
            var formattedDate =  year+"/"+month+"/"+day
          }

          
          return formattedDate; //03/02/2021
    }


    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>Stock List</p> 
                <Row>
                    <Col xs={12}  className='columnborder'>
                        <div>
                            <Row>
                                <Col sm={6} lg={2}>
                                        <FormControl fullWidth sx={{width: '100%',  mt: 2 }}>
                                                <InputLabel id="demo-simple-select-label">Counter</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={counter}
                                                label="Age"
                                                onChange={(event) => counterChange(event)}
                                                size="small"
                                                >
                                                    {
                                                        counterlist.map((i, k) => {
                                                            return(
                                                                <MenuItem value={i.ctrid}>{i.ctrname}</MenuItem>
                                                            )
                                                        
                                                        })
                                                    }
                                                </Select>
                                        </FormControl>
                                </Col>
                               
                                <Col xs={12} md={2}>
                                    <div style={{justifyContent: 'center', display: 'flex'}}>
                                    {
                                            checkloader ?
                                            <Rings
                                                height="50"
                                                width="50"
                                                color='#0d6efd'
                                                ariaLabel='loading'
                                            />:
                                        <Button onClick={() => getData()} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 17, width: '50%'}} variant="contained">Search</Button>
                                    }
                                    </div>
                                </Col>
                            </Row>
                           
                        </div>
                    </Col> 
                </Row>
                <div className='bulk-table'>
                <Table responsive bordered>
                    <thead>
                        <tr>
                            <th style={{width: '5%',fontSize: 12}}>SL NO</th>
                            <th style={{width: '5%',fontSize: 12}}>ITEM CODE</th>
                            <th style={{width: "25%",fontSize: 12}}>DESCRIPTION</th>
                            <th style={{width: '5%',fontSize: 12}}>BOTTLE(CLOSING)</th>
                            <th style={{width: '5%',fontSize: 12}}>PEGS(CLOSING)</th>
                            <th style={{width: '5%',fontSize: 12}}>BOTTLE(RATE)</th>
                            <th style={{width: '5%',fontSize: 12}}>PEGS(RATE)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            source.map((i, k) => {
                                return(
                                    <tr>
                                        <td>{k+1}</td>
                                        <td>{i.code}</td>
                                        <td>{i.productname}</td>
                                        <td>{i.closingbottle}</td>
                                        <td>{i.closingpeg}</td>
                                        <td>{i.bottlerate}</td>
                                        <td>{i.pegrate}</td>
                                    </tr>
                                )
                            })
                        }
                        
                    </tbody>
                    </Table>
                </div>
            </Paper>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(Stocklistsale);