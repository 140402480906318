import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { SidebarData } from "./sidebarData";
import SubMenu from "./subMenu";
import './sidebar.css'
import { IconContext } from "react-icons/lib";
import { Container, Row, Col, Button, Form  } from "react-bootstrap";
import Rightsidebar from "./rightsidebar";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import {
    addToCart,
	saveBranch
  } from "../../redux/Shopping/shopping-actions";
import { connect } from "react-redux";
import logo from '../../assets/images/logo.jpeg';


const Nav = styled.div`
	background: #fff;
	height: 60px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	z-index: 999;
	position: fixed;
	width: 100%;
	box-shadow: 5px 7px 26px -5px #cdd4e7;
}`;

const SidebarWrap = styled.div`
	width: 100%;
`;

const Sidebar = ({addToCart, branchid,saveBranch, branchdetails,isAdmin, ...props}) => {
	const [sidebar, setSidebar] = useState(true);
	const [source, setSource] = React.useState([]);
	const [branch, setBranch] = React.useState(branchid);
	const [username, setUsername] = React.useState();
	
	const delay = ms => new Promise(res => setTimeout(res, ms));

	const showSidebar = (childData) => {
		props.getData(childData)
	};

	useEffect(() => {
		var isadmin =  localStorage.getItem("isAdmin")
		var username =  localStorage.getItem("username")
		console.log("username", username)
		setUsername(username)
		// getData()
		checkUser(isadmin)
    }, []);

	const checkUser = async(check) => {
		// await delay(6000);
		if(isAdmin == true) {
			getData()
		}
		if(isAdmin == false){
			var userBranches = JSON.parse(localStorage.getItem("allowedBranches"))
			setSource(userBranches)
			addToCart(userBranches[0].brid)
			setBranch(userBranches[0].brid);
			getBranchdetails(userBranches[0].brid)
		}
	}

	
    const handleChange = (event) => {
        setBranch(event.target.value);
		addToCart(event.target.value)
		getBranchdetails(event.target.value)
    };

	const getBranchdetails = (id) => {
		var token = localStorage.getItem("token")
        fetch(global.url + "api/Branch/GetBranchById?id=" + id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("branch detail Response", responseJson)
			 saveBranch(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
	}

	const getData = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Branch/GetBranchList?orderBy=Name&companyId=1&includeBlocked=False", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("branch view Response", responseJson)
             setSource(responseJson)
			 addToCart(responseJson[0].brid)
			 setBranch(responseJson[0].brid);
			 getBranchdetails(responseJson[0].brid)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

	return (
		<>
		<IconContext.Provider value={{ color: "#fff" }}>
			<Nav>
				<div className="menu-icon" to="#">
					<FaIcons.FaBars style={{color:'#000', fontSize: 15}}  onClick={() =>showSidebar(!props.activeData)} />
				</div>
				
				<Rightsidebar />
			</Nav>
				<div className={"main-menu"}>
					<div style={{marginTop: 20}} className="userpic">
					<img src={logo} style={{maxWidth: '100%'}}/>
					
					</div>
					<p className="paragraphcommon" style={{textAlign: 'center', marginTop: 10, color: '#060606'}}>{isAdmin ? "Admin" : username}</p>
					<p style={{textAlign: 'center', fontSize:11}}></p>
					<div style={{paddingRight: 25, paddingLeft: 25}}>
						<FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
							<InputLabel id="demo-simple-select-label">Branch</InputLabel>
							<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={branch}
							label="Age"
							onChange={handleChange}
							size="small"
							>
							{source.map((i) => {
									return(
										<MenuItem value={i.brid}>{i.brname}</MenuItem>
									)
								})
							}
							</Select>
						</FormControl>
					</div>
					<SidebarWrap className="menu-container">
						{SidebarData.map((item, index) => {
							return <SubMenu item={item} key={index} parent = {() =>showSidebar(!props.activeData)}/>
						})}
					</SidebarWrap>
				</div>
		</IconContext.Provider>
		</>
	);
};
const mapDispatchToProps = (dispatch) => {
    return {
      addToCart: (item) => {
          dispatch(addToCart(item))
        },
		saveBranch: (item) => {
			dispatch(saveBranch(item))
		  },
    };
  };

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
		isAdmin: state.shop.isAdmin
    };
};

  export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);