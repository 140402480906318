import React, { useState, useEffect } from "react";
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button, Modal} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { connect } from "react-redux";

const columns = [
    { id: '1', label: 'Tariff', minWidth: 50,span: false },
    { id: '1', label: 'Extra Person', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Edit',
      minWidth: 100,
      span: true
    },
  ];
const source = [{}, {}]

function Roomtariffmaster({branchid}){

    const [age, setAge] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [sourcecat, setSourcecat] = React.useState('');
    const [roomtype, setRoomtype] = React.useState('');
    const [sourceList, setSourcelist] = React.useState([])
    const [roomtypelist, setRoomtypelist] = React.useState([])
    const [modal, setModal] = React.useState(false);
    const [tarif, setTarif] = React.useState('');
    const [person, setPerson] = React.useState('');
    const [tarifId, setTarifid] = React.useState('');

    const [source, setSource] = React.useState([]);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };

      const roomtypeChange = (event) => {
        setRoomtype(event.target.value);
      };

      const sourceChange = (event) => {
        setSourcecat(event.target.value);
      };

      const getSource = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Source/GetSourceList", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("branch view Response", responseJson)
             setSourcelist(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getRoomtype = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/RoomType/GetRoomTypeList?branchId=" + branchid, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             setRoomtypelist(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }


    useEffect(() => {
        getData()

    }, [branchid]);


    const getData = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/RoomTariff/GetRoomTariffList?branchId="+ branchid, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("tariff", responseJson)
             setSource(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const addClick = () => {
        getSource()
        setModal(true)
        getRoomtype()
        setTarifid('')
        setTarif('')
        setPerson('')
    }

    const editClick =(
        id,
        tarif,
        person,
        Room,
        source
    ) => {
        setModal(true)
        getRoomtype()
        getSource()
        setTarifid(id)
        setTarif(tarif)
        setPerson(person)
        setRoomtype(Room)
        setSourcecat(source)
    }

    const onSubmit = () => {
        var token = localStorage.getItem("token")
        const data = {
            "tariffid": tarifId,
            "roomtypeid": roomtype,
            "sourceid": sourcecat,
            "tariff": tarif,
            "extraperson": person,
            "branchid": branchid,
            "sgst": '',
            "cgst": '',
            "sourcename": "",
            "roomtype": ""
          }
          console.log('data', data)
        fetch(global.url + "api/RoomTariff/SaveRoomTariff", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            if(responseJson.error == false) {
                alert(responseJson.message)
                setModal(false)
                getData()
            }
            else{
                alert(responseJson.message)
            }
        })
        .catch((error)=>{
            alert("error occured")
            console.log(error)
        })
    }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
                    <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Room Tariff Master</p>
                    <Button variant="primary" onClick={() => addClick()} >Add</Button>
                </div> 
                <div style={{height: 2, width: '100%', backgroundColor: '#d3d3d3', marginTop: 30}}></div>
                <div className='bulk-table'>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                                    colSpan={column.span == true ? 2: 1}
                                >
                                    {column.label}
                                    
                                </TableCell>
                                ))}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {source
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                return (
                                    <TableRow  tabIndex={-1} key={row.code}>
                                        <TableCell>
                                            {row.tariff}
                                        </TableCell>
                                        <TableCell>
                                            {row.extraperson}
                                        </TableCell>
                                        <TableCell >
                                            <a href="javascript:void(0)" onClick={() => editClick(
                                                    row.tariffid,
                                                    row.tariff,
                                                    row.extraperson,
                                                    row.roomtypeid,
                                                    row.sourceid,
                                            )}>
                                                <FaIcons.FaEdit fontSize={20} color="blue" />
                                            </a>
                                        </TableCell>
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
                        </TableContainer>
                        <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={source.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
            </Paper>
            <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Room Tarif Creation
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 3, width: '100%' }}>
                                <InputLabel id="demo-simple-select-helper-label">Room Type</InputLabel>
                                <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={roomtype}
                                label="Age"
                                onChange={roomtypeChange}
                                size="small"
                                
                                >
                                <MenuItem value="">
                                <em>None</em>
                                </MenuItem>
                                {roomtypelist.map((item, index) => (
                                    <MenuItem value={item.roomtypeid}>{item.roomname}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 3, width: '100%' }}>
                                <InputLabel id="demo-simple-select-helper-label">Source</InputLabel>
                                <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={sourcecat}
                                label="Age"
                                onChange={sourceChange}
                                size="small"
                                
                                >
                                <MenuItem value="">
                                <em>None</em>
                                </MenuItem>
                                {sourceList.map((item, index) => (
                                    <MenuItem value={item.sourceid}>{item.sourcename}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={tarif} onChange={(e) => setTarif(e.target.value)}  id="outlined-basic" label="Tariff" variant="outlined"  size="small" type='number' />
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={person} onChange={(e) => setPerson(e.target.value)} id="outlined-basic" label="Extra Person" variant="outlined"  size="small" type='number' />
                        </FormControl>
                    </Col>
                    <Col xs ={12} style={{marginTop:30}}>
                      <Button onClick={onSubmit} style={{float: 'right'}} variant="primary">Submit</Button>
                      <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="danger">Cancel</Button>
                    </Col>
                  </Row>
              </Modal.Body>
           </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
    };
};

export default connect(mapStateToProps)(Roomtariffmaster);