import React, {useState, useEffect, useRef} from 'react'
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { connect } from "react-redux";
import ReactToPrint from 'react-to-print';
import logo from '../../../assets/images/logo.jpeg';

const columns = [
    { id: '1', label: 'Sl No.', minWidth: 50,span: false },
    { id: '2', label: 'Voucher Date', minWidth: 50,span: false },
    { id: '3', label: 'Voucher No.', minWidth: 50,span: false },
    { id: '1', label: 'Transaction Type', minWidth: 50,span: false },
    { id: '2', label: 'Ref/Checque No.', minWidth: 50,span: false },
    { id: '3', label: 'Narration', minWidth: 50,span: false },
    { id: '1', label: 'Debit', minWidth: 50,span: false },
    { id: '2', label: 'Creit', minWidth: 50,span: false },
  ];


function Bankbook({branchid, branchdetails}){
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [source, setSource] = React.useState([]);
    const componentRef = useRef();
    const [counterlist, setCounterlist] = React.useState([]);
    const [counter, setCounter] = React.useState('');
    const [billtypelist, setBilltypelist] = React.useState([]);
    const [billType, setBilltype] = React.useState('');

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();

    const [date1, setDate1] = React.useState(year + '-' + month + '-' + date);
    const [date2, setDate2] = React.useState(year + '-' + month + '-' + date);
    const [ledger, setLedger] = React.useState('');
    const [mode, setMode] = React.useState('');
    const [ledglist, setLedg] =  React.useState([]);

    const [debittotal, setDebittotal] = React.useState(0);
    const [credittotal, setCredittotal] = React.useState(0);

    const [countername, setCountername] = React.useState("")

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
  
      const handleChangeRowsPerPage = (event) => {
          setRowsPerPage(+event.target.value);
          setPage(0);
        };

    const modeChange = (event) => {
        setMode(event.target.value);
      };

      useEffect(() => {
        getData()
        getBilltype()
    }, [branchid]);

    const billtypeChange = (event, index) => {
        console.log("index", index)
        console.log("event", event)
        setBilltype(event.target.value);
        if(index.props.children == "BAR" ){
            getSpecaccountlist(1)
        }else if(index.props.children == "LODGE"){
            getSpecaccountlist(2)
        }
        else if(index.props.children == "RESTAURANT"){
            getSpecaccountlist(3)
        }


      };
      const getSpecaccountlist = (id) => {
        
            var token = localStorage.getItem("token")
            fetch(global.url + "api/Accounts/GetBankLedgerListNew?branchId=" + branchid + "&counterId=" + id, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("list response", responseJson)
                setLedg(responseJson)
            })
            .catch((error)=>{
                console.log(error)
            })
        
        
    }

    const getBilltype = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/BillSettings/GetBillTypeByForm?companyId=1&branchId=" + branchid + "&formType=PAY", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("billSettigsList response", responseJson)
              setBilltypelist(responseJson.billSettigsList)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      useEffect(() => {
        getBilltype()
      },[])

    useEffect(() => {
        getTotalamount()
    }, [source]);

    useEffect(() => {
        getCounter()
      }, [branchid]);

      const counterChange = (event) => {
        setCounter(event.target.value)
      }

    const getCounter = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Counter/GetCounterlist?companyId=1&branchId="+ branchid + "&includeBlocked=false", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("counterlist response", responseJson)
              setCounterlist(responseJson)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

    const getTotalamount = () => {
        var dtotalAmount = 0;
        var ctotalAmount = 0;
        for (var i = 0; i < source.length; i++) {
            dtotalAmount += parseFloat(source[i].dtdebit)
            ctotalAmount += parseFloat(source[i].dtcredit)
        }
       setDebittotal(dtotalAmount.toFixed(2))
       setCredittotal(ctotalAmount.toFixed(2))
    }

    const legerChange = (event) => {
        setLedger(event.target.value)
        if(event.target.value == '1'){
            setCountername("Bar")
        }
        if(event.target.value == '2'){
            setCountername("Lodge")
        }
        if(event.target.value == '3'){
            setCountername("Restaurant")
        }
    }

    //   const getLedger = () => {
    //     var token = localStorage.getItem("token")
    //     fetch(global.url + "api/Accounts/GetBankLedgerList?branchId=" + branchid, {
    //         method: "GET",
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + token
    //         },
            
    //     })
    //     .then((response)=> response.json())
    //     .then((responseJson)=>{
    //          console.log("ledger Response", responseJson)
    //          setLedg(responseJson)
    //     })
    //     .catch((error)=>{
    //         console.log(error)
    //     })
    // }


    const getData = () => {
        var token = localStorage.getItem("token")
        var data = {
            "counterId": "",
            "companyId": 1,
            "branchId": branchid,
            "fromDate": date1,
            "toDate": date2,
            "createdBy": "",
            "accId": ledger,
            "godownId": "",
            "productId": "",
            "unitId": "",
            "voucherType": mode,
            "bankGroupId": "",
            "sectionId": "",
            "deparmentId": "",
            "categoryId": "",
            "subCategoryId": "",
            "color": "",
            "sizeId": "",
            "userId": "",
            "cashGroupId": "",
            "posOnly": "",
            "groupId": "",
            "supplierGroupId": "",
            "deliveryAccId": "",
            "discountAccId": "",
            "directSaleOnly": "",
            "salesAccountAccId": "",
            "contryId": "",
            "waiterId": ""
        }
        console.log("paymentdata", data)
        fetch(global.url + "api/Accounting/GetBankBook", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("payment", responseJson)
             setSource(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const filterData = () => {
        if(ledger == "") {
            alert("Please select ledger")

        }
        else{
            getData()
        }
    }

    const formatDate = (datestring, type) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
          

          if(type == "time") {
            var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          }
          else{
            var formattedDate =  day+"/"+month+"/"+year
          }
          return formattedDate; //03/02/2021
    }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>Bankbook</p> 
                <Row>
                    <Col xs={12}  className='columnborder'>
                        <div>
                            <Row>
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="From Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setDate1(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="To Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setDate2(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col sm={3} style={{marginTop:20}}>
                                    <FormControl sx={{  width: '100%' }}>
                                            <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Counter</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                               
                                                value={billType}
                                                label="Age"
                                                onChange={(e, index)=>billtypeChange(e, index)}
                                                size="small"
                                                
                                            >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {billtypelist.map((row, index) => {
                                        return (
                                            <MenuItem value={row.btypeid}>{row.formname}</MenuItem>
                                        )})}
                                            </Select>
                                    </FormControl>
                                </Col> 
                                <Col xs={12} md={3}>
                                    <FormControl fullWidth sx={{width: '100%', mt: 3 }}>
                                            <InputLabel id="demo-simple-select-label">Ledger</InputLabel>
                                            <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={ledger}
                                            label="Age"
                                            onChange={legerChange}
                                            size="small"
                                            >
                                                {
                                                    ledglist.map((i, k) => {
                                                        return(
                                                            <MenuItem value={i.accid}>{i.accname}</MenuItem>
                                                        )
                                                    
                                                    })
                                                }
                                
                                            </Select>
                                    </FormControl>
                                </Col>
                                {/* <Col xs={12} md={3}>
                                    <FormControl fullWidth sx={{mt: 2,width: '100%' }}>
                                        <InputLabel id="demo-simple-select-label">Mode</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Age"
                                        onChange={modeChange}
                                        size="small"
                                        >
                                        <MenuItem value="cash">Cash</MenuItem>
                                        <MenuItem value="bank">Bank</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Col> */}
                               
                                <Col xs={12} md={2}>
                                    <div style={{justifyContent: 'center', display: 'flex'}}>
                                        <Button onClick={() => filterData()} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">Search</Button>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} >
                                    <ReactToPrint
                                        trigger={() => <Button style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">Print this out!</Button>}
                                        content={() => componentRef.current}
                                    />
                                </Col>
                            </Row>
                           
                        </div>
                    </Col> 
                </Row>
                <div style={{height: 2, width: '100%', backgroundColor: '#d3d3d3', marginTop: 30}}></div>
                <div className='bulk-table'>
                    <TableContainer sx={{ maxHeight: 440 }}>
                    <div  className='section-to-print' ref={componentRef} style={{width: '95%'}}>
                        <Table stickyHeader aria-label="sticky table" >
                            <TableHead>
                            <TableRow style={{height: 80}} >
                                    <TableCell colSpan={8}  style={{padding: 0}}>
                                        <Row>
                                            <img src={logo} style={{width: 100, position: 'absolute', top: 0}}/>
                                            
                                            <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                                            <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                                            </Col>
                                            <p className='printdate' style={{ position: 'absolute', left: '85%'}}>{formatDate(new Date)}</p>  
                                        </Row>
                                    </TableCell>
                                 </TableRow>
                                 <TableRow>
                                    
                                    <TableCell colSpan={8} style={{padding: 0}}>
                                        <div style={{display: 'flex', justifyContent: 'center', fontWeight: 600}} className='captiontable'>
                                            <p style={{fontSize: 13}}>BANKBOOK DETAILS FROM {date1.split("-").reverse().join("-")} TO {date2.split("-").reverse().join("-")}</p>
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold',}}>
                                    Sl No.
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', }}>
                                    Voucher Date
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold',}}>
                                    Voucher No.
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', }}>
                                    Transaction Type
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold',}}>
                                    Ref / Cheque No
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', }}>
                                    Narration
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', }}>
                                    Debit
                                    </TableCell>
                                    
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', }}>
                                    Credit
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {source
                                .map((row, index) => {
                                return (
                                    <TableRow  tabIndex={-1} key={row.code}>
                                        <TableCell  className='tablecellPrint'>
                                        {index + 1}
                                        </TableCell>
                                        <TableCell  className='tablecellPrint'>
                                        {(new Date(row.vchdate)).toLocaleDateString()}
                                        </TableCell>
                                        <TableCell  className='tablecellPrint'>
                                        {row.vchdocno}
                                        </TableCell>
                                        <TableCell  className='tablecellPrint'>
                                        {row.trnname}
                                        </TableCell>
                                        <TableCell  className='tablecellPrint'>
                                       
                                        </TableCell>
                                        <TableCell  className='tablecellPrint'>
                                        {row.vchremarks}
                                        </TableCell>
                                        <TableCell  className='tablecellPrint' style={{textAlign: 'right'}}>
                                        {row.dtdebit.toFixed(2)}
                                        </TableCell>
                                        <TableCell  className='tablecellPrint' style={{textAlign: 'right'}}>
                                        {row.dtcredit.toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                );
                                })}
                                <TableRow  tabIndex={-1}>
                                        <TableCell  className='tablecellPrint' colSpan={6}>
                                        Total
                                        </TableCell>
                                        <TableCell  className='tablecellPrint' style={{textAlign: 'right'}}>
                                        {debittotal}
                                        </TableCell>
                                        <TableCell  className='tablecellPrint' style={{textAlign: 'right'}}>
                                        {credittotal}
                                        </TableCell>
                                    </TableRow>
                            </TableBody>
                        </Table>
                        </div>
                        </TableContainer>
                    </div>
            </Paper>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(Bankbook);