import React, {useState, useEffect,useRef} from 'react';
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button, Modal} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
// import Loader from '../loader/loader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TimePicker from '@mui/lab/TimePicker';
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { connect } from "react-redux";
import ReactToPrint from 'react-to-print';
import { Rings  } from  'react-loader-spinner';
import logo from '../../../assets/images/logo.jpeg';


const columns = [
    { id: '1', label: 'Code', minWidth: 50,span: false },
    { id: '2', label: 'Description', minWidth: 50,span: false },
    { id: '3', label: 'Opening', minWidth: 50,span: false },
    { id: '4', label: 'RCVD', minWidth: 50,span: false },
    { id: '5', label: 'TRFD', minWidth: 50,span: false },
    { id: '6', label: 'TOTAL', minWidth: 50,span: false },
    { id: '7', label: 'Sales', minWidth: 50,span: false },
    { id: '8', label: 'Closing', minWidth: 50,span: false },
    { id: '9', label: 'Amount', minWidth: 50,span: false },
  ];

  

  function Counterstock({branchid, branchdetails}) {
    const [age, setAge] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [source, setSource] = React.useState([])

    const [counterlist, setCounterlist] = React.useState([]);
    const [counter, setCounter] = useState("");
    const [countername, setCountername] = useState("");

    const [loader, setLoader] = useState(false);
    
    const componentRef = useRef();

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();


    const [date1, setDate1] = React.useState(year + '-' + month + '-' + date);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const counterChange = (event) => {
        setCounter(event.target.value)
        getCounterbyid(event.target.value)
    }


      const getCounterbyid = (id) => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Counter/GetCounterById?id=" + id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              setCountername(responseJson.ctrname)
          })
          .catch((error)=>{
              console.log(error)
          })
      }



    useEffect(() => {
        getData()
        getCounter()
    }, [branchid]);

    const onFilter =()=> {

    }

    const getCounter = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Counter/GetCounterlist?companyId=1&branchId="+ branchid + "&includeBlocked=false", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("counterlist response", responseJson)
              setCounterlist(responseJson)
          })
          .catch((error)=>{
              console.log(error)
          })
      }


    const getData = () => {
        setLoader(true)
        var token = localStorage.getItem("token")
        var data = {
            "companyId": 1,
            "branchId": branchid,
            "fromDate":  date1,
            "toDate": date1,
            "createdBy": global.userid,
            "billno": "",
            "counterId": counter
          }
        fetch(global.url + "api/SalesReport/GetCounterStockListOld", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("GetCounterStockList view Response", responseJson)
             setLoader(false)
             setSource(responseJson)
        })
        .catch((error)=>{
            setLoader(false)
            console.log(error)
        })
    }
    const formatDate = (datestring) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
        //   console.log("timeString12hr", timeString12hr)

          var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          return formattedDate; //03/02/2021
    }
      return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2 }}>
            <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>Counter Stock Report</p> 
            <Row>
                    <Col xs={12}  className='columnborder'>
                        <div>
                            <Row>
                            <Col sm={6} lg={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="Bill Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setDate1(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col sm={6} lg={2}>
                                        <FormControl fullWidth sx={{width: '100%',  mt: 2 }}>
                                                <InputLabel id="demo-simple-select-label">Counter</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={counter}
                                                label="Age"
                                                onChange={(event) => counterChange(event)}
                                                size="small"
                                                >
                                                    {
                                                        counterlist.map((i, k) => {
                                                            return(
                                                                <MenuItem value={i.ctrid}>{i.ctrname}</MenuItem>
                                                            )
                                                        
                                                        })
                                                    }
                                                </Select>
                                        </FormControl>
                            </Col>
                               
                                <Col xs={12} md={2}>
                                    <div  style={{display: 'flex', justifyContent: 'center'}}>
                                        {
                                        loader ? 
                                        <Rings
                                            height="50"
                                            width="50"
                                            color='#0d6efd'
                                            ariaLabel='loading'
                                        /> : 
                                            <Button onClick={getData} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 17, width: '50%'}} variant="contained">Search</Button>
                                        }
                                    </div>
                                </Col>
                                {/* <Col xs={12} md={2}>
                                    <div style={{ justifyContent: 'center', display: 'flex'}}>
                                        <Button  onClick={() => onPrint()}  style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">print</Button>
                                    </div>
                                </Col> */}
                                <Col xs={12} md={4} >
                                    <ReactToPrint
                                        trigger={() => <Button style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">Print this out!</Button>}
                                        content={() => componentRef.current}
                                    />
                                </Col>
                            </Row>
                           
                        </div>
                    </Col> 
                </Row>
                <div style={{margin: 20,}}>
                {/* {loader ? <Loader /> : */}
                    <TableContainer sx={{ maxHeight: 440, marginTop: 2 }}>
                        <div  className='section-to-print' ref={componentRef}>
                            {/* <Row> 
                                
                                <img src={require("../../../assets/images/logo.jpeg").default} style={{width: 100, position: 'absolute'}}/>
                                
                                <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                                <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                                </Col>
                            </Row> */}
                        <Table id="table-to-xls"  stickyHeader aria-label="sticky table" >
                            <TableHead className="tableheaderexe">
                                <TableRow style={{height: 80}} >
                                    <TableCell colSpan={13}  style={{padding: 0}}>
                                        <Row>
                                            <img src={logo} style={{width: 100, position: 'absolute', top: 0}}/>
                                            
                                            <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                                            <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                                            </Col>
                                            <p className='printdate' style={{ position: 'absolute', left: '85%'}}>{formatDate(new Date)}</p>  
                                        </Row>
                                    </TableCell>
                                 </TableRow>
                                 <TableRow>
                                    
                                    <TableCell colSpan={13} style={{padding: 0}}>
                                        <div style={{display: 'flex', justifyContent: 'center', fontWeight: 600}} className='captiontable'>
                                            COUNTER STOCK OF {countername} FOR {date1.split("-").reverse().join("-")}
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: "10%"}}>
                                    Code
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: "45%"}}>
                                    Description
                                    </TableCell>
                                    <TableCell colSpan={2} className='tablecellPrint' style={{fontWeight: 'bold',  width: "5%"}}>
                                    Opening
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold',  width: "5%"}}>
                                     RCVD
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold',width: "5%"}}>
                                    TRFD
                                    </TableCell>
                                    <TableCell colSpan={2} className='tablecellPrint' style={{fontWeight: 'bold', width: "5%"}}>
                                    <span style={{float: 'right'}}>TOTAL</span>
                                    </TableCell>
                                  <TableCell colSpan={2} className='tablecellPrint' style={{fontWeight: 'bold',width: "5%"}}>
                                    Sales
                                    </TableCell>
                                    <TableCell  colSpan={2} className='tablecellPrint' style={{fontWeight: 'bold',width: "5%"}}>
                                    Closing
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold',width: "25%", textAlign: 'center'}}>
                                    Amount
                                    </TableCell>

                            
                                </TableRow>

                                <TableRow>
                                    
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>
                                    
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>
                                    
                                    </TableCell>
                                    
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>
                                    BOTS
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>
                                    PEGS
                                    </TableCell>

                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>
                                    
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>
                                    
                                    </TableCell>
                                    
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>
                                    <span style={{float: 'right'}}>BOTS</span>
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>
                                    <span style={{float: 'right'}}>PEGS</span>
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>
                                        BOTS
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>
                                        PEGS
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>
                                        BOTS
                                    </TableCell>
                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>
                                        PEGS
                                    </TableCell>

                                    <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>
                                    
                                    </TableCell>

                            
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                            source.map((i, k) => {
                                return (
                                    <TableRow className='tablerowPrint'  tabIndex={-1} key={k}>
                                        <TableCell className='tablecellPrint' >
                                        {i.code}
                                        </TableCell>
                                        <TableCell className='tablecellPrint'  >
                                        {i.productname}
                                        </TableCell>
                                        

                                        <TableCell className='tablecellPrint' style={{textAlign: 'right'}} >
                                        {i.openingbottle}
                                        </TableCell>
                                        <TableCell className='tablecellPrint' style={{textAlign: 'right'}} >
                                        {i.openingpeg.toFixed(2)}
                                        </TableCell>
                                        <TableCell className='tablecellPrint' style={{textAlign: 'right'}} >
                                        {i.received == 0 ? null : i.received}
                                        </TableCell>
                                        <TableCell className='tablecellPrint' style={{textAlign: 'right'}} >
                                        {i.transfered == 0 ? null : i.transfered}
                                        </TableCell>
                                        <TableCell className='tablecellPrint' style={{textAlign: 'right'}} >
                                        <span style={{float: 'right'}}>{i.totalbottle}</span>
                                        </TableCell>
                                        <TableCell className='tablecellPrint' style={{textAlign: 'right'}}>
                                        <span style={{float: 'right'}}>{i.totalpeg.toFixed(2)}</span>
                                        </TableCell>
                                        <TableCell className='tablecellPrint' style={{textAlign: 'right'}} >
                                       
                                        </TableCell>
                                        <TableCell className='tablecellPrint' style={{textAlign: 'right'}} >
                                    
                                        </TableCell>
                                        <TableCell className='tablecellPrint' style={{textAlign: 'right'}} >
                                    
                                        </TableCell>
                                        <TableCell className='tablecellPrint' style={{textAlign: 'right'}} >
                          
                                        </TableCell>
                                        <TableCell className='tablecellPrint' style={{textAlign: 'right'}} >
                                  
                                        </TableCell>
                                        
                                        
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
                    </div>
                    </TableContainer>
                       
                </div>
            </Paper> 
            
        </div>
      );
  }

  const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(Counterstock);
