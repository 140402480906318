import React, {useState, useEffect,useRef} from 'react';
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Occupancy from '../Counter/Occupancy/Occupancy';
import { connect } from "react-redux";
import ReactToPrint from 'react-to-print';
import logo from '../../assets/images/logo.jpeg';

function OccupancyReg({branchid, branchdetails}){
    const componentRef = useRef();
    const [age, setAge] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();

    const [source, setSource] = React.useState([]);
    const [fromdate, setFromdate] = React.useState(year + '-' + month + '-' + date );
    const [todate, setTodate] = React.useState(year + '-' + month + '-' + date );

    useEffect(() => {
        getData()
        
    }, [branchid]);

    const getData = () => {
        var token = localStorage.getItem("token")
        var data = {
            "companyId": 1,
            "branchId": branchid,
            "fromDate": fromdate,
            "toDate": todate,
            "createdBy": "",
            "billno": "",
            "counterId": "",
            "userId": "",
            "soldItemsOnly": "",
            "accId": ""
          }
        fetch(global.url + "api/BookingReports/GetOccupancyList", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("GetCounterStockList view Response", responseJson)
             
             setSource(responseJson)
        })
        .catch((error)=>{
         
            console.log(error)
        })
    }

    const formatDate = (datestring, type) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
        }
    
        const zeroDate = "0001-01-01T00:00:00";
        if (datestring === zeroDate) {
            return ""; // or any other representation for this special case, e.g., return "N/A";
        }
    
        var date = new Date(datestring);
        var month = pad2(date.getMonth() + 1);
        var day = pad2(date.getDate());
        var year = date.getFullYear();
    
        const timeString12hr = new Date(datestring)
            .toLocaleTimeString('en-US', { timeZone: 'Asia/Kolkata', hour12: true, hour: 'numeric', minute: 'numeric' });
    
        if (type === "time") {
            var formattedDate = day + "/" + month + "/" + year + " " + timeString12hr;
        } else {
            var formattedDate = year + "-" + month + "-" + day + " " + timeString12hr;
        }
    
        return formattedDate;
    };
    
    const formatDate2 = (datestring) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
        //   console.log("timeString12hr", timeString12hr)

          var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          return formattedDate; //03/02/2021
    }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>Room Occupancy Register</p> 
                <Row>
                    <Col xs={12}  className='columnborder'>
                        <div>
                            <Row>
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ mt: 3, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="From Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setFromdate(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ mt: 3, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="To Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setTodate(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={2}>
                                    <div style={{justifyContent: 'center', display: 'flex'}}>
                                        <Button onClick={() => getData()} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 20, width: '50%'}} variant="contained">Search</Button>
                                    </div>
                                </Col>
                                <Col xs={12} md={3}>
                                    <ReactToPrint
                                        trigger={() => <Button  style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 17, width: '50%'}} variant="contained">Print this out!</Button>}
                                        content={() => componentRef.current}
                                    />
                                </Col>
                            </Row>
                           
                        </div>
                    </Col> 
                </Row>
                <div style={{height: 2, width: '100%', backgroundColor: '#d3d3d3', marginTop: 30}}></div>
                <TableContainer >
                <div className='bulk-table section-to-print' ref={componentRef} style={{width: '95%'}}>
                {/* <Row>         
                    <img src={require("../../assets/images/logo.jpeg").default} style={{width: 100, position: 'absolute'}}/>
                    <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                    <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                    </Col>
                </Row> */}
                
                        <Table stickyHeader aria-label="sticky table">
                        {/* <caption className='captiontable'>Occupancy Register Report from {fromdate.split("-").reverse().join("-")} to {todate.split("-").reverse().join("-")}</caption> */}
                            <TableHead>
                            <TableRow style={{height: 80}} >
                                    <TableCell colSpan={7}  style={{padding: 0}}>
                                        <Row>
                                            <img src={logo} style={{width: 100, position: 'absolute', top: 0}}/>
                                            
                                            <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                                            <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                                            </Col>
                                            <p className='printdate' style={{ position: 'absolute', left: '85%'}}>{formatDate2(new Date)}</p> 
                                        </Row>
                                    </TableCell>
                                 </TableRow>
                                 <TableRow>
                                    
                                    <TableCell colSpan={7} style={{padding: 0}}>
                                        <div style={{display: 'flex', justifyContent: 'center', fontWeight: 600}} className='captiontable'>
                                            OCCUPANCY REGISTER REPORT FROM {fromdate.split("-").reverse().join("-")} TO {todate.split("-").reverse().join("-")}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            <TableRow>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '10%'}}>Room No.</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '10%'}}>GRC</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '10%'}}>Source</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '10%'}}>PAX</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '10%'}}>Check In</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '10%'}}>Check Out</TableCell>
                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', width: '15%'}}>Address</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {source
                                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((i) => {
                                return (
                                    <TableRow  tabIndex={-1} key={i.code}>
                                        <TableCell className='tablecellPrint'>
                                            {i.room}
                                        </TableCell>
                                        <TableCell className='tablecellPrint'>
                                            {i.grc}
                                        </TableCell>
                                        <TableCell className='tablecellPrint' >
                                            {i.source}
                                        </TableCell>
                                        <TableCell className='tablecellPrint' >
                                            {i.pax}
                                        </TableCell>
                                        <TableCell className='tablecellPrint'>
                                        {formatDate(i.checkin, "time") }
                                        </TableCell>
                                        <TableCell className='tablecellPrint'>
                                         {formatDate(i.checkout, "time")}
                                        </TableCell>
                                        <TableCell className='tablecellPrint'>
                                         {i.address}
                                        </TableCell>
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
                </div>
                </TableContainer>
            </Paper>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(OccupancyReg);