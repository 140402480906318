import React, { useState, useEffect,useRef } from "react";
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import { connect } from "react-redux";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import ReactToPrint from 'react-to-print';
import logo from '../../assets/images/logo.jpeg';

function Waitersalesreport({branchid, branchdetails}){
    const componentRef = useRef();
    const [age, setAge] = React.useState('');
    const [source, setSource] = React.useState([]);
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();


    const [fromdate, setFromdate] = React.useState(year + '-' + month + '-' + date);
    const [todate, setTodate] = React.useState(year + '-' + month + '-' + date);

    useEffect(() => {
        getData()
    }, [branchid]);


    const getData = () => {
        var token = localStorage.getItem("token")
        var data = {
                "counterId": "",
                "companyId": 1,
                "branchId": branchid,
                "fromDate": fromdate,
                "toDate": todate,
                "createdBy": global.userid,
                "accId": "",
                "godownId": "",
                "productId": "",
                "unitId": "",
                "voucherType": "",
                "bankGroupId": "",
                "sectionId": "",
                "deparmentId": "",
                "categoryId": "",
                "subCategoryId": "",
                "color": "",
                "sizeId": "",
                "userId": "",
                "cashGroupId": "",
                "posOnly": true,
                "groupId": "",
                "supplierGroupId": "",
                "deliveryAccId": "",
                "discountAccId": "",
                "directSaleOnly": true,
                "salesAccountAccId": "",
                "contryId": "",
                "waiterId": ""
              
        }
        console.log("data", data)
        fetch(global.url + "api/SalesReport/GetWaiterSalesList", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
            
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("GetWaiterSalesList Response", responseJson)
             if(responseJson.message) {

             }
             else{
                setSource(responseJson)
             }
            
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const formatDate = (datestring) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
        //   console.log("timeString12hr", timeString12hr)

          var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          return formattedDate; //03/02/2021
    }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>Waiter Sales Report</p> 
                <Row>
                    <Col xs={12}  className='columnborder'>
                        <div>
                            <Row>
                                {/* <Col xs={12} md={3}>
                                    <FormControl fullWidth sx={{ mt: 3, width: '100%' }}>
                                        <InputLabel id="demo-simple-select-label">Source</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={age}
                                        label="Age"
                                        onChange={handleChange}
                                        size="small"
                                        >
                                        <MenuItem value={10}>Room</MenuItem>
                                       
                                        </Select>
                                    </FormControl>
                                </Col> */}
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="From Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setFromdate(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="To Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setTodate(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={3}>
                                <div style={{justifyContent: 'center', display: 'flex'}}>
                                        <Button onClick={getData} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 17, width: '50%'}} variant="contained">Search</Button>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} >
                                    <ReactToPrint
                                        trigger={() => <Button style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 17, width: '50%'}} variant="contained">Print this out!</Button>}
                                        content={() => componentRef.current}
                                    />
                                </Col>
                            </Row> 
                        </div>
                    </Col>
                </Row>
                <div style={{margin: 20,}}>
                {/* {loader ? <Loader /> : */}
                    <TableContainer sx={{ maxHeight: 440, marginTop: 2 }}>
                        <div  className='section-to-print' ref={componentRef} style={{width: '95%'}}>
                        <Table id="table-to-xls"  stickyHeader aria-label="sticky table" >
                        <TableHead className="tableheaderexe">
                        <TableRow style={{height: 80}} >
                                    <TableCell colSpan={9}  style={{padding: 0}}>
                                        <Row>
                                            <img src={logo} style={{width: 100, position: 'absolute', top: 0}}/>
                                            
                                            <Col xs={12} style={{justifyContent: 'center', display: 'flex'}}>
                                            <p style={{fontSize: 25, fontWeight: 'bold'}}>{branchdetails.brname}</p>
                                            </Col>
                                            <p className='printdate' style={{ position: 'absolute', left: '85%'}}>{formatDate(new Date)}</p>  
                                        </Row>
                                    </TableCell>
                                 </TableRow>
                                 <TableRow>
                                    
                                    <TableCell colSpan={9} style={{padding: 0}}>
                                        <div style={{display: 'flex', justifyContent: 'center', fontWeight: 600}} className='captiontable'>
                                            WAITER SALES REPORT  FROM {fromdate.split("-").reverse().join("-")} TO  {todate.split("-").reverse().join("-")}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            <TableRow>
                                <TableCell className='tablecellPrint'>Date</TableCell>
                                <TableCell className='tablecellPrint'>Categoryname</TableCell>
                                <TableCell className='tablecellPrint'>Bar</TableCell>
                                <TableCell className='tablecellPrint'>A/C</TableCell>
                                
                                <TableCell className='tablecellPrint'>Room Service</TableCell>
                                <TableCell className='tablecellPrint'>W/S</TableCell>
                                <TableCell className='tablecellPrint'>local</TableCell>
                                <TableCell className='tablecellPrint'>Ex</TableCell>
                                <TableCell className='tablecellPrint'>Total</TableCell>
                            </TableRow>
                        </TableHead>
                        
                            {
                                source.map((i, k) => {
                                    return(
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className='tablecellPrint'  colSpan={9}>{(new Date(i.sadocdate)).toLocaleDateString()}</TableCell>
                                               
                                            </TableRow>
                                            {
                                                i.waiters == null ? null :
                                                i.waiters.map((waiters, index) => {
                                                    return(
                                                       <>
                                                            {
                                                                waiters.waiterCategory.map((waiterCategory, j) => {
                                                                    return(
                                                                        <TableRow>
                                                                            {
                                                                                j==0 ? 
                                                                                <TableCell className='tablecellPrint' rowSpan={waiters.waiterCategory.length}>
                                                                                    {waiters.waitername}
                                                                                </TableCell> :
                                                                                null
                                                                            }
                                                                            <TableCell className='tablecellPrint' >{waiterCategory.catname}</TableCell>
                                                                            <TableCell className='tablecellPrint' style={{textAlign: 'right'}}>{waiterCategory.bar.toFixed(2)}</TableCell>
                                                                            <TableCell className='tablecellPrint' style={{textAlign: 'right'}}>{waiterCategory.ac.toFixed(2)}</TableCell>
                                                                            <TableCell className='tablecellPrint' style={{textAlign: 'right'}}>{waiterCategory.room.toFixed(2)}</TableCell>
                                                                            <TableCell className='tablecellPrint' style={{textAlign: 'right'}}>{waiterCategory.ws.toFixed(2)}</TableCell>
                                                                            <TableCell className='tablecellPrint' style={{textAlign: 'right'}}>{waiterCategory.local.toFixed(2)}</TableCell>
                                                                            <TableCell className='tablecellPrint' style={{textAlign: 'right'}}>{waiterCategory.ex.toFixed(2)}</TableCell>
                                                                            <TableCell className='tablecellPrint' style={{textAlign: 'right'}}>{waiterCategory.amount.toFixed(2)}</TableCell>
                                                                        </TableRow>
                                                                        
                                                                    )
                                                                })
                                                            }
                                                            <TableRow>
                                                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}} colSpan={2}>Subtotal</TableCell>
                                                                <TableCell className='tablecellPrint' style={{textAlign: 'right', fontWeight: 'bold'}}>{waiters.subtotalbar.toFixed(2)}</TableCell>
                                                                <TableCell className='tablecellPrint' style={{textAlign: 'right', fontWeight: 'bold'}}>{waiters.subtotalac.toFixed(2)}</TableCell>
                                                                <TableCell className='tablecellPrint' style={{textAlign: 'right', fontWeight: 'bold'}}>{waiters.subtotalroom.toFixed(2)}</TableCell>
                                                                <TableCell className='tablecellPrint' style={{textAlign: 'right', fontWeight: 'bold'}}>{waiters.subtotalws.toFixed(2)}</TableCell>
                                                                <TableCell className='tablecellPrint' style={{textAlign: 'right', fontWeight: 'bold'}}>{waiters.subtotallocal.toFixed(2)}</TableCell>
                                                                <TableCell className='tablecellPrint' style={{textAlign: 'right', fontWeight: 'bold'}}>{waiters.subtotalex.toFixed(2)}</TableCell>
                                                                <TableCell className='tablecellPrint' style={{textAlign: 'right', fontWeight: 'bold'}}>{waiters.subtotalamount.toFixed(2)}</TableCell>
                                                            </TableRow>
                                                        </>
                                                    )
                                                }
                                                )
                                            }
                                            <TableRow>
                                                <TableCell className='tablecellPrint' colSpan={2} style={{fontWeight: 'bold', }}>Liquor Total</TableCell>
                                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right' }}>{i.grandtotalbar.toFixed(2)}</TableCell>
                                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right' }}>{i.grandtotalac.toFixed(2)}</TableCell>
                                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right' }}>{i.grandtotalroom.toFixed(2)}</TableCell>
                                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right' }}>{i.grandtotalws.toFixed(2)}</TableCell>
                                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right' }}>{i.grandtotallocal.toFixed(2)}</TableCell>
                                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right' }}>{i.grandtotalex.toFixed(2)}</TableCell>
                                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}} >{i.liquorgrandtotal.toFixed(2)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className='tablecellPrint' colSpan={2} style={{fontWeight: 'bold', }}>Food Total</TableCell>
                                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right' }}>{i.grandtotalbarfood.toFixed(2)}</TableCell>
                                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right' }}>{i.grandtotalacfood.toFixed(2)}</TableCell>
                                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right' }}>{i.grandtotalroomfood.toFixed(2)}</TableCell>
                                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right' }}>{i.grandtotalwsfood.toFixed(2)}</TableCell>
                                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right' }}>{i.grandtotallocalfood.toFixed(2)}</TableCell>
                                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right' }}>{i.grandtotalexfood.toFixed(2)}</TableCell>
                                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}} >{i.foodgrandtotal.toFixed(2)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className='tablecellPrint' colSpan={2} style={{fontWeight: 'bold', }}>Grand Total</TableCell>
                                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right' }}>{i.nettotalbar.toFixed(2)}</TableCell>
                                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right' }}>{i.nettotalac.toFixed(2)}</TableCell>
                                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right' }}>{i.nettotalroom.toFixed(2)}</TableCell>
                                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right' }}>{i.nettotalws.toFixed(2)}</TableCell>
                                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right' }}>{i.nettotallocal.toFixed(2)}</TableCell>
                                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right' }}>{i.nettotalex.toFixed(2)}</TableCell>
                                                <TableCell className='tablecellPrint' style={{fontWeight: 'bold', textAlign: 'right'}} >{i.nettotal.toFixed(2)}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )
                                })
                            }
                    </Table>
                    </div>
                    </TableContainer>
                </div>
            </Paper>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(Waitersalesreport);