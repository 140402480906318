import React, {Component, useEffect, useState, useRef} from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { Container, Row, Col, Form, Table, Modal  } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import * as IconName from "react-icons/bs";
import * as GI from "react-icons/gi";
import * as BI from "react-icons/bi";
import * as RI from "react-icons/ri";
import Autocomplete from '@mui/material/Autocomplete';
import { useParams } from 'react-router-dom';
import { connect } from "react-redux";

function Contra({branchid}) {

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();

    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    const [billType, setBilltype] = React.useState('');
    const [billtypelist, setBilltypelist] = React.useState([]);
    const [invoice, setInvoice] = React.useState('');
    const [docnum, setDocnum] = React.useState("");
    const [allaccountlist, setAllaccountlist] = React.useState([]);
    
    const [voucherno, setVoucherno] = useState("");
    const [refno, setRefno] = React.useState("");
    const [registerDate, setRegisterdate] = React.useState(year + '-' + month + '-' + date);
    const [refdate, setRefdate] = React.useState(year + '-' + month + '-' + date);
    const [tableRow, setTableRow] = React.useState([{
        $id: "",
        dtjvid: 0,
        dtvid: 0,
        dtaccid: "",
        dtnarration: "",
        dtdebit: 0.0,
        dtcredit: 0.0,
        code: "",
        account: ""
      }]);
    const [selectedIndex, setSelectedindex] = React.useState("")
    const [textArray, setTextarray] = React.useState([{key: "", value: ""}])
    const [codelist, setCodelist] = React.useState([])
    const inputRef = useRef();
    const resultsRef = useRef();
    const [modal, setModal] = React.useState(false);
    const [editmode, setEditmode] = React.useState(false);
    const {id} = useParams()

    const [code, setCode] = useState("");
    const [journalId, setJournalid] = useState("");
    
    const [remarks, setRemarks] = useState("");
    const [userId, setUserid] = useState("");
    const [debitTotal, setDebittotal] = useState(0);
    const [creditTotal, setCredittotal] = useState(0);
    const hasResults = code.length > 0;
    

    const addRow = () => {
        setTableRow([...tableRow, {$id: "",
        dtjvid: 0,
        dtvid: 0,
        dtaccid: "",
        dtnarration: "",
        dtdebit: 0.0,
        dtcredit: 0.0,
        code: "",
        account: ""}])
    }

      useEffect(() => {
        
        calcTotal()
      }, [tableRow]);

      useEffect(() => {
        console.log("id", id)
        getBilltype()
        // if(id == "add") {
        //     getInvoiceno()
        //     setEditmode(false)
        // }
        // else{
        //    getDetailsbyid(id)
        //    setEditmode(true)
        // }
        // var userdata = JSON.parse(localStorage.getItem("user"))
        // var branch = JSON.parse(localStorage.getItem("branch"))
        // var company = JSON.parse(localStorage.getItem("company"))
        // setUsercompanyid(company)
        // setUserbranchid(branch)
        // setUsertype(userdata.user_type)
        // setUserid(userdata.user_id)
      }, []);



      const getDetailsbyid = (id) => {
        // console.log("id", id)
        // const data = {
        //     "journal_id": id,
        // }
        // fetch(global.url + "viewJournal", {
        //     method: "POST",
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify(data),
        // })
        // .then((response)=> response.json())
        // .then((responseJson)=>{
        //     console.log("journal head", responseJson)
        //     if(responseJson.status == "false") {
        //         console.log("journal data", responseJson.data)
        //         setVoucherno(responseJson.data.journal_voucher_no)
        //         setRefno(responseJson.data.journal_ref_no)
                
        //         var invoiceDate=new Date(responseJson.data.journal_date).toISOString();
        //         var referenedate=new Date(responseJson.data.journal_ref_date).toISOString();
        //         setRegisterdate(invoiceDate)
        //         setRefdate(referenedate)
        //         setTableRow(responseJson.details)
        //         setRemarks(responseJson.data.journal_remarks)
        //         setJournalid(responseJson.data.journal_id)
        //     }
        // })
        // .catch((error)=>{
        //     console.log(error)
        // })
  }

      const getInvoiceno = (id) => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/BillSettings/GetNextBillNo?billId=" + id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("GetNextBillNo response", responseJson)
              setInvoice(responseJson.billno)
              setDocnum(responseJson.billprefix + responseJson.billno + responseJson.billsuffix)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const calcTotal = () => {
          var debitTotal = 0;
          var creditTotal = 0
          for(var i=0; i<tableRow.length; i++) {
                debitTotal += parseInt(tableRow[i].dtdebit) 
                creditTotal += parseInt(tableRow[i].dtcredit) 
          }
          setDebittotal(debitTotal)
          setCredittotal(creditTotal)
      }

    const getSearch = (text) => {
        // var data = {
        //     "account_code" : text,
        // }
        
        // fetch(global.url + "searchJournal", {
        //     method: "POST",
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify(data),
        // })
        // .then((response)=> response.json())
        // .then((responseJson)=>{
        //     console.log("search response", responseJson)
        //     setCodelist(responseJson.data)
        // })
        // .catch((error)=>{
        //     console.log(error)
        // })
    }

    const debitChange = (event, key) => {
        var input = [...tableRow]
        input[key].dtdebit = event.target.value
        input[key].creditdisabled = true
        input[key].debitdisabled = false
        if(event.target.value == ""){
            input[key].creditdisabled = false
        }
        setTableRow(input)
    }

    const creditChange = (event, key) => {
        var input = [...tableRow]
        input[key].dtcredit = event.target.value
        input[key].creditdisabled = false
        input[key].debitdisabled = true
        if(event.target.value == ""){
            input[key].debitdisabled = false
        }
        setTableRow(input)
    }

    const narrationChange = (event, key) => {
        var input = [...tableRow]
        input[key].dtnarration = event.target.value
        setTableRow(input)
    }



    const inputHandler = (text, key)=> {
       
      }

      const onSubmit = () => {
        var token = localStorage.getItem("token")
          if(debitTotal == creditTotal) {
              
                const exampleArray = []
                for(var i = 0 ; i< tableRow.length; i++) {
                    if(tableRow[i].jd_account_id_fk == "") {
                        // tableRow.splice(i, 1)
                    }
                    else{
                        exampleArray.push(tableRow[i])
                    }
                    
                }
                var paymentdate;
                var refdate1;
                if(editmode == true) {
                    paymentdate = registerDate
                    refdate1 = refdate
                }
                else{
                    paymentdate = registerDate
                    refdate1 = refdate
                }

                if(exampleArray.length == 2){
                    const data = {
                        "cnid": 0,
                        "vid": 0,
                        "cnno": invoice,
                        "cndate": registerDate,
                        "cnrefno": refno,
                        "cnnarration": remarks,
                        "cncomid": 1,
                        "cnbranchid": branchid,
                        "cncreatedby": global.userid,
                        "cnupdatedby": global.userid,
                        "cncreatedon": "",
                        "cnupdatedon": "",
                        "cnnonum": docnum,
                        "cnformid": billType,
                        "details": tableRow,
                        "printFile": "",
                        "amountsInWords": ""
                    }
                    console.log("data", data)
                    fetch(global.url + "api/Contra/SaveContra", {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        },
                        body: JSON.stringify(data),
                    })
                    .then((response)=> response.json())
                    .then((responseJson)=>{
                        console.log("response", responseJson)
                        if(responseJson.error == false) {
                            alert(responseJson.message)
                            window.location.reload();
                        }
                        else{
                            alert(responseJson.data)
                        }
                    })
                    .catch((error)=>{
                        console.log(error)
                    })

                }
                else{
                    alert("Only 2 entry possible")
                }

                
            }
            else{
                alert("debit and credit no equal")
            }
      }

      const listClickcode = (index, code, name, id) => {
        // var input = [...tableRow]
        // input[index].key = index
        // input[index].jd_code = code
        // input[index].jd_account_id_fk = id
        // input[index].account_name = name
        // setTableRow(input)
        // addRow()
        // setModal(false)
      }

      const modelOpen = (index) => {
            setSelectedindex(index)
            getSearch("")
            getAllaccountlist()
            setModal(true)
      }

      const getAllaccountlist = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Accounts/GetLedgerList?orderBy={orderBy}&companyId=1&branchId=" + branchid + "&includeBlocked=false", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("list response", responseJson)
              setAllaccountlist(responseJson)
          })
          .catch((error)=>{
              console.log(error)
          })
    }

      const billtypeChange = (event) => {
        setBilltype(event.target.value);
        getInvoiceno(event.target.value)
      };

      const getBilltype = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/BillSettings/GetBillTypeByForm?companyId=1&branchId=" + branchid + "&formType=CN", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("billSettigsList response", responseJson)
              setBilltypelist(responseJson.billSettigsList)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const tableselectedAccount1 = (code, name, id) => {
            var input = [...tableRow]
            input[selectedIndex].account = name
            input[selectedIndex].code = code
            input[selectedIndex].dtaccid = id
            setTableRow(input)
            setModal(false)
        }
    
        return (
                <div className="main-content">
                 
                    <Row>
                        <Col sm={6}>
                            <Row>
                                <Col xs={12}>
                                    <p style={{fontWeight: 'bold', fontSize: 20}}>Contra</p>
                                </Col>
                                <Col sm={2} style={{marginTop:30}}>
                                    <p className="label">Bill Type</p>
                                </Col>
                                <Col sm={4} style={{marginTop:30}}>
                                    <FormControl sx={{  width: '100%' }}>
                                            <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Bill Type</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={billType}
                                                label="Age"
                                                onChange={billtypeChange}
                                                size="small"
                                                
                                            >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {billtypelist.map((row, index) => {
                                                return (
                                                    <MenuItem value={row.btypeid}>{row.formname}</MenuItem>
                                                )}
                                            )}
                                            </Select>
                                    </FormControl>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={2} style={{marginTop:30}}>
                                    <p className="label">DOC NO</p>
                                </Col>
                                <Col sm={4} style={{marginTop:30}}>
                                    <FormControl sx={{width: '100%'}}>
                                        <TextField disabled value={docnum} id="outlined-basic" label="DOC NO" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>

                                <Col sm={2} style={{marginTop:30}}>
                                    <p className="label">Ref No</p>
                                </Col>
                                <Col sm={4} style={{marginTop:30}}>
                                    <FormControl sx={{width: '100%'}}>
                                        <TextField value={refno} onChange={(e) => setRefno(e.target.value)} id="outlined-basic" label="Ref No" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={2} style={{marginTop:20}}>
                                    <p className="label">Doc Date</p>
                                </Col>
                                <Col sm={4} style={{marginTop:20}}>
                                    {/* <FormControl sx={{  width: '100%' }}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                    size="small"
                                                        label="Date"
                                                        value={registerDate}
                                                        onChange={(newValue) => {
                                                            setRegisterdate(newValue);
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                            </LocalizationProvider>
                                    </FormControl> */}
                                    <FormControl sx={{ width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            value={registerDate}
                                            onChange={(newValue) => {
                                                setRegisterdate(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>

                                <Col sm={2} style={{marginTop:20}}>
                                    <p className="label">Ref Date</p>
                                </Col>
                                <Col sm={4} style={{marginTop:20}}>
                                    {/* <FormControl sx={{  width: '100%' }}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                        size="small"
                                                        label="Ref Date"
                                                        value={refdate}
                                                        onChange={(newValue) => {
                                                            setRefdate(newValue);
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                            </LocalizationProvider>
                                    </FormControl> */}
                                     <FormControl sx={{ width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            value={refdate}
                                            onChange={(newValue) => {
                                                setRefdate(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{marginTop: 20}}>
                        <Col xs={12}>
                            <Table bordered>
                                <thead>
                                    <tr>
                                        <th>SlNo</th>
                                        <th style={{width: '20%'}}>Code</th>
                                        <th style={{width: '30%'}}>Account</th>
                                        <th  style={{width: '10%'}}>Debit</th>
                                        <th  style={{width: '10%'}}>Credit</th>
                                        <th>Narration</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableRow.map((item, index) => {
                                        return (
                                            <tr>
                                                <td>{index+1}</td>
                                                <td onClick={() => modelOpen(index)}>{item.code}</td>
                                                <td>{item.account}</td>
                                                <td><input autocomplete="off"  type="number" disabled = {item.debitdisabled == true? true: false} value={item.dtdebit} onChange={(event) => debitChange(event, index)} /></td>
                                                <td><input autocomplete="off"  type="number" disabled = {item.creditdisabled == true? true: false} value={item.dtcredit} onChange={(event) => creditChange(event, index)} /></td>
                                                <td><input autocomplete="off"  type="text" value={item.dtnarration} onChange={(event) => narrationChange(event, index)} /></td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td colSpan={3}>Total</td>
                                        <td>{debitTotal}</td>
                                        <td>{creditTotal}</td>
                                        <td></td>
                                    </tr>
                                    
                                </tbody>
                            </Table>
                            <Button onClick={() => addRow()} variant="outlined">Add</Button>
                        </Col>
                    </Row>
                    <Row style={{marginTop: 20}}>
                        <Col xs={12}>
                            <TextareaAutosize
                                value={remarks} 
                                onChange={(e) => setRemarks(e.target.value)} 
                                minRows={3}
                                placeholder="Remarks"
                                style={{background: 'transparent', width: '100%'}}
                            />
                        </Col>
                    </Row>
                    <Row style={{marginTop: 20}}>
                        <Col xs={12} >
                            <ul className="list-inline">
                                <li><Button onClick={() => onSubmit()} variant="outlined"><IconName.BsDownload style={{marginRight: 10}}/>Save</Button></li>
                                <li><Button variant="outlined"><GI.GiBinoculars style={{marginRight: 10}}/>Find</Button></li>
                                <li><Button variant="outlined"><BI.BiPrinter style={{marginRight: 10}}/>Print</Button></li>
                                <li><Button variant="outlined"><RI.RiDeleteBin6Line style={{marginRight: 10}}/>Delete</Button></li>
                                <li><Button variant="outlined"><RI.RiCloseFill style={{marginRight: 10}}/>Close</Button></li>
                            </ul>
                        </Col>
                    </Row>

                    <Modal
                        size="lg"
                        show={modal}
                        onHide={() => setModal(false)}
                        aria-labelledby="example-modal-sizes-title-lg"
                        >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-lg">
                            Select Account
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col xs={12}>
                                    <ul>
                                    {allaccountlist.map((row, index) => {
                                        return (
                                            <a style={{textDecoration: 'none', listStyle: 'none'}} href="javascript:void(0)" onClick={() =>tableselectedAccount1(row.acccode, row.accname, row.accid, "", row.balance)}>
                                                <li className="pay-lists" style={{color: '#000', marginTop: 5, fontSize: 15, cursor: 'pointer'}}>{row.accname} - {row.accledger}</li>
                                            </a>
                                        )})
                                        }
                                    </ul>
                                </Col>
                                
                            </Row>
                        </Modal.Body>
                    </Modal>
                </div>
        );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(Contra);
