import React, {Component, useEffect, useState} from 'react';
import { Container, Row, Col, Button, Form  } from "react-bootstrap";
import { BsPersonFill, BsFillLockFill, BsFillUnlockFill } from "react-icons/bs";
import * as FaIcons from "react-icons/fa";
import { BiUser, BiNotepad, BiHomeAlt} from "react-icons/bi";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { MdPayment } from "react-icons/md";
import { BsReceiptCutoff } from "react-icons/bs";
import Tooltip from '@mui/material/Tooltip';
import { useLocation } from 'react-router-dom';
import { Route, useHistory } from 'react-router-dom';
import './sidebar.css';
import './rightsidebar.css';
import * as FiIcons from "react-icons/fi";


function Rightsidebar(props) {
    const [toggle,  setToggle] = useState(false)
    const location = useLocation();
    let history = useHistory();

    const showSidebar = (childData) => {
        setToggle(childData)
    };

    const logout = () => {
        // localStorage.removeItem("isAuthenticated")
        localStorage.clear()
        history.push('/')
    }
  return (
    <div className={toggle == true? "rightToggle" : ""} style={{marginLeft: 'auto', marginRight: 20}} to="#">
            <FiIcons.FiLogOut onClick={() => logout()} style={{color: '#000', cursor: 'pointer'}}/>
    </div>
  );
}

export default Rightsidebar;
