import React, {useEffect} from 'react'
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Form, Button} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { connect } from "react-redux";

function EditGrc({branchid}){

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();

    const [checkin, setCheckin] = React.useState(year + '-' + month + '-' + date +  'T' + hours + ":" + minutes)
    const [expected, setExpected] = React.useState(year + '-' + month + '-' + date +  'T' + hours + ":" + minutes)
    const [original, setOriginal] = React.useState(year + '-' + month + '-' + date +  'T' + hours + ":" + minutes)

    const [grc, setGrc] = React.useState('');
    const [roomno, setRoomno] = React.useState('');

    const [sourcelist, setSourcelist] = React.useState([])

    const [tariff, setTariff] = React.useState('');
    const [cgst, setCgst] = React.useState('');
    const [sgst, setSgst] = React.useState('');
    const [extrabed, setExtrabed] = React.useState('');
    const [extrabedcost, setExtrabedcost] = React.useState('');
    const [hrs, setHrs] = React.useState('');
    const [sourceid, setSourceid] = React.useState("")
    const [bookingid, setBookingid] = React.useState("")
    const [guest, setGuest] = React.useState()

    const handleGuestChange = (e) => {
        const inputValue = e.target.value;
        const integerPart = parseInt(inputValue.replace(/\D/g, ''), 10);
        if (!isNaN(integerPart)) {
          setGuest(integerPart.toString());
        } else {
          setGuest('');
        }
      };

    const hrsChange = (event) => {
        setHrs(event.target.value)
      }

      const handleChange = (event) => {
        setSourceid(event.target.value);
    };

    useEffect(() =>{
        getSourceList()
    }, [])

    const searchClick = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Booking/GetDataForEditGRC?branchId=" + branchid + "&bookingNo=" + grc + "&roomNo=" + roomno, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }, 
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("grc bills", responseJson)
             setTariff(responseJson.tariff)
             setCgst(responseJson.cgst)
             setSgst(responseJson.sgst)
             setExtrabed(responseJson.extrabed)
             setExtrabedcost(responseJson.extrabedcost)
             setHrs(responseJson.extratime)
             setSourceid(responseJson.sourceid)
             setCheckin(responseJson.arrival)
             setExpected(responseJson.departure)
             setOriginal(responseJson.departure)
             setBookingid(responseJson.bookingid)
             setGuest(responseJson.guestnumber)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getSourceList = () => {
        var token = localStorage.getItem("token")
        fetch(global.url + "api/Source/GetSourceList", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            setSourcelist(responseJson)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

    const updateClick = () => {
        var token = localStorage.getItem("token")
        const data = {
            "bookingid": bookingid,
            "roomid": "",
            "sourceid": sourceid,
            "tariff": tariff,
            "discountid": "",
            "discount": "",
            "extrabed": extrabed,
            "extrabedcost": extrabedcost,
            "bedcode": "",
            "earlycheckin": "",
            "sgst": sgst,
            "cgst": cgst,
            "igst": "",
            "adult": "",
            "children": "",
            "extratime": "",
            "arrival": checkin,
            "departure": original,
            "status": "",
            "customerid": "",
            "branchid": "",
            "advance": "",
            "notes": "",
            "formid": "",
            "docno": "",
            "docnum": "",
            "createdby": "",
            "createdon": "",
            "updatedby": "",
            "updatedon": "",
            "paymode": "",
            "refno": "",
            "payhours": "",
            "vid": "",
            "guestid": "",
            "billno": "",
            "billnonum": "",
            "reversecharge": "",
            "billdate": "",
            "customerDetails": {
              "$id": "",
              "customerid": "",
              "customername": "",
              "addresS1": "",
              "addresS2": "",
              "addresS3": "",
              "state": "",
              "district": "",
              "phone": "",
              "mobile": "",
              "email": "",
              "gstin": "",
              "other": "",
              "panno": "",
              "proofid": "",
              "proofnumber": "",
              "purpose": "",
              "nationality": "",
              "status": "",
              "gender": "",
              "age": "",
              "proof": "",
              "proofurl": ""
            },
            "roomDetails": [
              {
                "$id": "",
                "roomid": "",
                "roomname": "",
                "floorid": "",
                "branchid": "",
                "roomstatus": "",
                "roomtypeid": "",
                "roomno": "",
                "tariffid": "",
                "currentvid": "",
                "salesvid": "",
                "roomtype": "",
                "floor": "",
                "tarifftype": ""
              },
              {
                "$ref": ""
              }
            ],
            "billDetails": [
              {
                "$id": "",
                "bookedroomid": "",
                "vid": "",
                "roomid": "",
                "roomguest": "",
                "roomrate": "",
                "sgst": "",
                "cgst": "",
                "noofdays": "",
                "startdate": "",
                "enddate": "",
                "description": "s",
                "total": ""
              },
              {
                "$ref": ""
              }
            ],
            "amountsInWords": "",
            "createdUser": "",
            "taxDetails": {
              "$id": "",
              "hsn": "",
              "tariff": "",
              "sgst": "",
              "cgst": "",
              "sales": "",
              "total": ""
            },
            "branchDetails": {
              "$id": "",
              "brid": "",
              "brname": "",
              "braddresS1": "",
              "braddresS2": "",
              "braddresS3": "",
              "brcntid": "",
              "brstid": "",
              "brgstin": "",
              "brmobile": "",
              "brmemail": "",
              "brwebsite": "",
              "brwts": "",
              "brprfx": "",
              "brlact": "",
              "brlong": "",
              "brcode": "",
              "brcaption": "",
              "brcomid": "",
              "brblocked": "",
              "brcashid": "",
              "brdistid": ""
            },
            "total": "",
            "roomno": "",
            "actualtariff": "",
            "guestnumber" : parseInt(guest),
            "currentRoomNumber": roomno,
           
          }
        fetch(global.url + "api/Booking/EditGrc", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),  
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("update Response", responseJson)  
             alert(responseJson.message) 
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold'}}>Edit GRC</p> 
                <Row>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 3, width: '100%' }}>
                            <TextField id="outlined-basic" value={grc} onChange={(e) => setGrc(e.target.value)} label="GRC*" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3}>
                        <FormControl sx={{ mt: 3, width: '100%' }}>
                            <TextField id="outlined-basic" value={roomno} onChange={(e) => setRoomno(e.target.value)} label="Room No.*" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3}>
                        <Button onClick={searchClick} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 25, width: '100%'}} variant="contained">Search</Button>
                    </Col>
                </Row>
                <div style={{marginTop: 60}}>
                    <Row>
                        <Col xs={12} md={3}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <TextField id="outlined-basic" value={tariff} onChange={(e) => setTariff(e.target.value)} label="Tariff" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={3}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <TextField id="outlined-basic" value={cgst} onChange={(e) => setCgst(e.target.value)} label="CGST" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={3}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <TextField id="outlined-basic" value={sgst} onChange={(e) => setSgst(e.target.value)} label="SGST" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={3}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <TextField id="outlined-basic" value={extrabed} onChange={(e) => setExtrabed(e.target.value)} label="Extra bed" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={3}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                <TextField id="outlined-basic" value={extrabedcost} onChange={(e) => setExtrabedcost(e.target.value)} label="Extra Bed Cost" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={3}>
                            <FormControl fullWidth sx={{ mt: 3, width: '100%' }}>
                                <InputLabel id="demo-simple-select-label">Hrs</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={hrs}
                                label="Age"
                                onChange={hrsChange}
                                size="small"
                                >
                                <MenuItem value={12}>12</MenuItem>
                                <MenuItem value={24}>24</MenuItem>
                                </Select>
                            </FormControl>
                            
                        </Col>
                        <Col xs={12} md={3}>
                            <FormControl fullWidth sx={{ mt: 3, width: '100%' }}>
                                <InputLabel id="demo-simple-select-label">Source</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={sourceid}
                                label="Age"
                                onChange={handleChange}
                                size="small"
                                >
                                    {
                                        sourcelist.map((i, k) => {
                                            return(
                                                <MenuItem value={i.sourceid}>{i.sourcename}</MenuItem>
                                            )
                                        
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Col>
                       
                    </Row>
                    <Row style={{marginTop: 20}}>
                        <Col xs={12} md={3}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField
                                    id="datetime-local"
                                    label="Check in"
                                    type="datetime-local"
                                    defaultValue={checkin}
                                    sx={{ width: 250 }}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                    value={checkin}
                                    onChange={(newValue) => {
                                        setCheckin(newValue.target.value);
                                    }}
                                />
                            </FormControl>
                        </Col>
                        <Col xs={12} md={3}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField
                                    id="datetime-local"
                                    label="Expected Checkout"
                                    type="datetime-local"
                                    defaultValue={expected}
                                    sx={{ width: 250 }}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                    value={expected}
                                    onChange={(newValue) => {
                                        setExpected(newValue.target.value);
                                    }}
                                />
                            </FormControl>
                        </Col>
                        <Col xs={12} md={3}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField
                                    id="datetime-local"
                                    label="Original Checkout"
                                    type="datetime-local"
                                    defaultValue={original}
                                    sx={{ width: 250 }}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                    value={original}
                                    onChange={(newValue) => {
                                        setOriginal(newValue.target.value);
                                    }}
                                />
                            </FormControl>
                        </Col>
                         <Col xs={12} md={3}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                <TextField id="outlined-basic" value={guest} onChange={handleGuestChange} label="Guest No." variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={3}>
                            <Button onClick={updateClick} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 25, width: '100%'}} variant="contained">Update</Button>
                        </Col>
                    </Row>
                </div>
            </Paper>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetail: state.shop.branchdetail,
    };
};

export default connect(mapStateToProps)(EditGrc);