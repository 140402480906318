import React, {useState, useEffect} from 'react'
import Paper from '@mui/material/Paper';
import {Container, Row, Col, Button} from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { connect } from "react-redux";

const columns = [
    { id: '1', label: 'Voc No.', minWidth: 50,span: false },
    { id: '2', label: 'Date', minWidth: 50,span: false },
    { id: '3', label: 'Rec/Pay', minWidth: 50,span: false },
    { id: '4', label: 'Closed By', minWidth: 50,span: false },
    { id: '5', label: 'Amount', minWidth: 50,span: false },
   
  ];

function FrontOffice({branchid}){

    const [source, setSource] = React.useState([])
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };

    
      var today = new Date();
      var year = today.getFullYear();
      var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
      var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
      var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
      var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();
  
      const [date1, setDate1] = React.useState(year + '-' + month + '-' + date);
      const [date2, setDate2] = React.useState(year + '-' + month + '-' + date);

    
      useEffect(() => {
        getData()
    }, [branchid]);

    const getData = () => {
        var token = localStorage.getItem("token")
        var data = {
            "fromdate" : date1,
            "todate" : date2,
            "branchid" : branchid
        }
        console.log("GetNewLodgeDayClose", data)
        fetch(global.url + "api/Booking/GetLodgeDayCloseHistory", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
             console.log("GetNewLodgeDayClose Response", responseJson)
             setSource(responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const formatDate = (datestring, type) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();

          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
          );
          

          if(type == "time") {
            var formattedDate =  day+"/"+month+"/"+year + " " + timeString12hr;
          }
          else{
            var formattedDate =  day+"/"+month+"/"+year
          }

          
          return formattedDate; //03/02/2021
    }

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                <p style={{fontSize: 25, fontWeight: 'bold', textAlign: 'center'}}>Front Office Day Close History</p> 
                <Row>
                    <Col xs={12}  className='columnborder'>
                        <div>
                            <Row>
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="From Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setDate1(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={3}>
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField
                                            id="datetime-local"
                                            label="To Date"
                                            type="date"
                                            defaultValue={year + '-' + month + '-' + date}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={(newValue) => {
                                                setDate2(newValue.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={2}>
                                    <div style={{justifyContent: 'center', display: 'flex'}}>
                                        <Button onClick={getData} style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 17, width: '50%'}} variant="contained">Search</Button>
                                    </div>
                                </Col>
                                {/* <Col xs={12} md={2}>
                                    <div style={{ justifyContent: 'center', display: 'flex'}}>
                                        <Button style={{backgroundColor: '#03a5fc', color: '#fff', marginTop: 17, width: '50%'}} variant="contained">print</Button>
                                    </div>
                                </Col> */}
                            </Row>
                           
                        </div>
                    </Col>
                    
                </Row>
                <div style={{height: 2, width: '100%', backgroundColor: '#d3d3d3', marginTop: 30}}></div>
                <div className='bulk-table'>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                                    colSpan={column.span == true ? 2: 1}
                                >
                                    {column.label}
                                    
                                </TableCell>
                                ))}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {source
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                return (
                                    <TableRow  tabIndex={-1} key={row.code}>
                                        
                                        <TableCell>
                                        {row.voucherno}
                                        </TableCell>
                                        <TableCell>
                                        {formatDate(row.voucherdate, "date")}
                                        </TableCell>
                                        <TableCell>
                                        {row.vouchertype}
                                        </TableCell>
                                        <TableCell>
                                        {row.username}
                                        </TableCell>
                                        <TableCell style={{textAlign: 'center'}}>
                                        {row.amount.toFixed(2)}
                                        </TableCell>
                                        {/* <TableCell >
                                            <a href="javascript:void(0)">
                                                <FaIcons.FaEdit fontSize={20} color="blue" />
                                            </a>
                                        </TableCell> */}
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
                        </TableContainer>
                        <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={source.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
            </Paper>
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        branchid: state.shop.branchid,
        branchdetails: state.shop.branchdetails,
    };
};

export default connect(mapStateToProps)(FrontOffice);